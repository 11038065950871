import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
//import "./inscripcionhojasestilocol.css";
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { ApiUrl } from '../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as xlsx from "xlsx";
import generatePDF from "./reportGeneratorHistoriaClinica";
import generatePDFEC from "./reportGeneratorEvolClinica";

const cookie = new Cookies();
const urlListaPacientes = ApiUrl + "consultarpacientes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urleliminarPaciente = ApiUrl + "eliminardatospaciente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsDatosHClinica = ApiUrl + "insertardatoshclinica" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListDatosHClinica = ApiUrl + "consultarhistoriapaciente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListDatosEClinica = ApiUrl + "consultarevolucionclinica" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsDatosEClinica = ApiUrl + "insertardatoseclinica" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urleliminarEClinica = ApiUrl + "eliminarevolucionclinica" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const datosHistoriaClinica = ApiUrl + "datoshistoriaclipdf" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 1;

export default class ReporteHistoriaClinica extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            listadmin: [],
            listareas: [],
            ruta: "",
            pagina: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            justClicked: null,
            listpacientes: [],
            listhclinica: [],
            listevoluciones: [],
            nombrepaciente: "",
            form1: {
            },
            passwordmod: null,
            disableForm: true,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

  
    generarPdfEClinica = (registro) => {
        generatePDFEC(this.state.nombrepaciente, JSON.stringify(registro));
    }
      generarPdfHClinica = () => {

                  /*  alert("Hola");
                  
          alert(this.state.nombrepaciente);
          alert(this.state.listhclinica);
          const myObjStr = JSON.stringify(this.state.listhclinica);*/

        axios
            .post(datosHistoriaClinica, {//guarda para enviar los atributos y envia
                idPaciente: this.state.id_paciente, 
            })
            .then(response => {
                if (response.data.data != null) {
                  //  alert("hola si hay respuesta");
                   generatePDF(response.data.data);
                }
                else {
                    this.setState({ estado: "No existe datos para mostrar" });
                    swal({
                        text: "No existe datos para mostrar",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })

    }



    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalEvolucion: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }


    handleChangePaciente = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        await this.setState({ nombrepaciente: option })
    }

    ocultarModalEvolucion = () => {
        this.setState({ modalEvolucion: false });
    }

    componentDidMount() {
        this.setState({ id: 0 });
        axios.get(urlListaPacientes)
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                this.setState({ listpacientes: respuesta.data.data });
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listpacientes
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNombres = item.nombres_paciente.toUpperCase()
            const itemDataCedula = item.cedula_paciente.toUpperCase()
            const campo = itemDataNombres + " " + itemDataCedula;
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }


    handleChangeEvolucionClinica = (event) => {
        axios
            .post(urlListDatosEClinica, {
                id_hclinica: this.state.id_hclinica,
            })
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                this.setState({ listevoluciones: respuesta.data.data });
                if (this.state.pageCount == 1) {
                    this.setState({ itemOffset: 0 });
                }
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                this.setState((state) => {
                    return { itemOffset: newOffset }
                });
                const endOffset = newOffset + itemsPerPage;
                //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState({ itemOffset: newOffset });
                //alert("Count:"+this.state.pageCount)
                //alert(this.state.itemOffset);
                //FIN DE LA PAGINACION
            })
        this.setState({ modalEvolucion: true });
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    handleChangeHistoriaClinica = (event) => {
        axios
            .post(urlListDatosHClinica, {
                id_paciente: this.state.id_paciente,
            })
            .then(response => {
                if (Object.keys(response.data.data).length != 0) {
                    this.setState({ listhclinica: response.data.data });
                    this.state.listhclinica.map((person) => {
                        $(function () {
                            $("#id_hclinica").val(person.id_historia_clinica);
                            $("#motivo").val(person.motivo_historia_clinica);
                            $("#henfermedad").val(person.henfermedad_historia_clinica);
                            $("#antpatolpers").val(person.antpatolpers_historia_clinica);
                            $("#antpatolfami").val(person.antpatolfami_historia_clinica);
                            $("#hpsicosocial").val(person.hpsicosocial_historia_clinica);
                            $("#gobstetrica").val(person.gobstetrica_historia_clinica);
                            $("#habgenerales").val(person.habgenerales_historia_clinica);
                            $("#habnutricio").val(person.habnutricio_historia_clinica);
                            $("#biotipo").val(person.biotipo_historia_clinica);
                            $("#facies").val(person.facies_historia_clinica);
                            $("#actypostura").val(person.actypostura_historia_clinica);
                            $("#pielymucosa").val(person.pielymucosa_historia_clinica);
                            $("#peloyunias").val(person.peloyunias_historia_clinica);
                            $("#tcs").val(person.tcs_historia_clinica);
                            $("#fc").val(person.fc_historia_clinica);
                            $("#fr").val(person.fr_historia_clinica);
                            $("#ta").val(person.ta_historia_clinica);
                            $("#t").val(person.t_historia_clinica);
                            $("#sato2").val(person.sato2_historia_clinica);
                            $("#peso").val(person.peso_historia_clinica);
                            $("#talla").val(person.talla_historia_clinica);
                            $("#imc").val(person.imc_historia_clinica);
                            $("#contextura").val(person.contextura_historia_clinica);
                            $("#pa").val(person.pa_historia_clinica);
                            $("#porcgrasa").val(person.porcgrasa_historia_clinica);
                            $("#porcmusculo").val(person.porcmusculo_historia_clinica);
                            $("#porcagua").val(person.porcagua_historia_clinica);
                            $("#grasavisceral").val(person.grasavisceral_historia_clinica);
                            $("#tmb").val(person.tmb_historia_clinica);
                            $("#red").val(person.red_historia_clinica);
                            $("#pesoideal").val(person.pesoideal_historia_clinica);
                            $("#scardiorres").val(person.scardiorres_historia_clinica);
                            $("#sdigestivo").val(person.sdigestivo_historia_clinica);
                            $("#soma").val(person.soma_historia_clinica);
                            $("#snervioso").val(person.snervioso_historia_clinica);
                            $("#datexamcompl").val(person.datexamcompl_historia_clinica);
                            $("#valnaturopatica").val(person.valnaturopatica_historia_clinica);
                            $("#diagpresnatu").val(person.diagpresnatu_historia_clinica);
                            $("#diagdefinitivo").val(person.diagdefinitivo_historia_clinica);
                            $("#planterapeutico").val(person.planterapeutico_historia_clinica);
                        });

                        this.setState({ id_hclinica: person.id_historia_clinica });
                        this.setState({ motivo: person.motivo_historia_clinica });
                        this.setState({ henfermedad: person.henfermedad_historia_clinica });
                        this.setState({ antpatolpers: person.antpatolpers_historia_clinica });
                        this.setState({ antpatolfami: person.antpatolfami_historia_clinica });
                        this.setState({ hpsicosocial: person.hpsicosocial_historia_clinica });
                        this.setState({ gobstetrica: person.gobstetrica_historia_clinica });
                        this.setState({ habgenerales: person.habgenerales_historia_clinica });
                        this.setState({ habnutricio: person.habnutricio_historia_clinica });
                        this.setState({ biotipo: person.biotipo_historia_clinica });
                        this.setState({ facies: person.facies_historia_clinica });
                        this.setState({ actypostura: person.actypostura_historia_clinica });
                        this.setState({ pielymucosa: person.pielymucosa_historia_clinica });
                        this.setState({ peloyunias: person.peloyunias_historia_clinica });
                        this.setState({ tcs: person.tcs_historia_clinica });
                        this.setState({ fc: person.fc_historia_clinica });
                        this.setState({ fr: person.fr_historia_clinica });
                        this.setState({ ta: person.ta_historia_clinica });
                        this.setState({ t: person.t_historia_clinica });
                        this.setState({ sato2: person.sato2_historia_clinica });
                        this.setState({ peso: person.peso_historia_clinica });
                        this.setState({ talla: person.talla_historia_clinica });
                        this.setState({ imc: person.imc_historia_clinica });
                        this.setState({ contextura: person.contextura_historia_clinica });
                        this.setState({ pa: person.pa_historia_clinica });
                        this.setState({ porcgrasa: person.porcgrasa_historia_clinica });
                        this.setState({ porcmusculo: person.porcmusculo_historia_clinica });
                        this.setState({ porcagua: person.porcagua_historia_clinica });
                        this.setState({ grasavisceral: person.grasavisceral_historia_clinica });
                        this.setState({ tmb: person.tmb_historia_clinica });
                        this.setState({ red: person.red_historia_clinica });
                        this.setState({ pesoideal: person.pesoideal_historia_clinica });
                        this.setState({ scardiorres: person.scardiorres_historia_clinica });
                        this.setState({ sdigestivo: person.sdigestivo_historia_clinica });
                        this.setState({ snervioso: person.snervioso_historia_clinica });
                        this.setState({ datexamcompl: person.datexamcompl_historia_clinica });
                        this.setState({ valnaturopatica: person.valnaturopatica_historia_clinica });
                        this.setState({ diagpresnatu: person.diagpresnatu_historia_clinica });
                        this.setState({ diagdefinitivo: person.diagdefinitivo_historia_clinica });
                        this.setState({ planterapeutico: person.planterapeutico_historia_clinica });
                    });
                } else {
                    $(function () {
                        $("#id_hclinica").val(0);
                        $("#motivo").val("");
                        $("#henfermedad").val("");
                        $("#antpatolpers").val("");
                        $("#antpatolfami").val("");
                        $("#hpsicosocial").val("");
                        $("#gobstetrica").val("");
                        $("#habgenerales").val("");
                        $("#habnutricio").val("");
                        $("#biotipo").val("");
                        $("#facies").val("");
                        $("#actypostura").val("");
                        $("#pielymucosa").val("");
                        $("#peloyunias").val("");
                        $("#tcs").val("");
                        $("#fc").val("");
                        $("#fr").val("");
                        $("#ta").val("");
                        $("#t").val("");
                        $("#sato2").val("");
                        $("#peso").val("");
                        $("#talla").val("");
                        $("#imc").val("");
                        $("#contextura").val("");
                        $("#pa").val("");
                        $("#porcgrasa").val("");
                        $("#porcmusculo").val("");
                        $("#porcagua").val("");
                        $("#grasavisceral").val("");
                        $("#tmb").val("");
                        $("#red").val("");
                        $("#pesoideal").val("");
                        $("#scardiorres").val("");
                        $("#sdigestivo").val("");
                        $("#soma").val("");
                        $("#snervioso").val("");
                        $("#datexamcompl").val("");
                        $("#valnaturopatica").val("");
                        $("#diagpresnatu").val("");
                        $("#diagdefinitivo").val("");
                        $("#planterapeutico").val("");
                    });
                    this.setState({ id_hclinica: 0 });
                    this.setState({ motivo: "" });
                    this.setState({ henfermedad: "" });
                    this.setState({ antpatolpers: "" });
                    this.setState({ antpatolfami: "" });
                    this.setState({ hpsicosocial: "" });
                    this.setState({ gobstetrica: "" });
                    this.setState({ habgenerales: "" });
                    this.setState({ habnutricio: "" });
                    this.setState({ biotipo: "" });
                    this.setState({ facies: "" });
                    this.setState({ actypostura: "" });
                    this.setState({ pielymucosa: "" });
                    this.setState({ peloyunias: "" });
                    this.setState({ tcs: "" });
                    this.setState({ fc: "" });
                    this.setState({ fr: "" });
                    this.setState({ ta: "" });
                    this.setState({ t: "" });
                    this.setState({ sato2: "" });
                    this.setState({ peso: "" });
                    this.setState({ talla: "" });
                    this.setState({ imc: "" });
                    this.setState({ contextura: "" });
                    this.setState({ pa: "" });
                    this.setState({ porcgrasa: "" });
                    this.setState({ porcmusculo: "" });
                    this.setState({ porcagua: "" });
                    this.setState({ grasavisceral: "" });
                    this.setState({ tmb: "" });
                    this.setState({ red: "" });
                    this.setState({ pesoideal: "" });
                    this.setState({ scardiorres: "" });
                    this.setState({ sdigestivo: "" });
                    this.setState({ snervioso: "" });
                    this.setState({ datexamcompl: "" });
                    this.setState({ valnaturopatica: "" });
                    this.setState({ diagpresnatu: "" });
                    this.setState({ diagdefinitivo: "" });
                    this.setState({ planterapeutico: "" });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                //window.location.reload(true);
            })
        this.setState({ disableForm: false });
    }
    //FIN DE PAGINACION


    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalModUsuario: true });
    }

    registrarDatosEvolucion = () => {
        let formularioValido = false;
        let fechaevol = this.state.fechaevol;
        let terapeuta = this.state.terapeuta;
        let evolucion = this.state.evolucion;
        let indicaciones = this.state.indicaciones;

        if (!fechaevol || !terapeuta || !evolucion || !indicaciones) {
            this.setState({ estado: "Existen campos vacios" });
            swal({
                text: "Existen campos vacios",
                icon: "error",
                button: "Aceptar",

            });
        } else {
            axios
                .post(urlInsDatosEClinica, {
                    fechaevol: fechaevol,
                    terapeuta: terapeuta,
                    evolucion: evolucion,
                    indicaciones: indicaciones,
                    id_hclinica: this.state.id_hclinica,
                })
                .then(response => {
                    if (response.data.success == true) {

                        this.setState({ estado: "Datos registrados correctamente" });
                        swal({
                            text: "Datos registrados correctamente",
                            icon: "success",
                            button: "Aceptar",

                        });
                        axios
                            .post(urlListDatosEClinica, {
                                id_hclinica: this.state.id_hclinica,
                            })
                            .then(respuesta => {
                                this.setState({ fechaevol: "" });
                                this.setState({ terapeuta: "" });
                                this.setState({ evolucion: "" });
                                this.setState({ indicaciones: "" });
                                $(function () {
                                    $("#fechaevol").val("");
                                    $("#terapeuta").val("");
                                    $("#evolucion").val("");
                                    $("#indicaciones").val("");
                                });
                                listaauxiliar = respuesta.data.data;
                                this.setState({ listevoluciones: respuesta.data.data });
                                if (this.state.pageCount == 1) {
                                    this.setState({ itemOffset: 0 });
                                }
                                const estud = respuesta.data.data;
                                this.setState((state) => {
                                    return { estud: estud }
                                }
                                );
                                const EstudianteBackup = estud;

                                this.setState((state) => {
                                    return { EstudianteBackup: EstudianteBackup }
                                });
                                //PAGINACION
                                const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                this.setState((state) => {
                                    return { itemOffset: newOffset }
                                });
                                const endOffset = newOffset + itemsPerPage;
                                //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState({ itemOffset: newOffset });
                                //alert("Count:"+this.state.pageCount)
                                //alert(this.state.itemOffset);
                                //FIN DE LA PAGINACION
                            })
                    }
                })
                .catch(error => {
                    console.log(error);

                    this.setState({ estado: "Error No se pudo conectar con el servidor" });
                    //window.location.reload(true);
                })
        }
    }

   

    eliminarEvolucion = (id_eclinica) => {
        var combmsmalert = "¿Está seguro que desea eliminar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urleliminarEClinica, {
                            id_eclinica: id_eclinica,
                        })
                        .then(response => {
                            if (response.data.success == true) {

                                this.setState({ estado: "Datos eliminados correctamente" });
                                swal({
                                    text: "Datos eliminados correctamente",
                                    icon: "success",
                                    button: "Aceptar",

                                });
                                axios
                                    .post(urlListDatosEClinica, {
                                        id_hclinica: this.state.id_hclinica,
                                    })
                                    .then(respuesta => {
                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ listevoluciones: respuesta.data.data });
                                        if (this.state.pageCount == 1) {
                                            this.setState({ itemOffset: 0 });
                                        }
                                        const estud = respuesta.data.data;
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                        this.setState((state) => {
                                            return { itemOffset: newOffset }
                                        });
                                        const endOffset = newOffset + itemsPerPage;
                                        //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState({ itemOffset: newOffset });
                                        //alert("Count:"+this.state.pageCount)
                                        //alert(this.state.itemOffset);
                                        //FIN DE LA PAGINACION
                                    })
                            }
                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            //window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    //Ingresar y modificar los datos de la historia clínica
    registrarDatosHistoriaPaciente = () => {

        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlInsDatosHClinica, {
                            id: this.state.id_hclinica,
                            id_paciente: this.state.id_paciente,
                            motivo: this.state.motivo,
                            henfermedad: this.state.henfermedad,
                            antpatolpers: this.state.antpatolpers,
                            antpatolfami: this.state.antpatolfami,
                            hpsicosocial: this.state.hpsicosocial,
                            gobstetrica: this.state.gobstetrica,
                            habgenerales: this.state.habgenerales,
                            habnutricio: this.state.habnutricio,
                            biotipo: this.state.biotipo,
                            facies: this.state.facies,
                            actypostura: this.state.actypostura,
                            pielymucosa: this.state.pielymucosa,
                            peloyunias: this.state.peloyunias,
                            tcs: this.state.tcs,
                            fc: this.state.fc,
                            fr: this.state.fr,
                            ta: this.state.ta,
                            t: this.state.t,
                            sato2: this.state.sato2,
                            peso: this.state.peso,
                            talla: this.state.talla,
                            imc: this.state.imc,
                            contextura: this.state.contextura,
                            pa: this.state.pa,
                            porcgrasa: this.state.porcgrasa,
                            porcmusculo: this.state.porcmusculo,
                            porcagua: this.state.porcagua,
                            grasavisceral: this.state.grasavisceral,
                            tmb: this.state.tmb,
                            red: this.state.red,
                            pesoideal: this.state.pesoideal,
                            scardiorres: this.state.scardiorres,
                            sdigestivo: this.state.sdigestivo,
                            soma: this.state.soma,
                            snervioso: this.state.snervioso,
                            datexamcompl: this.state.datexamcompl,
                            valnaturopatica: this.state.valnaturopatica,
                            diagpresnatu: this.state.diagpresnatu,
                            diagdefinitivo: this.state.diagdefinitivo,
                            planterapeutico: this.state.planterapeutico,
                        })
                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {

                                this.setState({ estado: "Datos registrados correctamente" });
                                swal({
                                    text: "Datos registrados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });

                                this.setState({ id_hclinica: response.data.data });
                            }
                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            //window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });

    }

    mayusNombre = async (e) => {
        $("#nombres").val(e.target.value.toUpperCase());
        this.setState({ nombres: e.target.value.toUpperCase() })
    }

    mayusOcupacion = async (e) => {
        $("#ocupacion").val(e.target.value.toUpperCase());
        this.setState({ ocupacion: e.target.value.toUpperCase() })
    }

    mayusReligion = async (e) => {
        $("#religion").val(e.target.value.toUpperCase());
        this.setState({ religion: e.target.value.toUpperCase() })
    }

    mayusNombrePersRef = async (e) => {
        $("#persreferencia").val(e.target.value.toUpperCase());
        this.setState({ persreferencia: e.target.value.toUpperCase() })
    }

    mayusNombreTerapeuta = async (e) => {
        $("#terapeuta").val(e.target.value.toUpperCase());
        this.setState({ terapeuta: e.target.value.toUpperCase() })
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>
                            -Reporte de Pacientes
                        </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-6 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <select className="form-select" id="id_paciente" name="id_paciente" value={this.state.value} onChange={this.handleChangePaciente} aria-label="Default select example" required>
                                        <option value="" selected disabled>Seleccione el paciente</option>
                                        {this.state.listpacientes.map(person => <option key={person.id_paciente} data-nombre={person.nombres_paciente} value={person.id_paciente}  > {person.nombres_paciente}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-1 col-xl-1 centrar" >
                                <button class="btn back-istmas centrar" onClick={() => this.handleChangeHistoriaClinica()} > <i className=" fas fa-search" ></i></button>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-2 col-xl-2 centrar" >
                                <button class="btn back-istmas centrar" onClick={() => this.handleChangeEvolucionClinica()} > <i className=" fas fa-address-card" > </i> Evolución</button>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-4 col-xl-4 centrar" >

                            </div>
                        </div>
                        <div className="row" hidden={this.state.disableForm}>
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card border-dark text-center ">
                                    <div className="card-header ">
                                        <b>HISTORIA CLÍNICA DE PACIENTES</b>
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div className="row ">
                                            <input type="hidden" name="id_hclinica" class="id_hclinica" id="id_hclinica" value="" />
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">ANAMNESIS</label></b>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Motivo de consulta:</label>
                                                <textarea type="text" id="motivo" name="motivo" onChange={this.handleChange} className="form-control" placeholder="Ingrese el motivo de consulta" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Historia de la enfermedad actual:</label>
                                                <textarea type="text" id="henfermedad" name="henfermedad" onChange={this.handleChange} className="form-control" placeholder="Ingrese la historia de la enfermedad actual" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Antecedentes patológicos personales:</label>
                                                <textarea type="text" id="antpatolpers" name="antpatolpers" onChange={this.handleChange} className="form-control" placeholder="Enfermedades, accidentes, cirugías, alergias" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Antecedentes patológicos familiares:</label>
                                                <textarea type="text" id="antpatolfami" name="antpatolfami" onChange={this.handleChange} className="form-control" placeholder="Ingrese los antecedentes patológicos familiares" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Historia psicosocial:</label>
                                                <textarea type="text" id="hpsicosocial" name="hpsicosocial" onChange={this.handleChange} className="form-control" placeholder="Emocionales, factores de riesgo para enfermedades en el ambiente familiar y socio laboral" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Gineco-obstétrica:</label>
                                                <textarea type="text" id="gobstetrica" name="gobstetrica" onChange={this.handleChange} className="form-control" placeholder="Ingrese información Gineco-obstétrica" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Hábitos generales:</label>
                                                <textarea type="text" id="habgenerales" name="habgenerales" onChange={this.handleChange} className="form-control" placeholder="Sueño, miccional, defecatorio, ejercicio, tabaco, licor, -otros- medicinas, terapias" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Hábitos nutricionales:</label>
                                                <textarea type="text" id="habnutricio" name="habnutricio" onChange={this.handleChange} className="form-control" placeholder="Variedad, cantidad, frecuencia, hidratación, otros" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                                <b><label className="form-label">EXAMEN FÍSICO GENERAL</label></b>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Biotipo:</label>
                                                <textarea type="text" id="biotipo" name="biotipo" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Facies:</label>
                                                <textarea type="text" id="facies" name="facies" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Actitud y postura:</label>
                                                <textarea type="text" id="actypostura" name="actypostura" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Piel y mucosa:</label>
                                                <textarea type="text" id="pielymucosa" name="pielymucosa" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Pelo y uñas:</label>
                                                <textarea type="text" id="peloyunias" name="peloyunias" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Tejido celular subcutáneo :</label>
                                                <textarea type="text" id="tcs" name="tcs" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                                <b><label className="form-label">Signos vitales:</label></b>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">F.C.:</label>
                                                <input type="text" id="fc" name="fc" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">F.R.:</label>
                                                <input type="text" id="fr" name="fr" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">T.A.:</label>
                                                <input type="text" id="ta" name="ta" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">T°:</label>
                                                <input type="text" id="t" name="t" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Sat.O2:</label>
                                                <input type="text" id="sato2" name="sato2" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                                <b><label className="form-label">Composición corporal:</label></b>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Peso:</label>
                                                <input type="text" id="peso" name="peso" onChange={this.handleChange} className="form-control" placeholder="Kilogramos" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Talla:</label>
                                                <input type="text" id="talla" name="talla" onChange={this.handleChange} className="form-control" placeholder="Metros" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">IMC:</label>
                                                <input type="text" id="imc" name="imc" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Contextura:</label>
                                                <input type="text" id="contextura" name="contextura" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2" style={{ textAlign: 'left' }}>
                                                <label className="form-label">P.A.:</label>
                                                <input type="text" id="pa" name="pa" onChange={this.handleChange} className="form-control" placeholder="Ingrese la informacion" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">% de grasa:</label>
                                                <input type="text" id="porcgrasa" name="porcgrasa" onChange={this.handleChange} className="form-control" placeholder="Grasa" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">% de músculo:</label>
                                                <input type="text" id="porcmusculo" name="porcmusculo" onChange={this.handleChange} className="form-control" placeholder="Musculo" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">% de agua:</label>
                                                <input type="text" id="porcagua" name="porcagua" onChange={this.handleChange} className="form-control" placeholder="Agua" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Grasa visceral:</label>
                                                <input type="text" id="grasavisceral" name="grasavisceral" onChange={this.handleChange} className="form-control" placeholder="Visceral" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-4 col-xl-4" style={{ textAlign: 'left' }}>
                                                <label className="form-label">TMB:</label>
                                                <input type="text" id="tmb" name="tmb" onChange={this.handleChange} className="form-control" placeholder="TMB" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-4 col-xl-4" style={{ textAlign: 'left' }}>
                                                <label className="form-label">RED:</label>
                                                <input type="text" id="red" name="red" onChange={this.handleChange} className="form-control" placeholder="RED" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-4 col-xl-4" style={{ textAlign: 'left' }}>
                                                <label className="form-label">Peso ideal:</label>
                                                <input type="text" id="pesoideal" name="pesoideal" onChange={this.handleChange} className="form-control" placeholder="Peso Ideal" required />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                                <b><label className="form-label">EXAMEN FÍSICO POR SISTEMAS:</label></b>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">S. Cardiorrespiratorio:</label>
                                                <textarea type="text" id="scardiorres" name="scardiorres" onChange={this.handleChange} className="form-control" placeholder="Cardiorrespiratorio" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">S. Digestivo:</label>
                                                <textarea type="text" id="sdigestivo" name="sdigestivo" onChange={this.handleChange} className="form-control" placeholder="Digestivo" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">SOMA:</label>
                                                <textarea type="text" id="soma" name="soma" onChange={this.handleChange} className="form-control" placeholder="SOMA" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6" style={{ textAlign: 'left' }}>
                                                <label className="form-label">S. Nervioso:</label>
                                                <textarea type="text" id="snervioso" name="snervioso" onChange={this.handleChange} className="form-control" placeholder="Nervioso" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <br />
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">DATOS DE EXÁMENES COMPLEMENTARIOS:</label></b>
                                                <textarea type="text" id="datexamcompl" name="datexamcompl" onChange={this.handleChange} className="form-control" placeholder="Ingrese la información" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">VALORACIÓN NATUROPÁTICA:</label></b>
                                                <textarea type="text" id="valnaturopatica" name="valnaturopatica" onChange={this.handleChange} className="form-control" placeholder="Reflexología, Iridología, Bioenergético, Floral, Oriental, otros" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">DIAGNÓSTICO PRESUNTIVO NATUROPÁTICO:</label></b>
                                                <textarea type="text" id="diagpresnatu" name="diagpresnatu" onChange={this.handleChange} className="form-control" placeholder="Ingrese la información" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">DIAGNÓSTICO DEFINITIVO:</label></b>
                                                <textarea type="text" id="diagdefinitivo" name="diagdefinitivo" onChange={this.handleChange} className="form-control" placeholder="Ingrese la información" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12" style={{ textAlign: 'left' }}>
                                                <b><label className="form-label">PLAN TERAPÉUTICO:</label></b>
                                                <textarea type="text" id="planterapeutico" name="planterapeutico" onChange={this.handleChange} className="form-control" placeholder="Ingrese la información" required></textarea>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                                <div className="mb-3">
                                                    <button id="agregar" type="button"
                                                        className="btn  back-istmas centrar"
                                                        style={{ margin: "5px", width: "170px" }}
                                                        onClick={() => this.registrarDatosHistoriaPaciente()} >
                                                        <b>Guardar</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" size='lg' isOpen={this.state.modalEvolucion}>
                                    <ModalHeader>
                                        <h5>EVOLUCIÓN CLÍNICA</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <label className="form-label"><h6><i class="fa fa-user" aria-hidden="true"></i> {this.state.nombrepaciente} &nbsp;</h6></label>
                                                <button onClick={() => this.generarPdfHClinica()} > <i className=" fas fa-file-pdf" ></i></button>
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <div className="card border-dark text-center ">

                                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                                        <div className="row ">
                                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2 centrar" >
                                                                <div className="mb-3">
                                                                    <label className="form-label">Fecha:</label>
                                                                    <input type="date" id="fechaevol" name="fechaevol" onChange={this.handleChange} className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-lg-2 col-xl-2 centrar" >
                                                                <div className="mb-3">
                                                                    <label className="form-label">Terapeuta:</label>
                                                                    <input type="text" id="terapeuta" name="terapeuta" onChange={this.handleChange} onKeyUp={this.mayusNombreTerapeuta} className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                                                <div className="mb-3">
                                                                    <label className="form-label">Evolución:</label>
                                                                    <textarea id="evolucion" name="evolucion" className="form-control" placeholder="Ingrese la evolución del paciente" onChange={this.handleChange}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                                                <div className="mb-3">
                                                                    <label className="form-label">Indicaciones:</label>
                                                                    <textarea id="indicaciones" name="indicaciones" className="form-control" placeholder="Ingrese las indicaciones" onChange={this.handleChange}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-lg-1 col-xl-1 centrar" >
                                                                <div className="mb-3 centrar">
                                                                    <br /><br />
                                                                    <button class="btn back-istmas centrar" onClick={() => this.registrarDatosEvolucion()} > <i className=" fas fa-plus" ></i></button>
                                                                </div>
                                                            </div>
                                                            <label id="error" name="error" className="form-label"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                                        <div className="card border-dark text-center ">
                                                            <div className="card-header ">
                                                                HISTORIAL DE EVOLUCIÓN
                                                            </div>
                                                            <div className="card-body centrar " style={{ width: "100%" }}>
                                                                <div class="table-responsive">
                                                                    <table className="table  text-left"  >
                                                                        <thead >
                                                                            <tr >
                                                                                <th scope="col"><b>Fecha</b></th>
                                                                                <th scope="col"><b>Terapeuta</b></th>
                                                                                <th scope="col"><b>Evolución</b></th>
                                                                                <th scope="col"><b>Indicaciones</b></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.currentItems &&
                                                                                this.state.currentItems.map((consola) => (
                                                                                    <tr class="list-afiliados" key={consola.id_evolucion_clinica}  >
                                                                                        <td >{consola.fecha_evolucion_clinica}</td>
                                                                                        <td >{consola.terapeuta_evolucion_clinica}</td>
                                                                                        <td >{consola.descripcion_evolucion_clinica}</td>
                                                                                        <td >{consola.indicaciones_evolucion_clinica}</td>
                                                                                        <td>
                                                                                            <button onClick={() => this.eliminarEvolucion(consola.id_evolucion_clinica)} > <i className=" fas fa-trash" ></i></button><br />
                                                                                            <button onClick={() => this.generarPdfEClinica(consola)} > <i className=" fas fa-file-pdf" ></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <ReactPaginate
                                                                nextLabel="next >"
                                                                onPageChange={this.handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={this.state.pageCount}
                                                                marginPagesDisplayed={2}
                                                                previousLabel="< previous"
                                                                pageClassName="page-item"
                                                                pageLinkClassName="page-link"
                                                                previousClassName="page-item"
                                                                previousLinkClassName="page-link"
                                                                nextClassName="page-item"
                                                                nextLinkClassName="page-link"
                                                                breakLabel="..."
                                                                breakClassName="page-item"
                                                                breakLinkClassName="page-link"
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                renderOnZeroPageCount={null}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalEvolucion}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


