/*import axios from 'axios'
import React, { Component } from 'react'*/
import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//Rutas de ingreso de matriz
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarBecasEstudsxPeriodo = ApiUrl + "ListarBecasEstudsxPeriodo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 3;

export default class EstCarreraCicloBecas extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            EstudianteBackup: [],
            periodo: '',
            periodoesc: '',
            modalReportIngresarBecas: false,

            //nuevas variables
            periodosId: [],
            listabecas: [],
            periodos: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }


    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var perio = this.state.idPeriodo;
        this.setState({ periodoesc: perio })
    }

    handleChangeListarEstBecas = async (e) => {

        axios
            .post(urlListarBecasEstudsxPeriodo, {
                idPeriodo: this.state.periodoesc,
            })
            .then(response => {
                const respuesta = response.data.data;
                this.setState({ listabecas: respuesta });
                listaauxiliar = this.state.listabecas;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listabecas;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            })
    }

    componentDidMount = async (e) => {
        //listar todos los periodos
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Reporte de Estudiantas con Beca por Período</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periodo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEstBecas} />
                                    <ReactHTMLTableToExcel
                                        id="botonExportarExcel"
                                        className="btn btn-success"
                                        table="tablaestbecadosexcel"
                                        filename="EstudiantesBecadosExcel"
                                        sheet="pagina 1 "
                                        buttonText="Exportar a Excel"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12 centrar" >

                            <div class="table-responsive">
                                <table id="tablaestbecados" className="table text-left table-bordered">
                                    <thead >
                                        <tr>
                                            <th scope="col">ESTUDIANTE</th>
                                            <th scope="col">MONTO</th>
                                            <th scope="col">%ARANCEL</th>
                                            <th scope="col">%MANUTENC</th>
                                            <th scope="col">TIPO</th>
                                            <th scope="col">FINANCIAM</th>
                                            <th scope="col">RAZÓN1</th>
                                            <th scope="col">RAZÓN2</th>
                                            <th scope="col">RAZÓN3</th>
                                            <th scope="col">RAZÓN4</th>
                                            <th scope="col">RAZÓN5</th>
                                            <th scope="col">RAZÓN6</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.estudiante_id}>
                                                <td class="estudiante">{consola.primer_nombre_estudiante + " " + consola.segundo_nombre_estudiante + " " + consola.primer_apellido_estudiante + " " + consola.segundo_apellido_estudiante}</td>
                                                <td class="monto">${consola.monto}</td>
                                                <td class="arancel">{consola.porcentaje_arancel}%</td>
                                                <td class="manutencion">{consola.porcentaje_manuntencion}%</td>
                                                <td scope="tipo">
                                                    {(() => {
                                                        if (consola.tipo_beca_id == "1")
                                                            return <span>Total</span>
                                                        if (consola.tipo_beca_id == "2")
                                                            return <span>Parcial</span>
                                                        if (consola.tipo_beca_id == "3")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="financiamiento">
                                                    {(() => {
                                                        if (consola.financiamiento_beca == "1")
                                                            return <span>Fondos Propios</span>
                                                        if (consola.financiamiento_beca == "2")
                                                            return <span>Transferencia del estado</span>
                                                        if (consola.financiamiento_beca == "3")
                                                            return <span>No Donaciones</span>
                                                        if (consola.financiamiento_beca == "4")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon1">
                                                    {(() => {
                                                        if (consola.primera_razon_beca == "1")
                                                            return <span>Socioeconómica</span>
                                                        if (consola.primera_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon2">
                                                    {(() => {
                                                        if (consola.segunda_razon_beca == "1")
                                                            return <span>Excelencia</span>
                                                        if (consola.segunda_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon3">
                                                    {(() => {
                                                        if (consola.tercera_razon_beca == "1")
                                                            return <span>Deportista</span>
                                                        if (consola.tercera_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon4">
                                                    {(() => {
                                                        if (consola.cuarta_razon_beca == "1")
                                                            return <span>Pueblos y Nacionalidades</span>
                                                        if (consola.cuarta_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon5">
                                                    {(() => {
                                                        if (consola.quinta_razon_beca == "1")
                                                            return <span>Discapacidad</span>
                                                        if (consola.quinta_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                                <td scope="razon6">
                                                    {(() => {
                                                        if (consola.sexta_razon_beca == "1")
                                                            return <span>Otra</span>
                                                        if (consola.sexta_razon_beca == "2")
                                                            return <span>NA</span>
                                                    })()}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-responsive">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                        <div class="table-responsive" hidden>
                            <table id="tablaestbecadosexcel" className="table text-left table-bordered">
                                <thead >
                                    <tr>
                                        <th scope="col">ESTUDIANTE</th>
                                        <th scope="col">MONTO</th>
                                        <th scope="col">%ARANCEL</th>
                                        <th scope="col">%MANUTENC</th>
                                        <th scope="col">TIPO</th>
                                        <th scope="col">FINANCIAM</th>
                                        <th scope="col">RAZÓN1</th>
                                        <th scope="col">RAZÓN2</th>
                                        <th scope="col">RAZÓN3</th>
                                        <th scope="col">RAZÓN4</th>
                                        <th scope="col">RAZÓN5</th>
                                        <th scope="col">RAZÓN6</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listabecas.map((consola) => (
                                        <tr key={consola.estudiante_id}>
                                            <td class="estudiante">{consola.primer_nombre_estudiante + " " + consola.segundo_nombre_estudiante + " " + consola.primer_apellido_estudiante + " " + consola.segundo_apellido_estudiante}</td>
                                            <td class="monto">{consola.monto}</td>
                                            <td class="arancel">{consola.porcentaje_arancel}</td>
                                            <td class="manutencion">{consola.porcentaje_manuntencion}</td>
                                            <td scope="tipo">
                                                {(() => {
                                                    if (consola.tipo_beca_id == "1")
                                                        return <span>Total</span>
                                                    if (consola.tipo_beca_id == "2")
                                                        return <span>Parcial</span>
                                                    if (consola.tipo_beca_id == "3")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="financiamiento">
                                                {(() => {
                                                    if (consola.financiamiento_beca == "1")
                                                        return <span>Fondos Propios</span>
                                                    if (consola.financiamiento_beca == "2")
                                                        return <span>Transferencia del estado</span>
                                                    if (consola.financiamiento_beca == "3")
                                                        return <span>No Donaciones</span>
                                                    if (consola.financiamiento_beca == "4")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon1">
                                                {(() => {
                                                    if (consola.primera_razon_beca == "1")
                                                        return <span>Socioeconómica</span>
                                                    if (consola.primera_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon2">
                                                {(() => {
                                                    if (consola.segunda_razon_beca == "1")
                                                        return <span>Excelencia</span>
                                                    if (consola.segunda_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon3">
                                                {(() => {
                                                    if (consola.tercera_razon_beca == "1")
                                                        return <span>Deportista</span>
                                                    if (consola.tercera_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon4">
                                                {(() => {
                                                    if (consola.cuarta_razon_beca == "1")
                                                        return <span>Pueblos y Nacionalidades</span>
                                                    if (consola.cuarta_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon5">
                                                {(() => {
                                                    if (consola.quinta_razon_beca == "1")
                                                        return <span>Discapacidad</span>
                                                    if (consola.quinta_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                            <td scope="razon6">
                                                {(() => {
                                                    if (consola.sexta_razon_beca == "1")
                                                        return <span>Otra</span>
                                                    if (consola.sexta_razon_beca == "2")
                                                        return <span>NA</span>
                                                })()}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div >
        )
    }
}

