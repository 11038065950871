import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/";
const idAdministrativo = cookie.get("idAdministrativo");
export default class SidebarRecepcion extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })
            })
    }

    render() {
        return (
            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">REGISTRADOS</div>
                            <Link to="/recepcion">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal A</b>
                                </div>
                            </Link>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAdministrativo" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-hospital-user"></i>
                                </div>
                                INTERESADOS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAdministrativo" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Recepcion/ReporteInteresadosRec">  <div className="nav-link movil" href="#">Reporte de Interesados (General)</div></Link>
                                    <Link to="/Recepcion/ReporteInteresadosEnEsperaRec">  <div className="nav-link movil" href="#">Reporte de Interesados (En Espera)</div></Link>
                                    <Link to="/Recepcion/ReporteInteresadosAtendRec">  <div className="nav-link movil" href="#">Reporte de Interesados (Atendidos)</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseInscritos" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-hospital-user"></i>
                                </div>
                                INSCRITOS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseInscritos" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Recepcion/ReporteInscritosRec">  <div className="nav-link movil" href="#">Reporte de registrados en el sistema informático de gestión</div></Link>
                         
                                </nav>
                            </div>

                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                <i className="far fa-user"></i>                                       </div>
                                PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePerfil" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Recepcion/VerPerfilRecep" ><div className="nav-link puntero movil" href="#">Ver Perfil </div></Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>


        )
    }
}
