import axios from 'axios';
import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import { Footer } from '../footer/Footer';
import { NavbarLog } from '../navbar/NavbarLog'
//import "../services/ApiRest"
import './CarrerasScreen.css';
import { ApiUrl } from '../services/ApiRest';
import { Alert } from 'bootstrap';
const baseUrl = ApiUrl + "listcarrvisuest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const cookies = new Cookies();

export default class CarrerasScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            carrera: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        var idCarrera = this.state.idCarrera;
        cookies.set('idCarrera', idCarrera, { path: "/" });
        window.location.href = "/carrera";
    }

    componentDidMount() {
   
        axios.get(baseUrl)
            .then(res => {
                const carrera = res.data.data;
          
                this.setState({ carrera });
            })
    }

    render() {
        return (
            <div className="Login-component">
                <NavbarLog />
                <div className="container ">
                    <div className="row" >
                        <div className="card shadow p-3  bg-white rounded"  >
                            <div className="card-header text-center">
                                <h4>Sistema Académico Institucional ISTMAS</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="carrera-select   col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 color-istmas" >
                            <div className="card shadow p-3 mb-5 bg-white rounded" >
                                <div className="card-header text-center">
                                    <h4>CARRERAS ISTMAS</h4>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3" >
                                        <label className="form-label">Seleccione la carrera que desea inscribirse</label>
                                        <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                            <option value="undefined">Seleccione una carrera</option>
                                            {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" >
                            <div className="">
                                <img src={"./assets/img/loginsistemaacad.png"} className="img-login rounded " width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>





        )
    }
}
