import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarUComunicacion'
import { ApiUrl } from '../components/services/ApiRest';
import ReporteInteresados from '../components/ucomunicacion/interesados/ReporteInteresadosUC'
import ReporteInteresadosEnEspera from '../components/ucomunicacion/interesados/ReporteInteresadosEnEsperaUC'
import ReporteInteresadosAtend from '../components/ucomunicacion/interesados/ReporteInteresadosAtendUC'
import VerPerfil from '../components/ucomunicacion/perfil/VerPerfil'

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasUComunicacion extends Component {
    componentDidMount() {

        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })

            })
    }


    render() {

        if (!cookie.get("log")) {
            return (<Redirect to="/login" />);
        }

        if (cookie.get("rol") !== "administrativo") {
        }


        return (

            <>
                <NavbarGeneral />
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>

                            <div className="container-fluid">
                                <Contenido />
                                {/*  Interesados */}
                                <Route path="/UComunicacion/ReporteInteresados" component={ReporteInteresados} />
                                <Route path="/UComunicacion/ReporteInteresadosEnEspera" component={ReporteInteresadosEnEspera} />
                                <Route path="/UComunicacion/ReporteInteresadosAtend" component={ReporteInteresadosAtend} />
                                <Route path="/UComunicacion/VerPerfilUComuni" component={VerPerfil} />
                            </div>
                        </main>
                        <FooterDashboard />
                    </div>
                </div>
            </>
        )
    }
}
