import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const UrlListarEvaluaciones = ApiUrl + "listarevaluacionesactivas" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarSubcriterio = ApiUrl + "insertarsubcriterio" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarCritEncargadoxEval = ApiUrl + "listarcriteriosencargadoxeval" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarSubcritxEvalCritAdm = ApiUrl + "listarsubcritxevalcritadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarIndicadoresexSubcrit = ApiUrl + "listarindicadoresexsubcrit" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarIndicadore = ApiUrl + "insertarindicadore" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEvidenciasxInd = ApiUrl + "listarevidenciasxind" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarUsuarioAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarEvidencia = ApiUrl + "insertarevidencia" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarMediosVerifexEvid = ApiUrl + "listarmediosverifexevid" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlInsertarMedioVerificacione = ApiUrl + "insertarmedioverificacione" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 3;

export default class GestionEncargadoCriterio extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            modalReportIngresarSubcrit: false,
            modalReportIngresarInd: false,
            modalReportIngresarEvid: false,
            modalReportIngresarMedv: false,
            eval_seleccionada: '',
            criterio_seleccionado: '',
            ind_seleccionado: '',
            idsubcrit_seleccionado: '',
            descsubcrit_seleccionado: '',
            evid_seleccionada: '',
            administrativos: [],
            nombre_criterio: '',
            nombre_evaluacion: '',
            //nuevas variables
            listaeval: [],
            listacriterios: [],
            listaindicadorese: [],
            listaevidencias: [],
            listamedios: [],
            porcentaje_criterio: 0,
            color_criterio: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            disableForm: true,
            disableForm2: true,
            disableForm3: true,
            disableForm4: true,
            archivo_medver: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeFile = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ archivo_medver: e.target.files });
    }

    handleChangeDevolverIdEval = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ eval_seleccionada: this.state.idEval });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_evaluacion: option })
        this.handleChangeListarCriteriosxEvaluacion();
    }

    handleChangeDevolverIdCriterio = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ criterio_seleccionado: this.state.idCriterio });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_criterio: option })
    }

    handleChangeListarIndicadorese = async (e) => {
        axios
            .post(UrlListarIndicadoresexSubcrit, {
                id_subcrit: this.state.idsubcrit_seleccionado,
            })
            .then(response => {
                this.setState({ listaindicadorese: response.data.data })
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarSubcriteriouno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_subcrit = "";
        var desc_subcrit = "";

        $(".afiliado:checked").each(function () {
            id_subcrit = $(this).parent().parent().find('td').eq(0).html();
            desc_subcrit = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ idsubcrit_seleccionado: id_subcrit })
        this.setState({ descsubcrit_seleccionado: desc_subcrit })

        this.handleChangeListarIndicadorese();
        this.setState({ disableForm2: false });
    }

    handleChangeListarSubcritxEvalCritAdm = async (e) => {
        this.setState({ disableForm2: true });
        this.setState({ disableForm3: true });
        this.setState({ disableForm4: true });
        axios
            .post(UrlListarSubcritxEvalCritAdm, {
                id_evaluacion: this.state.eval_seleccionada,
                id_criterio: this.state.criterio_seleccionado,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_criterio: response.data.data1 });
                this.setState({ color_criterio: response.data.data2 });

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ disableForm: false });
    }

    handleChangeListarSubcritSinCerrar = async (e) => {
        axios
            .post(UrlListarSubcritxEvalCritAdm, {
                id_evaluacion: this.state.eval_seleccionada,
                id_criterio: this.state.criterio_seleccionado,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_criterio: response.data.data1 });
                this.setState({ color_criterio: response.data.data2 });

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ disableForm: false });
    }

    handleChangeListarEvaluaciones = async (e) => {
        //planes
        axios.get(UrlListarEvaluaciones)
            .then(respuesta => {
                this.setState({ listaeval: respuesta.data.data });
            })
    }

    handleChangeListarCriteriosxEvaluacion = async (e) => {
        const cookie = new Cookies();
        const idAdministrativo = cookie.get("idAdministrativo");

        axios
            .post(UrlListarCritEncargadoxEval, {
                id: this.state.eval_seleccionada,
                id_administrativo: idAdministrativo,
            })
            .then(response => {
                this.setState({ listacriterios: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarEvaluaciones();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ subcriterio: null });
        this.setState({ modalReportIngresarSubcrit: true });
    }

    mostrarModalIngresarInd = () => {
        this.setState({ id_ind: 0 });
        this.setState({ desc_ind: null });
        this.setState({ modalReportIngresarInd: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.evaluacionescriteriosadm_subcriterio);
            $("#criterio").val(registro.id_criterio);
            $("#subcriterio").val(registro.descripcion_subcriterio);
        });

        this.setState({ id: registro.evaluacionescriteriosadm_subcriterio });
        this.setState({ criterio: registro.id_criterio });
        this.setState({ subcriterio: registro.descripcion_subcriterio });
        this.setState({ modalReportIngresarSubcrit: true });
    }

    mostrarModalModificarInd = (registro) => {
        $(function () {
            $("#id_ind").val(registro.id_indicadore);
            $("#desc_ind").val(registro.descripcion_indicadore);
        });

        this.setState({ id_ind: registro.id_indicadore });
        this.setState({ desc_ind: registro.descripcion_indicadore });
        this.setState({ modalReportIngresarInd: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarSubcrit: false });
    }

    ocultarModalIngresarInd = () => {
        this.setState({ modalReportIngresarInd: false });
    }

    registrarSubcriterio = () => {
        if (this.state.subcriterio != null) {
            const f = new FormData();
            f.append("id", this.state.id);
            f.append("id_evaluacion", this.state.eval_seleccionada);
            f.append("id_criterio", this.state.criterio_seleccionado);
            f.append("subcriterio", this.state.subcriterio);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(urlInsertarSubcriterio, f, config)
                .then(response => {
                    if (response.data.success === true) {
                        swal({
                            text: "Operación Exitosa!!",
                            icon: "success",
                            button: "Aceptar",
                        });
                        axios.post(UrlListarSubcritxEvalCritAdm, {
                            id_evaluacion: this.state.eval_seleccionada,
                            id_criterio: this.state.criterio_seleccionado,
                        })
                            .then(respuesta => {
                                listaauxiliar = respuesta.data.data;
                                if (this.state.pageCount == 1) {
                                    this.setState({ itemOffset: 0 });
                                }

                                //PAGINACION
                                const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                this.setState((state) => {
                                    return { itemOffset: newOffset }
                                });
                                const endOffset = newOffset + itemsPerPage;
                                //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState({ itemOffset: newOffset });
                            })
                        this.ocultarModalIngresar();
                    }
                })
                .catch(error => {
                    this.setState({ error: "No existe conexión con el servidor" });
                    swal({
                        text: "No existe conexión con el servidor",
                        icon: "error",
                        button: "Aceptar",
                    });
                });
        }
        else {
            swal({
                text: "Ingresar campos requeridos",
                icon: "warning",
                button: "Aceptar",
            });
        }
    }

    registrarIndicador = () => {
        if (this.state.desc_ind != null) {
            const f = new FormData();
            f.append("id", this.state.id_ind);
            f.append("desc_ind", this.state.desc_ind);
            f.append("subcriterio_id", this.state.idsubcrit_seleccionado);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(urlInsertarIndicadore, f, {

            }, config)
                .then(response => {
                    if (response.data.success === true) {
                        this.handleChangeListarIndicadorese();
                        this.ocultarModalIngresarInd();
                    }
                })
                .catch(error => {
                    this.setState({ error: "No existe conexión con el servidor" });
                    swal({
                        text: "No existe conexión con el servidor",
                        icon: "error",
                        button: "Aceptar",
                    });
                });
        }
        else {
            swal({
                text: "Ingresar campos requeridos",
                icon: "warning",
                button: "Aceptar",
            });
        }
    }

    seleccionarIndicadoruno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_ind = "";
        var desc_ind = "";

        $(".afiliado:checked").each(function () {
            id_ind = $(this).parent().parent().find('td').eq(0).html();
            desc_ind = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ ind_seleccionado: id_ind })

        this.handleChangeListarEvidenciasxInd();
        this.setState({ disableForm3: false });
    }

    handleChangeListarAdm() {
        axios.get(UrlListarUsuarioAdm)
            .then(res => {
                const administrativos = res.data.data;
                this.setState({ administrativos });
            })
    }

    handleChangeListarEvidenciasxInd = async (e) => {
        this.handleChangeListarAdm();

        axios
            .post(urlListarEvidenciasxInd, {
                id: this.state.ind_seleccionado,
            })
            .then(response => {
                this.setState({ listaevidencias: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }

    mostrarModalIngresarEvid = () => {
        this.setState({ id: 0 });
        this.setState({ descripcion: null });
        this.setState({ responsable: null });
        this.setState({ fentrega: null });
        this.setState({ estadoevid: null });
        this.setState({ observaciones: "" });
        this.setState({ modalReportIngresarEvid: true });
    }

    mostrarModalModificarEvid = (registro) => {
        var vfentrega = registro.fechaentrega_evidencia;
        var vobser = registro.observ_evidencia;
        if (vfentrega == null) {
            vfentrega = "";
        }

        if (vobser == null) {
            vobser = "";
        }
        $(function () {
            $("#id").val(registro.id_evidencia);
            $("#detalle").val(registro.detalle_evidencia);
            $("#responsable").val(registro.id_administrativo);
            $("#fentrega").val(vfentrega);
            $("#estadoevid").val(registro.estado_evidencia);
            $("#observaciones").val(vobser);
        });

        this.setState({ id: registro.id_evidencia });
        this.setState({ detalle: registro.detalle_evidencia });
        this.setState({ responsable: registro.id_administrativo });
        this.setState({ fentrega: vfentrega });
        this.setState({ estadoevid: registro.estado_evidencia });
        this.setState({ observaciones: vobser });
        this.setState({ modalReportIngresarEvid: true });
    }

    ocultarModalIngresarEvid = () => {
        this.setState({ modalReportIngresarEvid: false });
    }


    registrarEvidencia = () => {
        if (this.state.detalle != null && this.state.responsable != null && this.state.fentrega != null && this.state.estadoevid != null) {
            const f = new FormData();
            f.append("id", this.state.id);
            f.append("detalle", this.state.detalle);
            f.append("responsable", this.state.responsable);
            f.append("fechaentrega", this.state.fentrega);
            f.append("estadoevid", this.state.estadoevid);
            f.append("observaciones", this.state.observaciones);
            f.append("indicador", this.state.ind_seleccionado);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(urlInsertarEvidencia, f, {

            }, config)
                .then(response => {
                    if (response.data.success === true) {
                        swal({
                            text: "Operación Exitosa!!",
                            icon: "success",
                            button: "Aceptar",
                        });
                        this.handleChangeListarEvidenciasxInd();
                        this.ocultarModalIngresarEvid();
                    }
                })
                .catch(error => {
                    this.setState({ error: "No existe conexión con el servidor" });
                    swal({
                        text: "No existe conexión con el servidor",
                        icon: "error",
                        button: "Aceptar",
                    });
                });
        }
        else {
            swal({
                text: "Ingresar campos requeridos",
                icon: "warning",
                button: "Aceptar",
            });
        }
    }

    handleChangeListarMediosVerife = async (e) => {
        axios
            .post(UrlListarMediosVerifexEvid, {
                id_evidencia: this.state.evid_seleccionada,
            })
            .then(response => {
                this.setState({ listamedios: response.data.data })
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarEvidenciauno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_evidencia = "";
        var desc_evidencia = "";

        $(".afiliado:checked").each(function () {
            id_evidencia = $(this).parent().parent().find('td').eq(0).html();
            desc_evidencia = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ evid_seleccionada: id_evidencia })
        this.setState({ descevid_seleccionada: desc_evidencia })

        this.handleChangeListarMediosVerife();
        this.setState({ disableForm4: false });
    }

    mostrarModalIngresarMedVer = () => {
        this.setState({ id_medver: 0 });
        this.setState({ desc_medver: null });
        this.setState({ archivo_medver_mod: "" });
        this.setState({ obser_medver: "" });
        this.setState({ estado_medver: null });
        this.setState({ modalReportIngresarMedv: true });
    }

    ocultarModalIngresarMedVer = () => {
        this.setState({ modalReportIngresarMedv: false });
    }

    mostrarModalModificarMedVer = (registro) => {
        var vobservaciones = registro.obser_medioverificacione;
        if (vobservaciones == null) {
            vobservaciones = "";
        }
        var varchivo = registro.archivo_medioverificacione;
        if (varchivo == null) {
            varchivo = "";
        }
        $(function () {
            $("#id_medver").val(registro.id_medioverificacione);
            $("#desc_medver").val(registro.desc_medioverificacione);
            $("#archivo_medver_mod").val(varchivo);
            $("#obser_medver").val(vobservaciones);
            $("#estado_medver").val(registro.estado_medioverificacione);
        });

        this.setState({ id_medver: registro.id_medioverificacione });
        this.setState({ desc_medver: registro.desc_medioverificacione });
        this.setState({ archivo_medver_mod: varchivo });
        this.setState({ obser_medver: vobservaciones });
        this.setState({ estado_medver: registro.estado_medioverificacione });
        this.setState({ modalReportIngresarMedv: true });
    }

    VerificarExtensionArchivo = () => {
        var narchivo = 0;
        if (this.state.archivo_medver != "") {
            var fileInput = document.getElementById('archivo_medver');
            var filePath = fileInput.value;
            var allowedExtensions = /(.pdf)$/i;
            if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3)) {
                swal({
                    text: "El archivo debe ser formato PDF",
                    icon: "error",
                    button: "Aceptar",
                });
                this.setState({ varchivo: 1 });
                narchivo = 1;
            }
        }
        return (narchivo);
    }

    registrarMedioVerificacion = () => {
        if (this.VerificarExtensionArchivo() != 1) {
            if (this.state.desc_medver != null && this.state.estado_medver != null) {
                const f = new FormData();
                f.append("id_medver", this.state.id_medver);
                f.append("desc_medver", this.state.desc_medver);
                f.append("archivo_medver", this.state.archivo_medver[0]);
                f.append("obser_medver", this.state.obser_medver);
                f.append("estado_medver", this.state.estado_medver);
                f.append("evidencia_id", this.state.evid_seleccionada);
                f.append("archivo_medver_mod", this.state.archivo_medver_mod);

                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }

                axios.post(UrlInsertarMedioVerificacione, f, config)
                    .then(response => {
                        if (response.data.success === true) {
                            swal({
                                text: "Operación Exitosa!!",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.handleChangeListarSubcritSinCerrar();
                            this.handleChangeListarIndicadorese();
                            this.handleChangeListarEvidenciasxInd();
                            this.handleChangeListarMediosVerife();
                            this.ocultarModalIngresarMedVer();
                        }
                    })
                    .catch(error => {
                        this.setState({ error: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",
                        });
                    });
            } else {
                swal({
                    text: "Ingresar campos requeridos",
                    icon: "warning",
                    button: "Aceptar",
                });
            }
        }
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Subcriterios por Evaluación</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Evaluaciones</b></label>
                                    <select className="form-select" name="idEval" value={this.state.value} onChange={this.handleChangeDevolverIdEval} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una evaluación</option>
                                        {this.state.listaeval.map(person => <option key={person.id_evaluacion} data-nombre={person.nombre_evaluacion} value={person.id_evaluacion} > {person.nombre_evaluacion}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Criterio</b></label>
                                    <select className="form-select" name="idCriterio" value={this.state.value} onChange={this.handleChangeDevolverIdCriterio} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un criterio</option>
                                        {this.state.listacriterios.map(person => <option key={person.id_criterio} data-nombre={person.nombre_criterio} value={person.id_criterio} > {person.nombre_criterio}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b> </b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarSubcritxEvalCritAdm} />
                                </div>
                            </div>
                        </div>
                        <div className="card border-dark text-center text-white" hidden={this.state.disableForm}>
                            <div className="card-header" class={this.state.color_criterio}>
                                <b>PORCENTAJE DEL CRITERIO: {this.state.porcentaje_criterio}%</b>
                            </div>
                        </div>
                        <div className="card border-dark text-center" hidden={this.state.disableForm}>
                            <div className="card-header ">
                                <b>REPORTE DE SUBCRITERIOS</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Porcentaje</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_subcriterio}>
                                                <td class="id">{consola.id_subcriterio}</td>
                                                <td class="subcriterio">{consola.descripcion_subcriterio}</td>
                                                <td class={consola.color}>{consola.porcentaje_subcrit}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarSubcriteriouno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div class="table-responsive">
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                                <Modal isOpen={this.state.modalReportIngresarSubcrit} >
                                    <ModalHeader>
                                        Registro de Subcriterio por Evaluación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Evaluación:</label>
                                                <input type="text" id="evaluacion" name="evaluacion" onChange={this.handleChange} value={this.state.nombre_evaluacion} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Criterio:</label>
                                                <input type="text" id="criterioe" name="criterioe" onChange={this.handleChange} value={this.state.nombre_criterio} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Subcriterio:</label>
                                                <input type="text" id="subcriterio" name="subcriterio" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarSubcriterio()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresar()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="card border-dark text-center" hidden={this.state.disableForm2}>
                            <div className="card-header ">
                                <b>REPORTE DE INDICADORES POR SUBCRITERIO</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Porcentaje</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listaindicadorese.map((consola) => (
                                            <tr key={consola.id_indicadore}>
                                                <td class="id">{consola.id_indicadore}</td>
                                                <td>{consola.descripcion_indicadore}</td>
                                                <td class={consola.color}>{consola.porcentaje_ind}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarIndicadoruno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarInd(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarInd} >
                                    <ModalHeader>
                                        Registro de Indicadores por Subcriterio
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id_ind" class="id_ind" id="id_ind" value="" />
                                                <label className="form-label">Subcriterio:</label>
                                                <textarea id="subcriterio" name="subcriterio" value={this.state.descsubcrit_seleccionado} onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled></textarea>
                                                <label className="form-label">Indicador:</label>
                                                <input type="text" id="desc_ind" name="desc_ind" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarIndicador()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarInd()}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarInd()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="card border-dark text-center" hidden={this.state.disableForm3}>
                            <div className="card-header ">
                                <b>REPORTE DE EVIDENCIAS</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Responsable</th>
                                            <th>Fecha de Entrega</th>
                                            <th>Estado</th>
                                            <th>Observaciones</th>
                                            <th>Metas</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listaevidencias.map((consola) => (
                                            <tr key={consola.id_evidencia}>
                                                <td class="id">{consola.id_evidencia}</td>
                                                <td class="desc">{consola.detalle_evidencia}</td>
                                                <td class="respon">{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                <td class="fecha">{consola.fechaentrega_evidencia}</td>
                                                <td class="estadoe">{consola.estado_evidencia}</td>
                                                <td class="observ">{consola.observ_evidencia}</td>
                                                <td class={consola.color}>{consola.porcentaje}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEvidenciauno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarEvid(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarEvid} >
                                    <ModalHeader>
                                        Registro de Evidencia
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Detalle:</label>
                                                <input type="text" id="detalle" name="detalle" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Responsable:</label>
                                                <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.administrativos.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                </select>
                                                <label className="form-label">Fecha de entrega:</label>
                                                <input type="date" id="fentrega" name="fentrega" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Estado:</label>
                                                <select className="form-select" id="estadoevid" name="estadoevid" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                    <option value="" selected disabled>Seleccione</option>
                                                    <option value="Pendiente">Pendiente</option>
                                                    <option value="Cumple">Cumple</option>
                                                    <option value="Cumple Parcialmente">Cumple Parcialmente</option>
                                                    <option value="No Cumple">No Cumple</option>
                                                </select>
                                                <label className="form-label">Observaciones:</label>
                                                <textarea type="text" id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required></textarea>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarEvidencia()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarEvid()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarEvid()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="card border-dark text-center" hidden={this.state.disableForm4}>
                            <div className="card-header ">
                                <b>REPORTE DE MEDIOS DE VERIFICACIÓN</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Archivo</th>
                                            <th>Observaciones</th>
                                            <th>Estado</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listamedios.map((consola) => (
                                            <tr key={consola.id_medioverificacione}>
                                                <td>{consola.id_medioverificacione}</td>
                                                <td>{consola.desc_medioverificacione}</td>
                                                <td>
                                                    {(() => {
                                                        if (consola.ruta) {
                                                            return <a href={consola.ruta} target="blank"><i class="fa fa-2x fa-file-pdf" aria-hidden="true"></i></a>
                                                        }
                                                    })()}
                                                </td>
                                                <td>{consola.obser_medioverificacione}</td>
                                                {(() => {
                                                    if (consola.estado_medioverificacione == "Pendiente") {
                                                        return <td class="estadodpt table-danger">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Corrección") {
                                                        return <td class="estadodpt table-info">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Completado") {
                                                        return <td class="estadodpt table-warning">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Verificado") {
                                                        return <td class="estadodpt table-success">{consola.estado_medioverificacione}</td>
                                                    }
                                                })()}
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarMedVer(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarMedv} >
                                    <ModalHeader>
                                        Registro de Medio de Verificación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id_medver" class="id_medver" id="id_medver" value="" />
                                                <input type="hidden" name="archivo_medver_mod" class="archivo_medver_mod" id="archivo_medver_mod" value="" />
                                                <label className="form-label">Evidencia:</label>
                                                <textarea id="evidencia" name="evidencia" value={this.state.descevid_seleccionada} onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled></textarea>
                                                <label className="form-label">Descripción:</label>
                                                <input type="text" id="desc_medver" name="desc_medver" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Archivo:</label>
                                                <input type="file" id="archivo_medver" name="archivo_medver" onChange={this.handleChangeFile} className="form-control" placeholder="" autocomplete="off" />
                                                <label className="form-label">Observaciones:</label>
                                                <textarea id="obser_medver" name="obser_medver" onChange={this.handleChange} className="form-control"></textarea>
                                                <label className="form-label">Estado:</label>
                                                <select className="form-select" id="estado_medver" name="estado_medver" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                    <option value="">Seleccione</option>
                                                    <option value="Pendiente">Pendiente</option>
                                                    <option value="Corrección">Corrección</option>
                                                    <option value="Completado">Completado</option>
                                                    <option value="Verificado">Verificado</option>
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarMedioVerificacion()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarMedVer()}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarMedVer()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

