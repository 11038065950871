import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component, useState } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Cookies from 'universal-cookie';

const urlListarSedes = ApiUrl + "listarsedes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarestud = ApiUrl + "listestudmatriperiodcoo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListEstudEstadDocumentMatri = ApiUrl + "listestuddocumentmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarAsignaturas = ApiUrl + "listasignmatrireport" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstadosMatri = ApiUrl + "listestadosmatr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 3;
export default class ReportEstadoDocumentosMatri extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            sedes: [],
            estud: [],
            listaniveles: [],
            EstudianteBackup: [],
            textBuscar: "",
            descripCarrE: "",
            estado: "",
            listaperiodos: [],
            listasignaturas: [],
            listEstudMatriDoc: [],
            estadosmat: [],
            //variables para paginación
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportAsignatutasEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });

    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
        //Mostrar los estados de la matrícula
        axios.get(urlListarEstadosMatri)
            .then(respuesta => {
                const estadosmat = respuesta.data;
                this.setState({ estadosmat });

            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }

    //Mostrar las asignaturas matriculadas de un estudiante
    mostrarAsignaturasMat = (registro) => {
        axios.post(urlListarAsignaturas, {
            idEstudiante: registro.id_estudiante,
            idPeriodo: this.state.idPeriodoSelect,
            idCarrera: this.state.idCarrera
        })
            .then(response => {
                if (response.data.success === true) {
                    const listasignaturas = response.data.data;
                    this.setState({ descripCarrE: listasignaturas[0].descripcion_carrera });
                    this.setState({ listasignaturas });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ modalReportAsignatutasEst: true, form1: registro });
    }

    ocultarModalReportAsignaturas = () => {
        this.setState({ modalReportAsignatutasEst: false });
    }
    //Lista de estudiantes matriculados con el estado de los documentos de matrícula
    handleChangeEstadoDocument = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios.post(urlListEstudEstadDocumentMatri, {
            idPeriodoS: this.state.idPeriodoSelect,
            idCarreraS: this.state.idCarrera,
            idCicloNivelS: this.state.nivelEstu,
            idEstadoMatri: this.state.estadMat,
            idRol: cookie.get("rol"),

        })
            .then(respuesta => {

                if (respuesta.data.success == true) {

                    this.setState({ listEstudMatriDoc: respuesta.data.data });
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.state.itemOffset = 0;
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {
                    this.setState({ currentItems: null });
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe estudiantes matriculados en la coordinación seleccionada!",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }

    handleChangeSelectNivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarrera
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                const modalidadcarr = response.data.objcarrea[0].descripcion_modalidad_carrera;

                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                this.setState({ modalidad: modalidadcarr });
                let listaniveles1 = []
                for (let i = 1; i <= nivelcarrest; i++) {
                    let fila = {
                        codnivel: i,
                        valor: i
                    };
                    listaniveles1.push(fila);
                }
                this.setState({ listaniveles: listaniveles1 });
            })
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Estado de los documentos entregados por los estudiantes para la matrícula</b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Nivel</b></label>
                                    <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione </option>
                                        {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Estado de matrícula</b></label>
                                    <select className="form-select" id="estadMat" name="estadMat" value={this.state.value} onChange={this.handleChangeEstadoDocument} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el estado</option>
                                        {this.state.estadosmat.map(person => <option key={person.id} value={person.id} > {person.estado}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">No</th>
                                                        <th scope="col">Notas</th>
                                                        <th scope="col">Estado de matrícula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Identificación</th>
                                                        <th scope="col">Tipo de identifación</th>
                                                        <th scope="col">Sexo</th>
                                                        <th scope="col">Modalidad</th>
                                                        <th scope="col">Carrera</th>
                                                        <th scope="col">Paralelo</th>
                                                        <th scope="col">País</th>
                                                        <th scope="col">Ciudad de residencia</th>
                                                        <th scope="col">Centro de prácticas</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Dirección</th>
                                                        <th scope="col">Paz y Salvo</th>
                                                        <th scope="col">Registro de título en el Ministerio de Educación</th>
                                                        <th scope="col">Cédula(físico)</th>
                                                        <th scope="col">Título de bachiller(físico)</th>
                                                        <th scope="col">Fotos(físico)</th>
                                                        <th scope="col">Inscripción/Compromiso(físico)</th>
                                                        <th scope="col">Inscripción/Compromiso</th>
                                                        <th scope="col">Inscrito por</th>
                                                        <th scope="col">Asignaturas</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (

                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td >{consola.num_secuencial}</td>
                                                                <td >{consola.observacion}</td>
                                                                <td >{consola.estado.toUpperCase()}</td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td >{consola.numero_identificacion_estudiante}</td>
                                                                <td >{consola.descrip_tipo_identif.toUpperCase()}</td>
                                                                <td >{consola.descripcion_genero.toUpperCase()}</td>
                                                                <td >{this.state.modalidad.toUpperCase()}</td>
                                                                <td >{this.state.descripCarrE.toUpperCase()}</td>
                                                                <td >{consola.paralelo}</td>
                                                                <td >{consola.pais_residencia.toUpperCase()}</td>
                                                                <td >{consola.ciudad_residencia.toUpperCase()}</td>
                                                                <td >{consola.coordinacion}</td>
                                                                <td >{consola.celular_estudiante}</td>
                                                                <td >{consola.email_estudiante}</td>
                                                                <td >{consola.direccion_estudiante}</td>
                                                                <td >{consola.pazysalvo}</td>
                                                                <td >{consola.registro_titulo}</td>
                                                                <td >{consola.cedula_fisico}</td>
                                                                <td >{consola.titulo_fisico}</td>
                                                                <td >{consola.foto_fisico}</td>
                                                                <td >{consola.docinscompro_fisico}</td>
                                                                <td >{consola.estado_acta}</td>
                                                                <td >{consola.nomadministrativo}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarAsignaturasMat(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <table className="table  text-left" id="tablalistestuddoc" name="tablalistestuddoc" hidden>
                                                <thead >
                                                    <tr >
                                                        <th scope="col">No</th>
                                                        <th scope="col">NOTAS</th>
                                                        <th scope="col">ESTADO DE MATRÍCULA</th>
                                                        <th scope="col">PRIMER APELLIDO</th>
                                                        <th scope="col">SEGUNDO APELLIDO</th>
                                                        <th scope="col">PRIMER NOMBRE</th>
                                                        <th scope="col">SEGUNDO NOMBRE</th>
                                                        <th scope="col">IDENTIFICACIÓN</th>
                                                        <th scope="col">TIPO DE INDENTIFICACIÓN</th>
                                                        <th scope="col">SEXO</th>
                                                        <th scope="col">MODALIDAD</th>
                                                        <th scope="col">CARRERA</th>
                                                        <th scope="col">PARALELO</th>
                                                        <th scope="col">PAÍS</th>
                                                        <th scope="col">CIUDAD DE RESIDENCIA</th>
                                                        <th scope="col">CENTRO DE PRÁCTICAS</th>
                                                        <th scope="col">CELULAR</th>
                                                        <th scope="col">EMAIL</th>
                                                        <th scope="col">DIRECCIÓN</th>
                                                        <th scope="col">PAZ Y SALVO</th>
                                                        <th scope="col">REGISTRO DE TÍTULO EN EL MINISTERIO DE EDUCACIÓN</th>
                                                        <th scope="col">CÉDULA</th>
                                                        <th scope="col">TÍTULO DE BACHILLER</th>
                                                        <th scope="col">FOTOS</th>
                                                        <th scope="col">INSCRIPCIÓN/COMPROMISO(DOCUMENTO)</th>
                                                        <th scope="col">INSCRIPCIÓN/COMPROMISO</th>
                                                        <th scope="col">INSCRITO por</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listEstudMatriDoc.map(consola => (
                                                        <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                            <td >{consola.num_secuencial}</td>
                                                            <td >{consola.observacion}</td>
                                                            <td >{consola.estado.toUpperCase()}</td>
                                                            <td >{consola.primer_apellido_estudiante}</td>
                                                            <td>{consola.segundo_apellido_estudiante}</td>
                                                            <td>{consola.primer_nombre_estudiante}</td>
                                                            <td>{consola.segundo_nombre_estudiante}</td>
                                                            <td >{consola.numero_identificacion_estudiante}</td>
                                                            <td >{consola.descrip_tipo_identif.toUpperCase()}</td>
                                                            <td >{consola.descripcion_genero.toUpperCase()}</td>
                                                            <td >{this.state.modalidad.toUpperCase()}</td>
                                                            <td >{this.state.descripCarrE.toUpperCase()}</td>
                                                            <td >{consola.paralelo}</td>
                                                            <td >{consola.pais_residencia.toUpperCase()}</td>
                                                            <td >{consola.ciudad_residencia.toUpperCase()}</td>
                                                            <td >{consola.coordinacion}</td>
                                                            <td >{consola.celular_estudiante}</td>
                                                            <td >{consola.email_estudiante}</td>
                                                            <td >{consola.direccion_estudiante}</td>
                                                            <td >{consola.pazysalvo}</td>
                                                            <td >{consola.registro_titulo}</td>
                                                            <td >{consola.cedula_fisico}</td>
                                                            <td >{consola.titulo_fisico}</td>
                                                            <td >{consola.foto_fisico}</td>
                                                            <td >{consola.docinscompro_fisico}</td>
                                                            <td >{consola.estado_acta}</td>
                                                            <td >{consola.nomadministrativo}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>


                                            <Modal size='lg' isOpen={this.state.modalReportAsignatutasEst}>
                                                <ModalHeader>
                                                    <div className="alert alert-success" role="alert" >
                                                        <label className="form-label">{this.state.descripCarrE}</label>
                                                    </div>
                                                </ModalHeader>
                                                <ModalBody  >
                                                    <FormGroup>

                                                        <div class="table-responsive">
                                                            <table className="table table-bordered" id="tablacientificos" width="100%" >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Código</th>
                                                                        <th scope="col">Asignatura</th>
                                                                        <th scope="col">Nivel</th>
                                                                        <th scope="col">No Matrícula</th>
                                                                        <th scope="col">Paralelo</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.listasignaturas.map(consola => (
                                                                        <tr key={consola.id_asignatura}>
                                                                            <td>
                                                                                {consola.codigo_asignatura}

                                                                            </td>
                                                                            <td>{consola.descripcion_asignatura}
                                                                            </td>
                                                                            <td>{consola.nivel_asignatura}</td>
                                                                            <td>{consola.tipo_matricula_asignatura}
                                                                            </td>
                                                                            <td> {consola.nombre_paralelo}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAsignaturas}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>

                                        <div class="table-responsive">
                                            <ReactHTMLTableToExcel
                                                id="botonExportarExcel"
                                                className="btn btn-success"
                                                table="tablalistestuddoc"
                                                filename="estado_documentmatriculas"
                                                sheet="Estado_doc "
                                                buttonText="Exportar a Excel"
                                            />
                                        </div>

                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
