import React, { Component } from 'react'

export default class FooterDashboard extends Component {
    render() {
        return (
            
                <footer className="py-4 bg-light mt-auto">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between small">
                            <div className="text-muted">Copyright &copy; ISTMAS 2022</div>
                            <div className="text-muted">
                                <b>Dirección:</b> Loja 31-15 y Buenos Aires, Riobamba
                                &middot;
                                <b>Teléfonos:</b> 03-2961680,+593 988183081
                                &middot;
                                <b>Correo:</b> u.comunicacion@istmas.edu.ec
                            </div>
                        </div>
                    </div>
                </footer>
                
                
            
        )
    }
}
