import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
//import "./inscripcionhojasestilocol.css";
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as xlsx from "xlsx";

const cookie = new Cookies();
const urlListaUsuariosAdm = ApiUrl + "listusuariosadmucrecep"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlInsDatosInteresado = ApiUrl + "insertardatosinteresado"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlListaInteresados = ApiUrl + "consultarinteresados"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlEliminarInteresado = ApiUrl + "eliminardatosinteresado"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlIngInteresadosExcel = ApiUrl + "inginteresadosexcel"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlListarAreasInteres = ApiUrl + "consultarareasinteres"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;

let listaauxiliar = [];
const itemsPerPage = 3;

export default class ReporteInteresadosUC extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            listadmin: [],
            listareas: [],
            ruta: "",
            pagina: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            justClicked: null,
            listinteresados: [],
            form1: {
            },
            passwordmod: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalModUsuario: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    //controles del modal de  un registro de abono de matricula
    componentDidMount() {
        //listar los estudiantes que pagaron su matricula  en el periodo actual
        axios.get(urlListaInteresados)
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                this.setState({ listinteresados: respuesta.data.data });
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }
                    
                }
                );
                const EstudianteBackup = estud;
                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //this.handlePageClick();
                //FIN DE LA PAGINACION
            })
        axios.get(urlListaUsuariosAdm)
            .then(respuesta => {
                this.setState({ listadmin: respuesta.data.data });
            })
        axios.get(urlListarAreasInteres)
            .then(respuesta => {
                this.setState({ listareas: respuesta.data.data });
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listinteresados
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNombres = item.nombre_interesado.toUpperCase()
            const itemDataCiudad = item.ciudad_interesado.toUpperCase()
            const itemDataOrigen = item.origen_interesado.toUpperCase()
            const campo = itemDataNombres + " " + itemDataCiudad + itemDataOrigen;
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }
    ocultarModificarUsu = () => {
        this.setState({ modalModUsuario: false });
    }
    mostrarModalModUsu = (registro) => {
        $(function () {
            $("#id").val(registro.id_interesado);
            $("#nombres").val(registro.nombre_interesado);
            $("#telefono").val(registro.telefono_interesado);
            $("#correo").val(registro.correo_interesado);
            $("#ciudad").val(registro.ciudad_interesado);
            $("#origen").val(registro.origen_interesado);
            $("#area").val(registro.area_interesado);
            $("#estado").val(registro.estado_interesado);
            $("#observaciones").val(registro.observaciones_interesado);
            $("#responsable").val(registro.administrativo_id);
        });

        this.setState({ modalModUsuario: true });
        this.setState({ id: registro.id_interesado });
        this.setState({ nombres: registro.nombre_interesado });
        this.setState({ telefono: registro.telefono_interesado });
        this.setState({ correo: registro.correo_interesado });
        this.setState({ ciudad: registro.ciudad_interesado });
        this.setState({ origen: registro.origen_interesado });
        this.setState({ area: registro.area_interesado });
        this.setState({ estado: registro.estado_interesado });
        this.setState({ observaciones: registro.observaciones_interesado });
        this.setState({ responsable: registro.administrativo_id });
    }

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalModUsuario: true });
    }

    //Ingresar y modificar los datos del interesado
    registrarDatosInteresado = () => {

        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlInsDatosInteresado, {
                            id: this.state.id,
                            nombres: this.state.nombres,
                            telefono: this.state.telefono,
                            correo: this.state.correo,
                            ciudad: this.state.ciudad,
                            origen: this.state.origen,
                            area: this.state.area,
                            estado: this.state.estado,
                            observaciones: this.state.observaciones,
                            responsable: this.state.responsable,
                        })
                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {

                                this.setState({ estado: "Datos registrados correctamente" });
                                swal({
                                    text: "Datos registrados correctamente",
                                    icon: "success",
                                    button: "Aceptar",

                                });
                                //Actualizar la tabla de usuarios
                                axios.get(urlListaInteresados)
                                    .then(respuesta => {
                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ listinteresados: respuesta.data.data });
                                        if (this.state.pageCount == 1) {
                                            this.setState({ itemOffset: 0 });
                                        }
                                        const estud = respuesta.data.data;
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                        this.setState((state) => {
                                            return { itemOffset: newOffset }
                                        });
                                        const endOffset = newOffset + itemsPerPage;
                                        //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState({ itemOffset: newOffset });
                                        //alert("Count:"+this.state.pageCount)
                                        //alert(this.state.itemOffset);
                                        //FIN DE LA PAGINACION
                                    })
                                this.setState({ modalModUsuario: false });
                            }
                            else {
                                this.setState({ modalModUsuario: false });
                            }

                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            //window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });

    }

    eliminarInteresado = (id) => {
        swal({
            title: "Advertencia",
            text: "Está seguro de continuar?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(urlEliminarInteresado, {
                        id: id,
                    })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos Eliminados Exitosamente!!",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                //Actualizar la tabla de usuarios
                                axios.get(urlListaInteresados)
                                    .then(respuesta => {
                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ listinteresados: respuesta.data.data });
                                        const estud = respuesta.data.data;
                                        if (this.state.pageCount == 1) {
                                            this.setState({ itemOffset: 0 });
                                        }
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                        this.setState((state) => {
                                            return { itemOffset: newOffset }
                                        });
                                        const endOffset = newOffset + itemsPerPage;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState({ itemOffset: newOffset });
                                        //FIN DE LA PAGINACION
                                    })
                                this.setState({ modalModUsuario: false });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    readUploadFile = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //e.preventDefault();
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        };
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                console.log(json);
                axios.post(urlIngInteresadosExcel, {
                    interesados: json,
                }, axiosConfig)
                    .then(response => {
                        if (response.data.success === true) {
                            swal({
                                text: "Datos procesados correctamente",
                                icon: "success",
                                button: "Aceptar",
                            });
                            //Actualizar la tabla de usuarios
                            axios.get(urlListaInteresados)
                                .then(respuesta => {
                                    listaauxiliar = respuesta.data.data;
                                    this.setState({ listinteresados: respuesta.data.data });
                                    if (this.state.pageCount == 1) {
                                        this.setState({ itemOffset: 0 });
                                    }
                                    //this.setState({ itemOffset: 0 });
                                    const estud = respuesta.data.data;
                                    this.setState((state) => {
                                        return { estud: estud }
                                    }
                                    );
                                    const EstudianteBackup = estud;

                                    this.setState((state) => {
                                        return { EstudianteBackup: EstudianteBackup }
                                    });
                                    //PAGINACION
                                    const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                    this.setState((state) => {
                                        return { itemOffset: newOffset }
                                    });
                                    const endOffset = newOffset + itemsPerPage;
                                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                    this.setState({ itemOffset: newOffset });
                                    //FIN DE LA PAGINACION
                                })
                            this.setState({ modalModUsuario: false });
                        } else {
                            swal({
                                text: "No se actualizó los datos",
                                icon: "error",
                                button: "Aceptar",
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ error: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",

                        });
                    });
            };
            reader.readAsArrayBuffer(e.target.files[0]);
            $(function () {
                $("#upload").val('');
            });
        }
    }

    mayusNombre = async (e) => {
        $("#nombres").val(e.target.value.toUpperCase());
        this.setState({ nombres: e.target.value.toUpperCase() })
        //await this.setState({ [e.target.value]: e.target.value.toUpperCase() })
    }

    mayusCiudad = async (e) => {
        $("#ciudad").val(e.target.value.toUpperCase());
        this.setState({ ciudad: e.target.value.toUpperCase() })
        //await this.setState({ [e.target.value]: e.target.value.toUpperCase() })
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de Interesados (General)
                        </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        INTERESADOS
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Nombres</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Correo</th>
                                                        <th scope="col">Ciudad</th>
                                                        <th scope="col">Origen</th>
                                                        <th scope="col">Área</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Registro</th>
                                                        <th scope="col">Responsable</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_interesado}  >
                                                                <td >{consola.nombre_interesado}</td>
                                                                <td >{consola.telefono_interesado}</td>
                                                                <td >{consola.correo_interesado}</td>
                                                                <td >{consola.ciudad_interesado}</td>
                                                                <td >{consola.origen_interesado}</td>
                                                                <td >{consola.area_interesado}</td>
                                                                <td >
                                                                    <td >
                                                                        {(() => {
                                                                            if (consola.estado_interesado == "EN ESPERA")
                                                                                return <span class="mensajealerta"> EN ESPERA</span>;
                                                                            else
                                                                                return consola.estado_interesado;
                                                                        })()}
                                                                    </td>
                                                                </td>
                                                                <td >{consola.fecharegistro_interesado}</td>
                                                                <td >{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarModalModUsu(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                    <button onClick={() => this.eliminarInteresado(consola.id_interesado)} > <i className=" fas fa-trash" ></i></button>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" hidden>
                            <table id="tablainteresadosexcel" className="table  text-left"  >
                                <thead >
                                    <tr >
                                        <th scope="col">Nombres</th>
                                        <th scope="col">Celular</th>
                                        <th scope="col">Correo</th>
                                        <th scope="col">Ciudad</th>
                                        <th scope="col">Origen</th>
                                        <th scope="col">Área</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Registro</th>
                                        <th scope="col">Responsable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listinteresados.map((consola) => (
                                        <tr class="list-afiliados" key={this.state.estud.id_interesado}  >
                                            <td >{consola.nombre_interesado}</td>
                                            <td >{consola.telefono_interesado}</td>
                                            <td >{consola.correo_interesado}</td>
                                            <td >{consola.ciudad_interesado}</td>
                                            <td >{consola.origen_interesado}</td>
                                            <td >{consola.area_interesado}</td>
                                            <td >{consola.estado_interesado}</td>
                                            <td >{consola.fecharegistro_interesado}</td>
                                            <td >{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" size='lg' isOpen={this.state.modalModUsuario}>
                                    <ModalHeader>
                                        <h5></h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <label className="form-label"><h5>DATOS DE INTERESADO</h5></label>
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Nombres:</label>
                                                            <input type="text" id="nombres" name="nombres" onChange={this.handleChange} onKeyUp={this.mayusNombre} className="form-control" placeholder="Ingrese los nombres del interesado" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Teléfono:</label>
                                                            <input type="text" id="telefono" name="telefono" className="form-control" placeholder="Ingrese el teléfono del interesado" onChange={this.handleChange} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Correo:</label>
                                                            <input type="text" id="correo" name="correo" className="form-control" placeholder="Ingrese el correo del interesado" onChange={this.handleChange} required />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Ciudad:</label>
                                                            <input type="text" id="ciudad" name="ciudad" onChange={this.handleChange} onKeyUp={this.mayusCiudad} className="form-control" placeholder="Ingrese la ciudad del interesado" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Origen:</label>
                                                            <select className="form-select" id="origen" name="origen" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="">Seleccione</option>
                                                                <option value="LLAMADA">LLAMADA</option>
                                                                <option value="WHATSAPP">WHATSAPP</option>
                                                                <option value="MESSENGER">MESSENGER</option>
                                                                <option value="PÁGINA WEB">PÁGINA WEB</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Estado:</label>
                                                            <select className="form-select" id="estado" name="estado" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="">Seleccione</option>
                                                                <option value="EN ESPERA">EN ESPERA</option>
                                                                <option value="ATENDIDO INSCRITO">ATENDIDO INSCRITO</option>
                                                                <option value="ATENDIDO NO INSCRITO">ATENDIDO NO INSCRITO</option>
                                                                <option value="DESCARTADO">DESCARTADO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Observaciones:</label>
                                                            <textarea id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="Ingrese las observaciones presentadas" maxlength="500" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Responsable:</label>
                                                            <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="">Seleccione</option>
                                                                {this.state.listadmin.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar">
                                                        <div className="mb-3">
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Área:</label>
                                                            <select className="form-select" id="area" name="area" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="">Seleccione</option>
                                                                {this.state.listareas.map(person => <option key={person.nombre_area_interes} value={person.nombre_area_interes}  > {person.nombre_area_interes}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarDatosInteresado(this.state.form1)} >Registrar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModificarUsu}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar text-center" >
                                <div className="mb-3">
                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        onClick={() => this.mostrarModalIngresar()} >
                                        <b>Nuevo Interesado</b>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                <div className="mb-3">
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        class="boton_file"
                                        onChange={this.readUploadFile}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                <div className="mb-3">
                                    <ReactHTMLTableToExcel
                                        id="botonExportarExcel"
                                        className="btn btn-success"
                                        table="tablainteresadosexcel"
                                        filename="matrizinteresadosexcel"
                                        sheet="pagina 1 "
                                        buttonText="Descargar Datos"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


