import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';

const urlListarEstudCarrPeriod = ApiUrl + "listestudmatriperiodcarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarAsignaturas = ApiUrl + "listasignmatrireport" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class CalificacionesEst extends Component {

    constructor(props) {
        super(props)
        this.state = {
            carrera: [],
            estado: "",
            listasignaturas: [],
            listaperiodos: [],
            idPeriodo: 1,
            form1: {
            },
            //Variables para búsqueda
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportAsignatutasEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //listar todos los periodos
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula; //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1

        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }

    //Mostrar las asignaturas matriculadas de un estudiante
    mostrarAsignaturasMat = (registro) => {
        axios.post(urlListarAsignaturas, {
            idEstudiante: registro.id_estudiante,
            idPeriodo: this.state.idPeriodo,
            idCarrera: this.state.idCarrera
        })
            .then(response => {
                if (response.data.success === true) {
                    const listasignaturas = response.data.data;
                    this.setState({ listasignaturas });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ modalReportAsignatutasEst: true, form1: registro });
    }

    ocultarModalReportAsignaturas = () => {
        this.setState({ modalReportAsignatutasEst: false });
    }
    //Listas estudiamntes matriculados en un periodo y carrera
    handleChangeEstudCarrera = () => {
        axios.post(urlListarEstudCarrPeriod, {
            idPeriodSelect: this.state.idPeriodo,
            idCarreraSelect: this.state.idCarrera
        })
            .then(respuesta => {

                if (respuesta.data.success == true) {
                    listaauxiliar = respuesta.data.data;

                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;
                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.state.itemOffset = 0;
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  DE LA PAGINACION
                } else {
                    this.setState({ error: "¡No existe información!" });
                    swal({
                        text: "¡No existe información!",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Calificaciones de los estudiantes</b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeEstudCarrera} /><br />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        Calificaciones de los estudiantes
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Calificaciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td > {consola.numero_identificacion_estudiante} </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarAsignaturasMat(consola)}  > <i className="fas fa-clipboard-check" ></i></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <Modal size='lg' isOpen={this.state.modalReportAsignatutasEst}>
                                                <ModalHeader>
                                                    {this.state.form1.primer_apellido_estudiante + " " + this.state.form1.segundo_apellido_estudiante + " " + this.state.form1.primer_nombre_estudiante + " " + this.state.form1.segundo_nombre_estudiante}
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>
                                                        <div class="table-responsive">
                                                            <table className="table table-bordered" id="tablacientificos" width="100%" >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Código</th>
                                                                        <th scope="col">Asignatura</th>
                                                                        <th scope="col">Nivel</th>
                                                                        <th scope="col">No Matrícula</th>
                                                                        <th scope="col">Paralelo</th>
                                                                        <th scope="col">Parcial 1(10)</th>
                                                                        <th scope="col">Parcial 2(10)</th>
                                                                        <th scope="col">Parcial 3(10)</th>
                                                                        <th scope="col">Prom. Parciales(6)</th>
                                                                        <th scope="col">Examen Final(4)</th>
                                                                        <th scope="col">Promedio Acum.(10)</th>
                                                                        <th scope="col">Exam.Susp(10)</th>
                                                                        <th scope="col">Prom. Final(10)</th>
                                                                        <th scope="col">Estado</th>
                                                                        <th scope="col">Asistencia(%)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.listasignaturas.map(consola => (
                                                                        <tr key={consola.id_asignatura}>
                                                                            <td>
                                                                                {consola.codigo_asignatura}
                                                                            </td>
                                                                            <td>{consola.descripcion_asignatura}
                                                                            </td>
                                                                            <td>{consola.nivel_asignatura}</td>
                                                                            <td>{consola.tipo_matricula_asignatura}
                                                                            </td>
                                                                            <td> {consola.nombre_paralelo} </td>
                                                                            <td>{consola.parcial1}</td>
                                                                            <td>{consola.parcial2}</td>
                                                                            <td>{consola.parcial3}</td>
                                                                            <td>
                                                                                {(() => {

                                                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                                                    var promparcial = ((suma * 6) / 30);
                                                                                    promparcial = promparcial.toFixed(2);
                                                                                    return promparcial;

                                                                                })()}
                                                                            </td>
                                                                            <td>
                                                                                {(() => {
                                                                                    var examenfinal = ((consola.examen_final * 4) / 10);
                                                                                    examenfinal = examenfinal.toFixed(2);
                                                                                    return examenfinal;
                                                                                })()}
                                                                            </td>
                                                                            <td>
                                                                                {(() => {
                                                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                                                    var promparcial = ((suma * 6) / 30);
                                                                                    var promacum = promparcial + ((consola.examen_final * 4) / 10);
                                                                                    promacum = promacum.toFixed(2);
                                                                                    return promacum;
                                                                                })()}
                                                                            </td>
                                                                            <td>{consola.examen_suspension}</td>
                                                                            <td>
                                                                                {(() => {
                                                                                    var examenfinal = ((consola.examen_final * 4) / 10);
                                                                                    var suma = consola.parcial1 + consola.parcial2 + consola.parcial3;
                                                                                    var promparcial = ((suma * 6) / 30);
                                                                                    var promacum = promparcial + examenfinal;
                                                                                    var promediof = promacum.toFixed(2);
                                                                                    if (promacum >= 7) {
                                                                                        var promediof = promacum.toFixed(2);
                                                                                        return promediof;
                                                                                    }
                                                                                    else {
                                                                                        if (((promacum >= 4) && (promacum < 7)) && (consola.examen_suspension == 0)) {
                                                                                            var promediof = promacum.toFixed(2);;
                                                                                            return promediof;
                                                                                        }
                                                                                        else {

                                                                                            if (((promacum >= 4) && (promacum < 7)) && (consola.examen_suspension > 0)) {
                                                                                                var promediof = (promacum + consola.examen_suspension) / 2;
                                                                                                var resultado = promediof.toFixed(2);
                                                                                                return resultado;
                                                                                            }
                                                                                            else {
                                                                                                var promediof = promacum.toFixed(2);;
                                                                                                return promediof;
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                })()}
                                                                            </td>
                                                                            <td>
                                                                                {(() => {
                                                                                    switch (consola.equivalencia) {
                                                                                        case 0: return "REPROBADO";
                                                                                        case 1: return "APROBADO";
                                                                                        default: return ""
                                                                                    }
                                                                                })()}
                                                                            </td>
                                                                            <td>{consola.asistencia}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAsignaturas}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
