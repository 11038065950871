import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../../services/ApiRest"
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';

const cookie = new Cookies();
const UrlInsertarTerminosyCond = ApiUrl + "insertarterminosycond" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71"; //pasar id del estudiante
const UrlConsultarTerminosyCondEst = ApiUrl + "consultarterminosycondest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71"; //pasar id del estudiante
const urlListarUltPeriodo = ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const idEstudiante = cookie.get("idEstudiante");

export default class AgendaEst extends Component {
    constructor(props) {
        super(props)

        this.state = {
            estudiante: [],
            id_periodo: "",
            modalTermyCond: false,
            boton: true,
        }

    }

    mostrarModalTermyCond = () => {
        this.setState({ modalTermyCond: true });
    }

    ocultarModalTermyCond = () => {
        this.setState({ modalTermyCond: false });
    }

    componentDidMount() {
        axios.get(urlListarUltPeriodo)
            .then(res => {
                this.setState({ id_periodo: res.data.id_periodo_academico });
                let date = new Date();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const day = date.getDate();
                const currentDate = year + "-" + month + "/" + day;
                this.setState({ fecha_actual: currentDate });

                axios.post(UrlConsultarTerminosyCondEst, {
                    id_estudiante: cookie.get("idEstudiante"),
                    id_periodo: this.state.id_periodo,
                    })
                        .then(respuesta => {
                            if(respuesta.data.data === false)
                            {
                                this.setState({ boton: false });
                            }else
                            {
                                this.setState({ boton: true });
                            }
                        })
            })
    }

    registrarTerminosyCond = () => {
        const f = new FormData();
        f.append("id_estudiante", idEstudiante);
        f.append("id_periodo", this.state.id_periodo);
        f.append("fecha_aceptacion", this.state.fecha_actual);

        axios.post(UrlInsertarTerminosyCond, f)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.ocultarModalTermyCond();
                    axios.post(UrlConsultarTerminosyCondEst, {
                        id_estudiante: cookie.get("idEstudiante"),
                        id_periodo: this.state.id_periodo,
                        })
                            .then(respuesta => {
                                if(respuesta.data.data === false)
                                {
                                    this.setState({ boton: false });
                                }else
                                {
                                    this.setState({ boton: true });
                                }
                            })
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>

                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-body">
                        <iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" src="https://heyzine.com/flip-book/e7c74ee3ce.html" style={{border: '1px solid lightgray', width: '100%', height: '400px'}}></iframe>
                     <div className="text-center">
                            <button className="btn  back-istmas centrar" disabled={this.state.boton} onClick={() => this.mostrarModalTermyCond()}>Términos y Condiciones</button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modalTermyCond} >
                    <ModalHeader>
                        Registro de Conocimiento y Aceptación
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div className="mb-3 centrar">
                                <p>Declaro haber leído, entendido y aceptado todas las disposiciones, políticas, reglamentos
                                    y normativa interna del Instituto Superior Tecnológico Dr. Misael Acosta Solís. 
                                </p>
                                <p>En caso de incumplimiento de mis obligaciones, me sujetaré a lo dispuesto en el Régimen 
                                    Sancionatorio del Reglamento del Estudiante, así como a la normativa, disposiciones internas y externas aplicables.
                                </p>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <button class="btn btn-outline-primary" onClick={() => this.registrarTerminosyCond()} > Aceptar</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
