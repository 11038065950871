import axios from 'axios';
import React, { Component, useState } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import ReactPaginate from 'react-paginate'
import generatePDF from "./reportGenerator";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const carrerasUrl = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstadosMatri = ApiUrl + "listestadosmatr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaEstudEstadoMatr = ApiUrl + "listestudestadosmatr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;
export default class ReportEstudEstadoMatric extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            estud: [],
            ListEstMatEstado: [],
            estadosmat: [],
            EstudianteBackup: [],
            textBuscar: "",
            periodos: [],
            estado: "",
            idPeriodo: "1",
            idCarrera: "1",
            estadMat: "1",
            nomCarrera: "",
            nomEstado: "",
            nomPeriodo: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        //Tomar el nombre del período
        var nombreperiod = document.getElementById("idPeriodo");
        var selected = nombreperiod.options[nombreperiod.selectedIndex].text;
        this.setState({ nomPeriodo: selected });

    }

    componentDidMount() {
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })

        //Mostrar los estados de la matrícula
        axios.get(urlListarEstadosMatri)
            .then(respuesta => {
                const estadosmat = respuesta.data;
                this.setState({ estadosmat });

            })
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;   //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //Función de  páginación por medio de un clic
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }

    //Datos de la carrera
    handleChangeCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //Tomar el nombre de la carrera
        var nombrecarrera = document.getElementById("idCarrera");
        var selected = nombrecarrera.options[nombrecarrera.selectedIndex].text;
        this.setState({ nomCarrera: selected });
    }

    //Seleccionar un estado
    handleChangeEstado = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //Tomar el nombre del estado
        var nombreestado = document.getElementById("estadMat");
        var selected = nombreestado.options[nombreestado.selectedIndex].text;
        this.setState({ nomEstado: selected });
        axios
            .post(urlListaEstudEstadoMatr, {
                idcarrerae: this.state.idCarrera,
                idperiodoe: this.state.idPeriodo,
                idEstado: this.state.estadMat
            })
            .then(response => {
                if (response.data.success == true) {
                    listaauxiliar = response.data.data;
                    this.setState({ ListEstMatEstado: listaauxiliar });
                    const estud = response.data.data;

                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;
                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });

                    //Paginación
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //Fin paginación

                } else {
                    if (response.data.message == "vacio") {
                        const estudiantes = [];
                        this.setState({ estudiantes });
                        this.setState({ currentItems: null });

                        this.setState({ ListEstMatEstado: [] });
                        this.setState({ estado: "No existe datos para mostrar" });
                        swal({
                            text: "No existe datos para mostrar",
                            icon: "info",
                            button: "Aceptar",
                        });
                    }
                    else {
                        const estudiantes = [];
                        this.setState({ estudiantes });
                        this.setState({ currentItems: null });

                        this.setState({ ListEstMatEstado: [] });

                        this.setState({ estado: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",
                        });
                    }

                }
            })
            .catch(error => {
                const estudiantes = [];
                this.setState({ estudiantes });
                this.setState({ estado: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active"></li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de estudiantes según el estado de matrícula</b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" id="idPeriodo" name="idPeriodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label className="form-label"><b>Carrera</b></label>
                                        <select className="form-select" id="idCarrera" name="idCarrera" value={this.state.value} onChange={this.handleChangeCarrera} aria-label="Default select example">
                                            <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                            {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Estado</b></label>

                                    <select className="form-select" id="estadMat" name="estadMat" value={this.state.value} onChange={this.handleChangeEstado} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el estado</option>
                                        {this.state.estadosmat.map(person => <option key={person.id} value={person.id} > {person.estado}</option>)}
                                    </select>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left" id="tablamatriculaestado"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Ciclo</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td >{consola.numero_identificacion_estudiante}

                                                                </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>{consola.ciclo_nivel}</td>
                                                            </tr>
                                                        ))}

                                                </tbody>
                                            </table>
                                            <table id="tablamatriestadoexcel" className="table text-left" hidden>
                                                <thead >
                                                    <tr>
                                                        <td></td>
                                                        <td></td>


                                                        <td colspan="2" >
                                                            <img src="https://www.istmas.edu.ec/images/descargas/logos/logoh.png" width="35%" height="auto" />
                                                        </td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <th style={{ fontSize: 20 }} colspan="2" scope="col">

                                                            Lista de estudiantes con estado {this.state.nomEstado}

                                                        </th>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ 'align': 'left', 'font-weight': 'bold' }} scope="col">PERÍODO ACADÉMICO:</td>
                                                        <td colspan="2" scope="col">
                                                            {this.state.nomPeriodo}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ 'align': 'left', 'font-weight': 'bold' }} scope="col">CARRERA:</td>
                                                        <td colspan="2" scope="col">
                                                            {this.state.nomCarrera}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                    <tr>

                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">CÉDULA</th>
                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">PRIMER APELLIDO</th>
                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">SEGUNDO APELLIDO</th>
                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">PRIMER NOMBRE</th>
                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">SEGUNDO NOMBRE</th>
                                                        <th style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }} scope="col">CICLO</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.ListEstMatEstado.map(consola => (
                                                        <tr key={consola.id_estudiante}>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.numero_identificacion_estudiante}</td>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.primer_apellido_estudiante}</td>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.segundo_apellido_estudiante}</td>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.primer_nombre_estudiante}</td>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.segundo_nombre_estudiante}</td>
                                                            <td style={{ 'borderColor': "#4D4D4D", 'borderStyle': 'solid' }}>{consola.ciclo_nivel}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfooter>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr></tr>
                                                    <tr>
                                                    </tr>
                                                    <tr>
                                                    </tr>
                                                </tfooter>
                                            </table>
                                            <div className="row ">
                                                <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                    <div className="mb-3">
                                                        <ReactHTMLTableToExcel
                                                            id="botonExportarExcel"
                                                            className="btn btn-success"
                                                            table="tablamatriestadoexcel"
                                                            filename={"matriculas_estado_excel_" + this.state.estadMat}
                                                            sheet="Hoja 1"
                                                            buttonText="Exportar a Excel"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <ReactPaginate
                                                nextLabel="next >"
                                                onPageChange={this.handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                previousLabel="< previous"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
