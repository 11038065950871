import axios from 'axios';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../services/ApiRest";
import { ApiUrl } from '../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
import swal from 'sweetalert';
import { Alert } from 'bootstrap';
import ReactPaginate from 'react-paginate';

//Rutas de ingreso de matriz
const UrlListarEvaluaciones = ApiUrl + "listarplanes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarEvidencia = ApiUrl + "insertarproyecto" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEvidenciasxAdm = ApiUrl + "listarevidenciasxadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarUsiarioAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarMediosVerifexEvid = ApiUrl + "listarmediosverifexevid" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlInsertarMedioVerificacione = ApiUrl + "insertarmedioverificacione" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 4;

export default class GestionEvidenciasEval extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            ejes: [],
            modalReportIngresarObj: false,
            modalReportIngresarMedv: false,
            plan_seleccionado: '',
            eje_seleccionado: '',
            obj_seleccionado: '',
            ind_seleccionado: '',
            evid_seleccionada: '',
            nombre_plan: '',
            nombre_eje: '',
            varchivo: '',
            administrativos: [],
            //nuevas variables
            listaplanes: [],
            listaejes: [],
            listaobjetivos: [],
            listaindicadores: [],
            listaproyectos: [],
            listamedios: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            disableForm: true,
            disableForm2: true,
            archivo_medver: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeFile = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ archivo_medver: e.target.files });
    }

    handleChangeListarAdm() {
        axios.get(UrlListarUsiarioAdm)
            .then(res => {
                const administrativos = res.data.data;
                this.setState({ administrativos });
            })
    }

    handleChangeListarMediosVerife = async (e) => {
        axios
            .post(UrlListarMediosVerifexEvid, {
                id_evidencia: this.state.evid_seleccionada,
            })
            .then(response => {
                this.setState({ listamedios: response.data.data })
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarObjetivouno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_evidencia = "";
        var desc_evidencia = "";

        $(".afiliado:checked").each(function () {
            id_evidencia = $(this).parent().parent().find('td').eq(0).html();
            desc_evidencia = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ evid_seleccionada: id_evidencia })
        this.setState({ descevid_seleccionada: desc_evidencia })

        this.handleChangeListarMediosVerife();
        this.setState({ disableForm2: false });
    }


    handleChangeListarEvidenciasxAdm = () => {
        this.handleChangeListarAdm();
        const cookie = new Cookies();
        const idAdministrativo = cookie.get("idAdministrativo");

        axios
            .post(urlListarEvidenciasxAdm, {
                id: idAdministrativo,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ listamedios: [] })
                this.setState({ listaproyectos: response.data.data });
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                this.setState({ disableForm: false });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarEvidenciasxAdm();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalModificarMedVer = (registro) => {
        var vobservaciones = registro.obser_medioverificacione;
        if (vobservaciones == null) {
            vobservaciones = "";
        }
        var varchivo = registro.archivo_medioverificacione;
        if (varchivo == null) {
            varchivo = "";
        }
        $(function () {
            $("#id_medver").val(registro.id_medioverificacione);
            $("#desc_medver").val(registro.desc_medioverificacione);
            $("#archivo_medver_mod").val(varchivo);
            $("#obser_medver").val(vobservaciones);
            $("#estado_medver").val(registro.estado_medioverificacione);
        });

        this.setState({ id_medver: registro.id_medioverificacione });
        this.setState({ desc_medver: registro.desc_medioverificacione });
        this.setState({ archivo_medver_mod: varchivo });
        this.setState({ obser_medver: vobservaciones });
        this.setState({ estado_medver: registro.estado_medioverificacione });
        this.setState({ modalReportIngresarMedv: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarObj: false });
    }

    ocultarModalIngresarInd = () => {
        this.setState({ modalReportIngresarMedv: false });
    }

    mostrarModalIngresarMedVer = () => {
        this.setState({ id_medver: 0 });
        this.setState({ desc_medver: null });
        this.setState({ archivo_medver_mod: "" });
        this.setState({ archivo_medver: "" });
        this.setState({ obser_medver: "" });
        this.setState({ estado_medver: null });
        this.setState({ modalReportIngresarMedv: true });
    }

    VerificarExtensionArchivo = () => {
        var narchivo=0;
        if (this.state.archivo_medver != "") {
            var fileInput = document.getElementById('archivo_medver');
            var filePath = fileInput.value;
            var allowedExtensions = /(.pdf)$/i;
            if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransacciont != 3)) {
                swal({
                    text: "El archivo debe ser formato PDF",
                    icon: "error",
                    button: "Aceptar",
                });
                this.setState({ varchivo: 1 });
                narchivo=1;
            }
        }
        return(narchivo);
    }

    registrarMedioVerificacione = () => {
        if(this.VerificarExtensionArchivo() != 1){
            if (this.state.desc_medver != null && this.state.estado_medver != null) {
                const f = new FormData();
                f.append("id_medver", this.state.id_medver);
                f.append("desc_medver", this.state.desc_medver);
                f.append("archivo_medver", this.state.archivo_medver[0]);
                f.append("obser_medver", this.state.obser_medver);
                f.append("estado_medver", this.state.estado_medver);
                f.append("evidencia_id", this.state.evid_seleccionada);
                f.append("archivo_medver_mod", this.state.archivo_medver_mod);
    
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
    
                axios.post(UrlInsertarMedioVerificacione, f, config)
                    .then(response => {
                        if (response.data.success === true) {
                            swal({
                                text: "Operación Exitosa!!",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.handleChangeListarEvidenciasxAdm();
                            this.handleChangeListarMediosVerife();
                            this.ocultarModalIngresarInd();
                        }
                    })
                    .catch(error => {
                        this.setState({ error: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",
                        });
                    });
            } else {
                swal({
                    text: "Ingresar campos requeridos",
                    icon: "warning",
                    button: "Aceptar",
                });
            }
        }
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Evidencias</b>
                    </div>
                    <div className="card-body">
                        <div className="card border-dark text-center" hidden={this.state.disableForm}>
                            <div className="card-header ">
                                <b>REPORTE DE EVIDENCIAS</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Indicador</th>
                                            <th>Responsable</th>
                                            <th>Fecha de Entrega</th>
                                            <th>Estado</th>
                                            <th>Observaciones</th>
                                            <th>Metas</th>
                                            <th>Seleccionar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_evidencia}>
                                                <td class="id">{consola.id_evidencia}</td>
                                                <td class="desc">{consola.detalle_evidencia}</td>
                                                <td class="desc">{consola.descripcion_indicadore}</td>
                                                <td class="respon">{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                <td class="fecha">{consola.fechaentrega_evidencia}</td>
                                                <td class="estadoe">{consola.estado_evidencia}</td>
                                                <td class="obser">{consola.observ_evidencia}</td>
                                                <td class={consola.color}>{consola.porcentaje}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarObjetivouno} class="afiliado" /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div class="table-responsive">
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                                <Modal isOpen={this.state.modalReportIngresarObj} >
                                    <ModalHeader>
                                        Registro de Proyecto
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Descripción:</label>
                                                <input type="text" id="descripcion" name="descripcion" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" disabled />
                                                <label className="form-label">Responsable:</label>
                                                <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled>
                                                    <option value="">Seleccione</option>
                                                    {this.state.administrativos.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                </select>
                                                <label className="form-label">Fecha de entrega:</label>
                                                <input type="date" id="fentrega" name="fentrega" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Gasto estimado:</label>
                                                <input type="text" id="gasto" name="gasto" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Observaciones:</label>
                                                <input type="text" id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarProyecto()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <br /><br />
                        <div className="card border-dark text-center" hidden={this.state.disableForm2}>
                            <div className="card-header ">
                                <b>REPORTE DE MEDIOS DE VERIFICACIÓN</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Archivo</th>
                                            <th>Observaciones</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listamedios.map((consola) => (
                                            <tr key={consola.id_medioverificacione}>
                                                <td>{consola.id_medioverificacione}</td>
                                                <td>{consola.desc_medioverificacione}</td>
                                                <td>
                                                    {(() => {
                                                        if (consola.ruta) {
                                                            return <a href={consola.ruta} target="blank"><i class="fa fa-2x fa-file-pdf" aria-hidden="true"></i></a>
                                                        }
                                                    })()}
                                                </td>
                                                <td>{consola.obser_medioverificacione}</td>
                                                {(() => {
                                                    if (consola.estado_medioverificacione == "Pendiente") {
                                                        return <td class="estadodpt table-danger">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Corrección") {
                                                        return <td class="estadodpt table-info">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Completado") {
                                                        return <td class="estadodpt table-warning">{consola.estado_medioverificacione}</td>
                                                    }
                                                    if (consola.estado_medioverificacione == "Verificado") {
                                                        return <td class="estadodpt table-success">{consola.estado_medioverificacione}</td>
                                                    }
                                                })()}
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarMedVer(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarMedv} >
                                    <ModalHeader>
                                        Registro de Medio de Verificación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id_medver" class="id_medver" id="id_medver" value="" />
                                                <input type="hidden" name="archivo_medver_mod" class="archivo_medver_mod" id="archivo_medver_mod" value="" />
                                                <label className="form-label">Evidencia:</label>
                                                <textarea id="proyecto" name="proyecto" value={this.state.descevid_seleccionada} onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled></textarea>
                                                <label className="form-label">Descripción:</label>
                                                <input type="text" id="desc_medver" name="desc_medver" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" />
                                                <label className="form-label">Archivo:</label>
                                                <input type="file" id="archivo_medver" name="archivo_medver" onChange={this.handleChangeFile} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Observaciones:</label>
                                                <textarea id="obser_medver" name="obser_medver" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" disabled></textarea>
                                                <label className="form-label">Estado:</label>
                                                <select className="form-select" id="estado_medver" name="estado_medver" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                    <option value="" selected disabled>Seleccione</option>
                                                    <option value="Pendiente" disabled>Pendiente</option>
                                                    <option value="Corrección" disabled>Corrección</option>
                                                    <option value="Completado">Completado</option>
                                                    <option value="Verificado" disabled>Verificado</option>
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarMedioVerificacione()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarInd()}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarMedVer()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

