import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';

const cookie = new Cookies();

const baseUrl = ApiUrl + "listarpagosmatrixfechas" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
let listaauxiliar = [];
const itemsPerPage = 10;

export default class ListarEstuCanceladoMatricula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textBuscar: "",
            EstudianteBackup: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
    }

    handleChangeFecha = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //Listar los estudiantes que han cancelado completamente la matrícula
        axios
            .post(baseUrl, {
                //idEstudiante:cookie.get("idEstudiante"), 
                fechaIni: this.state.fechaIni,
                fechaFin: this.state.fechaFin,
            })
            .then(response => {
                //listaauxiliar= [];
                listaauxiliar = response.data.data;

                //BÚSQUEDA
                const estud = response.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })
    }

    //Listar estudiantes matriculados 
    componentDidMount = async (e) => {

    }

    //BUSQUEDA......

    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud;
        var newData = data.filter(function (item) {
            //variable del objeto

            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de Pagos de Matrículas por Fechas</b>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div class="row">
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                        <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Inicio:</b></label>
                                        <input type="date" name="fechaIni" className="form-control" onChange={this.handleChange} value={this.state.value} required />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Fin:</b></label>
                                        <input type="date" name="fechaFin" className="form-control" onChange={this.handleChange} value={this.state.value} required></input>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                    <div className="mb-3">
                                        <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeFecha} /><br />
                                        <ReactHTMLTableToExcel
                                            id="botonExportarExcel"
                                            className="btn btn-success"
                                            table="tablacientificos"
                                            filename="pagosmatriculaexcel"
                                            sheet="pagina 1 "
                                            buttonText="Exportar a Excel"
                                        />
                                    </div>
                                </div>
                            </div>
                            <table id="tablacientificos" hidden>
                                <thead>
                                    <tr>
                                        <th>Cédula</th>
                                        <th>Nombres y Apellidos</th>
                                        <th>Banco</th>
                                        <th>Tipo</th>
                                        <th>Número</th>
                                        <th>Valor</th>
                                        <th>Estado</th>
                                        <th>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaauxiliar.map((consola) => (
                                        <tr key={consola.id_pagos_matriculas}>
                                            <td>{consola.numero_identificacion_estudiante}</td>
                                            <td>{(() => {
                                                var segundoApellido = "";
                                                var segundoNombre = "";
                                                if (consola.segundo_apellido_estudiante != null) {
                                                    segundoApellido = consola.segundo_apellido_estudiante
                                                }
                                                if (consola.segundo_nombre_estudiante != null) {
                                                    segundoNombre = consola.segundo_nombre_estudiante
                                                }
                                                return consola.primer_apellido_estudiante + " " + segundoApellido + " " + consola.primer_nombre_estudiante + " " + segundoNombre;
                                            })()}
                                            </td>
                                            <td>{(() => {
                                                //Banco
                                                switch (consola.nombre_banco_beneficiario) {
                                                    case "1": return "Pacífico";
                                                    case "2": return "Pichincha-3257594104";
                                                    case "3": return "Pichincha-2100271381";
                                                    default: return ""
                                                }
                                            })()}</td>
                                            <td>

                                                {(() => {
                                                    //Tipo de deposito

                                                    switch (consola.tipo_deposito) {
                                                        case 1: return "Depósito";
                                                        case 2: return "Transferencia";
                                                        default: return "Efectivo"
                                                    }
                                                })()}</td>
                                            <td>{consola.num_deposito}</td>
                                            <td>${consola.valor_deposito}</td>
                                            <td>

                                                {(() => {
                                                    //Tipo de deposito

                                                    switch (consola.estado_abono_matricula) {
                                                        case 1: return "Enviado";
                                                        case 2: return "Aprobado";
                                                        case 3: return "Rechazado";
                                                        case 5: return "Aprobado Vicerrectorado";
                                                        case 6: return "Rechazado Vicerrectorado";
                                                        default: return ""
                                                    }
                                                })()}</td>
                                            <td>{consola.fecha_registro_comprobante}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            <div class="table-responsive">
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th>Cédula</th>
                                            <th>Nombres y Apellidos</th>
                                            <th>Banco</th>
                                            <th>Tipo</th>
                                            <th>Número</th>
                                            <th>Valor</th>
                                            <th>Estado</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_pagos_matriculas}>
                                                <td>{consola.numero_identificacion_estudiante}</td>
                                                <td>
                                                    {(() => {
                                                        var segundoApellido = "";
                                                        var segundoNombre = "";
                                                        if (consola.segundo_apellido_estudiante != null) {
                                                            segundoApellido = consola.segundo_apellido_estudiante
                                                        }
                                                        if (consola.segundo_nombre_estudiante != null) {
                                                            segundoNombre = consola.segundo_nombre_estudiante
                                                        }
                                                        return consola.primer_apellido_estudiante + " " + segundoApellido + " " + consola.primer_nombre_estudiante + " " + segundoNombre;
                                                    })()}

                                                </td>
                                                <td>{(() => {
                                                    //Banco
                                                    switch (consola.nombre_banco_beneficiario) {
                                                        case "1": return "Pacífico";
                                                        case "2": return "Pichincha-3257594104";
                                                        case "3": return "Pichincha-2100271381";
                                                        default: return ""
                                                    }
                                                })()}</td>
                                                <td> {(() => {
                                                    //Tipo de deposito

                                                    switch (consola.tipo_deposito) {
                                                        case 1: return "Depósito";
                                                        case 2: return "Transferencia";
                                                        default: return "Efectivo"
                                                    }
                                                })()}</td>
                                                <td>{consola.num_deposito}</td>
                                                <td>${consola.valor_deposito}</td>
                                                <td>{(() => {
                                                    //Tipo de deposito
                                                    switch (consola.estado_abono_matricula) {
                                                        case 1: return "Enviado";
                                                        case 2: return "Aprobado";
                                                        case 3: return "Rechazado";
                                                        case 5: return "Aprobado Vicerrectorado";
                                                        case 6: return "Rechazado Vicerrectorado";
                                                        default: return ""
                                                    }
                                                })()}</td>
                                                <td>{consola.fecha_registro_comprobante}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
