import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const UrlListarPlanes = ApiUrl + "listarplanes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarObjetivo = ApiUrl + "insertarobjetivo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarEjesxPlan = ApiUrl + "listarejesxplan" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarObjxPlanesEjesAdm = ApiUrl + "listarobjetivosxplanesejesadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarIndicadoresxObj = ApiUrl + "listarindicadoresxobj" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarIndicador = ApiUrl + "insertarindicador" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarProyectosxInd = ApiUrl + "listarproyectosxind" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarUsuarioAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarProyecto = ApiUrl + "insertarproyecto" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarMediosVerifxProy = ApiUrl + "listarmediosverifxproy" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlInsertarMedioVerificacion = ApiUrl + "insertarmedioverificacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 3;

export default class GestionObjIndicador extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            ejes: [],
            modalReportIngresarObj: false,
            modalReportIngresarInd: false,
            modalReportIngresarProy: false,
            modalReportIngresarMedv: false,
            plan_seleccionado: '',
            eje_seleccionado: '',
            ind_seleccionado: '',
            idobj_seleccionado: '',
            descobj_seleccionado: '',
            pro_seleccionado: '',
            nombre_plan: '',
            nombre_eje: '',
            administrativos: [],
            //nuevas variables
            listaplanes: [],
            listaejes: [],
            listaindicadores: [],
            listaproyectos: [],
            listamedios: [],
            porcentaje_eje: 0,
            color_eje: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            disableForm: true,
            disableForm2: true,
            disableForm3: true,
            disableForm4: true,
            archivo_medver: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeFile = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ archivo_medver: e.target.files });
    }

    handleChangeDevolverIdPlan = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ plan_seleccionado: this.state.idPlan });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_plan: option })
        this.handleChangeListarEjesxPlan();
    }

    handleChangeDevolverIdEje = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ eje_seleccionado: this.state.idEje });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_eje: option })
    }

    handleChangeListarIndicadores = async (e) => {
        axios
            .post(UrlListarIndicadoresxObj, {
                id_obj: this.state.idobj_seleccionado,
            })
            .then(response => {
                this.setState({listaindicadores: response.data.data})
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarObjetivouno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_obj = "";
        var desc_obj = "";

        $(".afiliado:checked").each(function () {
            id_obj = $(this).parent().parent().find('td').eq(0).html();
            desc_obj=$(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ idobj_seleccionado: id_obj })
        this.setState({ descobj_seleccionado: desc_obj })
        
        this.handleChangeListarIndicadores();
        this.setState({ disableForm2: false });
    }

    handleChangeListarObjxPlanesEjesAdm = async (e) => {
        this.setState({ disableForm2: true });
        this.setState({ disableForm3: true });
        this.setState({ disableForm4: true });
        axios
            .post(UrlListarObjxPlanesEjesAdm, {
                id_plan: this.state.plan_seleccionado,
                id_eje: this.state.eje_seleccionado,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_eje: response.data.data1 });
                this.setState({ color_eje: response.data.data2 });
                
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ disableForm: false });
    }

    handleChangeListarObjSinCerrar = async (e) => {
        axios
            .post(UrlListarObjxPlanesEjesAdm, {
                id_plan: this.state.plan_seleccionado,
                id_eje: this.state.eje_seleccionado,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_eje: response.data.data1 });
                this.setState({ color_eje: response.data.data2 });
                
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ disableForm: false });
    }

    handleChangeListarPlanes = async (e) => {
        //planes
        axios.get(UrlListarPlanes)
            .then(respuesta => {
                this.setState({ listaplanes: respuesta.data.data });
            })
    }

    handleChangeListarEjesxPlan = async (e) => {
        //ejes
        axios
            .post(UrlListarEjesxPlan, {
                id: this.state.plan_seleccionado,
            })
            .then(response => {
                this.setState({ listaejes: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarPlanes();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalReportIngresarObj: true });
    }

    mostrarModalIngresarInd = () => {
        this.setState({ id_ind: 0 });
        this.setState({ modalReportIngresarInd: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_objetivosop);
            $("#eje").val(registro.id_eje);
            $("#objetivo").val(registro.descripcion_objetivosop);
        });

        this.setState({ id: registro.id_objetivosop });
        this.setState({ eje: registro.id_eje });
        this.setState({ objetivo: registro.descripcion_objetivosop });
        this.setState({ modalReportIngresarObj: true });
    }

    mostrarModalModificarInd = (registro) => {
        $(function () {
            $("#id_ind").val(registro.id_indicador);
            $("#desc_ind").val(registro.descripcion_indicador);
        });

        this.setState({ id_ind: registro.id_indicador });
        this.setState({ desc_ind: registro.descripcion_indicador });
        this.setState({ modalReportIngresarInd: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarObj: false });
    }

    ocultarModalIngresarInd = () => {
        this.setState({ modalReportIngresarInd: false });
    }

    registrarObjetivo = () => {

        const f = new FormData();
        f.append("id", this.state.id);
        f.append("id_plan", this.state.plan_seleccionado);
        f.append("id_eje", this.state.eje_seleccionado);
        f.append("objetivo", this.state.objetivo);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarObjetivo, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.post(UrlListarObjxPlanesEjesAdm, {
                        id_plan: this.state.plan_seleccionado,
                        id_eje: this.state.eje_seleccionado,
                    })
                        .then(respuesta => {
                            listaauxiliar = respuesta.data.data;
                            if (this.state.pageCount == 1) {
                                this.setState({ itemOffset: 0 });
                            }

                            //PAGINACION
                            const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                            this.setState((state) => {
                                return { itemOffset: newOffset }
                            });
                            const endOffset = newOffset + itemsPerPage;
                            //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState({ itemOffset: newOffset });
                        })
                    this.ocultarModalIngresar();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    registrarIndicador = () => {

        const f = new FormData();
        f.append("id", this.state.id_ind);
        f.append("desc_ind", this.state.desc_ind);
        f.append("objetivosop_id", this.state.idobj_seleccionado);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarIndicador, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    this.handleChangeListarIndicadores();
                    this.ocultarModalIngresarInd();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarIndicadoruno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_ind = "";
        var desc_pro = "";

        $(".afiliado:checked").each(function () {
            id_ind = $(this).parent().parent().find('td').eq(0).html();
            desc_pro = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ ind_seleccionado: id_ind })

        this.handleChangeListarProyectosxInd();
        this.setState({ disableForm3: false });
    }

    handleChangeListarAdm() {
        axios.get(UrlListarUsuarioAdm)
            .then(res => {
                const administrativos = res.data.data;
                this.setState({ administrativos });
            })
    }

    handleChangeListarProyectosxInd = async (e) => {
        this.handleChangeListarAdm();

        axios
            .post(urlListarProyectosxInd, {
                id: this.state.ind_seleccionado,
            })
            .then(response => {
                this.setState({ listaproyectos: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }

    mostrarModalIngresarProy = () => {
        $(function () {
            $("#id").val();
            $("#descripcion").val();
            $("#responsable").val();
            $("#fentrega").val();
            $("#gasto").val();
            $("#observaciones").val();
        });
        this.setState({ id: 0 });
        this.setState({ descripcion: "" });
        this.setState({ responsable: "" });
        this.setState({ fentrega: "" });
        this.setState({ gasto: "" });
        this.setState({ observaciones: "" });
        this.setState({ modalReportIngresarProy: true });
    }

    mostrarModalModificarProy = (registro) => {
        var vfentrega = registro.fechaentrega_proyecto;
        var vgasto = registro.gastoestimado_proyecto;
        var vobser = registro.observaciones;
        if (vfentrega == null) {
            vfentrega = "";
        }
        if (vgasto == null) {
            vgasto = "";
        }
        if (vobser == null) {
            vobser = "";
        }
        $(function () {
            $("#id").val(registro.id_proyecto);
            $("#descripcion").val(registro.descripcion_proyecto);
            $("#responsable").val(registro.id_administrativo);
            $("#fentrega").val(vfentrega);
            $("#gasto").val(vgasto);
            $("#observaciones").val(vobser);
        });

        this.setState({ id: registro.id_proyecto });
        this.setState({ descripcion: registro.descripcion_proyecto });
        this.setState({ responsable: registro.id_administrativo });
        this.setState({ fentrega: vfentrega });
        this.setState({ gasto: vgasto });
        this.setState({ observaciones: vobser });
        this.setState({ modalReportIngresarProy: true });
    }

    ocultarModalIngresarProy = () => {
        this.setState({ modalReportIngresarProy: false });
    }

    registrarProyecto = () => {

        const f = new FormData();
        f.append("id", this.state.id);
        f.append("descripcion", this.state.descripcion);
        f.append("responsable", this.state.responsable);
        f.append("fechaentrega", this.state.fentrega);
        f.append("gastoestimado", this.state.gasto);
        f.append("observaciones", this.state.observaciones);
        f.append("indicador", this.state.ind_seleccionado);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarProyecto, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.handleChangeListarProyectosxInd();
                    this.ocultarModalIngresarProy();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    handleChangeListarMediosVerif = async (e) => {
        axios
            .post(UrlListarMediosVerifxProy, {
                id_pro: this.state.pro_seleccionado,
            })
            .then(response => {
                this.setState({ listamedios: response.data.data })
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    seleccionarProyectouno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_pro = "";
        var desc_pro = "";

        $(".afiliado:checked").each(function () {
            id_pro = $(this).parent().parent().find('td').eq(0).html();
            desc_pro = $(this).parent().parent().find('td').eq(1).html();
        });

        this.setState({ pro_seleccionado: id_pro })
        this.setState({ descpro_seleccionado: desc_pro })

        this.handleChangeListarMediosVerif();
        this.setState({ disableForm4: false });
    }

    mostrarModalIngresarMedVer = () => {
        $(function () {
            $("#id_medver").val();
            $("#desc_medver").val();
            $("#archivo_medver_mod").val();
            $("#obser_medver").val();
            $("#estado_medver").val();
        });
        this.setState({ id_medver: 0 });
        this.setState({ desc_medver: "" });
        this.setState({ archivo_medver_mod: "" });
        this.setState({ obser_medver: "" });
        this.setState({ estado_medver: "" });
        this.setState({ modalReportIngresarMedv: true });
    }

    ocultarModalIngresarMedVer = () => {
        this.setState({ modalReportIngresarMedv: false });
    }

    mostrarModalModificarMedVer = (registro) => {
        var vobservaciones = registro.obser_medioverificacion;
        if (vobservaciones == null) {
            vobservaciones = "";
        }
        var varchivo = registro.archivo_medioverificacion;
        if (varchivo == null) {
            varchivo = "";
        }
        $(function () {
            $("#id_medver").val(registro.id_medioverificacion);
            $("#desc_medver").val(registro.desc_medioverificacion);
            $("#archivo_medver_mod").val(varchivo);
            $("#obser_medver").val(vobservaciones);
            $("#estado_medver").val(registro.estado_medioverificacion);
        });

        this.setState({ id_medver: registro.id_medioverificacion });
        this.setState({ desc_medver: registro.desc_medioverificacion });
        this.setState({ archivo_medver_mod: varchivo });
        this.setState({ obser_medver: vobservaciones });
        this.setState({ estado_medver: registro.estado_medioverificacion });
        this.setState({ modalReportIngresarMedv: true });
    }

    registrarMedioVerificacion = () => {
        const f = new FormData();
        f.append("id_medver", this.state.id_medver);
        f.append("desc_medver", this.state.desc_medver);
        f.append("archivo_medver", this.state.archivo_medver[0]);
        f.append("obser_medver", this.state.obser_medver);
        f.append("estado_medver", this.state.estado_medver);
        f.append("proyecto_id", this.state.pro_seleccionado);
        f.append("archivo_medver_mod", this.state.archivo_medver_mod);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(UrlInsertarMedioVerificacion, f, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.handleChangeListarObjSinCerrar();
                    this.handleChangeListarIndicadores();
                    this.handleChangeListarProyectosxInd();
                    this.handleChangeListarMediosVerif();
                    this.ocultarModalIngresarMedVer();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Objetivos de Eje por Plan</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Planes Institucionales</b></label>
                                    <select className="form-select" name="idPlan" value={this.state.value} onChange={this.handleChangeDevolverIdPlan} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un plan</option>
                                        {this.state.listaplanes.map(person => <option key={person.id_plan} data-nombre={person.nombre_plan} value={person.id_plan} > {person.nombre_plan}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Eje</b></label>
                                    <select className="form-select" name="idEje" value={this.state.value} onChange={this.handleChangeDevolverIdEje} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un eje</option>
                                        {this.state.listaejes.map(person => <option key={person.id_eje} data-nombre={person.nombre_eje} value={person.id_eje} > {person.nombre_eje}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b> </b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarObjxPlanesEjesAdm} />
                                </div>
                            </div>
                        </div>
                        <div className="card border-dark text-center text-white" hidden={this.state.disableForm}>
                            <div className="card-header" class={this.state.color_eje}>
                                <b>PORCENTAJE DEL EJE: {this.state.porcentaje_eje}%</b>
                            </div>
                        </div>
                        <div className="card border-dark text-center" hidden={this.state.disableForm}>
                            <div className="card-header ">
                                <b>REPORTE DE OBJETIVOS</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Porcentaje</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_objetivosop}>
                                                <td class="id">{consola.id_objetivosop}</td>
                                                <td class="objetivos">{consola.descripcion_objetivosop}</td>
                                                <td class={consola.color}>{consola.porcentaje_obj}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarObjetivouno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div class="table-responsive">
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                                <Modal isOpen={this.state.modalReportIngresarObj} >
                                    <ModalHeader>
                                        Registro de Eje por Plan
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Plan:</label>
                                                <input type="text" id="plan" name="plan" onChange={this.handleChange} value={this.state.nombre_plan} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Eje:</label>
                                                <input type="text" id="plan" name="plan" onChange={this.handleChange} value={this.state.nombre_eje} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Objetivo:</label>
                                                <input type="text" id="objetivo" name="objetivo" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarObjetivo()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresar()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="card border-dark text-center" hidden={this.state.disableForm2}>
                            <div className="card-header ">
                                <b>REPORTE DE INDICADORES POR OBJETIVO</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Porcentaje</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listaindicadores.map((consola) => (
                                            <tr key={consola.id_indicador}>
                                                <td class="id">{consola.id_indicador}</td>
                                                <td>{consola.descripcion_indicador}</td>
                                                <td class={consola.color}>{consola.porcentaje_ind}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarIndicadoruno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarInd(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarInd} >
                                    <ModalHeader>
                                        Registro de Indicadores por Objetivo
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id_ind" class="id_ind" id="id_ind" value="" />
                                                <label className="form-label">Objetivo:</label>
                                                <textarea id="objetivo" name="objetivo" value={this.state.descobj_seleccionado} onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled></textarea>
                                                <label className="form-label">Indicador:</label>
                                                <input type="text" id="desc_ind" name="desc_ind" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarIndicador()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarInd()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarInd()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <div className="card border-dark text-center" hidden={this.state.disableForm3}>
                            <div className="card-header ">
                                <b>REPORTE DE PROYECTOS</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Responsable</th>
                                            <th>Fecha de Entrega</th>
                                            <th>Gasto estimado</th>
                                            <th>Observaciones</th>
                                            <th>Metas</th>
                                            <th>Seleccionar</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listaproyectos.map((consola) => (
                                            <tr key={consola.id_proyecto}>
                                                <td class="id">{consola.id_proyecto}</td>
                                                <td class="desc">{consola.descripcion_proyecto}</td>
                                                <td class="respon">{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                <td class="fecha">{consola.fechaentrega_proyecto}</td>
                                                <td class="gestimado">${consola.gastoestimado_proyecto}</td>
                                                <td class="observ">{consola.observaciones}</td>
                                                <td class={consola.color}>{consola.porcentaje}%</td>
                                                <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarProyectouno} class="afiliado" /></td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarProy(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarProy} >
                                    <ModalHeader>
                                        Registro de Proyecto
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Descripción:</label>
                                                <input type="text" id="descripcion" name="descripcion" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Responsable:</label>
                                                <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.administrativos.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                </select>
                                                <label className="form-label">Fecha de entrega:</label>
                                                <input type="date" id="fentrega" name="fentrega" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Gasto estimado:</label>
                                                <input type="text" id="gasto" name="gasto" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Observaciones:</label>
                                                <input type="text" id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarProyecto()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarProy()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarProy()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <div className="card border-dark text-center" hidden={this.state.disableForm4}>
                            <div className="card-header ">
                                <b>REPORTE DE MEDIOS DE VERIFICACIÓN</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Descripción</th>
                                            <th>Archivo</th>
                                            <th>Observaciones</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listamedios.map((consola) => (
                                            <tr key={consola.id_medioverificacion}>
                                                <td>{consola.id_medioverificacion}</td>
                                                <td>{consola.desc_medioverificacion}</td>
                                                <td>
                                                    {(() => {
                                                        if (consola.ruta) {
                                                            return <a href={consola.ruta} target="blank"><i class="fa fa-2x fa-file-pdf" aria-hidden="true"></i></a>
                                                        }
                                                    })()}
                                                </td>
                                                <td>{consola.obser_medioverificacion}</td>
                                                {(() => {
                                                    if (consola.estado_medioverificacion == "Pendiente") {
                                                        return <td class="estadodpt table-danger">{consola.estado_medioverificacion}</td>
                                                    }
                                                    if (consola.estado_medioverificacion == "Corrección") {
                                                        return <td class="estadodpt table-info">{consola.estado_medioverificacion}</td>
                                                    }
                                                    if (consola.estado_medioverificacion == "Completado") {
                                                        return <td class="estadodpt table-warning">{consola.estado_medioverificacion}</td>
                                                    }
                                                    if (consola.estado_medioverificacion == "Verificado") {
                                                        return <td class="estadodpt table-success">{consola.estado_medioverificacion}</td>
                                                    }
                                                })()}
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificarMedVer(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Modal isOpen={this.state.modalReportIngresarMedv} >
                                    <ModalHeader>
                                        Registro de Medio de Verificación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id_medver" class="id_medver" id="id_medver" value="" />
                                                <input type="hidden" name="archivo_medver_mod" class="archivo_medver_mod" id="archivo_medver_mod" value="" />
                                                <label className="form-label">Proyecto:</label>
                                                <textarea id="proyecto" name="proyecto" value={this.state.descpro_seleccionado} onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled></textarea>
                                                <label className="form-label">Descripción:</label>
                                                <input type="text" id="desc_medver" name="desc_medver" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                <label className="form-label">Archivo:</label>
                                                <input type="file" id="archivo_medver" name="archivo_medver" onChange={this.handleChangeFile} className="form-control" placeholder="" autocomplete="off" />
                                                <label className="form-label">Observaciones:</label>
                                                <textarea type="text" id="obser_medver" name="obser_medver" onChange={this.handleChange} className="form-control"></textarea>
                                                <label className="form-label">Estado:</label>
                                                <select className="form-select" id="estado_medver" name="estado_medver" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                    <option value="">Seleccione</option>
                                                    <option value="Pendiente">Pendiente</option>
                                                    <option value="Corrección">Corrección</option>
                                                    <option value="Completado">Completado</option>
                                                    <option value="Verificado">Verificado</option>
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarMedioVerificacion()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresarMedVer()}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                                <div class="card-footer text-muted">
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            onClick={() => this.mostrarModalIngresarMedVer()} >
                                            <b>Registrar</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

