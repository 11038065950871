import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/";
const idAdministrativo = cookie.get("idAdministrativo");
export default class SidebarColecturia extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })
            })
    }

    render() {
        return (
            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">PERSONAL COLECTURIA</div>
                            <Link to="/colecturia">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal A</b>
                                </div>
                            </Link>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAdministrativo" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-hospital-user"></i>
                                </div>
                                COLECTURIA
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAdministrativo" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/colecturia/MatriculaCarrPeriodoColecturia">  <div className="nav-link movil" href="#">Costos de Matrícula</div></Link>
                                    <Link to="/colecturia/ColegiaturaCarrPeriodoColecturia">  <div className="nav-link movil" href="#">Costos de Colegiatura</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseInscripcion" aria-expanded="false" aria-controls="collapseLayouts">

                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-clipboard"></i>
                                </div>
                                MATRÍCULAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseInscripcion" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">

                                </nav>
                            </div>

                            <div className="collapse" id="collapseInscripcion" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/colecturia/ListarEstInscriAproColecturia">  <div className="nav-link movil" href="#">Pagos de Matrícula</div></Link>
                                    <Link to="/colecturia/ListarEstudiantesMatriculaCancelada">  <div className="nav-link movil" href="#">Estudiantes con Pagos Cancelados</div></Link>
                                    <Link to="/colecturia/ListarEstuPagoMatriFecha">  <div className="nav-link movil" href="#">Pagos de Matrículas por Fechas</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseMatriculas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                COLEGIATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>

                            <div className="collapse" id="collapseMatriculas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">

                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/colecturia/ColegiaturasEstCarreraCiclo">  <div className="nav-link movil" href="#">Reporte de Colegiaturas (Carrera y Ciclo)</div></Link  >
                                    <Link to="/colecturia/ColegiaturasEstxFechas">  <div className="nav-link movil" href="#">Reporte de Estudiante por Fechas</div></Link  >
                                    <Link to="/colecturia/ColegiaturasGeneralxFechas">  <div className="nav-link movil" href="#">Reporte General de abonos por fechas</div></Link  >
                                    <Link to="/colecturia/ColegiaturasEstColegiaturaPagada">  <div className="nav-link movil" href="#">Reporte de Colegiaturas Pagadas</div></Link  >
                                    <Link to="/colecturia/ColegiaturasEstColegiaturaUnPago">  <div className="nav-link movil" href="#">Reporte de Pagos  del ciclo completo</div></Link>
                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapsePlataforma" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                PLATAFORMA
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapsePlataforma" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/colecturia/EstadoEstCarreraCicloColect">  <div className="nav-link movil" href="#">Estado de Estudiantes</div></Link>
                                </nav>
                            </div>
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>                                       </div>
                                PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePerfil" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">

                                    <Link to="/colecturia/VerPerfilColecturia" ><div className="nav-link movil" href="#">Ver Perfil </div></Link>

                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}
