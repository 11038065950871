import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarACalidad'
import { ApiUrl } from '../components/services/ApiRest';
import GestionEvaluaciones from '../components/acalidad/evaluaciones/GestionEvaluaciones'
import GestionCriterios from '../components/acalidad/evaluaciones/GestionCriterios'
import GestionCriteriosEvaluacion from '../components/acalidad/evaluaciones/GestionCriteriosEvaluacion'
import GestionSubcritIndicador from '../components/acalidad/evaluaciones/GestionSubcritIndicador'

const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasACalidad extends Component {
    componentDidMount() {

        axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" )
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante });
           
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})
          })
    }    

    render() {
        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
        }

        return ( 
            <>
                <NavbarGeneral /> 
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                        
                            <div className="container-fluid">                       
                            <Contenido /> 
                                    {/*  Calidad */}
                                    <Route path="/acalidad/GestionEvaluaciones" component={GestionEvaluaciones} /> 
                                    <Route path="/acalidad/GestionCriterios" component={GestionCriterios} /> 
                                    <Route path="/acalidad/GestionCriteriosEvaluacion" component={GestionCriteriosEvaluacion} /> 
                                    <Route path="/acalidad/GestionSubcritIndicador" component={GestionSubcritIndicador} /> 
                            </div>
                        </main>
                        <FooterDashboard />                        
                    </div>                 
                </div>            
            </>
        )
    }
}
