/*import axios from 'axios'
import React, { Component } from 'react'*/
import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//Rutas de ingreso de matriz
const cookie = new Cookies();
const inscribirUrlListarCarrera = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera = ApiUrl + "numnivelescarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlColegituraPeriodC = ApiUrl + "ingresarcolegituraperiodcarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = inscribirUrlListarCarrera;
const UrlListarEstxFecha = ApiUrl + "UrlListarEstxFecha" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarPagosCuotaEstxMatriculaFechas = ApiUrl + "ListarPagosCuotaEstxMatriculaFechas" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
let listaauxiliar = [];
const itemsPerPage = 10;

export default class ColegiaturasEstxFechas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            nivel1: '',
            nivel2: '',
            nivel3: '',
            nivel4: '',
            nivel5: '',
            nivel6: '',
            EstudianteBackup: [],
            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            carrera: [],
            estado: '',

            //nuevas variables
            nombresEstudiante: "",
            cedulaEstudianteSelect: "",
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listaestudiantes: [],
            listacuotasestudiante: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }

    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.periodoId,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    handleChangeListarEstxFecha = async (e) => {
        this.setState({ listacuotasestudiante: [] });
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(UrlListarEstxFecha, {
                fechaIni: this.state.fechaIni,
                fechaFin: this.state.fechaFin,
            })
            .then(response => {
                var array = response.data.data;
                var hash = {};
                cookie.set('fechaIni', this.state.fechaIni, { path: "/" });
                cookie.set('fechaFin', this.state.fechaFin, { path: "/" });
                array = array.filter(function (current) {
                    var exists = !hash[current.id_matricula];
                    hash[current.id_matricula] = true;
                    return exists;
                });
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const estud = response.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //periodos
        axios.get(ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const periodos = respuesta.data.descripcion_periodo_academico;
                const periodosId = respuesta.data.id_periodo_academico;
                this.setState({ periodoId: respuesta.data.id_periodo_academico });
                this.setState({ periodos });
                this.setState({ periodosId });
                // console.log("periodos"+periodosId);
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listaestudiantes;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " "+ itemDataPrimerNom +" " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if(text=="")
        {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    registrarColegiaturasPeriodo = async (e) => {
        // e.preventDefault();
        let materiasselect = [];
        document.querySelectorAll('#tablaasignaturas tbody tr').forEach(function (e) {
            var idnivel = e.querySelector('.codigo').innerText
            var valorM = $("#" + idnivel + "valormensual").val();
            var valorT = $("#" + idnivel + "valortotal").val();
            //  alert(valorM);
            //  alert(valorT);
            let fila = {
                idnivelf: idnivel,
                valormf: valorM,
                valortf: valorT,
            };
            materiasselect.push(fila);
        });
        var combinancion = JSON.stringify(materiasselect);

        axios
            .post(inscribirUrlColegituraPeriodC, {//guarda para enviar los atributos y envia
                periodoactualef: this.state.periodoId,//5periodosId
                listColegiaturasPf: materiasselect,
                idCarreraf: this.state.idCarrera,
            })
            .then(response => {
                const list = response.data.data;
                if (response.data.success === true) {
                    swal({
                        text: "Datos  registrados",
                        icon: "success",
                        button: "Aceptar",
                    });

                    //llama a la lista de colegiaturas para actualizar
                    axios
                        .post(devuelNumNivelesCarrera, {
                            idCarreraf: this.state.idCarrera,
                            periodoIdf: this.state.periodoId,
                        })
                        .then(response => {
                            const listaniveles1 = response.data.data;
                            this.setState({ listaniveles: listaniveles1 });
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                }
                else {
                    swal({
                        text: "Datos no  registrados",
                        icon: "error",
                        button: "Aceptar",
                    });
                }
            })
            .catch(error => {
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                swal({
                    text: "Datos no  registrados",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }

    seleccionarEstudianteuno = async (e) => {
        this.setState({ listacuotasestudiante: [] });
        await this.setState({ [e.target.name]: e.target.value })
        var id_matricula = "";
        var id_carrera = "";
        var id_ciclo = "";
        $(".afiliado:checked").each(function () {
            id_matricula = $(this).parent().parent().find('td').eq(0).html();
            id_carrera = $(this).parent().parent().find('td').eq(1).html();
            id_ciclo = $(this).parent().parent().find('td').eq(2).html();
        });

        axios
            .post(UrlListarPagosCuotaEstxMatriculaFechas, {
                idMatriculaEst: id_matricula,
                idCarreraEst: id_carrera,
                idCicloEst: id_ciclo,
                fechaIni: cookie.get("fechaIni"),
                fechaFin: cookie.get("fechaFin"),
            })
            .then(response => {
                this.setState({ listacuotasestudiante: response.data.data });
            })
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Colegiaturas de Estudiante por Fecha</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Inicio:</b></label>
                                    <input type="date" name="fechaIni" className="form-control" onChange={this.handleChange} value={this.state.value} required />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Fin:</b></label>
                                    <input type="date" name="fechaFin" className="form-control" onChange={this.handleChange} value={this.state.value} required></input>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEstxFecha} /><br />
                                    <ReactHTMLTableToExcel
                                        id="botonExportarExcel"
                                        className="btn btn-success"
                                        table="tablacientificos"
                                        filename="pagosmatriculaexcel"
                                        sheet="pagina 1 "
                                        buttonText="Exportar a Excel"
                                    />
                                </div>
                            </div>
                        </div>
                        <table id="tablacientificos" hidden>
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Nombres y Apellidos</th>
                                    <th>Banco</th>
                                    <th>Tipo</th>
                                    <th># Comprobante</th>
                                    <th>Valor</th>
                                    <th>Mes</th>
                                    <th>Estado</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listacuotasestudiante.map((consola) => (
                                    <tr key={consola.id_pagos_matriculas}>
                                        <td>{consola.numero_identificacion_estudiante}</td>
                                        <td>{consola.primer_apellido_estudiante + " " + consola.segundo_apellido_estudiante + " " + consola.primer_nombre_estudiante + " " + consola.segundo_nombre_estudiante}</td>
                                        <td>{(() => {
                                            //Banco
                                            switch (consola.nombre_banco_benef_pens) {
                                                case "1": return "Pacífico";
                                                case "2": return "Pichincha-3257594104";
                                                case "3": return "Pichincha-2100271381";
                                                case "4": return "Austro";
                                                default: return ""
                                            }
                                        })()}</td>
                                        <td>
                                            {(() => {
                                                //Tipo de deposito

                                                switch (consola.tipo_deposito_pens) {
                                                    case 1: return "Depósito";
                                                    case 2: return "Transferencia";
                                                    default: return "Efectivo"
                                                }
                                            })()}</td>
                                        <td>{consola.num_deposito_pens}</td>
                                        <td>${consola.cantidad_abono_pens}</td>
                                        <td>{consola.descripcion_pension_mes}</td>
                                        <td>
                                            {(() => {
                                                //Tipo de deposito

                                                switch (consola.estado_abono_pens) {
                                                    case 1: return "Enviado";
                                                    case 2: return "Aprobado";
                                                    case 3: return "Rechazado";
                                                    case 4: return "Aprobado Vicerrectorado";
                                                    case 5: return "Rechazado Vicerrectorado";
                                                    default: return ""
                                                }
                                            })()}</td>
                                        <td>{consola.fecha_regis_depos_pens}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <div class="table-responsive">
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>Cédula</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Primer Nombre</th>
                                        <th>Segundo Nombre</th>
                                        <th>Seleccionar</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_matricula}>
                                            <td class="id oculto">{consola.id_matricula}</td>
                                            <td class="carrera oculto">{consola.carrera_id}</td>
                                            <td class="ciclo oculto">{consola.ciclo_nivel}</td>
                                            <td class="codigo">{consola.numero_identificacion_estudiante}</td>
                                            <td class="primerape">{consola.primer_apellido_estudiante}</td>
                                            <td class="segundoape">{consola.segundo_apellido_estudiante}</td>
                                            <td class="primernom">{consola.primer_nombre_estudiante}</td>
                                            <td class="segundonom">{consola.segundo_nombre_estudiante}</td>
                                            <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        <b>ABONOS DE COLEGIATURA DEL ESTUDIANTE</b>
                                    </div>

                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">MES</th>
                                                        <th>BANCO</th>
                                                        <th>TIPO</th>
                                                        <th># COMPROBANTE</th>
                                                        <th>VALOR</th>
                                                        <th>ESTADO</th>
                                                        <th>FECHA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listacuotasestudiante.map(consola => (
                                                        <tr key={consola.id_pension_mes}>
                                                            <td class="mes">{consola.descripcion_pension_mes}</td>
                                                            <td>
                                                                {(() => {
                                                                    //Banco
                                                                    switch (consola.nombre_banco_benef_pens) {
                                                                        case "1": return "Pacífico";
                                                                        case "2": return "Pichincha-3257594104";
                                                                        case "3": return "Pichincha-2100271381";
                                                                        case "4": return "Austro";
                                                                        default: return ""
                                                                    }
                                                                })()}
                                                            </td>
                                                            <td>

                                                                {(() => {
                                                                    //Tipo de deposito
                                                                    switch (consola.tipo_deposito_pens) {
                                                                        case 1: return "Depósito";
                                                                        case 2: return "Transferencia";
                                                                        default: return "Efectivo"
                                                                    }
                                                                })()}</td>
                                                            <td>{consola.num_deposito_pens}</td>
                                                            <td>${consola.cantidad_abono_pens}</td>
                                                            <td>
                                                                {(() => {
                                                                    //Estado de abono
                                                                    switch (consola.estado_abono_pens) {
                                                                        case 1: return "Enviado";
                                                                        case 2: return "Aprobado";
                                                                        case 3: return "Rechazado";
                                                                        case 4: return "Aprobado Vicerrectorado";
                                                                        case 5: return "Rechazado Vicerrectorado";
                                                                        default: return ""
                                                                    }
                                                                })()}</td>
                                                            <td>{consola.fecha_regis_depos_pens}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

