import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const UrlListarEstPeriodoTodos = ApiUrl + "ListarEstPeriodoTodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModificarDatosEst = ApiUrl + "modifdatosestudiante" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class EstadoEstCarreraCicloColect extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            EstudianteBackup: [],

            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            carrera: [],
            usuario_moodle: [],
            estado: '',

            //nuevas variables
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listaestudiantes: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {

            this.setState({ modalPagoColegituraMes: false });
            this.setState({ modalReportAbonosCuotaEst: false });

        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }





    handleChangeListarEstTodosPeriodo = async (e) => {
        this.setState({ usuario_moodle: [] });
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(UrlListarEstPeriodoTodos, {
                idPeriodo: this.state.periodoId,
            })
            .then(response => {
                var array = response.data.data;
                var hash = {};
                array = array.filter(function (current) {
                    var exists = !hash[current.id_matricula];
                    hash[current.id_matricula] = true;
                    return exists;
                });
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listaestudiantes;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);


        //periodos
        axios.get(ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const periodos = respuesta.data.descripcion_periodo_academico;
                const periodosId = respuesta.data.id_periodo_academico;
                this.setState({ periodoId: respuesta.data.id_periodo_academico });
                this.setState({ periodos });
                this.setState({ periodosId });
                // console.log("periodos"+periodosId);
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listaestudiantes;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    seleccionarEstudianteuno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var email = "";
        var idEstudiante = "";
        $(".afiliado:checked").each(function () {
            email = $(this).parent().parent().find('td').eq(3).html();
            idEstudiante = $(this).parent().parent().find('td').eq(4).html();
            //nombrescompletos=$(this).parent().parent().find('td').eq(1).html()+" "+$(this).parent().parent().find('td').eq(2).html()+" "+$(this).parent().parent().find('td').eq(3).html()+" "+$(this).parent().parent().find('td').eq(4).html();
        });
        $(function () {
            $("#CorreoEst").val(email);
        });
        this.setState({ idEstudianteSelect: idEstudiante });


    }


    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    actualizarCorreo = () => {
        axios
            .post(urlModificarDatosEst, {
                idEstud: this.state.idEstudianteSelect,
                emailEst: this.state.CorreoEst,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios
                        .post(UrlListarEstPeriodoTodos, {
                            idPeriodo: this.state.periodoId,
                        })
                        .then(response => {
                            var array = response.data.data;
                            var hash = {};
                            array = array.filter(function (current) {
                                var exists = !hash[current.id_matricula];
                                hash[current.id_matricula] = true;
                                return exists;
                            });
                            this.setState({ listaestudiantes: array });
                            listaauxiliar = this.state.listaestudiantes;
                            var cantidadestud = listaauxiliar.length;
                            this.setState({ cantidadestud });
                            //BÚSQUEDA
                            const EstudianteBackup = this.state.listaestudiantes;

                            this.setState((state) => {
                                return { EstudianteBackup: EstudianteBackup }
                            });

                            //PAGINACION
                            this.setState({ itemOffset: 0 });
                            const endOffset = this.state.itemOffset + itemsPerPage;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                            this.setState((state) => {
                                return { itemOffset: state.itemOffset + endOffset }
                            });

                            //FIN DE LA PAGINACION
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "No se actualizó los datos",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalPagoColegituraMes: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>Actualizar la información del estudiante</b>
                    </div>
                    <div className="card-body">

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 centrar" >


                                <div className="mb-3">

                                </div>

                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">
                                <div className="mb-3">

                                    <input class="btn back-istmas centrar" type="button" value="Todos" onClick={this.handleChangeListarEstTodosPeriodo} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Primer Apellido</th>
                                    <th>Segundo Apellido</th>
                                    <th>Primer Nombre</th>
                                    <th>Segundo Nombre</th>
                                    <th>Seleccionar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.currentItems && this.state.currentItems.map((consola) => (
                                    <tr key={consola.id_matricula}>
                                        <td class="id oculto">{consola.id_matricula}</td>
                                        <td class="carrera oculto">{consola.carrera_id}</td>
                                        <td class="ciclo oculto">{consola.ciclo_nivel}</td>
                                        <td class="email oculto">{consola.email_estudiante}</td>
                                        <td class="id_est oculto">{consola.id_estudiante}</td>
                                        <td class="codigo ">{consola.numero_identificacion_estudiante}</td>
                                        <td class="primerape">{consola.primer_apellido_estudiante}</td>
                                        <td class="segundoape">{consola.segundo_apellido_estudiante}</td>
                                        <td class="primernom">{consola.primer_nombre_estudiante}</td>
                                        <td class="segundonom">{consola.segundo_nombre_estudiante}</td>
                                        <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" /></td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive">
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                            <div className="card text-center ">
                                <div className="card-header ">
                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                        <div className="mb-3">
                                            <label className="form-label">Correo</label>
                                            <input type="email" id="CorreoEst" name="CorreoEst" className="form-control" placeholder="correo@hotmail.com" onChange={this.handleChange} required />
                                            <br />
                                            <button class="btn btn-outline-primary" onClick={this.actualizarCorreo}>Modificar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

