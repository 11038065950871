import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ApiUrl } from '../components/services/ApiRest'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarCoordinacionAcad'
import MatricularEstudiantes from '../components/secretaria/matriculas/MatricularEstudiante'
import MatriculasPeriodo from '../components/secretaria/matriculas/MatriculasPeriodo'
import MatrizMatriculados from '../components/secretaria/matriculas/MatrizMatriculados'
import CalificacionesEstud from '../components/coordinacionacad/calificaciones/CalificacionesEstud'
import AsignaturasEstCarreraCicloAdm from '../components/coordinacionacad/asignaturas/AsignaturasEstCarreraCicloAdm'
import AsignaturasDocentePeriodoAdm from '../components/coordinacionacad/asignaturas/AsignaturasDocentePeriodoAdm'
import MatriculasPeriodC from '../components/coordinacionacad/matriculas/MatriculasPeriodCoord'
import VerPerfil from '../components/coordinacionacad/perfil/VerPerfil'
import CalificacionDetall from '../components/coordinacionacad/calificaciones/CalificacionesEstudDetall'

const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/";
const idAdministrativo = cookie.get("idAdministrativo"); 

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasCoordinacionAcad extends Component {
    componentDidMount() {
         axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" )
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante }); 
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})
          })
    }    


    render() {
        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
         
        }


        return (
            
            <>
                <NavbarGeneral /> 
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                        
                            <div className="container-fluid">                       
                            <Contenido /> 
                                    {/* Matriculas */}                            
                                    <Route  path="/Secretaria/MatricularEstudiante"  component={MatricularEstudiantes} /> 
                                    <Route  path="/Secretaria/MatriculasPeriodo"  component={MatriculasPeriodo} /> 
                                    <Route  path="/Secretaria/MatrizMatriculados"  component={MatrizMatriculados} /> 
                                    <Route  path="/Coordinacionacad/AsignaturasEstCarreraCicloAdm"  component={AsignaturasEstCarreraCicloAdm} /> 
                                    <Route  path="/Coordinacionacad/AsignaturasDocentePeriodoAdm"  component={AsignaturasDocentePeriodoAdm} /> 
                                    <Route  path="/Coordinacionacad/CalificacionesEstud"  component={CalificacionesEstud} /> 
                                    <Route  path="/Coordinacionacad/MatriculasPeriodC"  component={MatriculasPeriodC} />
                                    <Route path="/Coordinacionacad/VerPerfilCoordAcad" component={VerPerfil} />
                                    <Route path="/Coordinacionacad/CalificacionesDetall" component={CalificacionDetall} />
                            </div>
                        </main>
                        <FooterDashboard />                        
                    </div>                 
                </div>            
            </>
        )
    }
}
