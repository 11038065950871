import axios from 'axios';
import React, { Component } from 'react'
import "../../services/ApiRest"
import { ApiUrl } from '../../services/ApiRest';
import '../../administrador/administrador.css';
import $ from 'jquery';
import swal from 'sweetalert';
import { Alert } from 'bootstrap';
const urlListaRolesAdm = ApiUrl + "listarolesadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarPersonal = ApiUrl + "ingpersonaladm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


export default class IngresarDatosDoc extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listroles: [],
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ fotografia: e.target.files })

    }
    componentDidMount() {
        axios.get(urlListaRolesAdm)
            .then(response => {
                const listroles = response.data.data;

                this.setState({ listroles });
            })
    }
    //Ingresar los datos de los docentes
    ingresarPersonal = async (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        if (this.state.password == this.state.confirmacionpassword) {
            if (this.state.fotografia == null) {
                swal({
                    text: "No se ha seleccionado ningun archivo",
                    icon: "error",
                    button: "Aceptar",

                });
            }
            else {

                //Crear la lista de roles seleccionados
                let rolesselect = [];
                $("input[type=checkbox]:checked").each(function () {
                    var rolseleccionado = $(this).parent().parent().find('td').eq(0).text();
                    let fila = {
                        irol: rolseleccionado,
                        codigorol: rolseleccionado,
                    };
                    rolesselect.push(fila);
                });
                var gsonroles = JSON.stringify(rolesselect);
              //  alert(combinancion);

                const f = new FormData();
                f.append("numidentificacion", this.state.numidentificacion);
                f.append("PrimerApell", this.state.PrimerApell.toUpperCase());
                f.append("SegundoApell", this.state.SegundoApell.toUpperCase());
                f.append("PrimerNom", this.state.PrimerNom.toUpperCase());
                f.append("SegudoNom", this.state.SegudoNom.toUpperCase());
                f.append("FechaNac", this.state.NumFechaNac);
                f.append("Correo", this.state.CorreoEst);
                f.append("numCelular", this.state.numCelular);
                f.append("telfConvencional", this.state.telfConvencional);
                f.append("direcciondomi", this.state.direcciondomi);
                f.append("password", this.state.confirmacionpassword);
                f.append("fotografia", this.state.fotografia[0]);
                f.append("listroles", gsonroles);
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                await axios
                    .post(urlIngresarPersonal, f)
                    .then(response => {
                        if (response.data.success === true) {
                            e.target.reset(); //resetea valores del formulario
                            this.setState({ estado: "Datos Agregados Correctamente" });
                            swal({
                                text: "Datos Agregados Correctamente",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.setState({ errorPrimerApell: '' });
                        }
                        else {

                            if (response.data.message = 'Existe') {
                                swal({
                                    text: "Ya se encuentra registrado",
                                    icon: "error",
                                    button: "Aceptar"

                                });
                                this.setState({ estado: "Ya se encuentra registrado" });
                            }
                            else {
                                if (response.data.message == "validation_error") {
                                    this.setState({ estado: "Formato del archivo incorrecto" });
                                    swal({
                                        text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                        icon: "error",
                                        button: "Aceptar",

                                    });
                                }
                                else {
                                    this.setState({ estado: response.data.message });
                                    swal({
                                        text: "Porfavor llenar nuevamente el formulario",
                                        icon: "error",
                                        button: "Aceptar"

                                    });
                                }
                            }

                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ estado: "Error No se pudo conectar con el servidor" });
                    })
            }
        }
        else {
            this.setState({ estado: "Las contraseñas no coinciden" });
            swal({
                text: "Las contraseñas no coinciden",
                icon: "error",
                button: "Aceptar"
            });
        }
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header">
                        <i className="far fa-user"></i>
                        <b>-Ingresar </b>
                    </div>
                    <div className="card-body">
                        <div className="row centrar" style={{ width: "100%", alignItems: "center" }}>
                            <div className="card shadow p-3 mb-5 bg-white rounded ">
                                <div className="card-header text-center head-form" >
                                    <h4>Ingresar los datos del personal</h4>
                                </div>
                                <div className="card-body ">
                                    <form className="" onSubmit={this.ingresarPersonal} style={{ padding: 10 }} id="create-course-form" >
                                        <div className="row ">
                                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Número de identificación</label>
                                                    <input type="text" id="numidentificacion" name="numidentificacion" onChange={this.handleChange} className="form-control" placeholder="Sin guión" required />
                                                    <div id="errorNumIdentificacionId" className="form-text color-validacion "   >{this.state.errorCedula}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Primer apellido</label>
                                                    <input type="text" id="PrimerApell" name="PrimerApell" className="form-control" placeholder="Primer Apellido" onChange={this.handleChange} required />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Segundo apellido</label>
                                                    <input type="text" id="SegundoApell" name="SegundoApell" className="form-control" placeholder="Segundo Apellido" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Primer nombre</label>
                                                    <input type="text" id="PrimerNom" name="PrimerNom" className="form-control" placeholder="Primer Nombre" onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row ">
                                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Segundo nombre</label>
                                                    <input type="text" id="SegudoNom" name="SegudoNom" className="form-control" placeholder="Segundo Nombre" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Ingrese la fecha de nacimiento</label>
                                                    <input type="date" id="NumFechaNac" name="NumFechaNac" onChange={this.handleChange} className="form-control" placeholder="1999-01-01" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Correo</label>
                                                    <input type="email" id="CorreoEst" name="CorreoEst" className="form-control" placeholder="correo@hotmail.com" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Número de celular</label>
                                                    <input type="text" id="numCelular" name="numCelular" className="form-control" placeholder="0990000000" onChange={this.handleChange} required />
                                                    <div id="errorCorreoId" className="form-text color-validacion "   ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Número de Convencional</label>
                                                    <input type="text" id="telfConvencional" name="telfConvencional" className="form-control" placeholder="Teléfono" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Dirección domiciliaria</label>
                                                    <input type="text" id="direcciondomi" name="direcciondomi" className="form-control" placeholder="Dirección" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Contraseña</label>
                                                    <input type="password" id="password" name="password" className="form-control" placeholder="Contraseña" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Confirmar contraseña</label>
                                                    <input type="password" id="confirmacionpassword" name="confirmacionpassword" onChange={this.handleChange} className="form-control" placeholder="Confirmar Contraseña" required />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Selecione el rol</label>

                                                </div>
                                                <div class="table-responsive">
                                                <table id="tablaroles" className="table  text-left" >
                                                    <thead >
                                                        <tr>
                                                            <th scope="col">Código</th>
                                                            <th scope="col">Rol</th>
                                                            <th scope="col">Asignar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.listroles.map(consola => (
                                                            <tr key={consola.id_rol}>
                                                                <td class="idrol">{consola.id_rol}</td>
                                                                <td class="descriprol" >{consola.descripcion_rol}</td>
                                                                <td class="selectrol">
                                                                    <input disabled={(this.state.disablmodmat) ? "disabled" : ""} name={"check" + consola.id_rol} id={"check" + consola.id_rol} type="checkbox" />
                                                                    {(() => {
                                                                        $('#check' + consola.id_rol).checked = false;
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                 </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                <div className="mb-3">
                                                    <label className="form-label">Subir su fotografía</label>
                                                    <input className="form-control" type="file" id="fotografia" name="fotografia" onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn  back-istmas centrar" style={{ margin: "5px" }}  > <b>Enviar Formulario</b></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        )
    }
}
