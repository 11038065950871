import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component, useState } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Cookies from 'universal-cookie';

const urlListEstudEstadDocumentMatri = ApiUrl + "listestuddocumentmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarAsignaturas = ApiUrl + "listasignmatrireport" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstadosMatri = ApiUrl + "listestadosmatr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCantidadMatriculados = ApiUrl + "cantmatriculados" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 3;
export default class EstadoDocumentosMatri extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            listaniveles: [],
            descripCarrE: "",
            estado: "",
            listaperiodos: [],
            listEstudMatriDoc: [],
            estadosmat: [],

        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        //Listar todos los períodos
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
        //Mostrar los estados de la matrícula
        axios.get(urlListarEstadosMatri)
            .then(respuesta => {
                const estadosmat = respuesta.data;
                this.setState({ estadosmat });

            })
    }


    handleChangeCantMatriculados = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarrera
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                const modalidadcarr = response.data.objcarrea[0].descripcion_modalidad_carrera;

                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                this.setState({ modalidad: modalidadcarr });
                //Cantidad de matriculados
                axios
                    .post(urlCantidadMatriculados, {
                        idPeriodoS: this.state.idPeriodoSelect,
                        idCarreraS: this.state.idCarrera,
                        numNiveles: nivelcarrest,
                        idEstado: this.state.estadMat
                    })
                    .then(response => {
                        if (response.data.success == true) {
                            this.setState({ listaniveles: response.data.data });
                            this.setState({ sumaTotal: response.data.sumat });
                        }
                        else {
                            swal({
                                text: "¡No existe información!",
                                icon: "info",
                                button: "Aceptar",
                            });

                        }

                    })

            })
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Cantidad de estudiantes matriculados</b>
                    </div>
                    <div className="card-body">

                        <div class="row">

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeCantMatriculados} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Estado de la matrícula</b></label>
                                    <select className="form-select" id="estadMat" name="estadMat" value={this.state.value} onChange={this.handleChangeCantMatriculados} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el estado</option>
                                        {this.state.estadosmat.map(person => <option key={person.id} value={person.id} > {person.estado}</option>)}
                                    </select>
                                </div>
                            </div>

                        </div>
                        <br />
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        CANTIDAD DE MATRICULADOS
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-center" id="tablacantmatri" name="tablacantmatri" >
                                                <thead >
                                                    <tr >
                                                        <th colSpan="2">{this.state.descripCarrE.toUpperCase()}</th>
                                                    </tr>
                                                    <tr >
                                                        <th scope="col">CICLO</th>
                                                        <th scope="col">CANTIDAD</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listaniveles.map(consola => (
                                                        <tr class="list-afiliados" key={consola.codnivel}  >
                                                            <td >{consola.nombre_ciclo}</td>
                                                            <td >{consola.cantidad}</td>
                                                        </tr>
                                                    ))}
                                                    <tr >
                                                        <th scope="col">Total:</th>
                                                        <th scope="col">{this.state.sumaTotal}</th>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div class="table-responsive">
                                            <ReactHTMLTableToExcel
                                                id="botonExportarExcel"
                                                className="btn btn-success"
                                                table="tablacantmatri"
                                                filename="cantidad_matriculados"
                                                sheet="cantidad "
                                                buttonText="Exportar a Excel"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
