import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,

} from "react-router-dom";

import LoginScreen from '../components/auth/LoginScreen';
import ErrorPermiso from '../components/errors/ErrorPermiso';
import Carrera from '../components/iniciales/Carrera';
import CarrerasScreen from '../components/iniciales/CarrerasScreen';
import RutasAdministrativo from './RutasAdministrativo';
import RutasSecretaria from './RutasSecretaria';
import RutasColecturia from './RutasColecturia';
import RutasVicerrectoradoAdm from './RutasVicerrectoradoAdm';
import RutasEstudiante from './RutasEstudiante';
import RutasBienestar from './RutasBienestar';
import RutasDocente from './RutasDocente';
import RutasCoordinacionAcad from './RutasCoordinacionAcad';
import RutasVicerrectoradoAcad from './RutasVicerrectoradoAcad';
import RutasUComunicacion from './RutasUComunicacion';
import RutasRecepcion from './RutasRecepcion';
import RutasAtencionPacientes from './RutasAtencionPacientes';
import RutasDirectorPracticas from './RutasDirectorPracticas';
import RutasPlanificacion from './RutasPlanificacion';
import RutasEvaluacion from './RutasEvaluacion';
import RutasACalidad from './RutasACalidad';
import RutasRectorado from './RutasRectorado';
import RutasGestorPlataforma from './RutasGestorPlataforma';

export default class RoutesApp extends Component {
    render() {

        return (
            <div>

                <Router>
                    <div>
                        <Switch>
                            <Route exact path="/" component={CarrerasScreen} />
                            <Route exact path="/carrera" component={Carrera} />
                            <Route exact path="/login" component={LoginScreen} />
                            <Route path="/errorpermiso" component={ErrorPermiso} />
                            <Route path="/administrativo" component={RutasAdministrativo} />
                            <Route path="/estudiante" component={RutasEstudiante} />
                            <Route path="/colecturia" component={RutasColecturia} />
                            <Route path="/secretaria" component={RutasSecretaria} />
                            <Route path="/vicerrectoradoadm" component={RutasVicerrectoradoAdm} />
                            <Route path="/bienestar" component={RutasBienestar} />
                            <Route path="/docente" component={RutasDocente} />
                            <Route path="/coordinacionacad" component={RutasCoordinacionAcad} />
                            <Route path="/vicerrectoradoacad" component={RutasVicerrectoradoAcad} />
                            <Route path="/ucomunicacion" component={RutasUComunicacion} />
                            <Route path="/recepcion" component={RutasRecepcion} />
                            <Route path="/atencionpacientes" component={RutasAtencionPacientes} />
                            <Route path="/directorpracticas" component={RutasDirectorPracticas} />
                            <Route path="/planificacion" component={RutasPlanificacion} />
                            <Route path="/evaluacion" component={RutasEvaluacion} />
                            <Route path="/acalidad" component={RutasACalidad} />
                            <Route path="/rectorado" component={RutasRectorado} />
                            <Route path="/gestorplataforma" component={RutasGestorPlataforma} />
                            
                            {/* <Route  path="/errorpermiso" component={RutasAdministrativo} /> */}
                        </Switch>
                    </div>
                </Router>

            </div>
        )
    }
}