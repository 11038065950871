import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarVicerrectoradoAdm'
import PagosMatriculaTransferencia from '../components/vicerrectoradoadm/matriculas/ListarPagosMatTransfe'
import ColegiaturasTransfEstCarreraCiclo from '../components/vicerrectoradoadm/colegiaturas/ColegiaturasTransfEstCarreraCiclo'
import ColegiaturasEstCarreraCicloGenVic from '../components/vicerrectoradoadm/colegiaturas/ColegiaturasEstCarreraCicloGenVic'
import ColegiaturasEstColegUnPagoVic from '../components/vicerrectoradoadm/colegiaturas/ColegiaturasEstColegUnPagoVic'
import GestionDepartamentos from '../components/vicerrectoradoadm/departamentos/GestionDepartamentos'
import Notificacionestudiante from '../components/vicerrectoradoadm/notificaciones/NotificacionesEst'
import CantidadMatri from '../components/vicerrectoradoadm/matriculas/CantidadMatriculados'

import { ApiUrl } from '../components/services/ApiRest';
import VerPerfil from '../components/vicerrectoradoadm/perfil/VerPerfil'


const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasVicerrectoradoAdm extends Component {
    componentDidMount() {
      
        axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante });
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})
          })
    }    

    render() {
        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
        }

        return (
            
            <>
                <NavbarGeneral /> 
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                        
                            <div className="container-fluid">                       
                                 <Contenido /> 
                                    {/* Matriculas */}
                                    <Route  path="/vicerrectoradoadm/aprobarpagotransferencia"  component={PagosMatriculaTransferencia} /> 
                                    <Route  path="/vicerrectoradoadm/colegiaturastransfestcarreraciclo"  component={ColegiaturasTransfEstCarreraCiclo} />
                                    <Route  path="/vicerrectoradoadm/colegiaturasestcarreraciclogenvic"  component={ColegiaturasEstCarreraCicloGenVic} />
                                    <Route  path="/vicerrectoradoadm/colegiaturasEstColegUnPagoVic"  component={ColegiaturasEstColegUnPagoVic} />
                                    <Route path="/vicerrectoradoadm/VerPerfilVicerrAdm" component={VerPerfil} />
                                    <Route path="/vicerrectoradoadm/GestionDepartamentos" component={GestionDepartamentos} />
                                    <Route path="/vicerrectoradoadm/notificacionestudiante" component={Notificacionestudiante} />
                                    <Route path="/vicerrectoradoadm/cantidadmatri" component={CantidadMatri} />
                          
                            </div>
                        </main>
                        <FooterDashboard />                        
                    </div>                 
                </div>            
            </>
        )
    }
}
