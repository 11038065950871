import React, { Component } from 'react'

export default class Contenido extends Component {
    render() {
        return (
                <div className="animate__animated animate__fadeIn">

                        <h1 className="mt-4 ">SISTEMA ACADÉMICO</h1>
                        <img class="logoist" src="/logov.png"/>
                        {/* preguntar si es administrativo */}                       
                   
                </div>   


                
        
        )
    }
}
