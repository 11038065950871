import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';

import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as xlsx from "xlsx";
import generatePDF from "./reportGeneratorActaCompSecre";

const urlListaUsuariosAdm = ApiUrl + "listusuariosadmucrecep" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlReportRegistSIGAceptActaC = ApiUrl + "regisensigaceptactcomprom" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPeriodoMatricula = ApiUrl + "periodomatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlConsultarEstadoProceMatri = ApiUrl + "consultprocesomatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEstudianteActaCompr = ApiUrl + "reporteactacomproestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCarreraInscritasEst = ApiUrl + "listarcarrerasinscrestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;

export default class ActaCompromisoEst extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EstudianteBackup: [],
            listadmin: [],
            listageneral: [],
            carreramat: [],
            periodos: [],
            pagina: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //modales
            modalInformUsuario: false,
            isDisabled: true,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }


    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalInformUsuario: false });
        }
    }



    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    //controles del modal de  un registro de abono de matricula
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);

        axios.get(urlListaUsuariosAdm)
            .then(respuesta => {
                this.setState({ listadmin: respuesta.data.data });
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listageneral;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const itemNomAdmin = item.nom_administrativo.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula + " " + itemNomAdmin;
            //variable del buscador
            const textData = text.toUpperCase();
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.listaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
        this.setState({ itemOffset: 0 });
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    ocultarInformUsuario = () => {
        this.setState({ modalInformUsuario: false });
    }
    mostrarModalInformUsu = (registro) => {
        this.setState({ isDisabled: true });
        $(function () {
            $("#idEstudiante").val(registro.id_estudiante);
            $("#nombres").val(registro.primer_nombre_estudiante + " " + registro.segundo_nombre_estudiante);
            $("#apellidos").val(registro.primer_apellido_estudiante + " " + registro.segundo_apellido_estudiante);
            $("#telefono").val(registro.celular_estudiante);
            $("#correo").val(registro.email_estudiante);
            if (registro.administrativo_id != null)
                $("#responsable").val(registro.administrativo_id);
        });
        this.setState({ modalInformUsuario: true });
        this.setState({ idEstudiante: registro.id_estudiante });
        this.setState({ responsable: registro.administrativo_id });
        //Visualizar el período vigente para matrículas
        axios.get(urlPeriodoMatricula)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                    const idUltPeriod = listaperiodos[cant - 1].id_periodo_academico;
                    $(function () {
                        $("#idPeriodo").val(idUltPeriod);
                    });
                    this.setState({ idPeriodo: idUltPeriod });
                    //Verificar estado de proceso de matrícula
                    axios.post(urlConsultarEstadoProceMatri, {
                        idEstudiante: registro.id_estudiante,
                        idPeriodoMatri: idUltPeriod
                    })
                        .then(respuesta => {
                            const estadoprocesomat = respuesta.data.data;
                            $("#observaciones").val(estadoprocesomat);
                        })
                }
            })
        //Consultar las carreras que se haya inscrito el estudiante
        axios
            .post(urlCarreraInscritasEst, {
                idEstudiantein: registro.id_estudiante,
            })
            .then(response => {
                if (response.data.success == true) {
                    const carreramat = response.data.data;
                    var array = carreramat;
                    var hash = {};
                    array = array.filter(function (current) {
                        var exists = !hash[current.id_carrera];
                        hash[current.id_carrera] = true;
                        return exists;
                    });
                    this.setState({ carreramat: array });
                    //codigo
                    const listacarreras = array;
                    const cant = listacarreras.length;
                    if (cant != 0) {
                        const idUltPeriod = listacarreras[cant - 1].id_carrera;
                        $(function () {
                            $("#idCarrera").val(idUltPeriod);
                        });
                        this.setState({ idCarrera: idUltPeriod });
                    }
                    //fincodigo
                }
                else {
                
                    swal({
                        text: "No tiene asignada ninguna carrera; favor de comunicarse con la unidad de TI.",
                        icon: "info",
                        button: "Aceptar",
                    });
                }


            })

    }
    generarActaCompromiso = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })


        axios
            .post(urlDatosEstudianteActaCompr, {
                idEstud: this.state.idEstudiante,
                idPeriodo: this.state.idPeriodo,
                idCarrEstudiante: this.state.idCarrera
            })
            .then(response => {
                if (response.data.data != null) {
                    generatePDF(response.data.data);
                    this.setState({ modalInformUsuario: false });
                }
                else {

                    this.setState({ estado: "No existe datos para mostrar" });
                    swal({
                        text: "No existe datos para mostrar",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }
    //Reporte de estudiantes que han llenado el formulario de registro de datos en el SIG
    reporteEstudRegistradosSIG = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios.post(urlReportRegistSIGAceptActaC, {
            fechaI: this.state.fechaInicio,
            fechaF: this.state.fechaFin,
            estado: this.state.estadactacomprom,
        })
            .then(response => {
                if (response.data.success === true) {
                    var array = response.data.data;
                    //lista general= lista de centros de prácticas
                    this.setState({ listageneral: array });
                    listaauxiliar = this.state.listageneral;
                    //BÚSQUEDA
                    const listaBackup = this.state.listageneral;
                    this.setState((state) => {
                        return { listaBackup: listaBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN DE LA PAGINACION
                }
                else {
                    swal({
                        text: "No existe información",
                        icon: "warning",
                        button: "Aceptar",
                    });
                    this.setState({ listageneral: [] });
                    this.setState({ currentItems: [] });
                    this.setState({ itemOffset: 0 });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Acta de compromiso
                        </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-6 col-xl-6 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-3 centrar" >
                                <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Inicio</b></label>
                                <input type="date" id="fechaInicio" name="fechaInicio" onChange={this.handleChange} className="form-control col-md-8" placeholder="1999-01-01" required />
                            </div>
                            <div className="col-12 col-sm-12  col-lg-12 col-xl-3 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Fecha Fin</b></label>
                                    <input type="date" id="fechaFin" name="fechaFin" onChange={this.handleChange} className="form-control col-md-8" placeholder="1999-01-01" required />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-12 col-xl-3 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Estado</b></label>
                                    <select className="form-select" name="estadactacomprom" id="estadactacomprom" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required >
                                        <option value="undefined" selected="true" disabled>Seleccione</option>
                                        <option key="0" value="0" > Pendiente</option>
                                        <option key="1" value="1" > Aceptado</option>
                                    </select>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-12 col-xl-3 centrar" >
                                <div className="mb-3">

                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        onClick={e =>
                                            this.reporteEstudRegistradosSIG(e)
                                        }
                                    >
                                        <b>Buscar</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ACTA DE COMPROMISO DE LOS ESTUDIANTES
                                    </div>

                                    <div className="card-body centrar " style={{ width: "100%" }}>


                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Fecha de registro</th>
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Estado de acta</th>
                                                        <th scope="col">Registrado por</th>
                                                        <th scope="col">Acta de compromiso</th>
                                                     
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={consola.id_estudiante}  >
                                                                <td >{consola.fecha_registro}</td>
                                                                <td >{consola.numero_identificacion_estudiante}</td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td >{consola.segundo_apellido_estudiante}</td>
                                                                <td >{consola.primer_nombre_estudiante}</td>
                                                                <td >{consola.segundo_nombre_estudiante}</td>
                                                                <td >{consola.estad_acta}</td>
                                                                <td >{consola.nom_administrativo}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarModalInformUsu(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                </td>
                                                               
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" hidden>
                            <table id="tablaregistradosexcel" className="table  text-left"  >
                                <thead >
                                    <tr >
                                        <th scope="col">Fecha de registro</th>
                                        <th scope="col">Cédula</th>
                                        <th scope="col">Primer Apellido</th>
                                        <th scope="col">Segundo Apellido</th>
                                        <th scope="col">Primer Nombre</th>
                                        <th scope="col">Segundo Nombre</th>
                                        <th scope="col">Registrado por</th>
                                        <th scope="col">Estado de acta</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listageneral.map((consola) => (
                                        <tr class="list-afiliados" key={consola.id_estudiante}  >
                                            <td >{consola.fecha_registro}</td>
                                            <td >{consola.numero_identificacion_estudiante}</td>
                                            <td >{consola.primer_apellido_estudiante}</td>
                                            <td >{consola.segundo_apellido_estudiante}</td>
                                            <td >{consola.primer_nombre_estudiante}</td>
                                            <td >{consola.segundo_nombre_estudiante}</td>
                                            <td >{consola.estad_acta}</td>
                                            <td >{consola.nom_administrativo}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" size='lg' isOpen={this.state.modalInformUsuario}>
                                    <ModalHeader>
                                        <h5>Asignación del estudiante a un administrativo</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>

                                            <div className="row ">
                                                <input type="hidden" id="idEstudiante" name="idEstudiante" onChange={this.handleChange} className="form-control" placeholder="Ingrese los nombres del interesado" disabled={this.state.isDisabled} />
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                                            <select className="form-select" id="idPeriodo" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example" disabled="true">
                                                                <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                                                {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                                            </select> </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label"><b> <i className="fa fa-calendar"></i> Carrera-Jornada</b></label>
                                                            <select className="form-select" id="idCarrera" name="idCarrera" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                                <option value="undefined">Seleccione la carrera</option>
                                                                {this.state.carreramat.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera+"-"+person.descrip_jornada}</option>)}
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Nombres:</label>
                                                            <input type="text" id="nombres" name="nombres" onChange={this.handleChange} onKeyUp={this.mayusNombre} className="form-control" placeholder="Ingrese los nombres del interesado" disabled={this.state.isDisabled} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <label className="form-label">Apellidos:</label>
                                                        <input type="text" id="apellidos" name="apellidos" onChange={this.handleChange} onKeyUp={this.mayusNombre} className="form-control" placeholder="Ingrese los nombres del interesado" disabled={this.state.isDisabled} />
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Teléfono:</label>
                                                            <input type="text" id="telefono" name="telefono" className="form-control" placeholder="Ingrese el teléfono del interesado" onChange={this.handleChange} disabled={this.state.isDisabled} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <label className="form-label">Correo electrónico:</label>
                                                        <input type="text" id="correo" name="correo" className="form-control" placeholder="Ingrese el teléfono del interesado" onChange={this.handleChange} disabled={this.state.isDisabled} />
                                                    </div>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Responsable:</label>
                                                            <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled="true" >
                                                                <option value="">Seleccione</option>
                                                                {this.state.listadmin.map(person => <option key={person.id_administrativo} value={person.id_administrativo}  > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 centrar" >
                                                        <div className="mb-3">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-12 col-xl-12 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Observaciones:</label>
                                                            <textarea id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="Ingrese las observaciones presentadas" maxlength="500" required disabled={this.state.isDisabled} ></textarea>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={e =>
                                            this.generarActaCompromiso(e)
                                        }  >Acta de Compromiso</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarInformUsuario}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-4 col-sm-4 col-lg-12 col-xl-4 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                            <div className="col-4 col-sm-4 col-lg-12 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <ReactHTMLTableToExcel
                                        id="botonExportarExcel"
                                        className="btn btn-success"
                                        table="tablaregistradosexcel"
                                        filename="registrados_en_el_SIG"
                                        sheet="pagina 1 "
                                        buttonText="Descargar Datos"
                                    />
                                </div>
                            </div>
                            <div className="col-4 col-sm-4 col-lg-12 col-xl-4 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

