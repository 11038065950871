import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ApiUrl } from '../components/services/ApiRest'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarSecretaria'
import MatricularEstudiantes from '../components/secretaria/matriculas/MatricularEstudiante'
import MatriculasPeriodo from '../components/secretaria/matriculas/MatriculasPeriodo'
import MatriculasCoord from '../components/secretaria/matriculas/MatriculasPeriodCoord'
import MatrizMatriculados from '../components/secretaria/matriculas/MatrizMatriculados'
import ListarAsignaturasDocSec from '../components/secretaria/asignaturas/ListarAsignaturasDocSec'
import Calificaciones from '../components/secretaria/calificaciones/CalificacionesEst'
import MatriculaPDF from '../components/secretaria/matriculas/GenerarPDFMatricula'
import PaseCicloEstCarreraCicloPerio from '../components/secretaria/matriculas/PaseCicloEstCarreraCicloPerio'
import Record from '../components/secretaria/calificaciones/RecordEst'
import VerPerfil from '../components/secretaria/perfil/VerPerfil'
import ModificarDatosEst from '../components/secretaria/matriculas/CambioDatosEst'
import ListEstudEstadoMatri from '../components/secretaria/matriculas/ReportEstudEstadoMatric'
import ActaCompromiso from '../components/secretaria/documentacion/ActaCompromisoEst'
import FotografiaEstudiante from '../components/secretaria/documentacion/FotografiascarnetEst'
import EstDocumentMatri from '../components/secretaria/documentacion/ReportEstadoDocumentosMatri'
import CantidadMatriculados from '../components/secretaria/matriculas/CantidadMatriculados'
const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/";
const idAdministrativo = cookie.get("idAdministrativo"); 

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasSecretaria extends Component {
    componentDidMount() {

         axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" )
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante }); 
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})

          })
    }    

    render() {
        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
         
        }

        return (
            
            <>
                <NavbarGeneral /> 
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                        
                            <div className="container-fluid">                       
                            <Contenido /> 
                                    {/* Matriculas */}                            
                                    <Route  path="/Secretaria/MatricularEstudiante"  component={MatricularEstudiantes} /> 
                                    <Route  path="/Secretaria/MatriculasPeriodo"  component={MatriculasPeriodo} /> 
                                    <Route  path="/Secretaria/MatriculasCoord"  component={MatriculasCoord} /> 
                                    <Route  path="/Secretaria/MatrizMatriculados"  component={MatrizMatriculados} /> 
                                    <Route  path="/Secretaria/ListarAsignaturasDocSec"  component={ListarAsignaturasDocSec} /> 
                                    <Route  path="/Secretaria/CalificacionesEstud"  component={Calificaciones} /> 
                                    <Route  path="/Secretaria/GenerarMatriPdf"  component={MatriculaPDF} /> 
                                    <Route  path="/Secretaria/PaseCicloEstCarreraCicloPerio"  component={PaseCicloEstCarreraCicloPerio} /> 
                                    <Route  path="/Secretaria/Record"  component={Record} /> 
                                    <Route path="/Secretaria/VerPerfilSecre" component={VerPerfil} />
                                    <Route path="/Secretaria/CambiarDatosEstud" component={ModificarDatosEst} />
                                    <Route path="/Secretaria/ListEstudEstadoMatricula" component={ListEstudEstadoMatri} />
                                    <Route path="/Secretaria/actacompromiso" component={ActaCompromiso} />
                                    <Route path="/Secretaria/fotocarnetestud" component={FotografiaEstudiante} />
                                    <Route path="/Secretaria/estaddocumenmatriculas" component={EstDocumentMatri} />
                                    <Route path="/Secretaria/CantidadMatriculados" component={CantidadMatriculados} />
                                 
                            </div>
                        </main>
                        <FooterDashboard />                        
                    </div>                 
                </div>            
            </>
        )
    }
}
