import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
//import "./inscripcionhojasestilocol.css";
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { ApiUrl } from '../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as xlsx from "xlsx";

const cookie = new Cookies();
const urlListaUsuariosAdm = ApiUrl + "listusuariosadmucrecep" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsDatosPaciente = ApiUrl + "insertardatospaciente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaPacientes = ApiUrl + "consultarpacientes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urleliminarPaciente = ApiUrl + "eliminardatospaciente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 3;

export default class ReportePacientes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            listadmin: [],
            listareas: [],
            ruta: "",
            pagina: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            justClicked: null,
            listpacientes: [],
            form1: {
            },
            passwordmod: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalModUsuario: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        axios.get(urlListaPacientes)
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                this.setState({ listpacientes: respuesta.data.data });
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }

                }
                );
                const EstudianteBackup = estud;
                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //this.handlePageClick();
                //FIN DE LA PAGINACION
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listpacientes
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNombres = item.nombres_paciente.toUpperCase()
            const itemDataCedula = item.cedula_paciente.toUpperCase()
            const campo = itemDataNombres + " " + itemDataCedula;
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }
    ocultarModificarUsu = () => {
        this.setState({ modalModUsuario: false });
    }
    mostrarModalModUsu = (registro) => {
        $(function () {
            $("#id").val(registro.id_paciente);
            $("#nombres").val(registro.nombres_paciente);
            $("#edad").val(registro.edad_paciente);
            $("#cedula").val(registro.cedula_paciente);
            $("#fechan").val(registro.fechanacimiento_paciente);
            $("#direccion").val(registro.direccion_paciente);
            $("#telefono").val(registro.telefono_paciente);
            $("#instruccion").val(registro.instruccion_paciente);
            $("#ocupacion").val(registro.ocupacion_paciente);
            $("#estadocivil").val(registro.estadocivil_paciente);
            $("#religion").val(registro.religion_paciente);
            $("#etnia").val(registro.etnia_paciente);
            $("#persreferencia").val(registro.personareferencia_paciente);
            $("#fonoreferencia").val(registro.telefonoreferencia_paciente);
        });

        this.setState({ modalModUsuario: true });
        this.setState({ id: registro.id_paciente });
        this.setState({ nombres: registro.nombres_paciente });
        this.setState({ edad: registro.edad_paciente });
        this.setState({ cedula: registro.cedula_paciente });
        this.setState({ fechan: registro.fechanacimiento_paciente });
        this.setState({ direccion: registro.direccion_paciente });
        this.setState({ telefono: registro.telefono_paciente });
        this.setState({ instruccion: registro.instruccion_paciente });
        this.setState({ ocupacion: registro.ocupacion_paciente });
        this.setState({ estadocivil: registro.estadocivil_paciente });
        this.setState({ religion: registro.religion_paciente });
        this.setState({ etnia: registro.etnia_paciente });
        this.setState({ persreferencia: registro.personareferencia_paciente });
        this.setState({ fonoreferencia: registro.telefonoreferencia_paciente });
    }

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalModUsuario: true });
    }

    //Ingresar y modificar los datos del interesado
    registrarDatosInteresado = () => {

        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlInsDatosPaciente, {
                            id: this.state.id,
                            nombres: this.state.nombres,
                            cedula: this.state.cedula,
                            fechan: this.state.fechan,
                            direccion: this.state.direccion,
                            telefono: this.state.telefono,
                            instruccion: this.state.instruccion,
                            ocupacion: this.state.ocupacion,
                            estadocivil: this.state.estadocivil,
                            religion: this.state.religion,
                            etnia: this.state.etnia,
                            persreferencia: this.state.persreferencia,
                            fonoreferencia: this.state.fonoreferencia,
                        })
                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {

                                this.setState({ estado: "Datos registrados correctamente" });
                                swal({
                                    text: "Datos registrados correctamente",
                                    icon: "success",
                                    button: "Aceptar",

                                });
                                //Actualizar la tabla de usuarios
                                axios.get(urlListaPacientes)
                                    .then(respuesta => {
                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ listpacientes: respuesta.data.data });
                                        if (this.state.pageCount == 1) {
                                            this.setState({ itemOffset: 0 });
                                        }
                                        const estud = respuesta.data.data;
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                        this.setState((state) => {
                                            return { itemOffset: newOffset }
                                        });
                                        const endOffset = newOffset + itemsPerPage;
                                        //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState({ itemOffset: newOffset });
                                        //alert("Count:"+this.state.pageCount)
                                        //alert(this.state.itemOffset);
                                        //FIN DE LA PAGINACION
                                    })
                                this.setState({ modalModUsuario: false });
                            }
                            else {
                                this.setState({ modalModUsuario: false });
                            }

                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            //window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });

    }

    eliminarPaciente = (id) => {
        swal({
            title: "Advertencia",
            text: "Está seguro de continuar?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(urleliminarPaciente, {
                        id: id,
                    })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos Eliminados Exitosamente!!",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                //Actualizar la tabla de usuarios
                                axios.get(urlListaPacientes)
                                    .then(respuesta => {
                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ listpacientes: respuesta.data.data });
                                        const estud = respuesta.data.data;
                                        if (this.state.pageCount == 1) {
                                            this.setState({ itemOffset: 0 });
                                        }
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                        this.setState((state) => {
                                            return { itemOffset: newOffset }
                                        });
                                        const endOffset = newOffset + itemsPerPage;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState({ itemOffset: newOffset });
                                        //FIN DE LA PAGINACION
                                    })
                                this.setState({ modalModUsuario: false });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    mayusNombre = async (e) => {
        $("#nombres").val(e.target.value.toUpperCase());
        this.setState({ nombres: e.target.value.toUpperCase() })
    }

    mayusOcupacion = async (e) => {
        $("#ocupacion").val(e.target.value.toUpperCase());
        this.setState({ ocupacion: e.target.value.toUpperCase() })
    }

    mayusReligion = async (e) => {
        $("#religion").val(e.target.value.toUpperCase());
        this.setState({ religion: e.target.value.toUpperCase() })
    }

    mayusNombrePersRef = async (e) => {
        $("#persreferencia").val(e.target.value.toUpperCase());
        this.setState({ persreferencia: e.target.value.toUpperCase() })
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de Pacientes
                        </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        PACIENTES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Nombres</th>
                                                        <th scope="col">Edad</th>
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Fecha de Nacimiento</th>
                                                        <th scope="col">Dirección</th>
                                                        <th scope="col">Teléfono</th>
                                                        <th scope="col">Ocupación</th>
                                                        <th scope="col">Fecha de Registro</th>
                                                        <th scope="col">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_paciente}  >
                                                                <td >{consola.nombres_paciente}</td>
                                                                <td >{consola.edad_paciente}</td>
                                                                <td >{consola.cedula_paciente}</td>
                                                                <td >{consola.fechanacimiento_paciente}</td>
                                                                <td >{consola.direccion_paciente}</td>
                                                                <td >{consola.telefono_paciente}</td>
                                                                <td >{consola.ocupacion_paciente}</td>
                                                                <td >{consola.fecharegistro_paciente}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarModalModUsu(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                    {/*<button onClick={() => this.eliminarPaciente(consola.id_paciente)} > <i className=" fas fa-trash" ></i></button>*/}
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" hidden>
                            <table id="tablapacientesexcel" className="table  text-left"  >
                                <thead >
                                    <tr >
                                        <th scope="col">Nombres</th>
                                        <th scope="col">Edad</th>
                                        <th scope="col">Cédula</th>
                                        <th scope="col">Fecha de Nacimiento</th>
                                        <th scope="col">Dirección</th>
                                        <th scope="col">Teléfono</th>
                                        <th scope="col">Instrucción</th>
                                        <th scope="col">Ocupación</th>
                                        <th scope="col">Estado Civil</th>
                                        <th scope="col">Religión</th>
                                        <th scope="col">Etnia</th>
                                        <th scope="col">Persona Referencia</th>
                                        <th scope="col">Teléfono Referencia</th>
                                        <th scope="col">Fecha de Registro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listpacientes.map((consola) => (
                                        <tr class="list-afiliados" key={this.state.estud.id_paciente}  >
                                            <td >{consola.nombres_paciente}</td>
                                            <td >{consola.edad_paciente}</td>
                                            <td >{consola.cedula_paciente}</td>
                                            <td >{consola.fechanacimiento_paciente}</td>
                                            <td >{consola.direccion_paciente}</td>
                                            <td >{consola.telefono_paciente}</td>
                                            <td >{consola.instruccion_paciente}</td>
                                            <td >{consola.ocupacion_paciente}</td>
                                            <td >{consola.estadocivil_paciente}</td>
                                            <td >{consola.religion_paciente}</td>
                                            <td >{consola.etnia_paciente}</td>
                                            <td >{consola.personareferencia_paciente}</td>
                                            <td >{consola.telefonoreferencia_paciente}</td>
                                            <td >{consola.fecharegistro_paciente}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" size='lg' isOpen={this.state.modalModUsuario}>
                                    <ModalHeader>
                                        <h5></h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <label className="form-label"><h5>DATOS DE PACIENTE</h5></label>
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Cédula:</label>
                                                            <input type="text" id="cedula" name="cedula" onChange={this.handleChange} className="form-control" placeholder="Ingrese la cedula del paciente" required />


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">

                                                            <label className="form-label">Nombres:</label>
                                                            <input type="text" id="nombres" name="nombres" onChange={this.handleChange} onKeyUp={this.mayusNombre} className="form-control" placeholder="Ingrese los nombres del paciente" required />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">

                                                            <label className="form-label">Fecha de nacimiento:</label>
                                                            <input type="date" id="fechan" name="fechan" onChange={this.handleChange} className="form-control" placeholder="Ingrese la fecha de nacimiento del paciente" required />


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Dirección:</label>
                                                            <input type="text" id="direccion" name="direccion" onChange={this.handleChange} className="form-control" placeholder="Ingrese la direccion del paciente" required />


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">

                                                            <label className="form-label">Teléfono:</label>
                                                            <input type="text" id="telefono" name="telefono" onChange={this.handleChange} className="form-control" placeholder="Ingrese el teléfono del paciente" required />


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">

                                                            <label className="form-label">Instrucción:</label>
                                                            <select className="form-select" id="instruccion" name="instruccion" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="" disabled selected>Seleccione</option>
                                                                <option value="PRIMARIA">PRIMARIA</option>
                                                                <option value="SECUNDARIA">SECUNDARIA</option>
                                                                <option value="SUPERIOR">SUPERIOR</option>
                                                                <option value="SUPERIOR">NINGUNA</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Ocupación:</label>
                                                            <input type="text" id="ocupacion" name="ocupacion" onChange={this.handleChange} onKeyUp={this.mayusOcupacion} className="form-control" placeholder="Ingrese la ocupación del paciente" required />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Estado Civil:</label>
                                                            <select className="form-select" id="estadocivil" name="estadocivil" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="" disabled selected>Seleccione</option>
                                                                <option value="SOLTERO">SOLTERO</option>
                                                                <option value="CASADO">CASADO</option>
                                                                <option value="DIVORCIADO">DIVORCIADO</option>
                                                                <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                                                                <option value="VIUDO">VIUDO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar">
                                                        <div className="mb-3">
                                                            <label className="form-label">Religión:</label>
                                                            <input type="text" id="religion" name="religion" onChange={this.handleChange} onKeyUp={this.mayusReligion} className="form-control" placeholder="Ingrese la religion del paciente" required />


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">

                                                            <label className="form-label">Etnía:</label>
                                                            <select className="form-select" id="etnia" name="etnia" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                                <option value="" disabled selected>Seleccione</option>
                                                                <option value="MESTIZO">MESTIZO</option>
                                                                <option value="INDIGENA">INDIGENA</option>
                                                                <option value="BLANCO">BLANCO</option>
                                                                <option value="AFRODESCENDIENTE">AFRODESCENDIENTE</option>
                                                                <option value="OTROS">OTROS</option>
                                                            </select>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar">
                                                        <div className="mb-3">

                                                            <label className="form-label">Nombre persona de referencia:</label>
                                                            <input type="text" id="persreferencia" name="persreferencia" onChange={this.handleChange} onKeyUp={this.mayusNombrePersRef} className="form-control" placeholder="Ingrese el nombre de la persona de referencia" required />


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar">
                                                        <div className="mb-3">

                                                            <label className="form-label">Teléfono persona de referencia:</label>
                                                            <input type="text" id="fonoreferencia" name="fonoreferencia" onChange={this.handleChange} className="form-control" placeholder="Ingrese el teléfono de la persona de referencia" required />


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.registrarDatosInteresado(this.state.form1)} >Registrar</button>

                                        <button class="btn btn-outline-primary" onClick={this.ocultarModificarUsu}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar text-center" >
                                <div className="mb-3">
                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        onClick={() => this.mostrarModalIngresar()} >
                                        <b>Nuevo Paciente</b>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 centrar" >
                                <div className="mb-3">
                                    <ReactHTMLTableToExcel
                                        id="botonExportarExcel"
                                        className="btn btn-success"
                                        table="tablapacientesexcel"
                                        filename="matrizpacientesexcel"
                                        sheet="pagina 1 "
                                        buttonText="Descargar Datos"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


