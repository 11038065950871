import axios from 'axios';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import swal from 'sweetalert';
import { Alert } from 'bootstrap';

const cookie = new Cookies();
const cedulaEstu = cookie.get("cedulaEstudiante");
const nombrescompletosc = cookie.get("nomCompleEst");
const urlConsultarValormatricula = ApiUrl + "consultarmatriestuperiod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPagoMatriculaeEstPeriod = ApiUrl + "visualizarpagomatriculaest" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPagoMatriculaComprobEstPeriod = ApiUrl + "mostarrutaimagencomprobantemat" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstudianteDatosAbonoMatricula = ApiUrl + "listabonosmatriculaperiod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoPagoMatricula = ApiUrl + "consultarestadopagomatricula" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const numNivelesCarreraEst = ApiUrl + "carreraestudniveles" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlConsultarValorMatriNivel = ApiUrl + "costomatriniv" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoActaCompromiso = ApiUrl + "estadoactacomprom" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstHistorialMatriculas = ApiUrl + "estudhistorialmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPeriodosCarrera = ApiUrl + "listperiodoscarrerahabmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;

export default class EstudianteInscripcion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cedula: cedulaEstu,
            modalModificar: false,
            nombrescompletos: nombrescompletosc,
            ciclosAcademicos: [],
            abonosmatricula: [],

            carrera: [],
            paralelo: [],
            periodos: [],
            idPeriodo: 0,
            estadoPeriod: 0,
            listaniveles: [],
            listanivelespm: [],

            cursos: [],
            cursoMatricularse: "",
            tipoMatricula: "1",
            estadopagoMatricula: "1",
            estadoverificar: "1",   // 1: No hay datos 2: hay datos
            costoMatricula: "100",
            estado: "",
            comprobante: "",
            comprobantemod: "",

            cantidadPago: "",
            fechaVencimiento: "2005-10-12",

            documentoscompl: "PENDIENTE",
            valormatricula: 0,
            valorMatriNivel: 0,
            estadomatriculae: "",
            depositomatriculae: "",
            //para habiliatr o deshabilitar
            disabledc: true,
            estadodatostabonomatri: 0,
            //variables placeholder
            cantidadPagopla: "",
            estadopagomatriculaest: "No registra",
            idpagosmatricula: 1,
            nombreArchivoG: "",
            estadopagomatricula: "",
            //modal

            modalPagoMatricula: false,
            modalRegistroPagoMatricula: false,
            modalVisualComprobante: false,
            disabledtransfe: true,
            disabledtransfemod: true,
            disabledpmv: true,
            disabledpmabv: true,
            disablednumbanbened: true,//#cuenta del banco beneficiario
            disabledformpag: true,
            disabledformpagmod: true,

            //variables  globales
            sumaTotalCuotas: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //formulario de pagos
            cuentadebitotransferencia: "",
            nomdueniocuentadebitotransferencia: "",
            numdeposito: "",
            numdepositomod: "",
            form: {
                // id_pago_abono_matricula_periodo :'',
            },
            bancobeneficario: "1",
            bancobeneficariomod: "1",
            numbancobeneficario: "7731051",
            validarcomprob: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeComprobante = this.handleChangeComprobante.bind(this);

        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {

            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalPagoMatricula: false });
            this.setState({ modalVisualComprobante: false });

        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    handleChangeComprobante = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobante: e.target.files });
        this.setState({ comprobantemod: e.target.files });

    }
    handleChangeCuentaBeneficiario = async (e) => {
        //e.preventDefault();
        //colocar la cuenta del banco seleccionado
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ disablednumbanbened: !this.state.disablednumbanbene });
        if (this.state.bancobeneficario == 1) {
            $(function () {
                $("#numbancobeneficario").val("7731051");

            });
            this.setState({ numbancobeneficario: "7731051" });
        }
        else {

            if (this.state.bancobeneficario == 2) {
                $(function () {
                    $("#numbancobeneficario").val("3257594104");

                });
                this.setState({ numbancobeneficario: "3257594104" });
            }
            else {
                if (this.state.bancobeneficario == 3) {

                    $(function () {
                        $("#numbancobeneficario").val("2100271381");

                    });
                    this.setState({ numbancobeneficario: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficario").val("06073611");

                    });
                    this.setState({ numbancobeneficario: "06073611" });
                }
            }
        }

    }

    //BANCO BENEFICIARIO
    handleChangeCuentaBeneficiarioMod = async (e) => {
        //e.preventDefault();
        //colocar la cuenta del banco seleccionado
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ disablednumbanbened: !this.state.disablednumbanbene });
        if (this.state.bancobeneficariomod == 1) {
            $(function () {
                $("#numbancobeneficariomod").val("7731051");
            });
            this.setState({ numbancobeneficariomod: "7731051" });
        }
        else {
            if (this.state.bancobeneficariomod == 2) {
                $(function () {
                    $("#numbancobeneficariomod").val("3257594104");
                });
                this.setState({ numbancobeneficariomod: "3257594104" });
            }

            else {
                if (this.state.bancobeneficariomod == 3) {

                    $(function () {
                        $("#numbancobeneficariomod").val("2100271381");
                    });
                    this.setState({ numbancobeneficariomod: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficariomod").val("06073611");
                    });
                    this.setState({ numbancobeneficariomod: "06073611" });
                }
            }
        }

    }

    //forma de pago efectivo
    handleChangeFormaPagoEfect = async (e) => {
        //e.preventDefault();
        //colocar la cuenta del banco seleccionado
        await this.setState({ [e.target.name]: e.target.value });
        //si es  un efectivo
        if (this.state.idTipoTransaccion == 3) {

            this.setState({ disabledct: this.state.disabledtransfe });
            this.setState({ disabledformpagd: this.state.disabledformpag });
        }
        if (this.state.idTipoTransaccion == 2) {

            this.setState({ disabledct: !this.state.disabledtransfe });
            this.setState({ disabledformpagd: !this.state.disabledformpag });

        }

        if (this.state.idTipoTransaccion == 1) {

            this.setState({ disabledct: this.state.disabledtransfe });
            this.setState({ disabledformpagd: !this.state.disabledformpag });
        }
    }
    //deshabilitar y habilitar  los botones del  modal modificar
    handleChangeFormaPagoModEfect = async (e) => {
        //e.preventDefault();
        //colocar la cuenta del banco seleccionado
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.idTipoTransaccionmod == 3) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: this.state.disabledformpagmod });
        }

        if (this.state.idTipoTransaccionmod == 2) {
            this.setState({ disabledctmod: !this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });
        }

        if (this.state.idTipoTransaccionmod == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });
        }

    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        //lista los períodos en base a la carrera
        axios
            .post(urlPeriodosCarrera, {//guarda para enviar los atributos y envia
                idCarrera: cookie.get("idCarreraEstu"),
            })
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                    const idUltPeriod = listaperiodos[cant - 1].id_periodo_academico;
                    $(function () {
                        $("#idPeriodo").val(idUltPeriod);
                    });
                    this.setState({ idPeriodo: idUltPeriod });

                    axios.post(urlConsultarValormatricula, {
                        idEstudianteDoc: cookie.get("idEstudiante"),
                        idPeriodoActual: idUltPeriod,
                        idCarrerae: cookie.get("idCarreraEstu")
                    })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                const valormatricula = respuesta.data.data;

                                this.setState({ valormatricula: valormatricula });
                            } else {
                                this.setState({ valormatricula: 0.0 });
                            }
                        })
                    //devuelve los datos del pago ingresado de la matricula en un periodo
                    axios.post(urlPagoMatriculaeEstPeriod, {
                        estudiante_id: cookie.get("idEstudiante"),
                        idPeriodoActual: idUltPeriod
                    })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                const pagomatriculaestud = respuesta.data.data;
                                this.setState({ cantidadPago: pagomatriculaestud[0].cantidad_pago });
                                this.setState({ cantidadPagopla: pagomatriculaestud[0].cantidad_pago });
                                this.setState({ idpagosmatricula: pagomatriculaestud[0].id_pagos_matriculas });
                                this.setState({ nombreArchivoG: pagomatriculaestud[0].comprobante_pago });
                                this.setState({ estadoverificar: 2 });
                                if (pagomatriculaestud[0].estado_pago == 1) {
                                    this.setState({ estadomatriculae: "Enviado" });
                                } else {
                                    if (pagomatriculaestud[0].estado_pago == 2) {
                                        this.setState({ estadomatriculae: "Aprobado" });
                                        this.setState({ disabledcim: this.state.disabledc })
                                    } else {
                                        this.setState({ estadomatriculae: "Rechazada" });
                                    }
                                }

                            } else {

                                //this.setState({estadomatricula:""});
                            }
                        })
                    //Visualiza reporte del registro de pago de matrícula y la suma total
                    axios.post(urlEstudianteDatosAbonoMatricula, {
                        idEstudianteDoc: cookie.get("idEstudiante"),
                        idPeriodoActual: idUltPeriod,
                        idCarrerae: cookie.get("idCarreraEstu")
                    })
                        .then(respuesta => {
                            if (respuesta.data.success == true) {
                                const datosabonomat = respuesta.data.data;
                                listaauxiliar = respuesta.data.data;
                                this.setState({ abonosmatricula: datosabonomat });
                                const sumcouta = respuesta.data.sumacouta;
                                this.setState({ sumaTotalCuotas: sumcouta });

                                //PAGINACION
                                this.setState({ itemOffset: 0 });
                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ abonosmatricula: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN  D DE LA PAGINACION
                            }
                        });

                    //Visualiza el estado del pago de matrícula
                    axios
                        .post(urlEstadoPagoMatricula, {
                            idEstudianteDoc: cookie.get("idEstudiante"),
                            idPeriodoActual: idUltPeriod
                        })
                        .then(response => {
                            const estadopagomat = response.data.data;
                            if (estadopagomat != null) {
                                if (estadopagomat == 1) {
                                    this.setState({ estadopagomatricula: "Enviado" });
                                    this.setState({ disabledpm: !this.state.disabledpmv })
                                }
                                else {
                                    if (estadopagomat == 4) {
                                        this.setState({ estadopagomatricula: "Pagado Parcialmente" });
                                        this.setState({ disabledpm: !this.state.disabledpmv })
                                    }
                                    else {
                                        if (estadopagomat == 2) {
                                            this.setState({ estadopagomatricula: "Pagado Totalmente" });
                                            this.setState({ disabledpm: this.state.disabledpmv });
                                        }

                                    }
                                }
                            }
                            else {
                                //Averiguar en el estado de acta de compromiso
                                axios
                                    .post(urlEstadoActaCompromiso, {
                                        idEstud: cookie.get("idEstudiante")
                                    })
                                    .then(response => {
                                        if (response.data.data != null) {
                                            if (response.data.data == 0) {
                                                //Verificar historial de matriculas
                                                axios
                                                    .post(urlEstHistorialMatriculas, {
                                                        idEstud: cookie.get("idEstudiante"),
                                                        idCarrera: cookie.get("idCarreraEstu")
                                                    })
                                                    .then(response => {
                                                        if (response.data.success == false) {
                                                            this.setState({ estadopagomatricula: "Usted aún no ha revisado ni aceptado el acta de compromiso." });
                                                            this.setState({ disabledpm: this.state.disabledpmv });
                                                        }
                                                        else {
                                                            this.setState({ estadopagomatricula: "" });
                                                            this.setState({ disabledpm: !this.state.disabledpmv });
                                                        }
                                                    })
                                            }
                                            else {
                                                this.setState({ estadopagomatricula: "" });
                                                this.setState({ disabledpm: !this.state.disabledpmv });
                                            }
                                        }
                                    })

                            }
                        })
                }
            })
        //numero de niveles
        axios
            .post(numNivelesCarreraEst, {
                idEstudiantee: cookie.get("idEstudiante"),
            })
            .then(response => {

                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ numnivelE: nivelcarrest });
            })
        //fin
    }

    mostrarModalPagoMatricula = (registro) => {

        //Realizar la lista de  niveles
        let listanivelespm1 = []
        for (let i = 1; i <= this.state.numnivelE; i++) {

            let fila = {
                codnivel: i,
                valor: i

            };

            listanivelespm1.push(fila);
        }
        this.setState({ listanivelespm: listanivelespm1 });
        this.setState({ modalPagoMatricula: true, form: registro });

        $(function () {
            $("#bancobeneficario").val("1");
            $("#numbancobeneficario").val("7731051");
        });
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ abonosmatricula: listaauxiliar.slice(newOffset, endOffset) });
        // this.setState({EstudianteBackup:listaauxiliar.slice(newOffset, endOffset)});
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }

    //FIN DE PAGINACION
    ocultarModalPagoMatricula = () => {
        this.setState({ modalPagoMatricula: false });
        //console.log( e.target.value);
    }

    ///controles del modal de  un registro de abono de matricula
    mostrarModalRegistroPagoMatricula = (registro) => {

        //dibujar el  numero de  niveles
        let listaniveles1 = []
        for (let i = 1; i <= this.state.numnivelE; i++) {

            let fila = {
                codnivel: i,
                valor: i

            };

            listaniveles1.push(fila);
        }
        this.setState({ listaniveles: listaniveles1 });

        $(function () {

            $("#nivelEstumod").val(registro.ciclo);
            $("#numdepositomod").val(registro.num_deposito);
            $("#fechadepositomod").val(registro.fecha_deposito);
            $("#cantidadPagomod").val(registro.valor_deposito);
            $("#idTipoTransaccionmod").val(registro.tipo_deposito);
            $("#cuentadebitotransferenciamod").val(registro.cuenta_debito_transferencia);
            $("#nomdueniocuentadebitotransferenciamod").val(registro.nom_dueno_cuenta_transf);
            $("#bancobeneficariomod").val(registro.nombre_banco_beneficiario);
            $("#numbancobeneficariomod").val(registro.cuenta_beneficiaria);

        });
        this.setState({ nivelEstumod: registro.ciclo })
        this.setState({ numdepositomod: registro.num_deposito })
        this.setState({ fechadepositomod: registro.fecha_deposito })
        this.setState({ cantidadPagomod: registro.valor_deposito })
        this.setState({ idTipoTransaccionmod: registro.tipo_deposito })
        this.setState({ cuentadebitotransferenciamod: registro.cuenta_debito_transferencia })
        this.setState({ nomdueniocuentadebitotransferenciamod: registro.nom_dueno_cuenta_transf })
        this.setState({ bancobeneficariomod: registro.nombre_banco_beneficiario })
        this.setState({ numbancobeneficariomod: registro.cuenta_beneficiaria })
        this.setState({ id_pagos_matriculasmod: registro.id_pago_abono_matricula_periodo })


        if ((registro.estado_abono_matricula == 2) || (registro.estado_abono_matricula == 5) || (registro.estado_abono_matricula == 6)) {

            this.setState({ disabledpmab: this.state.disabledpmabv })
        }
        else {
            this.setState({ disabledpmab: !this.state.disabledpmabv })
        }



        this.setState({ modalRegistroPagoMatricula: true, form: registro });


        if (registro.tipo_deposito == 3) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: this.state.disabledformpagmod });
        }

        if (registro.tipo_deposito == 2) {
            this.setState({ disabledctmod: !this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });
        }

        if (registro.tipo_deposito == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });
        }

        if (registro.estado_abono_matricula == 1) {
            this.setState({ disabledpmab: true })
        }

        //nuevo código
        if (registro.estado_abono_matricula != 3) {
            this.setState({ disabledpmab: this.state.disabledpmabv });
            $(function () {
                $("#bottmodif").text('');
            });
        }
        else {
            this.setState({ disabledpmab: !this.state.disabledpmabv });
        }
        //mensaje  de aviso a lado del botón modificar
        this.setState({ avisoestud: "En proceso de verificación" });
        if ((registro.estado_abono_matricula == 1) || (registro.estado_abono_matricula == 5) || (registro.estado_abono_matricula == 6)) {
            this.setState({ avisoestud: "En proceso de verificación" });
        }
        else {
            this.setState({ avisoestud: "" });
        }
    }
    ocultarModalRegistroPagoMatricula = () => {
        this.setState({ modalRegistroPagoMatricula: false });
        //console.log( e.target.value);
    }
    mostrarModalComprobanteAbonoMatricula = (registro) => {
        //devuelve los datos del pago ingresado de la matricula en un periodo
        axios.post(urlPagoMatriculaComprobEstPeriod, {
            idPagoAbonoMat: registro.id_pago_abono_matricula_periodo,

        })
            .then(respuesta => {
                if (respuesta.data.success == true) {

                    const pagomatriculaestud = respuesta.data.data;
                    const ruta = pagomatriculaestud;
                    this.setState({ ruta });
                }
            });
        this.setState({ modalVisualComprobante: true, form: registro });
    }

    ocultarModalVisualComprobante = () => {
        this.setState({ modalVisualComprobante: false });
    }

    //abonar  un valor  matrícula
    abonarMatriculaEstPeriod = (datos) => {

        var sumatotalcuotas = parseFloat(this.state.sumaTotalCuotas);
        var valoringresado = parseFloat(this.state.cantidadPago);
        var sumaaux = parseFloat(sumatotalcuotas + valoringresado);
        //   var auxvalormatricula = parseFloat(this.state.valorMatriNivel);
        var auxvalormatricula = parseFloat(2500);

        if (sumaaux <= auxvalormatricula) {

            /************DEFINIR  FECHA ACTUAL */
            var fecha = new Date();
            var dia = fecha.getDate();
            var mes = (fecha.getMonth() + 1);
            var anio = fecha.getFullYear();

            var fechaMatricula = `${anio}-${mes}-${dia}`;

            //define mes 
            var nombremes = mes + "";
            var numeroCaracteresmes = nombremes.length;
            if (numeroCaracteresmes == 1) {
                var mesfecha = "0" + nombremes;
            } else {
                mesfecha = nombremes;
            }
            //define dia
            var nombredia = dia + "";
            var numeroCaracteresdia = nombredia.length;
            if (numeroCaracteresdia == 1) {
                var diafecha = "0" + nombredia;
            } else {
                diafecha = nombredia;
            }
            var fechaactualgenerada = anio + "-" + mesfecha + "-" + diafecha


            var vidFileLength = $("#comprobante")[0].files.length;
            var comprobantemat = "";


            if (((this.state.comprobante == null) || (vidFileLength === 0)) && (this.state.idTipoTransaccion != 3)) {

                swal({
                    text: "No se ha seleccionado ningun archivo",
                    icon: "error",
                    button: "Aceptar",

                });

            } else {
                //Validar el comprobante
                var fileInput = document.getElementById('comprobante');
                var filePath = fileInput.value;
                var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                if ((!allowedExtensions.exec(filePath)) && (this.state.idTipoTransaccion != 3)) {
                    swal({
                        text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                        icon: "error",
                        button: "Aceptar",

                    });
                }
                else {

                    var comprobantemat = "";
                    var auxbanbenef = "";
                    var auxctabanbenef = "";
                    if (this.state.idTipoTransaccion == 3) {
                        comprobantemat = null;
                        auxbanbenef = "";
                        auxctabanbenef = "";
                    }
                    else {
                        comprobantemat = this.state.comprobantemod[0];
                        auxbanbenef = this.state.bancobeneficario;
                        auxctabanbenef = this.state.numbancobeneficario;
                    }


                    const f = new FormData();
                    f.append("url_comprobante", comprobantemat);
                    f.append("periodo_academico_id", this.state.idPeriodo);
                    f.append("estudiante_id", cookie.get("idEstudiante"));
                    f.append("fecha_matricula", fechaactualgenerada);//fecha en la registra el comprobante
                    f.append("num_depositof", this.state.numdeposito);
                    f.append("fecha_depositof", this.state.fechadeposito);
                    f.append("valor_cancelado", this.state.cantidadPago);
                    f.append("cuenta_debito_transferenciaf", this.state.cuentadebitotransferencia);
                    f.append("nom_dueno_cuenta_transff", this.state.nomdueniocuentadebitotransferencia);
                    f.append("nombre_banco_beneficiariof", auxbanbenef);
                    f.append("cuenta_beneficiariaf", auxctabanbenef);
                    f.append("tipo_pago", this.state.idTipoTransaccion);
                    f.append("nivel_est", this.state.nivelEstu);

                    const config = {
                        headers: { 'content-type': 'multipart/form-data' }
                    }


                    axios
                        //  .post(ApiUrl+"matriculas",f,config)
                        .post(ApiUrl + "abonarmatricula" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71", f, {//guarda para enviar los atributos y envia

                            idprobarcarrera: this.state.idCarrera
                        }, config)

                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {

                                this.setState({ estado: "Su comprobante ha sido enviada para revisión" });
                                swal({
                                    text: "Su comprobante ha sido enviada para revisión",
                                    icon: "success",
                                    button: "Aceptar",

                                });

                                this.setState({ modalPagoMatricula: false });
                                //actualizar la tabla de reporte
                                axios.post(urlEstudianteDatosAbonoMatricula, {
                                    idEstudianteDoc: cookie.get("idEstudiante"),
                                    idPeriodoActual: this.state.idPeriodo,
                                    idCarrerae: cookie.get("idCarreraEstu")
                                })
                                    .then(respuesta => {
                                        if (respuesta.data.success == true) {

                                            const datosabonomat = respuesta.data.data;
                                            this.setState({ abonosmatricula: datosabonomat });
                                        }
                                    });
                                //Actualizar el estado y bloqueo del botón abonar
                                axios
                                    .post(urlEstadoPagoMatricula, {//guarda para enviar los atributos y envia
                                        idEstudianteDoc: cookie.get("idEstudiante"),
                                        idPeriodoActual: this.state.idPeriodo
                                    })
                                    .then(response => {
                                        const estadopagomat = response.data.data;
                                        if (estadopagomat != null) {
                                            if (estadopagomat == 1) {
                                                this.setState({ estadopagomatricula: "Enviado" });
                                                this.setState({ disabledpm: !this.state.disabledpmv })
                                            }
                                            else {
                                                if (estadopagomat == 4) {
                                                    this.setState({ estadopagomatricula: "Pagado Parcialmente" });
                                                    this.setState({ disabledpm: !this.state.disabledpmv })
                                                }
                                                else {
                                                    if (estadopagomat == 2) {
                                                        this.setState({ estadopagomatricula: "Pagado Totalmente" });
                                                        this.setState({ disabledpm: this.state.disabledpmv });
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            this.setState({ estadopagomatricula: "" });
                                            this.setState({ disabledpm: !this.state.disabledpmv });
                                        }
                                    })
                            }
                            else {
                                if (response.data.status == "failed") {
                                    this.setState({ estado: "Formato del archivo incorrecto" });
                                    swal({
                                        text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                }
                                else {
                                    this.setState({ estado: "Valores no registrados" });
                                    swal({
                                        text: "Valores no registrados",
                                        icon: "error",
                                        button: "Aceptar",

                                    });
                                }

                                this.setState({ modalPagoMatricula: false });
                            }
                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            window.location.reload(true);
                        })
                }
            }//fin
        }
        else {
            /*this.setState({ estado: "El valor ingresado supera al valor de la matricula" });
            swal({
                text: "El valor ingresado supera al valor de la matricula",
                icon: "error",
                button: "Aceptar",

            });*/
        }
    }


    //modificar los datos del pago  de abono de matrícula
    modificarAbonoMatriculaEstPeriod = (datos) => {

        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    /************DEFINIR  FECHA ACTUAL */
                    var fecha = new Date();
                    var dia = fecha.getDate();
                    var mes = (fecha.getMonth() + 1);
                    var anio = fecha.getFullYear();
                    var fechaMatricula = `${anio}-${mes}-${dia}`;

                    //define mes 
                    var nombremes = mes + "";
                    var numeroCaracteresmes = nombremes.length;
                    if (numeroCaracteresmes == 1) {
                        var mesfecha = "0" + nombremes;
                    } else {
                        mesfecha = nombremes;
                    }
                    //define dia
                    var nombredia = dia + "";
                    var numeroCaracteresdia = nombredia.length;
                    if (numeroCaracteresdia == 1) {
                        var diafecha = "0" + nombredia;
                    } else {
                        diafecha = nombredia;
                    }
                    var fechaactualgeneradamod = anio + "-" + mesfecha + "-" + diafecha


                    var vidFileLength = $("#comprobantemod")[0].files.length;
                    var cargarArchivo = 0;
                    var comprobantenuevo;
                    var auxbanbenef = "";
                    var auxctabanbenef = "";

                    if ((vidFileLength === 0)) {

                        cargarArchivo = 0;
                        comprobantenuevo = null;

                        if (this.state.idTipoTransaccionmod == 3) {
                            auxbanbenef = "";
                            auxctabanbenef = "";
                        }
                        else {
                            auxbanbenef = this.state.bancobeneficariomod;
                            auxctabanbenef = this.state.numbancobeneficariomod;
                        }


                    } else {

                        if (this.state.comprobantemod != null) {
                            cargarArchivo = 1;
                            comprobantenuevo = this.state.comprobantemod[0];
                            auxbanbenef = this.state.bancobeneficariomod;
                            auxctabanbenef = this.state.numbancobeneficariomod;
                        }
                        else {
                            cargarArchivo = 0;
                            comprobantenuevo = null;
                            auxbanbenef = this.state.bancobeneficariomod;
                            auxctabanbenef = this.state.numbancobeneficariomod;
                        }
                    }

                    if (cargarArchivo == 1) {
                        //Validar el comprobante
                        var fileInput = document.getElementById('comprobantemod');
                        var filePath = fileInput.value;
                        var allowedExtensions = /(.jpg|.jpeg|.png)$/i;
                        if (!allowedExtensions.exec(filePath)) {
                            swal({
                                text: "El archivo debe ser formato:.jpeg/.jpg/.png",
                                icon: "error",
                                button: "Aceptar",

                            });
                        }
                        else {

                            const f = new FormData();
                            f.append("url_comprobante", comprobantenuevo);
                            f.append("fecha_matricula", fechaactualgeneradamod);//fecha en la registra el comprobante
                            f.append("num_depositof", this.state.numdepositomod);
                            f.append("fecha_depositof", this.state.fechadepositomod);
                            f.append("valor_cancelado", this.state.cantidadPagomod);
                            f.append("cuenta_debito_transferenciaf", this.state.cuentadebitotransferenciamod);
                            f.append("nom_dueno_cuenta_transff", this.state.nomdueniocuentadebitotransferenciamod);
                            f.append("nombre_banco_beneficiariof", auxbanbenef);
                            f.append("cuenta_beneficiariaf", auxctabanbenef);
                            f.append("tipo_pago", this.state.idTipoTransaccionmod);
                            f.append("nivel_est", this.state.nivelEstumod);
                            f.append("cargarchivomod", cargarArchivo);
                            f.append("idpagoabonomatri", datos.id_pago_abono_matricula_periodo);


                            const config = {
                                headers: { 'content-type': 'multipart/form-data' }
                            }

                            axios
                                //  .post(ApiUrl+"matriculas",f,config)
                                .post(ApiUrl + "modAbonoMatricula" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71", f, config)

                                .then(response => {
                                    console.log(response)
                                    if (response.data.success == true) {

                                        this.setState({ estado: "Datos modificados correctamente" });
                                        swal({
                                            text: "Datos modificados correctamente",
                                            icon: "success",
                                            button: "Aceptar",

                                        });
                                        //this.setState({cantidadPagopla:this.state.cantidadPago});
                                        this.setState({ modalRegistroPagoMatricula: false });

                                        //llama  actualizar la  tabla
                                        axios.post(urlEstudianteDatosAbonoMatricula, {
                                            idEstudianteDoc: cookie.get("idEstudiante"),
                                            idPeriodoActual: this.state.idPeriodo,
                                            idCarrerae: cookie.get("idCarreraEstu")
                                        })
                                            .then(respuesta => {
                                                if (respuesta.data.success == true) {
                                                    const datosabonomat = respuesta.data.data;
                                                    this.setState({ abonosmatricula: datosabonomat });

                                                }
                                            });

                                    }
                                    else {

                                        if (response.data.status == "failed") {
                                            this.setState({ estado: "Formato del archivo incorrecto" });
                                            swal({
                                                text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                                icon: "error",
                                                button: "Aceptar",

                                            });
                                        }
                                        else {
                                            this.setState({ estado: "Valores no registrados" });
                                            swal({
                                                text: "Valores no registrados",
                                                icon: "error",
                                                button: "Aceptar",

                                            });
                                        }


                                        this.setState({ modalRegistroPagoMatricula: false });


                                    }

                                })
                                .catch(error => {
                                    console.log(error);

                                    this.setState({ estado: "Error No se pudo conectar con el servidor" });
                                    window.location.reload(true);

                                })
                        }
                    }
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    handleChangeNivelCosto = async (e) => {

        await this.setState({ [e.target.name]: e.target.value })
        //Fecha actual

        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = (fecha.getMonth() + 1);
        var anio = fecha.getFullYear();
        var fechaMatricula = `${anio}-${mes}-${dia}`;

        //define mes 
        var nombremes = mes + "";
        var numeroCaracteresmes = nombremes.length;
        if (numeroCaracteresmes == 1) {
            var mesfecha = "0" + nombremes;
        } else {
            mesfecha = nombremes;
        }
        //define dia
        var nombredia = dia + "";
        var numeroCaracteresdia = nombredia.length;
        if (numeroCaracteresdia == 1) {
            var diafecha = "0" + nombredia;
        } else {
            diafecha = nombredia;
        }
        var fechaactualgenerada = anio + "-" + mesfecha + "-" + diafecha;


        axios
            .post(urlConsultarValorMatriNivel, {
                idCarreraf: cookie.get("idCarreraEstu"),
                periodoIdf: this.state.idPeriodo,
                nivelf: this.state.nivelEstu,
            })
            .then(response => {
                const costo = response.data.data;
                //Cálculo costo total
                if (fechaactualgenerada <= costo[0].fecha_matricula_extraord) {
                    const sumaTotal = parseFloat(costo[0].cantidad_pago) + parseFloat(costo[0].cant_pract_scomun) + parseFloat(costo[0].cant_pract_Lab);
                    this.setState({ valorMatriNivel: sumaTotal });
                }
                else {
                    const sumaTotal = parseFloat(costo[0].cantidad_pago_extraord) + parseFloat(costo[0].cant_pract_scomun) + parseFloat(costo[0].cant_pract_Lab);
                    this.setState({ valorMatriNivel: sumaTotal });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }
    //Selecciona el periodo y se visualiza si existe un pago de matrícula
    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        //devuelve los datos del pago ingresado de la matricula en un periodo//verificar
        this.setState({ estadoPeriod: 1 })

        axios.post(urlPagoMatriculaeEstPeriod, {
            estudiante_id: cookie.get("idEstudiante"),
            idPeriodoActual: this.state.idPeriodo
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    const pagomatriculaestud = respuesta.data.data;
                    this.setState({ cantidadPago: pagomatriculaestud[0].cantidad_pago });
                    this.setState({ cantidadPagopla: pagomatriculaestud[0].cantidad_pago });
                    this.setState({ idpagosmatricula: pagomatriculaestud[0].id_pagos_matriculas });
                    this.setState({ nombreArchivoG: pagomatriculaestud[0].comprobante_pago });
                    this.setState({ estadoverificar: 2 });
                    if (pagomatriculaestud[0].estado_pago == 1) {
                        this.setState({ estadomatriculae: "Enviado" });
                    } else {
                        if (pagomatriculaestud[0].estado_pago == 2) {
                            this.setState({ estadomatriculae: "Aprobado" });
                            this.setState({ disabledcim: this.state.disabledc })
                        } else {
                            this.setState({ estadomatriculae: "Rechazada" });
                        }

                    }

                } else {

                    //this.setState({estadomatricula:""});
                }
            })
        //Visualiza reporte del registro de pago de matrícula y la suma total
        axios.post(urlEstudianteDatosAbonoMatricula, {
            idEstudianteDoc: cookie.get("idEstudiante"),
            idPeriodoActual: this.state.idPeriodo,
            idCarrerae: cookie.get("idCarreraEstu")
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    const datosabonomat = respuesta.data.data;
                    listaauxiliar = respuesta.data.data;
                    this.setState({ abonosmatricula: datosabonomat });
                    const sumcouta = respuesta.data.sumacouta;
                    this.setState({ sumaTotalCuotas: sumcouta });

                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ abonosmatricula: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                }
            });

        //Visualiza el estado del pago de matrícula
        axios
            .post(urlEstadoPagoMatricula, {//guarda para enviar los atributos y envia
                idEstudianteDoc: cookie.get("idEstudiante"),
                idPeriodoActual: this.state.idPeriodo
            })
            .then(response => {
                const estadopagomat = response.data.data;
                if (estadopagomat != null) {
                    if (estadopagomat == 1) {
                        this.setState({ estadopagomatricula: "Enviado" });
                        this.setState({ disabledpm: !this.state.disabledpmv })
                    }
                    else {
                        if (estadopagomat == 4) {
                            this.setState({ estadopagomatricula: "Pagado Parcialmente" });
                            this.setState({ disabledpm: !this.state.disabledpmv })
                        }
                        else {
                            if (estadopagomat == 2) {
                                this.setState({ estadopagomatricula: "Pagado Totalmente" });
                                this.setState({ disabledpm: this.state.disabledpmv });
                            }

                        }
                    }
                }
                else {
                    this.setState({ estadopagomatricula: "" });
                    this.setState({ disabledpm: !this.state.disabledpmv });
                }

            })
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Matrícula</b>
                    </div>
                    <div className="card-body">

                        <div className="row ">
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <div className="mb-3">
                                        <label className="form-label">Nombres:</label><br />
                                        <label className="form-label">{this.state.nombrescompletos}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" id="idPeriodo" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            Nota: Una vez registrado el pago deslice la página ↓ hacia abajo para ver el detalle del mismo.
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        PROCESO
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Registrar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                        {this.state.estadopagomatricula}

                                                    </td>

                                                    <td>
                                                        <button disabled={(this.state.disabledpm) ? "disabled" : ""} onClick={() => this.mostrarModalPagoMatricula(1)} > <i className=" puntero fas fa-solid fa-image" ></i></button>

                                                    </td>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalVisualComprobante}>
                                    <ModalHeader>

                                        <h5>Comprobante</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <input name="id_pagos_matriculas" type="hidden" value={this.state.form.id_pago_abono_matricula_periodo}></input>


                                            <div className="mb-3 centrar">
                                                <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                    height='100%' ></input>
                                            </div>

                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalVisualComprobante}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>


                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalRegistroPagoMatricula}>
                                    <ModalHeader>

                                        <h5>Detalle</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <input name="id_pagos_matriculasmod" id="id_pagos_matriculasmod" type="hidden" value={this.state.form.id_pago_abono_matricula_periodo}></input>

                                            <div className="mb-3 centrar">

                                                <label className="form-label">Seleccione el nivel a cursar</label>
                                                <select className="form-select" id="nivelEstumod" name="nivelEstumod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                    <option value="undefined">Seleccione </option>

                                                    {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}

                                                </select>

                                                <label className="form-label">Seleccionar si realizó un Deposito o Transferencia</label>
                                                <select className="form-select" id="idTipoTransaccionmod" name="idTipoTransaccionmod" value={this.state.value} onChange={this.handleChangeFormaPagoModEfect} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    <option value="1">Depósito</option>
                                                    <option value="2">Transferencia</option>
                                                    <option value="3">Efectivo</option>

                                                </select>


                                                <label className="form-label">Número de papeleta, referencia o número de comprobante</label><br />
                                                <input type="text" id="numdepositomod" name="numdepositomod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagmodd) ? "disabled" : ""} required />

                                                <label className="form-label">Fecha de transacción</label><br />
                                                <input type="date" id="fechadepositomod" name="fechadepositomod" onChange={this.handleChange} className="form-control" placeholder="" required />


                                                <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                                <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPagomod" name="cantidadPagomod" onChange={this.handleChange} className="form-control" placeholder="0.00" required /></div>

                                                <label className="form-label">#Cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="cuentadebitotransferenciamod" name="cuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmod) ? "disabled" : ""} required />

                                                <label className="form-label">Nombre del Dueño de la cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="nomdueniocuentadebitotransferenciamod" name="nomdueniocuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledctmod) ? "disabled" : ""} required />

                                                <label className="form-label">Banco Beneficiario</label><br />

                                                <select className="form-select" id="bancobeneficariomod" name="bancobeneficariomod" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioMod} aria-label="Default select example" disabled={(this.state.disabledformpagmodd) ? "disabled" : ""} >
                                                    <option value="1" >PACÍFICO</option>
                                                    <option value="2">PICHINCHA-3257594104</option>
                                                    <option value="3">PICHINCHA-2100271381</option>
                                                    <option value="4">AUSTRO</option>
                                                </select>


                                                <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                                <input type="text" id="numbancobeneficariomod" name="numbancobeneficariomod" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disablednumbanbened) ? "disabled" : ""} required />

                                                <label className="form-label">Subir el  comprobante</label><br />
                                                <input className="form-control" type="file" id="comprobantemod" name="comprobantemod" onChange={this.handleChangeComprobante} disabled={(this.state.disabledformpagmodd) ? "disabled" : ""} required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        {this.state.avisoestud}
                                        <div id="bottmodif">
                                            <button class="btn btn-outline-primary" disabled={(this.state.disabledpmab) ? "disabled" : ""} onClick={() => this.modificarAbonoMatriculaEstPeriod(this.state.form)} > Modificar</button>
                                        </div>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalRegistroPagoMatricula}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>

                        <div className="card-body centrar " style={{ width: "100%" }}>
                            <div class="table-responsive">
                                <table id="tablaasignaturas" className="table  text-left">
                                    <thead >
                                        <tr>

                                            <th scope="col">ID</th>
                                            <th scope="col">Forma de Pago</th>
                                            <th scope="col">Fecha del Deposito</th>
                                            <th scope="col">valor cancelado</th>

                                            <th scope="col">Fecha de Registro</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Novedad</th>
                                            <th scope="col">Detalle</th>
                                            <th scope="col">Comprobante</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.abonosmatricula.map(consola => (
                                                <tr key={consola.id_pago_abono_matricula_periodo}>
                                                    <td class="ID">{consola.id_pago_abono_matricula_periodo}</td>
                                                    <td class="formadepago" >

                                                        {(() => {

                                                            //Paralelo
                                                            switch (consola.tipo_deposito) {

                                                                case 1: return "DEPOSITO";
                                                                case 2: return "TRANSFERENCIA";
                                                                case 3: return "EFECTIVO";
                                                                default: return ""
                                                            }
                                                        })()}




                                                    </td>

                                                    <td class="fecha" >{consola.fecha_deposito}</td>
                                                    <td class="valor" >${consola.valor_deposito}</td>
                                                    <td class="fecha  registro" >{consola.fecha_registro_comprobante}</td>
                                                    <td class="estado">


                                                        {(() => {
                                                            //Paralelo
                                                            switch (consola.estado_abono_matricula) {
                                                                case 1: return "Enviado";
                                                                case 2: return "Aprobado";
                                                                case 3: return "Rechazado";
                                                                case 5: return "En proceso";
                                                                case 6: return "En proceso";
                                                                default: return ""
                                                            }
                                                        })()}

                                                    </td>

                                                    <td class="detalle" >

                                                        {(() => {
                                                            //Paralelo
                                                            switch (consola.estado_abono_matricula) {
                                                                case 1: return "";
                                                                case 2: return "";
                                                                case 3: return consola.detalle;
                                                                case 5: return "";
                                                                case 6: return "";
                                                                default: return ""
                                                            }
                                                        })()}



                                                    </td>
                                                    <td class="detalle">
                                                        <button onClick={() => this.mostrarModalRegistroPagoMatricula(consola)} > <i className="puntero fas fa-pen" ></i></button>  </td>
                                                    <td class="comprobante">
                                                        <button onClick={() => this.mostrarModalComprobanteAbonoMatricula(consola)} > <i className=" puntero fas fa-solid fa-image" ></i></button>  </td>

                                                </tr>
                                            ))}
                                        <tr>
                                            <td colspan="3">
                                                <b>
                                                    SUMA
                                                </b >
                                            </td>
                                            <td >
                                                <b>
                                                    ${this.state.sumaTotalCuotas}</b >

                                            </td>

                                        </tr>



                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <ReactPaginate
                            nextLabel="next >"
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />


                        <Modal classname="modal-background" isOpen={this.state.modalPagoMatricula}>
                            <ModalHeader>
                                Registro de pago de matrícula

                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <div className="mb-3 centrar">

                                        <label className="form-label">Seleccione el nivel a cursar</label>
                                        <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChangeNivelCosto} aria-label="Default select example">
                                            <option value="undefined">Seleccione </option>

                                            {this.state.listanivelespm.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                        </select>
                                        Valor: {this.state.valorMatriNivel}
                                        <br />


                                        <label className="form-label">Seleccionar la forma de pago</label>
                                        <select className="form-select" name="idTipoTransaccion" value={this.state.value} onChange={this.handleChangeFormaPagoEfect} aria-label="Default select example" required>
                                            <option value="">Seleccione</option>
                                            <option value="1">Depósito</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Efectivo</option>

                                        </select>


                                        <label className="form-label">Número de papeleta, referencia o número de comprobante</label><br />
                                        <input type="text" name="numdeposito" disabled={(this.state.disabledformpagd) ? "disabled" : ""} onChange={this.handleChange} className="form-control" placeholder="" required />


                                        <label className="form-label">Fecha de depósito</label><br />
                                        <input type="date" name="fechadeposito" onChange={this.handleChange} className="form-control" placeholder="" required />


                                        <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                        <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" name="cantidadPago" onChange={this.handleChange} className="form-control" placeholder="0.00" required /></div>

                                        <label className="form-label">#Cuenta que se debito la transferencia</label><br />
                                        <input type="text" name="cuentadebitotransferencia" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} required />

                                        <label className="form-label">Nombre del Dueño de la cuenta que se debito la transferencia</label><br />
                                        <input type="text" name="nomdueniocuentadebitotransferencia" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} required />

                                        <label className="form-label">Banco Beneficiario</label><br />

                                        <select className="form-select" disabled={(this.state.disabledformpagd) ? "disabled" : ""} id="bancobeneficario" name="bancobeneficario" value={this.state.value} onChange={this.handleChangeCuentaBeneficiario} aria-label="Default select example">

                                            <option value="1">PACIFICO</option>
                                            <option value="2">PICHINCHA-3257594104 </option>
                                            <option value="3">PICHINCHA-2100271381 </option>
                                            <option value="4">AUSTRO</option>
                                        </select>
                                        <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                        <input type="text" id="numbancobeneficario" name="numbancobeneficario" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disablednumbanbened) ? "disabled" : ""} required />
                                        <label className="form-label">Subir el  comprobante</label><br />
                                        <input className="form-control" type="file" id="comprobante" name="comprobante" disabled={(this.state.disabledformpagd) ? "disabled" : ""} onChange={this.handleChangeComprobante} />

                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <button class="btn btn-outline-primary" onClick={() => this.abonarMatriculaEstPeriod(2)} > Aceptar</button>
                                <button class="btn btn-outline-primary" onClick={this.ocultarModalPagoMatricula}>Salir</button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>

            </div>
        )
    }
}
