import axios from 'axios';
import ReactPaginate from 'react-paginate'
import React, { Component } from 'react'
import "../../services/ApiRest";
import "../../coordinacionacad/coordacad.css";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { Alert } from 'bootstrap';
import * as xlsx from "xlsx";

const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarCarreras = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarAsignMatCiclo = ApiUrl + "ListarAsignMatCiclo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstudCarrPeriod = ApiUrl + "listestudasignamatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListParalelos = ApiUrl + "listodosparalelos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class CalificacionesEstud extends Component {

    constructor(props) {
        super(props)
        this.state = {
            carrera: [],
            listparalelos: [],
            estado: "",
            listasignaturas: [],
            listaperiodos: [],
            idPeriodo: 1,
            listaasigencab: [],
            listaniveles: [],
            //Variables para búsqueda
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,

        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportAsignatutasEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //listar todos los periodos
        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
        //Listar todos los paralelos
        axios
            .get(urlListParalelos)
            .then(response => {
                if (response.data.success == true) {
                    const listparalelos = response.data.data;
                    const cant = listparalelos.length;
                    if (cant != 0) {
                        this.setState({ listparalelos });
                    }
                }
            })

    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1

        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }


    handleChangeSelectNivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarrera
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                let listaniveles1 = []
                for (let i = 1; i <= nivelcarrest; i++) {
                    let fila = {
                        codnivel: i,
                        valor: i
                    };
                    listaniveles1.push(fila);
                }
                this.setState({ listaniveles: listaniveles1 });
            })
    }
    handleChangematcarrnivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var nuevalistaasig = null;
        axios
            .post(UrlListarAsignMatCiclo, {
                idCarrera: this.state.idCarrera,
                idCiclo: this.state.nivelEstu,
            })
            .then(response => {
                var array = response.data.data;
                this.setState({ listaasigencab: array });
                axios.post(urlListarEstudCarrPeriod, {
                    idPeriodSelect: this.state.idPeriodo,
                    idCarreraSelect: this.state.idCarrera,
                    idListaAsigna: array,
                    idCicloSelect: this.state.nivelEstu,
                    idParaleloSelect: this.state.idParalelo
                })
                    .then(respuesta => {

                        if (respuesta.data.success == true) {
                            listaauxiliar = respuesta.data.data;
                            const estud = respuesta.data.data;
                            this.setState((state) => {
                                return { estud: estud }
                            }
                            );
                            const EstudianteBackup = estud;
                            this.setState((state) => {
                                return { EstudianteBackup: EstudianteBackup }
                            });
                            //PAGINACION
                            this.state.itemOffset = 0;
                            const endOffset = this.state.itemOffset + itemsPerPage;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState((state) => {
                                return { itemOffset: state.itemOffset + endOffset }
                            });
                            //FIN  DE LA PAGINACION
                        } else {
                            this.setState({ error: "¡No existe información!" });
                            swal({
                                text: "¡No existe información!",
                                icon: "info",
                                button: "Aceptar",
                            });
                        }
                    })
            })
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Acta de Calificaciones</b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Paralelo</b></label>
                                    <select className="form-select" name="idParalelo" id="idParalelo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined">Seleccione un periodo:</option>
                                        {this.state.listparalelos.map(consola => <option key={consola.id_paralelo} value={consola.id_paralelo} > {consola.nombre_paralelo}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                <div className="mb-3">
                                    <label className="form-label"><b>Nivel</b></label>
                                    <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChangematcarrnivel} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione </option>
                                        {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ACTA DE CALIFICACIONES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Nº</th>
                                                        <th scope="col">CÉDULA</th>
                                                        <th scope="col">PRIMER APELLIDO</th>
                                                        <th scope="col">SEGUNDO APELLIDO</th>
                                                        <th scope="col">PRIMER NOMBRE</th>
                                                        <th scope="col">SEGUNDO NOMBRE</th>
                                                        {this.state.listaasigencab.map(consola => (
                                                            <th scope="col">{consola.descripcion_asignatura}</th>

                                                        ))}
                                                        <th scope="col">TOTAL</th>
                                                        <th scope="col">PROMEDIO</th>
                                                        <th scope="col">VALORACIÓN</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados " key={this.state.estud.id_estudiante}  >
                                                                <td>{consola.num_est}</td>
                                                                <td >{consola.numero_identificacion_estudiante}</td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                {consola.lista_calif_asig.map(consolaasig => (
                                                                    <td >
                                                                        {(() => {
                                                                            if (consolaasig.calificacion_prom < 7) {
                                                                                return <span class="mensajesusp"> {consolaasig.calificacion_prom}</span>
                                                                            }
                                                                            else {
                                                                                return <span> {consolaasig.calificacion_prom}</span>
                                                                            }
                                                                        })()}
                                                                    </td>

                                                                ))}
                                                                <td>{consola.total}</td>
                                                                <td>{consola.promedio}</td>
                                                                <td>{consola.valoracion}</td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
