import axios from 'axios';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactPaginate from 'react-paginate'
import generatePDF from "./reportGenerator";


const carrerasUrl = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const datosEstudianteMatricula = ApiUrl + "reportematriculaestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaEstudMatr = ApiUrl + "listarestudmatridifperiod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;
export default class GenerarPDFMatricula extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            periodos: [],
            cedulaEstudianteSelect: "",
            estado: "",
            objestudiante: null,
            idPeriodo: "1",
            idCarrera: "1",

            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    componentDidMount() {
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })

        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })
    }


    seleccionarEstudianteuno = (registro) => {
        axios
            .post(datosEstudianteMatricula, {//guarda para enviar los atributos y envia
                idEstud: registro.numero_identificacion_estudiante,
                idPeriodo: this.state.idPeriodo,
                idCarrera: this.state.idCarrera
            })
            .then(response => {
                if (response.data.data != null) {
                    generatePDF(response.data.data);
                }
                else {
                    this.setState({ estado: "No existe datos para mostrar" });
                    swal({
                        text: "No existe datos para mostrar",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;   //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }

    handleChangeEstudCarrera = async (e) => {
        axios
            .post(urlListaEstudMatr, {
                idcarrerae: this.state.idCarrera,
                idperiodoe: this.state.idPeriodo,
            })
            .then(response => {
                if (response.data.success == true) {
                    listaauxiliar = response.data.data;
                    const estud = response.data.data;

                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;
                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });

                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  DE LA PAGINACION

                } else {
                    if (response.data.message == "vacio") {
                        const estudiantes = [];
                        this.setState({ estudiantes });
                        this.setState({ estado: "No existe datos para mostrar" });
                        swal({
                            text: "No existe datos para mostrar",
                            icon: "info",
                            button: "Aceptar",
                        });
                    }
                    else {
                        const estudiantes = [];
                        this.setState({ estudiantes });
                        this.setState({ estado: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",
                        });
                    }

                }
            })
            .catch(error => {
                const estudiantes = [];
                this.setState({ estudiantes });
                this.setState({ estado: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Documento de la Matrícula </b>
                    </div>
                    <div className="card-body">
                        <div class="row">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label className="form-label"><b>Carrera</b></label>
                                        <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                            <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                            {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeEstudCarrera} /><br />
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">PDF</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td >{consola.numero_identificacion_estudiante}

                                                                </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>
                                                                    <button onClick={() => this.seleccionarEstudianteuno(consola)}  > <i className=" fas fa-file-pdf" ></i></button>

                                                                </td>
                                                            </tr>
                                                        ))}

                                                </tbody>
                                            </table>

                                        </div>
                                        <div class="table-responsive">
                                            <ReactPaginate
                                                nextLabel="next >"
                                                onPageChange={this.handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                previousLabel="< previous"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* escondidos */}
                        {/* fIN DE ESCONDIDOS */}
                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
