import React, { Component } from 'react';
import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';

import { Footer } from '../footer/Footer';
import { NavbarLog } from '../navbar/NavbarLog';
import './Login.css';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import { ApiUrl } from '../services/ApiRest';
import swal from 'sweetalert';


const baseUrl = ApiUrl + "authlogin?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const cookies = new Cookies();

export default class LoginScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listaroles: [],
            rol: 0,
            ci: '',
            password: '',
            islogged: false,     //pregunta si ya ha estado logeado
            error: '',
            modalPagoColegituraMes: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        this.setState({ error: "" });

        // console.log(this.state);
    }

    ocultarModalPagoColegituraMes = () => {
        this.setState({ modalPagoColegituraMes: false });

    }

    iniciarSesion = async (e) => {
        e.preventDefault();
        this.setState({ islogged: true }); //indica que se ha comenzado inicio de sesion
        //post q devuelve token,id del estudiante, mensaje, success


        await axios
            .post(baseUrl, {
                numero_identificacione: this.state.ci,
                password: this.state.password,
                // rol:this.state.rol
            })
            .then(response => {
                //console.log(response);
                if (response.data.success === true) {
                    var logeado = this.state.islogged;
                    var rol = response.data.id_rol;
                    this.setState({ listaroles: response.data.roles });

                    if (rol == 0) {
                        //DECLARACIOND E COOKI
                        //declaracion de Cookies que van a ser usadas en todo momento
                        cookies.set('log', logeado, { path: "/" })
                        cookies.set('rol', rol, { path: "/" })
                        //cookies.set('auxrol',auxrol,{path:"/"})
                        cookies.set('token', response.data.token, { path: "/" })
                        //eliminación de la cookie que viene de carreras
                        cookies.remove('idCarrera', { path: "/" });
                        //FIN DE  DECLARACION

                        if (cookies.get('rol') == 0) {
                            cookies.set('idEstudiante', response.data.estudiante_id, { path: "/" });
                            cookies.set('idCarreraEstu', response.data.carrera_id, { path: "/" });

                            var nombres = this.borrarEspaciosNombres(response.data.primernombre, response.data.segundonombre);
                            var apellidos = this.borrarEspaciosApellido(response.data.primerapellido, response.data.segundoapellido);
                            cookies.set('nombres', nombres, { path: "/" });
                            cookies.set('apellidos', apellidos, { path: "/" });
                            var NombresCompleto = nombres + " " + apellidos;
                            cookies.set('nomCompleEst', NombresCompleto, { path: "/" });
                            window.location.href = "/estudiante";
                        }
                    }
                    else {
                        if (response.data.roles.length == 1) {
                            this.dirigirUsuarioRol(response.data.roles[0]);
                        } else {
                            this.setState({ modalPagoColegituraMes: true });
                        }
                    }
                } else {
                    swal({
                        text: "El usuario o la contraseña no son correctos",
                        icon: "error",
                        button: "Aceptar",

                    });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }

    dirigirUsuarioRol = (admin_rol) => {
        this.setState({ islogged: true });
        var logeado = this.state.islogged;
        //declaracion de Cookies que van a ser usadas en todo momento
        cookies.set('log', logeado, { path: "/" });
        cookies.set('rol', admin_rol.rol_id, { path: "/" });

        //eliminación de la cookie que viene de carreras
        cookies.remove('idCarrera', { path: "/" });

        if (cookies.get('rol') == 1) {
            cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
            window.location.href = "/administrativo";
        } else {
            if (cookies.get('rol') == 2) {
                cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })

                window.location.href = "/docente";
            } else {

                if (cookies.get('rol') == 3) {
                    cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                    window.location.href = "/colecturia";
                }
                else {
                    if (cookies.get('rol') == 4) {
                        cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                        window.location.href = "/secretaria";
                    }
                    else {

                        if (cookies.get('rol') == 5) {
                            cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                            window.location.href = "/vicerrectoradoadm";
                        }
                        else {
                            if (cookies.get('rol') == 6) {
                                cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                window.location.href = "/bienestar";
                            }
                            else {
                                if (cookies.get('rol') == 7) {
                                    cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                    window.location.href = "/coordinacionacad";
                                } else {
                                    if (cookies.get('rol') == 8) {
                                        cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                        window.location.href = "/ucomunicacion";
                                    } else {
                                        if (cookies.get('rol') == 9) {
                                            cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                            window.location.href = "/recepcion";
                                        } else {
                                            if (cookies.get('rol') == 10) {
                                                cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                window.location.href = "/vicerrectoradoacad";
                                            }
                                            else {
                                                if (cookies.get('rol') == 11) {
                                                    cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                    window.location.href = "/atencionpacientes";
                                                }
                                                else {
                                                    if (cookies.get('rol') == 12) {
                                                        cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                        window.location.href = "/planificacion";
                                                    }
                                                    else {
                                                        if (cookies.get('rol') == 13) {
                                                            cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                            window.location.href = "/directorpracticas";
                                                        } else {
                                                            if (cookies.get('rol') == 14) {
                                                                cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                                window.location.href = "/evaluacion";
                                                            } else {
                                                                if (cookies.get('rol') == 15) {
                                                                    cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                                    window.location.href = "/acalidad";
                                                                }
                                                                else {
                                                                    if (cookies.get('rol') == 16) {
                                                                        cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                                        window.location.href = "/rectorado";
                                                                    } else {
                                                                        if (cookies.get('rol') == 17) {
                                                                            cookies.set('idAdministrativo', admin_rol.administrativo_id, { path: "/" })
                                                                            window.location.href = "/gestorplataforma";
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }


                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    borrarEspaciosNombres = (primernombre, segundonombre) => {
        var nombres = primernombre;
        if (segundonombre != null)
            nombres = nombres + " " + segundonombre;
        return nombres;

    }
    borrarEspaciosApellido = (primerapellido, segundoapellido) => {
        var apellidos = primerapellido;
        if (segundoapellido != null)
            apellidos = apellidos + " " + segundoapellido;
        return apellidos;
    }

    render() {

        if (cookies.get('log')) {
            if (cookies.get('rol') == 1) {
                return (<Redirect to="/administrativo" />);
            } else {
                if (cookies.get('rol') === 2) {
                    return (<Redirect to="/docente" />);
                } else {
                    if (cookies.get('rol') == 0) {
                        return (<Redirect to="/estudiante" />);
                    }
                    else {
                        if (cookies.get('rol') == 3) {
                            return (<Redirect to="/colecturia" />);
                        } else {

                            if (cookies.get('rol') == 4) {
                                return (<Redirect to="/secretaria" />);
                            }


                        }
                    }
                }
            }
        }


        return (

            <div>
                <NavbarLog />
                <div className="container" style={{ width: "70%" }}>
                    <div className="row ">
                        <div className="formulario   col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 color-istmas">
                            <div className="card shadow p-3 mb-5 bg-white rounded">
                                <div className="card-header">
                                    <h4>Inicio de Sesión</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.iniciarSesion} className="" style={{ padding: 2 }}>

                                        <div className="mb-3">
                                            <label className="form-label">Cédula de Identidad</label>
                                            <input type="text" name="ci" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" placeholder="Cédula sin guion" maxLength="10" aria-describedby="emailHelp" required />
                                            <div id="validacionCedula" className="form-text">{this.state.error}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Contraseña</label>
                                            <input type="password" name="password" onChange={this.handleChange} className="form-control" id="exampleInputPassword1" required />
                                        </div>

                                        <button type="submit" className="btn  back-istmas"><b>Iniciar Sesión</b></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className=" col col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                            <div className="">
                                <img src={"./assets/img/imageningreso.png"} className="img-login" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>

                </div>
                <Modal isOpen={this.state.modalPagoColegituraMes} >
                    <ModalHeader>
                        SELECCIONE UN ROL
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <table className="table  text-left"  >
                                <thead >
                                    <tr >
                                        <th scope="col">Rol</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listaroles.map((consola) => (
                                        <tr class="list-afiliados" key={consola.id_admin_rol}  >
                                            <td >{consola.descripcion_rol}</td>
                                            <td><input type="radio" value="1" name="afiliado" onClick={() => this.dirigirUsuarioRol(consola)} class="afiliado" /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <button class="btn btn-outline-primary" onClick={this.ocultarModalPagoColegituraMes}>Salir</button>
                    </ModalFooter>
                </Modal>


                <Footer />

            </div>
        )
    }
}