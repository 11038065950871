import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de consulta en el backend
const UrlListarPlanes = ApiUrl + "listarplanes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarEjexPlan = ApiUrl + "insertarejexplan" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarejesactivos = ApiUrl + "listarejesactivos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarEjesxPlan = ApiUrl + "listarejesxplan" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarUsiarioAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 4;

export default class GestionEjesPlan extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            ejes: [],
            modalReportIngresarPlan: false,
            plan_seleccionado: '',
            nombre_plan: '',
            administrativos: [],
            //nuevas variables
            listaplanes: [],
            listaejes: [],
            porcentaje_plan: 0,
            color_plan: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            disableForm: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeDevolverIdPlan = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ plan_seleccionado: this.state.idPlan });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_plan: option })
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeListarAdm() {
        axios.get(UrlListarUsiarioAdm)
            .then(res => {
                const administrativos = res.data.data;
                this.setState({ administrativos });
            })
    }

    handleChangeListarEjes() {
        axios.get(urlListarejesactivos)
            .then(res => {
                this.setState({ listaejes: res.data.data });
            })
    }

    handleChangeListarPlanes = async (e) => {
        //planes
        axios.get(UrlListarPlanes)
            .then(respuesta => {
                this.setState({ listaplanes: respuesta.data.data });
            })
    }

    handleChangeListarEjesxPlan = async (e) => {
        //ejes
        axios
            .post(UrlListarEjesxPlan, {
                id: this.state.plan_seleccionado,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_plan: response.data.data1 });
                this.setState({ color_plan: response.data.data2 });
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                this.setState({ disableForm: false });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarPlanes();
        this.handleChangeListarAdm();
        this.handleChangeListarEjes();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalReportIngresarPlan: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_planejeadm);
            $("#eje").val(registro.id_eje);
            $("#responsable").val(registro.id_administrativo);
        });

        this.setState({ id: registro.id_planejeadm });
        this.setState({ eje: registro.id_eje });
        this.setState({ responsable: registro.id_administrativo });
        this.setState({ modalReportIngresarPlan: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarPlan: false });
    }

    registrarEjePlan = () => {

        const f = new FormData();
        f.append("id", this.state.id);
        f.append("id_plan", this.state.plan_seleccionado);
        f.append("id_eje", this.state.eje);
        f.append("responsable", this.state.responsable);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarEjexPlan, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.post(UrlListarEjesxPlan, {
                        id: this.state.plan_seleccionado,
                    })
                        .then(respuesta => {
                            listaauxiliar = respuesta.data.data;
                            if (this.state.pageCount == 1) {
                                this.setState({ itemOffset: 0 });
                            }

                            //PAGINACION
                            const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                            this.setState((state) => {
                                return { itemOffset: newOffset }
                            });
                            const endOffset = newOffset + itemsPerPage;
                            //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState({ itemOffset: newOffset });
                        })
                    this.ocultarModalIngresar();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Ejes por Plan</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Planes Institucionales</b></label>
                                    <select className="form-select" name="idPlan" value={this.state.value} onChange={this.handleChangeDevolverIdPlan} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un plan</option>
                                        {this.state.listaplanes.map(person => <option key={person.id_plan} data-nombre={person.nombre_plan} value={person.id_plan} > {person.nombre_plan}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEjesxPlan} />
                                </div>
                            </div>
                        </div>
                        <div className="card border-dark text-center" hidden={this.state.disableForm}>
                            <div className="card border-dark text-center text-white">
                                <div className="card-header" class={this.state.color_plan}>
                                    <b>PORCENTAJE DEL PLAN: {this.state.porcentaje_plan}%</b>
                                </div>
                            </div>
                            <div className="card-header">
                                <b>REPORTE DE EJES POR PLAN</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Responsable</th>
                                            <th>Porcentaje</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_planejeadm}>
                                                <td class="id">{consola.id_planejeadm}</td>
                                                <td class="nombre_eje">{consola.nombre_eje}</td>
                                                <td class="responsable">{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                <td class={consola.color}>{consola.porcentaje_eje}%</td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div class="table-responsive">
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                                <Modal isOpen={this.state.modalReportIngresarPlan} >
                                    <ModalHeader>
                                        Registro de Eje por Plan
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Plan:</label>
                                                <input type="text" id="plan" name="plan" onChange={this.handleChange} value={this.state.nombre_plan} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Eje:</label>
                                                <select className="form-select" id="eje" name="eje" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.listaejes.map(person => <option key={person.id_eje} value={person.id_eje} > {person.nombre_eje}</option>)}
                                                </select>
                                                <label className="form-label">Responsable:</label>
                                                <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.administrativos.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarEjePlan()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div className="text-center">
                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        onClick={() => this.mostrarModalIngresar()} >
                                        <b>Registrar</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

