
import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText, Alert } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCarrera = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera = ApiUrl + "numnivelcarrperiodprac" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlPeriodosPracticas = ApiUrl + "ingresarhorasperiodoprac" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = inscribirUrlListarCarrera;
let listaauxiliar = [];
const itemsPerPage = 10;
export default class horasPracticasPreprof extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            estado: '',
            listDocumentPract: [],
            //nuevas variables
            numnivelescarr: '',
            listaperiodos: [],
            listaniveles: [],
            //modales
            modalReportDocuments: false,

        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportDocuments: false });

        }
    }

    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ listaniveles: [] });

        //devuelve los niveles de  una carrera y los datos registrados por cada nivel
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.idPeriodoSelect,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
    }

    registrarPracticasPeriodo = async (e) => {
        // e.preventDefault(); 
        //Lista de valores
        let materiasselect = [];
        document.querySelectorAll('#tablahoraspra tbody tr').forEach(function (e) {
            var idnivel = e.querySelector('.codigo').innerText
            //Horas  de servicio comunitario
            var valorSC = $("#" + idnivel + "numhorassc").val();
            if ((valorSC == 0) || (valorSC == "")) {
                valorSC = 0;
            }
            //Horas Laborales
            var valorL = $("#" + idnivel + "numhoraslab").val();
            if ((valorL == 0) || (valorL == "")) {
                valorL = 0;
            }
            //Crea el objeto
            let fila = {
                idnivelf: idnivel,
                numhorasservicioc: valorSC,
                numhoraslaborales: valorL,
            };
            materiasselect.push(fila);
        });

        axios
            .post(inscribirUrlPeriodosPracticas, {
                periodoactualef: this.state.idPeriodoSelect,
                listPeriodPrac: materiasselect,
                idCarreraf: this.state.idCarrera,
            })
            .then(response => {
                const list = response.data.data;
                if (response.data.success === true) {
                    swal({
                        text: "Datos ingresados exitosamente",
                        icon: "success",
                        button: "Aceptar",
                    });

                    //Actualiza el reporte
                    axios
                        .post(devuelNumNivelesCarrera, {
                            idCarreraf: this.state.idCarrera,
                            periodoIdf: this.state.idPeriodoSelect,
                        })
                        .then(response => {
                            const listaniveles1 = response.data.data;
                            this.setState({ listaniveles: listaniveles1 });
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                }
                else {
                    swal({
                        text: "Datos no  registrados",
                        icon: "error",
                        button: "Aceptar",
                    });

                }
            })
            .catch(error => {
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                swal({
                    text: "Datos no  registrados",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }


    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Horas Prácticas</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined">Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" id="idCarrera" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined">Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>


                        <div className="row ">
                            <div className="col-2 col-lg-2 col-xl-2 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                            <div className="col-8 col-sm-8 col-lg-8 col-xl-8 centrar" >
                                <div class="table-responsive">
                                    <table className="table table-bordered" id="tablahoraspra" name="tablahoraspra" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Nivel</th>
                                                <th>Horas de Servicio Comunitario</th>
                                                <th>Horas Laborales</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.listaniveles.map(consola => (
                                                <tr key={consola.codnivel}>
                                                    <td class="codigo" >{consola.codnivel}</td>
                                                    <td class="horassc" > <input type="text" name={consola.codnivel + "numhorassc"} id={consola.codnivel + "numhorassc"} className="form-control" placeholder={consola.valor_horassc} />
                                                    </td>

                                                    {
                                                        (() => {
                                                            $(function () {
                                                                $("#" + consola.codnivel + "numhorassc").val(consola.valor_horassc);
                                                            });
                                                        })()
                                                    }
                                                    <td class="horaslab" > <input type="text" name={consola.codnivel + "numhoraslab"} id={consola.codnivel + "numhoraslab"} className="form-control" placeholder={consola.valor_horaslab} />
                                                    </td>

                                                    {
                                                        (() => {
                                                            $(function () {
                                                                $("#" + consola.codnivel + "numhoraslab").val(consola.valor_horaslab);
                                                            });
                                                        })()
                                                    }

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="col-2 col-sm-2 col-lg-2 col-xl-2 centrar" >
                            </div>
                        </div>

                        <div className="text-center">
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                style={{ margin: "5px", width: "170px" }}
                                onClick={e =>
                                    this.registrarPracticasPeriodo(e)
                                }>
                                <b>Aceptar</b>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

