import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import "../../services/ApiRest"
import { ApiUrl } from '../../services/ApiRest';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/";
const idAdministrativo = cookie.get("idAdministrativo");
const urlCambioClavePer = ApiUrl + "cambioclavepersonal" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutacomprobpensmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


export default class VerPerfil extends Component {
    constructor(props) {
        super(props)

        this.state = {
            estudiante: [],
            password: "",
            confirmacionpassword: "",

        }

    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }
    componentDidMount() {
        //  axios.get(urlEstudiante + idEstudiante+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });

                axios.post(rutaimagen, {

                    nombrecomprobante: "usuario/" + estudiante.imagen_perfil_administrativo,
                })
                    .then(res => {
                        const ruta = res.data.data;
                        this.setState({ ruta });
                    })


            })
    }

    mostrarModalComprobanteAbonoCuota = (registro) => {
        axios.post(rutaimagen, {

            nombrecomprobante: "usuarios/" + registro.comprobante_pens,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        this.setState({ modalVisualComprobante: true, form: registro });
    }




    //Cambiar la contraseña de la cuenta del sistema académico
    cambiarContrasenaPer = async (e) => {
        //  this.setState({ [e.target.name]: e.target.value });
        if ((this.state.password != "") && (this.state.confirmacionpassword != "")) {

            if (this.state.password == this.state.confirmacionpassword) {
                axios
                    .post(urlCambioClavePer, {//guarda para enviar los atributos y envia
                        idUsuario: idAdministrativo,
                        passwordmod: this.state.password
                    })
                    .then(response => {

                        if (response.data.success == true) {

                            this.setState({ estado: "Datos modificados correctamente" });
                            swal({
                                text: "Datos modificados correctamente",
                                icon: "success",
                                button: "Aceptar",
                            });

                        }
                        else {
                            swal({
                                text: "Datos no modificados",
                                icon: "error",
                                button: "Aceptar"

                            });

                        }
                    })
                    .catch(error => {
                        this.setState({ estado: "Error No se pudo conectar con el servidor" });
                    })
            }
            else {
                this.setState({ estado: "Las contraseñas no coinciden" });
                swal({
                    text: "Las contraseñas no coinciden",
                    icon: "error",
                    button: "Aceptar"
                });
            }
        }
        else {
            this.setState({ estado: "El campo contraseña esta vacio" });
            swal({
                text: "El campo contraseña esta vacio",
                icon: "error",
                button: "Aceptar"
            });
        }

    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header center">
                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Imagen" width='100'
                            height='100' ></input>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Nombres: </b> {this.state.estudiante.nombres_administrativo}</label>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Apellidos: </b> {this.state.estudiante.apellidos_administrativo}</label>

                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Cédula de Identidad: </b> {this.state.estudiante.numero_identificacion_administrativo}</label>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Fecha de Nacimiento: </b> {this.state.estudiante.fecha_nacimiento_administrativo}</label>

                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Correo electrónico: </b> {this.state.estudiante.email_administrativo}</label>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Teléfono celular: </b> {this.state.estudiante.celular_administrativo}</label>

                                </div>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Dirección: </b> {this.state.estudiante.direccion_administrativo}</label>



                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Teléfono Convencional: </b> {this.state.estudiante.convencional_administrativo}</label>

                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> Contraseña</b> </label>
                                    <input type="password" id="password" name="password" className="form-control" placeholder="Contraseña" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Confirmar contraseña</b></label>
                                    <input type="password" id="confirmacionpassword" name="confirmacionpassword" onChange={this.handleChange} className="form-control" placeholder="Confirmar Contraseña" required />
                                </div>

                            </div>
                        </div>

                        <div className="text-center">

                            <button class="btn btn-outline-primary" onClick={this.cambiarContrasenaPer}>Guardar</button>

                        </div>

                    </div>
                </div>

            </div>
        )
    }
}