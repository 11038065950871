import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de consulta en el backend
const urlInsertarEje = ApiUrl + "insertareje" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEjes = ApiUrl + "listarejes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 4;

export default class GestionEjes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            ejes: [],
            modalReportIngresarEje: false,
            //nuevas variables
            listaejes: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeListarEjes() {
        axios.get(urlListarEjes)
            .then(response => {
                listaauxiliar = response.data.data;

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarEjes();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalReportIngresarEje: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_eje);
            $("#nombre_eje").val(registro.nombre_eje);
            $("#estado_eje").val(registro.estado_eje);
        });

        this.setState({ id: registro.id_eje });
        this.setState({ nombre_eje: registro.nombre_eje });
        this.setState({ estado_eje: registro.estado_eje });
        this.setState({ modalReportIngresarEje: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarEje: false });
    }

    registrarEje = () => {

        const f = new FormData();
        f.append("id", this.state.id);
        f.append("nombre_eje", this.state.nombre_eje);
        f.append("estado_eje", this.state.estado_eje);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarEje, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.get(urlListarEjes)
                        .then(respuesta => {
                            listaauxiliar = respuesta.data.data;
                            if (this.state.pageCount == 1) {
                                this.setState({ itemOffset: 0 });
                            }

                            //PAGINACION
                            const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                            this.setState((state) => {
                                return { itemOffset: newOffset }
                            });
                            const endOffset = newOffset + itemsPerPage;
                            //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState({ itemOffset: newOffset });
                        })
                    this.ocultarModalIngresar();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>Gestión de Ejes</b>
                    </div>
                    <div className="card border-dark text-center ">
                        <div className="card-header ">
                            <b>REPORTE DE EJES</b>
                        </div>
                        <div class="table-responsive">
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Estado</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_eje}>
                                            <td class="id">{consola.id_eje}</td>
                                            <td class="nombre">{consola.nombre_eje}</td>
                                            {(() => {
                                                if (consola.estado_eje == "Activo") {
                                                    return <td class="estadodpt table-success">{consola.estado_eje}</td>
                                                }
                                                else {
                                                    return <td class="estadodpt table-danger">{consola.estado_eje}</td>
                                                }
                                            })()}
                                            <td class="detalle">
                                                <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div class="table-responsive">
                                <ReactPaginate
                                    nextLabel="next >"
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                            <Modal isOpen={this.state.modalReportIngresarEje} >
                                <ModalHeader>
                                    Registro de Eje
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <div className="mb-3 centrar">
                                            <input type="hidden" name="id" class="id" id="id" value="" />
                                            <label className="form-label">Nombre:</label>
                                            <input type="text" id="nombre_eje" name="nombre_eje" onChange={this.handleChange} value={this.state.value} className="form-control" placeholder="" autocomplete="off" required />
                                            <label className="form-label">Estado:</label>
                                            <select className="form-select" id="estado_eje" name="estado_eje" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                <option value="">Seleccione</option>
                                                <option value="Activo">Activo</option>
                                                <option value="Inactivo">Inactivo</option>
                                            </select>
                                        </div>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <button class="btn btn-outline-primary" onClick={() => this.registrarEje()} > Ingresar</button>
                                    <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                </ModalFooter>
                            </Modal>
                            <div className="text-center">
                                <button id="agregar" type="button"
                                    className="btn  back-istmas centrar"
                                    style={{ margin: "5px", width: "170px" }}
                                    onClick={() => this.mostrarModalIngresar()} >
                                    <b>Registrar</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

