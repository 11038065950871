import React, { Component } from 'react'

export default class ErrorPermiso extends Component {
    render() {
        return (
            <div>
                <h1>Ups!!! Usted no tiene Permiso para ver esto</h1>
                
            </div>
        )
    }
}
