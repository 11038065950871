import axios from 'axios';
import $ from 'jquery';
import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';
import generatePDF from "./reportGeneratorActaComp";

const cookie = new Cookies();
const nombrescompletos = cookie.get("nomCompleEst");
const urlPeriodosCarrera = ApiUrl + "listperiodoscarrera" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEstudianteActaCompr = ApiUrl + "reporteactacomproestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoActaCompromiso = ApiUrl + "estadoactacomprom" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstMatriCicloPeriod = ApiUrl + "estudmatricicloperiod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCambEstadoActaComprom = ApiUrl + "cambestadoactacomprom" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstHistorialMatriculas = ApiUrl + "estudhistorialmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

export default class Actacompromisoest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            periodos: [],
            idPeriodo: 0,
            descripPeriod: "",
            estado: "",
        }
    }
    componentDidMount() {
        axios
            .post(urlEstadoActaCompromiso, {
                idEstud: cookie.get("idEstudiante")
            })
            .then(response => {
                if (response.data.data != null) {
                    if (response.data.data == 0) {
                        this.setState({ estado: "Aún no ha revisado el contenido del acta de compromiso." });
                        this.setState({ disabledbttacta: false })
                    }
                    else {
                        this.setState({ estado: "Usted ha aceptado el acta de compromiso." });
                        this.setState({ disabledbttacta: true })
                    }
                }

            })

        axios
            .post(urlPeriodosCarrera, {
                idCarrera: cookie.get("idCarreraEstu"),
            })
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                    const idUltPeriod = listaperiodos[cant - 1].id_periodo_academico;
                    const descripcionPeriodo = listaperiodos[cant - 1].descripcion_periodo_academico;
                    $(function () {
                        $("#idPeriodo").val(idUltPeriod);
                    });
                    this.setState({ idPeriodo: idUltPeriod });
                    this.setState({ descripPeriod: descripcionPeriodo });
                    //Buscar si esta matriculado en primer ciclo en el período o no consta ninguna matrícula
                    axios
                        .post(urlEstMatriCicloPeriod, {
                            idEstud: cookie.get("idEstudiante"),
                            idPeriodoAct: idUltPeriod

                        })
                        .then(response => {
                            if ((response.data.data == null) || (response.data.data == 1)) {
                                if (response.data.data == 1) {
                                    this.setState({ disabledacta: false })
                                }
                                else {
                                    //Verificar historial de matriculas
                                    axios
                                        .post(urlEstHistorialMatriculas, {
                                            idEstud: cookie.get("idEstudiante"),
                                            idCarrera: cookie.get("idCarreraEstu")
                                        })
                                        .then(response => {
                                            if (response.data.success == true) {
                                                this.setState({ disabledacta: true })
                                                this.setState({ estado: "" });
                                                this.setState({ disabledbttacta: true })
                                            }
                                            else {
                                                this.setState({ disabledacta: false })
                                        

                                            }
                                        })
                                }
                            }
                            else {
                                this.setState({ disabledacta: true })
                                this.setState({ estado: "" });
                                this.setState({ disabledbttacta: true })
                            }
                        })
                }
            })
    }
    generarActaCompromisoEstudiante = () => {
        axios
            .post(urlDatosEstudianteActaCompr, {
                idEstud: cookie.get("idEstudiante"),
                idPeriodo: this.state.idPeriodo,
                idCarrEstudiante: cookie.get("idCarreraEstu")
            })
            .then(response => {
                if (response.data.data != null) {
                   
                    generatePDF(response.data.data);
                }
                else {

                    this.setState({ estado: "No existe datos para mostrar" });
                    swal({
                        text: "No existe datos para mostrar",
                        icon: "info",
                        button: "Aceptar",
                    });
                }
            })
    }
    //Aceptar el acta de compromiso
    aceptarActaCompromiso = () => {
        var combmsmalert = "¿Está seguro de que desea aceptar el acta de compromiso?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urlCambEstadoActaComprom, {
                            idEstud: cookie.get("idEstudiante"),
                            estado: 1
                        })
                        .then(response => {
                            if (response.data.success == true) {
                                this.setState({ estado: "Usted ha aceptado el acta de compromiso." });
                                swal({
                                    text: "Usted ha aceptado el acta de compromiso.",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.setState({ disabledbttacta: true });
                            }
                            else {
                                this.setState({ estado: "Por favor, intente aceptar el acta de compromiso nuevamente." });
                                swal({
                                    text: "Por favor, intente aceptar el acta de compromiso nuevamente.",
                                    icon: "info",
                                    button: "Aceptar",
                                });
                                this.setState({ disabledbttacta: false });
                            }
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Acta de compromiso</b>
                    </div>
                    <div className="card-body">

                        <div className="row ">
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fas fa-user-alt"></i> Nombres Completos</b></label><br />

                                        <label className="form-label">{nombrescompletos}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label><br />
                                    <label className="form-label">  {this.state.descripPeriod}</label>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        DOCUMENTACIÓN
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">Documento</th>
                                                        <th scope="col">Archivo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <td>
                                                        Acta de compromiso
                                                    </td>
                                                    <td>
                                                        <button disabled={(this.state.disabledacta) ? "disabled" : ""} onClick={() => this.generarActaCompromisoEstudiante()}  > <i className=" fas fa-file-pdf" ></i></button>
                                                    </td>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-4 centrar" >
                            </div>
                            <div className="col-12 col-sm-12  col-lg-12 col-xl-4 centrar" >
                                <div className="mb-3">

                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        disabled={(this.state.disabledbttacta) ? "disabled" : ""}
                                        onClick={() => this.aceptarActaCompromiso()}  >
                                        <b>Aceptar</b>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-12 col-xl-4 centrar" >
                                <div className="mb-3">
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
