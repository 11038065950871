import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../navbar/NavbarLog';
import "./Carrera.css";
import { ApiUrl } from '../services/ApiRest';
import swal from 'sweetalert';

const cookies = new Cookies();
const idCarrera = cookies.get("idCarrera");
const baseUrl = ApiUrl + "carreras/" + idCarrera + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrl = ApiUrl + "estinscarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListar = ApiUrl + "listartipoi" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarSexos = ApiUrl + "listarsexos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarGeneros = ApiUrl + "listargeneros" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarEstadoCivil = ApiUrl + "listarEstadoCivil" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarEtnia = ApiUrl + "listetnia" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarNivelFormacion = ApiUrl + "nivelesformacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarPuebloNacionalidad = ApiUrl + "listarPuebloNacionalidad" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarTipoSangre = ApiUrl + "listtiposangre" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarTipoDiscap = ApiUrl + "listetiposdiscap" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarPais = ApiUrl + "listarPaises" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarProvincia = ApiUrl + "listarProvincias" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
//const inscribirUrlListarCanton = ApiUrl + "listarCantones" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCantonPost = ApiUrl + "listarCantonesActualizar" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarProvinciasPost = ApiUrl + "listarProvinciasActualizar" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarSectores = ApiUrl + "listarsectores" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarColegioPost = ApiUrl + "listarColegiosActualizar" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
//const inscribirUrlListartiposBachilerato = ApiUrl + "listartiposBachilerato" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListartiposcolegios = ApiUrl + "tiposcolegios" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCoordinacion = ApiUrl + "listarsedes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
//const inscribirListJornadas = ApiUrl + "listarjornadas" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarMediosC = ApiUrl + "listarmedios" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarJornadas = ApiUrl + "listarjornadasca" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


export default class Carrera extends Component {


    constructor(props) {
        super(props);
        this.state = {
            //variables para tablas externas
            tipoColegio: '',
            idPaisR: 56,
            idCantonR: '',
            idProvinciaR: '',
            idPaisO: 56,
            idProvinciaO: '',
            idCantonO: '',
            idTipodiscapacidad: '',
            PorcentajeDiscap: '',
            idEmpleaIngresos: '',
            gradodiscapacidad: '',
            NumCarnetCona: '',
            fechaemidiscapacidad: '',
            idCantonCole: '',
            nomColegioe: '',

            //Validaciones
            errorPrimerApell: '',
            comprobante: '',
            errorSegundoApell: '',
            errorCedula: '',
            errorCorreo: '',
            errorCelular: '',
            errorTotal: true,

            carrera: [],
            firstName: '',
            lastName: '',
            fechaNaci: null,
            email: '',
            telfCelular: '',
            telfConvencional: '',
            direccion: '',
            ci: '',
            estado: '',
            //Para habilitar y deshabilitar botones
            disabledc: true,
            disabledp: true,
            disabledv: true,
            disabledo: true,
            disabledb: true,
            disablede: true,
            disableder: true,
            disabledcole: true,
            disabledcoextra: false,
            disableetnia: true,


            //Variables Iniciales y list 
            tipoidentifcacione: '',
            numidentificacione: '',
            PrimerApell: '',
            SegundoApell: '',
            PrimerNom: '',
            SegudoNom: '',
            TipoTituloBachiller: '',
            EstudiosRealizados: '',
            especializacionbachiller: '',
            TituloBachiller: '',
            idSectore: ' ',
            jornadas: [],
            tipoidentifi: [],
            listsexos: [],
            listgeneros: [],
            listestadocivil: [],
            listetnia: [],
            listnivelformacion: [],
            listpueblonacionaldiad: [],
            listtiposangre: [],
            listtiposDiscap: [],
            listPais: [],
            listProvincia: [],
            listProvinciaR: [],
            listProvinciaCole: [],
            listCantonCole: [],
            listColegios: [],
            listTiposColegio: [],
            listCanton: [],
            listCantonR: [],
            listCarrera: [],
            listarsectore: [],
            listarColegio: [],
            coordinacion: [],
            listmedios: [],
            //modal
            modalPagoMatricula: false,


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFoto = this.handleChangeFoto.bind(this);


    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    handleChangeFoto = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobante: e.target.files })
    }



    handleChangeEtnia = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        if (this.state.idEtnia != 1) {
            this.setState({ disableetniab: this.state.disableetnia })
        }
        else {
            this.setState({ disableetniab: !this.state.disableetnia })

        }
    }

    handleChangeComprobantte = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ comprobante: e.target.files })
    }

    //*******PROVINCIA DE COLEGIO */
    handleChangerProvinciasCole = async (e) => {
        //e.preventDefault();
        await
            this.setState({ [e.target.name]: e.target.value })//Ayuda a guardar los datos del select   
        if (this.state.idPaisCole == 56) {
            this.setState({ disabledccole: !this.state.disabledcole })
            this.setState({ disabledccolee: !this.state.disabledcoextra })
            axios
                .post(inscribirUrlListarProvinciasPost, {//guarda para enviar los atributos y envia
                    idPaisAct: this.state.idPaisCole,
                })
                .then(response => {
                    const listProvinciaCole = response.data.data;
                    this.setState({ listProvinciaCole });
                })
        }
        else {
            this.setState({ disabledccolee: this.state.disabledcoextra })
            this.setState({ disabledccole: this.state.disabledcole })
        }
    }

    //****CANTONES DEL COLE */

    handleChangeCantonesCole = async (e) => {
        //e.preventDefault();
        await
            this.setState({ [e.target.name]: e.target.value })
        axios
            .post(inscribirUrlListarCantonPost, {

                idProvinciaAct: this.state.idProvinciaCole,

            })
            .then(response => {

                const listCantonCole = response.data.data;
                this.setState({ listCantonCole });
            })
    }
    //**********COLEGIOS*********** */

    handleChangeColegios = async (e) => {
        //e.preventDefault();
        await
            this.setState({ [e.target.name]: e.target.value })
        axios
            .post(inscribirUrlListarColegioPost, {
                idPCantonActCole: this.state.idCantonCole,
            })
            .then(response => {
                const listColegios = response.data.data;
                this.setState({ listColegios });
            })
    }

    ///***************CANTON DE ORIGEN********/

    handleChangeCantonesO = async (e) => {
        //e.preventDefault();
        await
            this.setState({ [e.target.name]: e.target.value })
        axios
            .post(inscribirUrlListarCantonPost, {
                idProvinciaAct: this.state.idProvinciaO,
            })
            .then(response => {
                const listCanton = response.data.data;
                this.setState({ listCanton });
            })
    }

    //***************CANTON DE RESIDENCIA*********** */
    handleChangeCantonesR = async (e) => {
        //e.preventDefault();
        await
            this.setState({ [e.target.name]: e.target.value })//Ayuda a guardar los datos del select
        axios
            .post(inscribirUrlListarCantonPost, {//guarda para enviar los atributos y envia
                idProvinciaAct: this.state.idProvinciaR,
            })
            .then(response => {
                const listCantonR = response.data.data;
                this.setState({ listCantonR });
            })

    }

    //***************************PROVINCIAS DE ORIGEN */


    handleChangerProvinciasO = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idPaisO == 56) {
            this.setState({ disabledce: !this.state.disablede })
            axios
                .post(inscribirUrlListarProvinciasPost, {
                    idPaisAct: this.state.idPaisO,
                })
                .then(response => {
                    const listProvincia = response.data.data;
                    this.setState({ listProvincia });
                })
        }
        else {
            this.setState({ disabledce: this.state.disablede })
        }
    }



    ///***********************PROVINCIAS DE RESIDENCIA**********************/

    handleChangerProvinciasR = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idPaisR == 56) {
            this.setState({ disabledcer: !this.state.disableder })
            axios
                .post(inscribirUrlListarProvinciasPost, {
                    idPaisAct: this.state.idPaisR,
                })
                .then(response => {
                    const listProvinciaR = response.data.data;
                    this.setState({ listProvinciaR });
                })
        }
        else {
            this.setState({ disabledcer: this.state.disableder });
        }

    }


    handleChangerValidarDiscapacidad = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idDiscapacidad == 2) {
            this.setState({ disabledca: this.state.disabledc })
        }
        else {
            this.setState({ disabledca: !this.state.disabledc })
        }
    }

    handleChangerPracticas = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idPractPre == 2) {
            this.setState({ disabledcp: this.state.disabledp })
        }
        else {
            this.setState({ disabledcp: !this.state.disabledp })
        }
    }
    handleChangerVinculacion = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idParticipacionProyectVin == 2 || this.state.idParticipacionProyectVin == 3) {
            this.setState({ disabledcv: this.state.disabledv })
        }
        else {
            this.setState({ disabledcv: !this.state.disabledv })
        }
    }

    handleChangeroOcupacion = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.idOcupaEst == 1) {
            this.setState({ disabledco: this.state.disabledo })
        }
        else {
            this.setState({ disabledco: !this.state.disabledo })
        }
    }

    //******************SI ES EXTRANJERO*** */
    handleChangeroExtranjero = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (!this.state.idPaisO == 56) {
            this.setState({ disabledce: this.state.disablede })
        }
        else {
            this.setState({ disabledce: !this.state.disablede })
        }
    }

    /***************BECA*********** */
    handleChangeroBECA = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        if (this.state.percibebeca == 2) {
            this.setState({ disabledcb: this.state.disabledb })
        }
        else {
            this.setState({ disabledcb: !this.state.disabledb })
        }
    }
    //**********VALIDACIONES********* */
    handleEventValidarCorreo = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var fred = validarCorreo(this.state.CorreoEst);
        if (fred == false) {

            swal({
                text: "Correo Incorrecto",
                icon: "error",
                button: "Aceptar",
            });

        }

    }


    handleEventPrimerApellido = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var primerApellV = validarNombreEstud(this.state.PrimerApell);
        if (primerApellV == false) {
            swal({
                text: "El primer apellido es incorrecto",
                icon: "error",
                button: "Aceptar",
            });
        }
    }

    handleEventSegundoApellido = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        var segundoApellV = validarNombreEstud(this.state.SegundoApell);
        if (segundoApellV == false) {
            swal({
                text: "El segundo apellido es incorrecto en caso de no tenerlo colocar: Ninguno",
                icon: "error",
                button: "Aceptar",
            });
        }
    }




    handleEventPrimerNombre = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var primerNombreV = validarNombreEstud(this.state.PrimerNom);
        if (primerNombreV == false) {
            swal({
                text: "El primer nombre es incorrecto",
                icon: "error",
                button: "Aceptar",
            });
        }

    }


    handleEventSegundoNombre = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var segundoNombreV = validarNombreEstud(this.state.SegudoNom);
        if (segundoNombreV == false) {
            swal({
                text: "El segundo nombre es incorrecto",
                icon: "error",
                button: "Aceptar",

            });
        }
    }



    handleEventValidarCelular = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var celul = validarCelular(this.state.numCelular);
        if (celul == false) {
            swal({
                text: "Número de celular incorrecto",
                icon: "error",
                button: "Aceptar",
            });
        }

    }

    handleEventValidarCedulaPasaporte = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        var valcelpas = validarCedulaPasaporte(this.state.numidentificacion, this.state.idTipoIdentificacion);
        if (valcelpas == false) {
            swal({
                text: "Número de identificación incorrecto",
                icon: "error",
                button: "Aceptar",

            });
        }

    }

    inscribirEstudiante = async (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
        //Validad identificador
        var validacion_cedula = validarCedulaPasaporte(this.state.numidentificacion, this.state.idTipoIdentificacion);
        if (validacion_cedula == true) {
            if (this.state.comprobante == null) {
                swal({
                    text: "No se ha seleccionado ningun archivo",
                    icon: "error",
                    button: "Aceptar",
                });
            }
            else {
                const f = new FormData();
          
                //Campos obligatorios
                f.append("idCarrerae", idCarrera);//5          
                f.append("PrimerNome", this.state.PrimerNom.toUpperCase());//5
                f.append("SegudoNome", this.state.SegudoNom.toUpperCase());//6
                f.append("PrimerApelle", this.state.PrimerApell.toUpperCase());//3
                f.append("SegundoApelle", this.state.SegundoApell.toUpperCase());//4
                f.append("numidentificacione", this.state.numidentificacion);//2
                f.append("NumFechaNace", this.state.NumFechaNac);//21
                f.append("CorreoEste", this.state.CorreoEst);//7
                f.append("numCelulare", this.state.numCelular);//8
                f.append("telfConvencionale", this.state.telfConvencional);//8
                f.append("nomColegioee", this.state.nomColegioe.toUpperCase());//29-32
                f.append(" direccione", this.state.direccion);//28

                //campos necesarios para la matriz
                //Bloque 1
                f.append("idDiscapacidade", this.state.idDiscapacidad);//17
                f.append("idOcupaEste", this.state.idOcupaEst);//35
                f.append("NumCarnetConae", this.state.NumCarnetCona);//19
                f.append("PorcentajeDiscape", this.state.PorcentajeDiscap);//18
                f.append("idFamiBonoDesarrolloe", this.state.idFamiBonoDesarrollo);//37
                f.append("IngresoHogare", this.state.IngresoHogar);//40
                f.append("NumMiembroHogare", this.state.NumMiembroHogar);//41

                //FORANEAS
                //Bloque 2
                f.append("tipoidentifcacione", this.state.idTipoIdentificacion);//1
                f.append("IdSexoe", this.state.idSexo);//11
                f.append("idGeneroe", this.state.idGenerom);//12
                f.append("idEtniae", this.state.idEtnia);//14
                f.append("idCantonOe", this.state.idCantonO);//24
                f.append("idCantonRe", this.state.idCantonR);//27

                //Bloque 3  
                f.append("idEstadoCivile", this.state.idEstadoCivil);//13
                f.append("idTipoSangree", this.state.idTipoSangre);//16
                f.append("idEmpleaIngresose", this.state.idEmpleaIngresos);//36
                f.append("idNivelFormPe", this.state.idNivelFormP);//38
                f.append("idNivelFormMe", this.state.idNivelFormM); //39            
                f.append("idPuebloNacionalidae", this.state.idPuebloNacionalida);//15
                // Bloque 4
                f.append("idPaisOe", this.state.idPaisO);//22
                f.append("idProvinciaOe", this.state.idProvinciaO);//23
                f.append("idPaisRe", this.state.idPaisR);//25
                f.append("idProvinciaRe", this.state.idProvinciaR);//26
                f.append("idTipodiscapacidade", this.state.idTipodiscapacidad);//20
                //bloque 5
                f.append("gradodiscapacidade", this.state.gradodiscapacidad.toUpperCase());//33
                f.append("fechaemidiscapacidade", this.state.fechaemidiscapacidad);//33
                f.append("idPaisColee", this.state.idPaisCole);
                f.append("idCantonColee", this.state.idCantonCole);
                f.append("TipoTituloBachillere", this.state.TipoTituloBachiller.toUpperCase());//33
                f.append("tituloBachillere", this.state.TituloBachiller.toUpperCase());//33
                f.append("especializacionbachillere", this.state.especializacionbachiller.toUpperCase());
                f.append("tipoColegioe", this.state.tipoColegio);
                f.append("estudiosrealizadose", this.state.EstudiosRealizados.toUpperCase());//34
                f.append("idCoorde", this.state.idCoord);//34
                f.append("idJornadae", this.state.idJornada);//35
                f.append("idMedioe", this.state.idMedio);//36

                f.append("url_comprobante", this.state.comprobante[0]);


                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                await axios
                    .post(inscribirUrl, f)
                    .then(response => {
                        if (response.data.success === true) {
                            this.setState({ estado: response.data.message });
                            e.target.reset(); //resetea valores del formulario
                            this.setState({ estado: "Datos Agregados Correctamente" });
                            swal({
                                text: "Datos Agregados Correctamente",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.setState({ errorPrimerApell: '' });
                        }
                        else {
                            if (response.data.message == 'Existe') {
                                swal({
                                    text: "Usted ya llenó anteriormente el formulario",
                                    icon: "error",
                                    button: "Aceptar"

                                });
                                this.setState({ estado: "Usted ya se encuentra registrado" });
                            }
                            else {
                                if (response.data.message == "validation_error") {
                                    this.setState({ estado: "Formato del archivo incorrecto" });
                                    swal({
                                        text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                        icon: "error",
                                        button: "Aceptar",

                                    });
                                }
                                else {
                                    this.setState({ estado: response.data.message });
                                    swal({
                                        text: "Porfavor llenar nuevamente el formulario",
                                        icon: "error",
                                        button: "Aceptar"

                                    });
                                }
                            }

                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ estado: "Error No se pudo conectar con el servidor" });

                    })
            }
        }
        else {
            swal({
                text: "Número de identificación incorrecto",
                icon: "error",
                button: "Aceptar",
            });
        }
    }


    componentDidMount() {


        axios.get(baseUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })


        axios.get(inscribirUrlListar)
            .then(res => {
                const tipoidentifi = res.data;
                this.setState({ tipoidentifi });

            })

        /*  axios.get(inscribirUrlListarJornadas)
              .then(res => {
                  const jornadas = res.data;
                  this.setState({ jornadas });
  
              })*/

        //jornadas
        //e.preventDefault();

        axios
            .post(inscribirUrlListarJornadas, {//guarda para enviar los atributos y envia
                idCarrerae: idCarrera,
            })
            .then(response => {
                const jornadas = response.data.data;
                this.setState({ jornadas });
            })


        //fin de las jornadas


        axios.get(inscribirUrlListarSexos)
            .then(respuesta => {
                const listsexos = respuesta.data;
                this.setState({ listsexos });
            })

        axios.get(inscribirUrlListarGeneros)
            .then(respuesta => {
                const listgeneros = respuesta.data;
                this.setState({ listgeneros });
            })
        //

        axios.get(inscribirUrlListarEstadoCivil)
            .then(respuesta => {
                const listestadocivil = respuesta.data;
                this.setState({ listestadocivil });
            })
        axios.get(inscribirUrlListarEtnia)
            .then(respuesta => {
                const listetnia = respuesta.data;
                this.setState({ listetnia });
            })
        axios.get(inscribirUrlListarNivelFormacion)
            .then(respuesta => {
                const listnivelformacion = respuesta.data;
                this.setState({ listnivelformacion });
            })
        axios.get(inscribirUrlListarPuebloNacionalidad)
            .then(respuesta => {
                const listpueblonacionaldiad = respuesta.data;
                this.setState({ listpueblonacionaldiad });
            })
        axios.get(inscribirUrlListarTipoSangre)
            .then(respuesta => {
                const listtiposangre = respuesta.data;
                this.setState({ listtiposangre });
            })
        axios.get(inscribirUrlListarTipoDiscap)
            .then(respuesta => {
                const listtiposDiscap = respuesta.data;
                this.setState({ listtiposDiscap });
            })

        axios.get(inscribirUrlListarPais)
            .then(respuesta => {
                const listPais = respuesta.data;
                this.setState({ listPais });
            })
        axios.get(inscribirUrlListarProvincia)
            .then(respuesta => {
                const listProvincia = respuesta.data;
                this.setState({ listProvincia });
            })

        axios.get(inscribirUrlListartiposcolegios)
            .then(respuesta => {
                const listTiposColegio = respuesta.data;
                this.setState({ listTiposColegio });
            })
        //listar sectores
        axios.get(inscribirUrlListarSectores)
            .then(respuesta => {
                const listarsectore = respuesta.data;
                this.setState({ listarsectore });
            })


        axios.get(inscribirUrlListarCoordinacion)
            .then(respuesta => {
                const coordinacion = respuesta.data;
                this.setState({ coordinacion });
                //mostrar todos los ciclos(semestres)
            })
        //Listar medios de comunicación

        axios.get(inscribirUrlListarMediosC)
            .then(respuesta => {
                const listmedios = respuesta.data;
                this.setState({ listmedios });
                //mostrar todos los ciclos(semestres)
            })
    }

    render() {
        return (
            <div>
                <NavbarLog />
                <div className="container">
                    <br />
                    <h3 className="titulo-istmas text-center"><b>{this.state.carrera.descripcion_carrera}</b></h3>
                    <hr />
                    <div className="row ">
                        <div className="  col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="card shadow p-3 mb-5 bg-white rounded">
                                <div className="card-header">
                                    <h5>Información General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">

                                        <div className="mb-3">
                                            <label className="form-label"><b>Modalidad: </b> {this.state.carrera.descripcion_modalidad_carrera}</label><br />

                                            <label className="form-label"><b>Duración: </b> {this.state.carrera.duracion_carrera}</label><br />

                                            <label className="form-label"><b>Título Otorgado: </b>{this.state.carrera.descripcion_titulo_carrera}</label>   <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="  col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="card shadow p-3 mb-5 bg-white rounded">
                                <div className="card-header">
                                    <h5>LOGO CARRERA</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                    </div>

                                </div>
                            </div>
                        </div>




                        <div className="  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="">
                                <div className="card-header">
                                    <h5>Perfil de egreso de la carrera</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <p className="text-justificado">{this.state.carrera.perfil_egreso_carrera}</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="">
                                <div className="card-header">
                                    <h5>Campo Ocupacional</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <p className="text-justificado">{this.state.carrera.campo_ocupacional_carrera}</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row centrar" style={{ width: "80%", alignItems: "center" }}>

                        <div className="card shadow p-3 mb-5 bg-white rounded ">
                            <div className="card-header text-center head-form" >
                                <h4>FORMULARIO DE INSCRIPCIÓN</h4>
                            </div>

                            <div className="card-body ">
                                <ol className="breadcrumb mb-4">
                                    <li className="breadcrumb-item active">{this.state.estado}</li>
                                </ol>
                                <form className="" onSubmit={this.inscribirEstudiante} style={{ padding: 10 }} id="create-course-form" >
                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione la Jornada</label>
                                            <select className="form-select" id="idJornada" name="idJornada" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required >
                                                <option value="">Seleccione</option>

                                                {this.state.jornadas.map(person => <option key={person.id_jornada} value={person.id_jornada} > {person.descrip_jornada}</option>)}

                                            </select>

                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">

                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el tipo de identificación</label>
                                            <select className="form-select" name="idTipoIdentificacion" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required >
                                                <option value="">Seleccione</option>

                                                {this.state.tipoidentifi.map(person => <option key={person.id_identificacion} value={person.id_identificacion} > {person.descripcion_tipo_identificacion}</option>)}

                                            </select>

                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Número de identificación</label>
                                                <input type="text" id="numidentificacion" name="numidentificacion" onChange={this.handleChange} className="form-control" placeholder="Sin guión" required />
                                                <div id="errorNumIdentificacionId" className="form-text color-validacion "   >{this.state.errorCedula}</div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Primer apellido</label>
                                                <input type="text" name="PrimerApell" onChange={this.handleChange} onMouseDown={this.handleEventValidarCedulaPasaporte} className="form-control" placeholder="PRIMER APELLIDO" required />
                                                <div id="errorPrimerApell" className="form-text color-validacion "   >{this.state.errorPrimerApell}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Segundo apellido</label>
                                                <input type="text" name="SegundoApell" onChange={this.handleChange} onMouseDown={this.handleEventPrimerApellido} className="form-control" placeholder="SEGUNDO APELLIDO" />
                                                <div id="errorSegundoApell" className="form-text color-validacion "   >{this.state.errorSegundoApell}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Primer nombre</label>
                                                <input type="text" name="PrimerNom" onChange={this.handleChange} className="form-control" placeholder="PRIMER NOMBRE" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Segundo nombre</label>
                                                <input type="text" name="SegudoNom" onMouseDown={this.handleEventPrimerNombre} onChange={this.handleChange} className="form-control" placeholder="SEGUNDO NOMBRE" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Correo</label>
                                                <input type="email" name="CorreoEst" onChange={this.handleChange} className="form-control" placeholder="Correo" required />
                                                <div id="errorCorreoId" className="form-text color-validacion "   >{this.state.errorCorreo}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Número de celular</label>
                                                <input type="text" name="numCelular" onMouseDown={this.handleEventValidarCorreo} onChange={this.handleChange} className="form-control" placeholder="Celular" required />
                                                <div id="errorCorreoId" className="form-text color-validacion "   >{this.state.errorCelular}</div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Número de Convencional</label>
                                                <input type="text" name="telfConvencional" onChange={this.handleChange} className="form-control" placeholder="Teléfono" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Seleccione el sexo</label>
                                                <select className="form-select" name="idSexo" value={this.state.value} onMouseDown={this.handleEventValidarCelular} onChange={this.handleChange} aria-label="Default select example" required >
                                                    <option value="">Seleccione</option>
                                                    {this.state.listsexos.map(person => <option key={person.id_sexo} value={person.id_sexo} > {person.descripcion_sexo}</option>)}
                                                </select>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <div className="mb-3">
                                                <label className="form-label">Seleccione el género</label>
                                                <select className="form-select" name="idGenerom" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.listgeneros.map(person => <option key={person.id_genero} value={person.id_genero} > {person.descripcion_genero}</option>)}

                                                </select>

                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">


                                                <label className="form-label">Seleccione el estado civil</label>
                                                <select className="form-select" name="idEstadoCivil" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    {this.state.listestadocivil.map(person => <option key={person.id_estado_civil} value={person.id_estado_civil} > {person.descripcion_estado_civil}</option>)}
                                                </select>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione la etnia</label>
                                            <select className="form-select" name="idEtnia" value={this.state.value} onChange={this.handleChangeEtnia} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listetnia.map(person => <option key={person.id_etnia} value={person.id_etnia} > {person.descripcion_etnia}</option>)}

                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">Seleccione el pueblo nacionalidad</label>
                                            <select className="form-select" name="idPuebloNacionalida" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disableetniab) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listpueblonacionaldiad.map(person => <option key={person.id_pueblo_nacionalidad} value={person.id_pueblo_nacionalidad} > {person.nombre_pueblo_nacionalidad}</option>)}
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">Seleccione el tipo de sangre</label>
                                            <select className="form-select" name="idTipoSangre" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listtiposangre.map(person => <option key={person.id_tipo_sangre} value={person.id_tipo_sangre} > {person.descripcion_tipo_sangre}</option>)}

                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">Seleccione si tiene alguna discapacidad</label>
                                            <select className="form-select" name="idDiscapacidad" value={this.state.value} onChange={this.handleChangerValidarDiscapacidad} aria-label="Default select example" required >
                                                <option value="">Seleccione</option>
                                                <option key="1" value="1" > Si</option>
                                                <option key="2" value="2" > No</option>
                                            </select>
                                        </div>
                                    </div>



                                    <div className="row ">

                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingrese el porcentaje de discapacidad</label>
                                                <input type="number" name="PorcentajeDiscap" disabled={(this.state.disabledca) ? "disabled" : ""} onChange={this.handleChange} className="form-control" placeholder="Porcentaje de Discapacidad" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingrese el número de carnet del CONADIS</label>
                                                <input type="text" disabled={(this.state.disabledca) ? "disabled" : ""} name="NumCarnetCona" onChange={this.handleChange} className="form-control" placeholder="Ninguno" required />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row ">

                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Grado de discapacidad</label>
                                                <input type="text" name="gradodiscapacidad" disabled={(this.state.disabledca) ? "disabled" : ""} onChange={this.handleChange} className="form-control" placeholder="ALTO" required />
                                            </div>
                                        </div>


                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                                            <div className="mb-3">
                                                <label className="form-label">Fecha de emisión del carnet de discapadidad</label>
                                                <input type="date" name="fechaemidiscapacidad" disabled={(this.state.disabledca) ? "disabled" : ""} onChange={this.handleChange} className="form-control" placeholder="Valor" required />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row ">

                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el tipo de discapacidad</label>
                                            <select className="form-select" name="idTipodiscapacidad" disabled={(this.state.disabledca) ? "disabled" : ""} value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required >
                                                <option value="">Seleccione</option>
                                                {this.state.listtiposDiscap.map(person => <option key={person.id_tipo_discapacidad} value={person.id_tipo_discapacidad} > {person.descripcion_tipo_discapacidad}</option>)}

                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingrese la fecha de nacimiento</label>
                                                <input type="date" name="NumFechaNac" onChange={this.handleChange} className="form-control" placeholder="1999-01-01" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el país de origen</label>
                                            <select className="form-select" name="idPaisO" value={this.state.value} onChange={this.handleChangerProvinciasO} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listPais.map(person => <option key={person.id_pais} value={person.id_pais} > {person.nombre_pais}</option>)}

                                            </select>

                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione la provincia de origen</label>
                                            <select className="form-select" name="idProvinciaO" value={this.state.value} onChange={this.handleChangeCantonesO} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listProvincia.map(person => <option key={person.id_provincia} value={person.id_provincia} > {person.nombre_provincia}</option>)}

                                            </select>

                                        </div>
                                    </div>
                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >


                                            <label className="form-label">Seleccione el cantón de origen</label>
                                            <select className="form-select" name="idCantonO" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listCanton.map(person => <option key={person.id_canton} value={person.id_canton} > {person.nombre_canton}</option>)}
                                            </select>
                                        </div>

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el país de residencia</label>
                                            <select className="form-select" name="idPaisR" value={this.state.value} onChange={this.handleChangerProvinciasR} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listPais.map(person => <option key={person.id_pais} value={person.id_pais} > {person.nombre_pais}</option>)}

                                            </select>

                                        </div>
                                    </div>

                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione la provincia de residencia</label>
                                            <select className="form-select" name="idProvinciaR" value={this.state.value} onChange={this.handleChangeCantonesR} aria-label="Default select example" disabled={(this.state.disabledcer) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listProvinciaR.map(person => <option key={person.id_provincia} value={person.id_provincia} > {person.nombre_provincia}</option>)}

                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el cantón de residencia</label>
                                            <select className="form-select" name="idCantonR" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledcer) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listCantonR.map(person => <option key={person.id_canton} value={person.id_canton} > {person.nombre_canton}</option>)}
                                            </select>

                                        </div>

                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Dirección domiciliaria</label>
                                                <input type="text" name="direccion" onChange={this.handleChange} className="form-control" placeholder="Dirección" required />
                                            </div>



                                        </div>


                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Escriba el colegio en el cual obtuvo su título de bachiller</label>

                                            <input type="text" name="nomColegioe" onChange={this.handleChange} className="form-control" placeholder="colegio" required />



                                        </div>



                                    </div>


                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el país en el cuál se encuentra el colegio en donde obtuvo su título de bachiller</label>
                                            <select className="form-select" name="idPaisCole" value={this.state.value} onChange={this.handleChangerProvinciasCole} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listPais.map(person => <option key={person.id_pais} value={person.id_pais} > {person.nombre_pais}</option>)}

                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione la provincia en el cuál se encuentra el colegio en donde obtuvo su título de bachiller</label>
                                            <select className="form-select" name="idProvinciaCole" value={this.state.value} onChange={this.handleChangeCantonesCole} aria-label="Default select example" disabled={(this.state.disabledccole) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listProvinciaCole.map(person => <option key={person.id_provincia} value={person.id_provincia} > {person.nombre_provincia}</option>)}

                                            </select>

                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Seleccione el cantón en el cuál se encuentra el colegio en donde obtuvo su título de bachiller</label>
                                            <select className="form-select" name="idCantonCole" value={this.state.value} onChange={this.handleChangeColegios} aria-label="Default select example" disabled={(this.state.disabledccole) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listCantonCole.map(person => <option key={person.id_canton} value={person.id_canton} > {person.nombre_canton}</option>)}
                                            </select>
                                        </div>

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">Seleccion el tipo de colegio</label>
                                            <select className="form-select" name="tipoColegio" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" >
                                                <option value="">Seleccione</option>
                                                {this.state.listTiposColegio.map(person => <option key={person.id_tipo_colegio} value={person.id_tipo_colegio} > {person.descripcion_tipo_colegio}</option>)}
                                            </select>

                                        </div>

                                    </div>

                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <div className="mb-3">
                                                <label className="form-label">Ingrese el Tipo de Título</label>
                                                <input type="text" name="TipoTituloBachiller" onChange={this.handleChange} className="form-control" placeholder="BACHILLER" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingrese el título de bachiller</label>
                                                <input type="text" name="TituloBachiller" onChange={this.handleChange} className="form-control" placeholder="CIENCIAS" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingrese la especialidad de su título de bachiller</label>
                                                <input type="text" name="especializacionbachiller" onChange={this.handleChange} className="form-control" placeholder="FÍSICO MATEMÁTICO" />
                                            </div>
                                        </div>


                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <div className="mb-3">
                                                <label className="form-label">Ingrese otros estudios realizados:tercer nivel,cuarto nivel</label>
                                                <input type="text" name="EstudiosRealizados" onChange={this.handleChange} className="form-control" placeholder="TÉCNOLOGO,LICENCIADO" />
                                            </div>
                                        </div>

                                    </div>



                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">¿Cuál es su ocupación actualmente?</label>
                                            <select className="form-select" name="idOcupaEst" value={this.state.value} onChange={this.handleChangeroOcupacion} aria-label="Default select example" required>
                                                <option value="">Seleccione  una  ocupación </option>
                                                <option key="1" value="1" > Solo Estudia</option>
                                                <option key="2" value="2" > Trabaja y estudia</option>
                                            </select>
                                        </div>

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">Sus ingresos prioritariamente son asignados a: </label>
                                            <select className="form-select" name="idEmpleaIngresos" value={this.state.value} disabled={(this.state.disabledco) ? "disabled" : ""} onChange={this.handleChange} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                <option key="1" value="1" > Financiar sus estudios</option>
                                                <option key="2" value="2" > Mantener a su hogar</option>
                                                <option key="3" value="3" > Gastos Personales</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >

                                            <label className="form-label">¿Algún integrante de su familia recibe el  bono de desarrollo humano?</label>
                                            <select className="form-select" name="idFamiBonoDesarrollo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                <option value="">Seleccione </option>
                                                <option key="1" value="1" > Si</option>
                                                <option key="2" value="2" > No</option>
                                            </select>

                                        </div>

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                            <label className="form-label">Nivel de formación del padre</label>


                                            <select className="form-select" name="idNivelFormP" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listnivelformacion.map(person => <option key={person.id_nivel_formacion} value={person.id_nivel_formacion} > {person.descripcion_nivel_formacion}</option>)}

                                            </select>




                                        </div>

                                    </div>


                                    <div className="row ">

                                        <div className="col-12 col-lg-5 col-xl-5 centrar" >


                                            <label className="form-label">Nivel de formación de la madre</label>

                                            <select className="form-select" name="idNivelFormM" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                <option value="">Seleccione</option>
                                                {this.state.listnivelformacion.map(person => <option key={person.id_nivel_formacion} value={person.id_nivel_formacion} > {person.descripcion_nivel_formacion}</option>)}

                                            </select>



                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >



                                            <div className="mb-3">



                                                <label className="form-label">Número de miembros del hogar</label>
                                                <input type="number" name="NumMiembroHogar" onChange={this.handleChange} className="form-control" placeholder="Valor" required />
                                            </div>

                                        </div>


                                    </div>

                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Ingreso del hogar</label>
                                                <input type="number" name="IngresoHogar" onChange={this.handleChange} className="form-control" placeholder="0" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">¿A qué coordinación desearía pertenecer?</label>
                                                <select className="form-select" id="idCoord" name="idCoord" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                    <option value="undefined">Seleccione la coordinación</option>

                                                    {this.state.coordinacion.map(person => <option key={person.id_sede} value={person.id_sede} > {person.descripcion_sede}</option>)}

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">Subir su fotografía para el carnet estudiantil</label>
                                                <input className="form-control" type="file" id="comprobante" name="comprobante" onChange={this.handleChangeFoto} required />
                                            </div>
                                            <a Target="_blank" href="https://www.istmas.edu.ec/images/pdf/Lineamiento_fotografia.pdf">Lineamientos de la fotografía</a>
                                        </div>
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <label className="form-label">¿Por qué medio se informó sobre el instituto?</label>
                                                <select className="form-select" id="idMedio" name="idMedio" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                    <option value="undefined">Seleccione el medio</option>

                                                    {this.state.listmedios.map(person => <option key={person.id_medio_comuni} value={person.id_medio_comuni} > {person.nombre_medio_comuni}</option>)}

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center">

                                        <button type="submit" className="btn  back-istmas centrar" style={{ margin: "5px" }}  > <b>Enviar Formulario</b></button>

                                        <Link to="/login">
                                            <button type="" className="btn  back-istmas centrar" style={{ margin: "5px" }}  ><b>Cancelar Inscripción</b></button>
                                        </Link>

                                    </div>

                                </form>

                            </div>
                        </div>



                    </div>


                </div>

            </div>
        )
    }
}

function validarCorreo(nombre) {

    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // alert(this.state.email);
    return re.test(nombre);

}
function validarCelular(nombre) {

    var re = /^([0-9])*$/;
    return re.test(nombre);
}

function validarNombreEstud(nombre) {
    var re = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    return re.test(nombre);

}

function validarCedulaPasaporte(nombre, tipo) {
    var re = false;
    if (tipo == 2) {
        re = /^([a-zA-Z0-9])*$/;
        return re.test(nombre);
    }
    else {
        re = validarCedula(nombre);
        return re;
    }
}
function validarCedula(cedulaecuato) {
    var cedula = cedulaecuato;
    var resultado_cedula = false;

    //Preguntamos si la cedula consta de 10 digitos
    if (cedula.length == 10) {

        //Obtenemos el digito de la region que sonlos dos primeros digitos
        var digito_region = cedula.substring(0, 2);

        //Pregunto si la region existe ecuador se divide en 24 regiones
        if (digito_region >= 1 && digito_region <= 24) {

            // Extraigo el ultimo digito
            var ultimo_digito = cedula.substring(9, 10);

            //Agrupo todos los pares y los sumo
            var pares = parseInt(cedula.substring(1, 2)) + parseInt(cedula.substring(3, 4)) + parseInt(cedula.substring(5, 6)) + parseInt(cedula.substring(7, 8));

            //Agrupo los impares, los multiplico por un factor de 2, si la resultante es > que 9 le restamos el 9 a la resultante
            var numero1 = cedula.substring(0, 1);
            var numero1 = (numero1 * 2);
            if (numero1 > 9) { var numero1 = (numero1 - 9); }

            var numero3 = cedula.substring(2, 3);
            var numero3 = (numero3 * 2);
            if (numero3 > 9) { var numero3 = (numero3 - 9); }

            var numero5 = cedula.substring(4, 5);
            var numero5 = (numero5 * 2);
            if (numero5 > 9) { var numero5 = (numero5 - 9); }

            var numero7 = cedula.substring(6, 7);
            var numero7 = (numero7 * 2);
            if (numero7 > 9) { var numero7 = (numero7 - 9); }

            var numero9 = cedula.substring(8, 9);
            var numero9 = (numero9 * 2);
            if (numero9 > 9) { var numero9 = (numero9 - 9); }

            var impares = numero1 + numero3 + numero5 + numero7 + numero9;

            //Suma total
            var suma_total = (pares + impares);

            //extraemos el primero digito
            var primer_digito_suma = String(suma_total).substring(0, 1);

            //Obtenemos la decena inmediata
            var decena = (parseInt(primer_digito_suma) + 1) * 10;

            //Obtenemos la resta de la decena inmediata - la suma_total esto nos da el digito validador
            var digito_validador = decena - suma_total;

            //Si el digito validador es = a 10 toma el valor de 0
            if (digito_validador == 10)
                var digito_validador = 0;

            //Validamos que el digito validador sea igual al de la cedula
            if (digito_validador == ultimo_digito) {
                resultado_cedula = true;
            } else {
                resultado_cedula = false;
            }

        } else {
            resultado_cedula = false;
        }
    } else {
        resultado_cedula = false;
    }
    //
    return resultado_cedula;
}

function check_text(input) {
    if (input.validity.patternMismatch) {
        input.setCustomValidity("Debe ingresar al menos 3 LETRAS");
    }
    else {
        input.setCustomValidity("");
    }
}


