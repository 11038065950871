import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from 'jquery';
import React, { Component, useState } from 'react';
import { ApiUrl } from '../../services/ApiRest';
import "../../vicerrectoradoacad/vicerrectoradoacad.css";
import swal from 'sweetalert';


const urlListarCoordinaciones = ApiUrl + "listarcoord" +"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCambiarEstCoord = ApiUrl + "estadcoord" +"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarCoord = ApiUrl + "ingresarcoord" +"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosSede = ApiUrl + "datossede"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifDatosCoord = ApiUrl + "modificarcoord"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCantonPost = ApiUrl + "listarCantonesActualizar"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarProvinciasPost = ApiUrl + "listarProvinciasActualizar"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


export default class ListCoordinaciones extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listacoord: [],
            listProvincia: [],
            listCanton: [],
            form: {
            },
            form1: {
            },
            form2: {
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarCoord: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }

    bttActDesac = (coord) => {
        var auxestad = 0;
        if (coord.estado_coord == 0) {
            auxestad = 1;
        }
        else {
            auxestad = 0;
        }
        axios.post(urlCambiarEstCoord, {
            idCoordinacion: coord.id_sede,
            estado: auxestad,
        })
            .then(response => {
                const est = response.data.data;
                axios.get(urlListarCoordinaciones)
                    .then(response => {
                        const listacoord = response.data.data;
                        this.setState({ listacoord });
                    })
            })
    }


    componentDidMount() {
        axios.get(urlListarCoordinaciones)
            .then(response => {
                const listacoord = response.data.data;
                this.setState({ listacoord });
            })
        axios
            .post(inscribirUrlListarProvinciasPost, {
                idPaisAct: 56,
            })
            .then(response => {
                const listProvincia = response.data.data;
                this.setState({ listProvincia });
            })
    }

    //Lista de cantones en ingreso de una coordinación
    handleChangeCantonesO = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        axios
            .post(inscribirUrlListarCantonPost, {
                idProvinciaAct: this.state.idProvinciaO,
            })
            .then(response => {
                const listCanton = response.data.data;
                this.setState({ listCanton });
            })
    }
    //Lista de cantones para modificar la coordinacion
    handleChangeCantonesOMod = async (e) => {
        await
            this.setState({ [e.target.name]: e.target.value })
        axios
            .post(inscribirUrlListarCantonPost, {
                idProvinciaAct: this.state.idProvinciaOMod,
            })
            .then(response => {
                const listCanton = response.data.data;
                this.setState({ listCanton });
            })
    }

    //Modal de Ingreso de coordinación
    modalAgregarCoord = (registro) => {
        this.setState({ modalIngresarCoord: true, form1: registro });
    }
    ocultarModalAgregarCoord = () => {
        this.setState({ modalIngresarCoord: false });
    }
    ingresarDatosCoordinacion = () => {
        axios
            .post(urlIngresarCoord, {
                descripcion: this.state.descripCoord,
                canton: this.state.idCantonO,
                estadoing: 1,

            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.get(urlListarCoordinaciones)
                        .then(response => {
                            const listacoord = response.data.data;
                            this.setState({ listacoord });
                        })
                    this.setState({ modalIngresarCoord: false });
                } else {
                    swal({
                        text: "No se actualizó los datos",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalIngresarCoord: false });
                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });
    }
    //Modal de modificar la sede
    modificarSede = (coord) => {
        axios.post(urlDatosSede, {
            idSede: coord.id_sede,
        })
            .then(res => {
                const coordinacion = res.data.data;
                $(function () {
                    $("#descripCoordMod").val(coordinacion[0].descripcion_sede);
                    $("#idProvinciaOMod").val(coordinacion[0].provincia_id);
                });
                this.setState({ descripCoordMod: coordinacion[0].descripcion_sede });
                this.setState({ idCantonOMod: coordinacion[0].canton_id });

                axios
                    .post(inscribirUrlListarCantonPost, {
                        idProvinciaAct: coordinacion[0].provincia_id,
                    })
                    .then(response => {
                        const listCanton = response.data.data;
                        this.setState({ listCanton });
                    })
            })
        this.setState({ modalModCoord: true, form2: coord });
    }

    ocultarModalModifSede = () => {
        this.setState({ modalModCoord: false });
    }

    modificarDatosSede = (datos) => {
        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urlModifDatosCoord, {
                            idCoordinacion: datos.id_sede,
                            descripCoordMod: this.state.descripCoordMod,
                            idCantonOMod: this.state.idCantonOMod,
                        })
                        .then(response => {

                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                axios.get(urlListarCoordinaciones)
                                    .then(response => {
                                        const listacoord = response.data.data;
                                        this.setState({ listacoord });
                                    })
                                this.setState({ modalModCoord: false });

                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalModCoord: false });

                            }

                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Lista de Coordinaciones </b>
                    </div>
                    <div className="card-body">

                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        COORDINACIONES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">ID</th>
                                                        <th scope="col">COORDINACIÓN</th>
                                                        <th scope="col">CANTÓN</th>
                                                        <th scope="col">ESTADO</th>
                                                        <th scope="col">MODIFICAR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listacoord.map(consola => (
                                                        <tr class="list-afiliados" key={consola.id_sede}  >

                                                            <td >{consola.id_sede}

                                                            </td>
                                                            <td >{consola.descripcion_sede}</td>
                                                            <td >{consola.nombre_canton}</td>
                                                            <td >
                                                                {(() => {
                                                                    //Paralelo
                                                                    switch (consola.estado_coord) {
                                                                        case 0: return <button class="bttsuspendido" onClick={() => this.bttActDesac(consola)}  > SUSPENDIDO</button>
                                                                        case 1: return <button class="bttactiva" onClick={() => this.bttActDesac(consola)}  > ACTIVO</button>
                                                                        default: return ""
                                                                    }
                                                                })()
                                                                }

                                                            </td>
                                                            <td>
                                                                <button onClick={() => this.modificarSede(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Modal isOpen={this.state.modalIngresarCoord}>
                                                <ModalHeader>
                                                    Ingresar la coordinación
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>
                                                        <label className="form-label">Nombre</label>
                                                        <input type="text" id="descripCoord" name="descripCoord" className="form-control" onChange={this.handleChange} required />

                                                        <label className="form-label">Seleccione la provincia de origen</label>
                                                        <select className="form-select" name="idProvinciaO" value={this.state.value} onChange={this.handleChangeCantonesO} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                            <option value="">Seleccione</option>
                                                            {this.state.listProvincia.map(person => <option key={person.id_provincia} value={person.id_provincia} > {person.nombre_provincia}</option>)}
                                                        </select>
                                                        <label className="form-label">Seleccione el cantón de origen</label>
                                                        <select className="form-select" name="idCantonO" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                            <option value="">Seleccione</option>
                                                            {this.state.listCanton.map(person => <option key={person.id_canton} value={person.id_canton} > {person.nombre_canton}</option>)}
                                                        </select>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.ingresarDatosCoordinacion()} > Ingresar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalAgregarCoord}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={e =>
                                                this.modalAgregarCoord(e)
                                            }
                                        >
                                            <b>Agregar</b>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalModCoord}>
                                    <ModalHeader>
                                        <h5></h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <label className="form-label">Nombre</label>
                                            <input type="text" id="descripCoordMod" name="descripCoordMod" className="form-control" onChange={this.handleChange} required />

                                            <label className="form-label">Seleccione la provincia de origen</label>
                                            <select className="form-select" id="idProvinciaOMod" name="idProvinciaOMod" value={this.state.value} onChange={this.handleChangeCantonesOMod} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listProvincia.map(person => <option key={person.id_provincia} value={person.id_provincia} > {person.nombre_provincia}</option>)}
                                            </select>
                                            <label className="form-label">Seleccione el cantón de origen</label>
                                            <select className="form-select" id="idCantonOMod" name="idCantonOMod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled={(this.state.disabledce) ? "disabled" : ""} required>
                                                <option value="">Seleccione</option>
                                                {this.state.listCanton.map(person => <option key={person.id_canton} value={person.id_canton} > {person.nombre_canton}</option>)}
                                            </select>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosSede(this.state.form2)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModifSede}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
