import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de consulta en el backend
const UrlListarEvaluaciones = ApiUrl + "listarevaluacionesactivas" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarCriterioxEval = ApiUrl + "insertarcriterioxevaluacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarcriteriosactivos = ApiUrl + "listarcriteriosactivos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListaCriteriosxEvaluacion = ApiUrl + "listarcriteriosxevaluacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarUsiarioAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 4;

export default class GestionCriteriosEvaluacion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            modalReportIngresarEval: false,
            eval_seleccionada: '',
            nombre_evaluacion: '',
            administrativos: [],
            //nuevas variables
            listaeval: [],
            listacriterios: [],
            porcentaje_eval: 0,
            color_eval: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            disableForm: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeDevolverIdEval = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ eval_seleccionada: this.state.idEval });
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-nombre');
        this.setState({ nombre_evaluacion: option })
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeListarAdm() {
        axios.get(UrlListarUsiarioAdm)
            .then(res => {
                const administrativos = res.data.data;
                this.setState({ administrativos });
            })
    }

    handleChangeListarCriterios() {
        axios.get(urlListarcriteriosactivos)
            .then(res => {
                this.setState({ listacriterios: res.data.data });
            })
    }

    handleChangeListarEvaluaciones = async (e) => {
        //evaluaciones
        axios.get(UrlListarEvaluaciones)
            .then(respuesta => {
                this.setState({ listaeval: respuesta.data.data });
            })
    }

    handleChangeListarCriteriosxEval = async (e) => {
        //criterios
        axios
            .post(UrlListaCriteriosxEvaluacion, {
                id: this.state.eval_seleccionada,
            })
            .then(response => {
                listaauxiliar = response.data.data;
                this.setState({ porcentaje_eval: response.data.data1 });
                this.setState({ color_eval: response.data.data2 });
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                this.setState({ disableForm: false });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarEvaluaciones();
        this.handleChangeListarAdm();
        this.handleChangeListarCriterios();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ criterio: null });
        this.setState({ responsable: null });
        this.setState({ modalReportIngresarEval: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_evalcritadm );
            $("#criterio").val(registro.id_criterio);
            $("#responsable").val(registro.id_administrativo);
        });

        this.setState({ id: registro.id_evalcritadm });
        this.setState({ criterio: registro.id_criterio });
        this.setState({ responsable: registro.id_administrativo });
        this.setState({ modalReportIngresarEval: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarEval: false });
    }

    registrarCriterioEval = () => {

        if (this.state.criterio != null && this.state.responsable != null){
        const f = new FormData();
        f.append("id", this.state.id);
        f.append("id_evaluacion", this.state.eval_seleccionada);
        f.append("id_criterio", this.state.criterio);
        f.append("responsable", this.state.responsable);

        axios.post(urlInsertarCriterioxEval, f)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    axios.post(UrlListaCriteriosxEvaluacion, {
                        id: this.state.eval_seleccionada,
                    })
                        .then(respuesta => {
                            listaauxiliar = respuesta.data.data;
                            if (this.state.pageCount == 1) {
                                this.setState({ itemOffset: 0 });
                            }

                            //PAGINACION
                            const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                            this.setState((state) => {
                                return { itemOffset: newOffset }
                            });
                            const endOffset = newOffset + itemsPerPage;
                            //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                            this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                            this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                            this.setState({ itemOffset: newOffset });
                        })
                    this.ocultarModalIngresar();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        }
        else{
            swal({
                text: "Ingresar campos requeridos",
                icon: "warning",
                button: "Aceptar",
            });
        }
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Criterios por Evaluación</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Evaluaciones Institucionales</b></label>
                                    <select className="form-select" name="idEval" value={this.state.value} onChange={this.handleChangeDevolverIdEval} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una evaluación</option>
                                        {this.state.listaeval.map(person => <option key={person.id_evaluacion} data-nombre={person.nombre_evaluacion} value={person.id_evaluacion} > {person.nombre_evaluacion}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4" >
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarCriteriosxEval} />
                                </div>
                            </div>
                        </div>
                        <div className="card border-dark text-center" hidden={this.state.disableForm}>
                            <div className="card border-dark text-center text-white">
                                <div className="card-header" class={this.state.color_eval}>
                                    <b>PORCENTAJE DE LA EVALUACIÓN: {this.state.porcentaje_eval}%</b>
                                </div>
                            </div>
                            <div className="card-header">
                                <b>REPORTE DE CRITERIOS POR EVALUACIÓN</b>
                            </div>
                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Responsable</th>
                                            <th>Porcentaje</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentItems && this.state.currentItems.map((consola) => (
                                            <tr key={consola.id_evalcritadm}>
                                                <td class="id">{consola.id_evalcritadm}</td>
                                                <td class="nombre_criterio">{consola.nombre_criterio}</td>
                                                <td class="responsable">{consola.nombres_administrativo} {consola.apellidos_administrativo}</td>
                                                <td class={consola.color}>{consola.porcentaje_criterio}%</td>
                                                <td class="detalle">
                                                    <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div class="table-responsive">
                                    <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                                <Modal isOpen={this.state.modalReportIngresarEval} >
                                    <ModalHeader>
                                        Registro de Criterio por Evaluación
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <input type="hidden" name="id" class="id" id="id" value="" />
                                                <label className="form-label">Evaluación:</label>
                                                <input type="text" id="evaluacion" name="evaluacion" onChange={this.handleChange} value={this.state.nombre_evaluacion} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                <label className="form-label">Criterio:</label>
                                                <select className="form-select" id="criterio" name="criterio" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="" selected disabled>Seleccione</option>
                                                    {this.state.listacriterios.map(person => <option key={person.id_criterio} value={person.id_criterio} > {person.nombre_criterio}</option>)}
                                                </select>
                                                <label className="form-label">Responsable:</label>
                                                <select className="form-select" id="responsable" name="responsable" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="" selected disabled>Seleccione</option>
                                                    {this.state.administrativos.map(person => <option key={person.id_administrativo} value={person.id_administrativo} > {person.nombres_administrativo + " " + person.apellidos_administrativo}</option>)}
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>

                                        <button class="btn btn-outline-primary" onClick={() => this.registrarCriterioEval()} > Ingresar</button>
                                        <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                                <div className="text-center">
                                    <button id="agregar" type="button"
                                        className="btn  back-istmas centrar"
                                        style={{ margin: "5px", width: "170px" }}
                                        onClick={() => this.mostrarModalIngresar()} >
                                        <b>Registrar</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

