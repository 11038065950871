/*import axios from 'axios'
import React, { Component } from 'react'*/
import React, { Component } from 'react'
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const inscribirUrlListarCarrera = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera = ApiUrl + "numnivelescarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlColegituraPeriodC = ApiUrl + "ingresarcolegituraperiodcarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = inscribirUrlListarCarrera;
const UrlListarEstMatCiclo = ApiUrl + "ListarEstMatCicloCuotaPagada" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarCuotasPagadasEstxMatricula = ApiUrl + "ListarCuotasPagadasEstxMatricula" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlSumTAbonosAprobColegCuotaColec = ApiUrl + "sumtabonosaprobcuotacolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlAbonosSinAprobar = ApiUrl + "abonossinaprobarpenmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const mostrarCuotasColeMesUrlColec = ApiUrl + "listcolegmesestudcolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoPensionM = ApiUrl + "consultarestpenmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutacomprobpensmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarUltimaBecasEst = ApiUrl + "ListarUltimabecasEst" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class ColegiaturasEstColegiaturaPagada extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            nivel1: '',
            nivel2: '',
            nivel3: '',
            nivel4: '',
            nivel5: '',
            nivel6: '',
            EstudianteBackup: [],

            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            periodo_seleccionado: '',
            carrera: [],
            estado: '',
            porcentaje_beca: 0,

            //nuevas variables
            nombresEstudiante: "",
            cedulaEstudianteSelect: "",
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listaestudiantes: [],
            listacuotasestudiante: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables del programador2
            form1: {

            },
            form: {
                id_pension_abono_mes: '',
            },
            cuotascolegiaturam: [],

            disabledformpag: true,
            disabledtransfe: true,
            disabledtotalbott: true,
            disabledbuscar: true,
            enunciadonumdeposit: "Número de depósito(número de papeleta11111 o referencia)",
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {

            this.setState({ modalPagoColegituraMes: false });
            this.setState({ modalReportAbonosCuotaEst: false });

        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }

    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.periodoId,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    handleChangeDevolverIdCiclo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeDevolverIdPeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ periodo_seleccionado: this.state.idPeriodo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeListarEstMatCiclo = async (e) => {

        this.setState({ listacuotasestudiante: [] });
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(UrlListarEstMatCiclo, {
                idCarrera: this.state.carrera_seleccionada,
                idCiclo: this.state.ciclo_seleccionado,
                idPeriodo: this.state.periodo_seleccionado,
            })
            .then(response => {
                this.setState({ disabledbuscar: false });
                var array = response.data.data;
                var hash = {};
                array = array.filter(function (current) {
                    var exists = !hash[current.id_matricula];
                    hash[current.id_matricula] = true;
                    return exists;
                });
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listaestudiantes;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //periodos
        axios.get(ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const periodos = respuesta.data.data;
                const periodosId = respuesta.data.id_periodo_academico;
                this.setState({ periodoId: respuesta.data.id_periodo_academico });
                this.setState({ periodos });
                this.setState({ periodosId });
                // console.log("periodos"+periodosId);
            })
    }

    registrarColegiaturasPeriodo = async (e) => {
        // e.preventDefault();
        let materiasselect = [];
        document.querySelectorAll('#tablaasignaturas tbody tr').forEach(function (e) {
            var idnivel = e.querySelector('.codigo').innerText
            var valorM = $("#" + idnivel + "valormensual").val();
            var valorT = $("#" + idnivel + "valortotal").val();
            //  alert(valorM);
            //  alert(valorT);
            let fila = {
                idnivelf: idnivel,
                valormf: valorM,
                valortf: valorT,
            };
            materiasselect.push(fila);
        });
        var combinancion = JSON.stringify(materiasselect);

        axios
            .post(inscribirUrlColegituraPeriodC, {//guarda para enviar los atributos y envia
                periodoactualef: this.state.periodoId,//5periodosId
                listColegiaturasPf: materiasselect,
                idCarreraf: this.state.idCarrera,
            })
            .then(response => {
                const list = response.data.data;
                if (response.data.success === true) {
                    swal({
                        text: "Datos registrados",
                        icon: "success",
                        button: "Aceptar",
                    });

                    //llama a la lista de colegiaturas para actualizar
                    axios
                        .post(devuelNumNivelesCarrera, {
                            idCarreraf: this.state.idCarrera,
                            periodoIdf: this.state.periodoId,
                        })
                        .then(response => {
                            const listaniveles1 = response.data.data;
                            this.setState({ listaniveles: listaniveles1 });
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                }
                else {
                    swal({
                        text: "Datos no  registrados",
                        icon: "error",
                        button: "Aceptar",
                    });

                }
            })
            .catch(error => {
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                swal({
                    text: "Datos no  registrados",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }

    seleccionarEstudianteuno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var id_matricula = "";
        var id_carrera = "";
        var id_ciclo = "";
        var id_est = "";
        $(".afiliado:checked").each(function () {
            id_matricula = $(this).parent().parent().find('td').eq(0).html();
            id_carrera = $(this).parent().parent().find('td').eq(1).html();
            id_ciclo = $(this).parent().parent().find('td').eq(2).html();
            id_est = $(this).parent().parent().find('td').eq(3).html();
            //nombrescompletos=$(this).parent().parent().find('td').eq(1).html()+" "+$(this).parent().parent().find('td').eq(2).html()+" "+$(this).parent().parent().find('td').eq(3).html()+" "+$(this).parent().parent().find('td').eq(4).html();
        });


        axios
            .post(UrlListarCuotasPagadasEstxMatricula, {
                idMatriculaEst: id_matricula,
                idCarreraEst: id_carrera,
                idCicloEst: id_ciclo,
            })
            .then(response => {
                const respuesta = response.data.data;
                this.setState({ listacuotasestudiante: respuesta });
              
            })



    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    mostrarModalReportAbonosCuota = (registro) => {

        axios.post(mostrarCuotasColeMesUrlColec, {
            idPensionMes: registro.id_pension_mes,
        })
            .then(response => {
                if (response.data.success === true) {
                    const cuotascolegiaturam = response.data.data;

                    this.setState({ cuotascolegiaturam });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        //suma total de abonos aprobados de la cuota de colegiatura
        axios.post(urlSumTAbonosAprobColegCuotaColec, {
            idPensionMes: registro.id_pension_mes,
        })
            .then(response => {
                if (response.data.success == true) {
                    const sumabonaprobcuotb = response.data.data;
                    this.setState({ sumabonaprobcuot: sumabonaprobcuotb });
                }
            });
        $("#nombremesalert").val(registro.descripcion_pension_mes);
        $("#idpensioncamest").val(registro.descripcion_pension_mes);

        this.setState({ nombremesalert: registro.descripcion_pension_mes });
        this.setState({ idpensioncamest: registro.id_pension_mes });


        this.setState({ modalReportAbonosCuotaEst: true, form1: registro });
        axios.post(urlAbonosSinAprobar, {
            idPensionMes: registro.id_pension_mes,
        })
            .then(response => {
                if (response.data.success == true) {
                    if (response.data.data == 0) {


                        this.setState({ disabledabonsinapf: !this.state.disabledabonsinap });

                    }
                    else {
                        this.setState({ disabledabonsinapf: this.state.disabledabonsinap });


                    }
                }
            });

        axios.post(urlEstadoPensionM, {
            idPensionMes: registro.id_pension_mes,
        })
            .then(response => {
                if (response.data.success == true) {
                    if (response.data.data == 4) {


                        this.setState({ disabledabonsinapf: true });

                    }

                }
            });

    }

    ocultarModalReportAbonosCuota = () => {
        this.setState({ modalReportAbonosCuotaEst: false });
    }

    mostrarModalPagoColegituraMes = (registro) => {
        axios.post(rutaimagen, {

            nombrecomprobante: "colegiaturas/" + registro.comprobante_pens,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })


        $(function () {
            $("#numdeposito").val(registro.num_deposito_pens);
            $("#fechadeposito").val(registro.fecha_deposito_pens);
            $("#cantidadPago").val(registro.cantidad_abono_pens);
            $("#cuentadebitotransferencia").val(registro.cuenta_debito_transf_pens);
            $("#nomdueniocuentdebittransf").val(registro.nom_dueno_cuenta_transf_pens);
            $("#bancobeneficario").val(registro.nombre_banco_benef_pens);
            $("#numbancobeneficario").val(registro.cuenta_beneficiaria_pens);
            $("#idTipoTransaccion").val(registro.tipo_deposito_pens);
            $("#detalle").val(registro.detalle_pens);

        });
        this.setState({ modalPagoColegituraMes: true, form: registro });
        this.setState({ disabledformpagd: this.state.disabledformpag });
        this.setState({ disabledct: this.state.disabledtransfe });
        this.setState({ disabledtotal: this.state.disabledtotalbott });

        this.setState({ numdeposito: registro.num_deposito_pens });
        this.setState({ fechadeposito: registro.fecha_deposito_pens });
        this.setState({ cantidadPago: registro.cantidad_abono_pens });
        this.setState({ cuentadebitotransferencia: registro.cuenta_debito_transf_pens });
        this.setState({ nomdueniocuentdebittransf: registro.nom_dueno_cuenta_transf_pens });
        this.setState({ bancobeneficario: registro.nombre_banco_benef_pens });
        this.setState({ numbancobeneficario: registro.cuenta_beneficiaria_pens });
        this.setState({ idTipoTransaccion: registro.tipo_deposito_pens });
        this.setState({ detalle: registro.detalle_pens });



        if ((registro.estado_abono_pens == 1 && (registro.tipo_deposito_pens != 2)) || registro.estado_abono_pens == 4 || registro.estado_abono_pens == 5) {
            this.setState({ disabottaprorechvicerr: !this.state.disabledaprorechvicerr });

        }
        else {
            this.setState({ disabottaprorechvicerr: this.state.disabledaprorechvicerr });

        }


        if (registro.tipo_deposito_pens == 1) {
            this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });

        }
        else {
            if (registro.tipo_deposito_pens == 2) {
                this.setState({ enunciadonumdeposit: "Número de comprobante" });

            }
            else {
                this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });

            }
        }




    }

    ocultarModalPagoColegituraMes = () => {
        this.setState({ modalPagoColegituraMes: false });
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listaestudiantes;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Reporte de Estudiantes con Colegiaturas Pagadas</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Ciclos</b></label>
                                    <select className="form-select" name="idCiclo" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un ciclo:</option>
                                        {this.state.listaniveles.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                        <option value="todos">Todos</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Período:</b></label><br />
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangeDevolverIdPeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un período:</option>
                                        {this.state.periodos.map(consola => <option key={consola.id_periodo_academico} value={consola.id_periodo_academico} > {consola.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} disabled={this.state.disabledbuscar} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2" >
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEstMatCiclo} />
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>Cédula</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Primer Nombre</th>
                                        <th>Segundo Nombre</th>
                                        <th>Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_matricula}>
                                            <td class="id oculto">{consola.id_matricula}</td>
                                            <td class="carrera oculto">{consola.carrera_id}</td>
                                            <td class="ciclo oculto">{consola.ciclo_nivel}</td>
                                            <td class="id_est oculto">{consola.id_estudiante}</td>
                                            <td class="codigo">{consola.numero_identificacion_estudiante}</td>
                                            <td class="primerape">{consola.primer_apellido_estudiante}</td>
                                            <td class="segundoape">{consola.segundo_apellido_estudiante}</td>
                                            <td class="primernom">{consola.primer_nombre_estudiante}</td>
                                            <td class="segundonom">{consola.segundo_nombre_estudiante}</td>
                                            <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        CUOTAS DE COLEGIATURA DEL ESTUDIANTE
                                    </div>

                                    <div className="card-body centrar " style={{ width: "100%" }}>

                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">MES</th>
                                                        <th scope="col">VALOR</th>
                                                        <th scope="col">ESTADO</th>
                                                        <th scope="col">RECARGO</th>
                                                        <th scope="col">DETALLE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listacuotasestudiante.map(consola => (
                                                        <tr key={consola.id_pension_mes}>
                                                            <td class="mes">{consola.descripcion_pension_mes}</td>
                                                            <td class="vm">${consola.valor_mensual}</td>
                                                            <td scope="estadO">
                                                                {(() => {
                                                                    if (consola.estado_pension_mes == "1")
                                                                        return <span>Pendiente</span>
                                                                    if (consola.estado_pension_mes == "2")
                                                                        return <span>En proceso</span>
                                                                    if (consola.estado_pension_mes == "3")
                                                                        return <span>Abonado</span>
                                                                    if (consola.estado_pension_mes == "4")
                                                                        return <span>Completado</span>
                                                                })()}
                                                            </td>
                                                            <td class="recargo">
                                                                {(() => {
                                                                    if (consola.recargo_pension_mes == "0")
                                                                        return <span>Sin recargo</span>
                                                                    if (consola.recargo_pension_mes == "1")
                                                                        return <span>Con recargo</span>
                                                                })()}
                                                            </td>
                                                            <td class="detalle">
                                                                <button onClick={() => this.mostrarModalReportAbonosCuota(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Modal size='lg' isOpen={this.state.modalReportAbonosCuotaEst}>
                                                <ModalHeader>
                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>

                                                        <label className="form-label"><h5>{this.state.form1.descripcion_pension_mes}</h5></label>
                                                        <input name="nombremesalert" id="nombremesalert" type="hidden" value={this.state.form1.descripcion_pension_mes} onChange={this.handleChange} ></input>
                                                        <input name="idpensioncamest" id="idpensioncamest" type="hidden" value={this.state.form1.descripcion_pension_mes} onChange={this.handleChange} ></input>

                                                        <label className="form-label"></label>
                                                        <div class="table-responsive">
                                                            <table className="table table-bordered" id="tablacientificos" width="100%" >
                                                                <thead>
                                                                    <tr>

                                                                        <th scope="col">Forma de pago</th>
                                                                        <th scope="col">Fecha del depósito</th>
                                                                        <th scope="col">Valor cancelado</th>
                                                                        <th scope="col">Fecha de registro</th>
                                                                        <th scope="col">Estado</th>
                                                                        <th scope="col">Novedad</th>
                                                                        <th scope="col">Detalle</th>

                                                                    </tr>
                                                                </thead>

                                                                <tbody>

                                                                    {this.state.cuotascolegiaturam.map(consola => (
                                                                        <tr key={consola.id_pension_abono_mes}>

                                                                            <td>
                                                                                {(() => {

                                                                                    //Paralelo
                                                                                    switch (consola.tipo_deposito_pens) {

                                                                                        case 1: return "DEPOSITO";
                                                                                        case 2: return "TRANSFERENCIA";
                                                                                        case 3: return "EFECTIVO";
                                                                                        default: return ""
                                                                                    }
                                                                                })()}

                                                                            </td>
                                                                            <td>{consola.fecha_deposito_pens}</td>
                                                                            <td>${consola.cantidad_abono_pens}

                                                                            </td>

                                                                            <td>{consola.fecha_regis_depos_pens}</td>
                                                                            <td>

                                                                                {(() => {

                                                                                    switch (consola.estado_abono_pens) {
                                                                                        case 1: return "Enviado";
                                                                                        case 2: return "Aprobado";
                                                                                        case 3: return "Rechazado";
                                                                                        case 4: return "Aprobado Vicerrectorado";
                                                                                        case 5: return "Rechazado Vicerrectorado";
                                                                                        default: return ""
                                                                                    }
                                                                                })()}
                                                                            </td>

                                                                            <td>{consola.detalle_pens}</td>
                                                                            <td class="detalle">

                                                                                <button onClick={() => this.mostrarModalPagoColegituraMes(consola)}  > <i className="fas fa-clipboard-list" ></i></button>


                                                                            </td>



                                                                        </tr>
                                                                    ))}
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <b>
                                                                                SUMA
                                                                            </b >
                                                                        </td>
                                                                        <td >
                                                                            <b>
                                                                                ${this.state.sumabonaprobcuot}
                                                                            </b >

                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAbonosCuota}>Salir</button>

                                                </ModalFooter>
                                            </Modal>
                                            <Modal isOpen={this.state.modalPagoColegituraMes} >
                                                <ModalHeader>
                                                    Registro de pago de colegiatura
                                                </ModalHeader>
                                                <ModalBody>
                                                    <FormGroup>
                                                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                            height='100%' ></input>
                                                        <br />
                                                        <br />
                                                        <h5>Detalle</h5>
                                                        <div className="mb-3 centrar">
                                                            <input name="numcuota" id="numcuota" type="hidden" value={this.state.form.id} onChange={this.handleChange} ></input>
                                                            <input name="nombremescuota" id="nombremescuota" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <input name="fechmaxpagocoleg" id="fechmaxpagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <input name="recargopagocoleg" id="recargopagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <label className="form-label"><h5>{this.state.form.nombremes}</h5></label>
                                                            <br />
                                                            <label className="form-label">Seleccionar la forma de pago</label>
                                                            <select className="form-select" id="idTipoTransaccion" name="idTipoTransaccion" value={this.state.value} onChange={this.handleChangeFormaPagoEfect} aria-label="Default select example" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required>
                                                                <option value="">Seleccione</option>
                                                                <option value="1">Depósito</option>
                                                                <option value="2">Transferencia</option>
                                                                <option value="3">Efectivo</option>
                                                            </select>
                                                            <label className="form-label">{this.state.enunciadonumdeposit}</label><br />
                                                            <input type="text" id="numdeposito" name="numdeposito" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" disabled={(this.state.disabledtotal) ? "disabled" : ""} required />
                                                            <label className="form-label">Fecha de depósito</label><br />
                                                            <input type="date" id="fechadeposito" name="fechadeposito" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Cantidad  de pago</label><br />
                                                            <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPago" name="cantidadPago" onChange={this.handleChange} className="form-control" placeholder="0.00" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required /></div>
                                                            <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                                            <input type="text" id="cuentadebitotransferencia" name="cuentadebitotransferencia" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                                            <input type="text" id="nomdueniocuentdebittransf" name="nomdueniocuentdebittransf" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Banco Beneficiario</label><br />
                                                            <select className="form-select" id="bancobeneficario" name="bancobeneficario" value={this.state.value} onChange={this.handleChangeCuentaBeneficiario} aria-label="Default select example" disabled={(this.state.disabledformpagd) ? "disabled" : ""}>
                                                                <option value="1">PACIFICO</option>
                                                                <option value="2">PICHINCHA-3257594104</option>
                                                                <option value="3">PICHINCHA-2100271381</option>
                                                                <option value="4">AUSTRO</option>
                                                            </select>
                                                            <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                                            <input type="text" id="numbancobeneficario" name="numbancobeneficario" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagd) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Detalle</label><br />
                                                            <input className="form-control" type="text" id="detalle" name="detalle" onChange={this.handleChange} disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required />

                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>

                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalPagoColegituraMes}>Salir</button>

                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

