import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from 'jquery';
import React, { Component, useState } from 'react';
import { ApiUrl } from '../../services/ApiRest';
import "../../vicerrectoradoacad/vicerrectoradoacad.css";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';


//const urlListDocumentos = ApiUrl + "listdocumentpract" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const urlIngresarDocumento = ApiUrl + "ingresardocumento" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlCambiarEstDocumentos = ApiUrl + "estaddocument" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosDocumento = ApiUrl + "datosdocumento" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifDatosDocumentos = ApiUrl + "modificardocumentp" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarDocumento = ApiUrl + "eliminardocument" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


const urlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListDocumentos = ApiUrl + "listdocumentpractlab" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";



let listaauxiliar = [];
const itemsPerPage = 10;

export default class GestionDocumentosPractLab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idDocumento: "",
            nomdocumentmod: "",
            coordinacion: [],
            listaBackup: [],
            form: {
            },
            periodos: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalIngresarCoord: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }
    handleChangeDocumento = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ documento: e.target.files })
    }

    handleChangeDocumentoMod = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ documentomod: e.target.files })
    }

    //Habilitar o Deshabilitar el documento de prácticas
    bttActDesac = (documentos) => {

        var auxestad = 0;
        if (documentos.estado == 0) {
            auxestad = 1;
        }
        else {
            auxestad = 0;
        }
        axios.post(urlCambiarEstDocumentos, {
            idDocumentPrac: documentos.id_documentos_pract,
            estado: auxestad,
        })
            .then(response => {
                if (response.data.success === true) {
                    axios.get(urlListDocumentos)
                        .then(response => {
                            if (response.data.success === true) {
                                var array = response.data.data;
                                //lista general= lista de centros de prácticas
                                this.setState({ listageneral: array });
                                listaauxiliar = this.state.listageneral;
                                //BÚSQUEDA
                                const listaBackup = this.state.listageneral;
                                this.setState((state) => {
                                    return { listaBackup: listaBackup }
                                });

                                //PAGINACION
                                this.setState({ itemOffset: 0 });

                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN DE LA PAGINACION
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                }
                else {

                    swal({
                        text: "No existe información",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }

            })
    }


    componentDidMount() {
        //Listar todos los períodos
        axios
            .get(urlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })


        axios.get(urlListDocumentos)
            .then(response => {
                var array = response.data.data;
                //lista general= lista de centros de prácticas
                this.setState({ listageneral: array });
                listaauxiliar = this.state.listageneral;
                //BÚSQUEDA
                const listaBackup = this.state.listageneral;
                this.setState((state) => {
                    return { listaBackup: listaBackup }
                });

                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })

    }



    //Modal de Ingreso de coordinación
    modalAgregarCentro = (registro) => {
        this.setState({ modalIngresarCentro: true, form1: registro });
    }
    ocultarModalAgregarCentro = () => {
        this.setState({ modalIngresarCentro: false });
    }

    //Modal de modificar el documento
    modalModificarDocumento = (documento) => {
        axios.post(urlDatosDocumento, {
            idDocumento: documento.id_documentos_pract,
        })
            .then(res => {
                const documentoP = res.data.data;

                $(function () {

                    $("#idDocumento").val(documentoP[0].id_documentos_pract);
                    $("#nomdocumentmod").val(documentoP[0].nombre_document);
                });
                this.setState({ idDocumento: documentoP[0].id_documentos_pract });
                this.setState({ nomdocumentmod: documentoP[0].nombre_document });
            })
        this.setState({ modalModDocumentoP: true });
    }







    ocultarModalModifCentro = () => {
        this.setState({ modalModDocumentoP: false });
    }

    modificarDatosDocumenP = (datos) => {


        var combmsmalert = "¿Está seguro que desea modificar los datos?";
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var vidFileLength = $("#documentomod")[0].files.length;
                    var cargarArchivo = 0;
                    var documentnuevo = "";

                    if ((vidFileLength === 0)) {
                        cargarArchivo = 0;
                        documentnuevo = null;
                    }
                    else {
                        cargarArchivo = 1;
                        documentnuevo = this.state.documentomod[0];
                    }

                    //Validar el comprobante
                    var fileInput = document.getElementById('documentomod');
                    var filePath = fileInput.value;
                    var allowedExtensions = /(.docx|.pdf)$/i;
                    if ((!allowedExtensions.exec(filePath)) && cargarArchivo == 1) {
                        swal({
                            text: "El archivo debe ser formato:.docx/.pdf",
                            icon: "error",
                            button: "Aceptar",

                        });
                    }
                    else {
                        const f = new FormData();
                        f.append("url_documentmod", documentnuevo);
                        f.append("idDocumento", this.state.idDocumento);
                        f.append("nomdocumentmod", this.state.nomdocumentmod);
                        f.append("cargarchivomod", cargarArchivo);

                        const config = {
                            headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios
                            .post(urlModifDatosDocumentos, f, config)
                            .then(response => {

                                if (response.data.success === true) {
                                    swal({
                                        text: "Datos procesados correctamente",
                                        icon: "success",
                                        button: "Aceptar",
                                    });
                                    this.setState({ modalModDocumentoP: false });
                                    //Actualizar el reporte
                                    axios.get(urlListDocumentos)
                                        .then(response => {
                                            if (response.data.success === true) {
                                                var array = response.data.data;
                                                //lista general= lista de centros de prácticas
                                                this.setState({ listageneral: array });
                                                listaauxiliar = this.state.listageneral;
                                                //BÚSQUEDA
                                                const listaBackup = this.state.listageneral;
                                                this.setState((state) => {
                                                    return { listaBackup: listaBackup }
                                                });

                                                //PAGINACION
                                                this.setState({ itemOffset: 0 });

                                                const endOffset = this.state.itemOffset + itemsPerPage;
                                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                                this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                                                this.setState((state) => {
                                                    return { itemOffset: state.itemOffset + endOffset }
                                                });
                                                //FIN DE LA PAGINACION
                                            }
                                        })
                                        .catch(error => {
                                            this.setState({ error: "No existe conexión con el servidor" });
                                            swal({
                                                text: "No existe conexión con el servidor",
                                                icon: "error",
                                                button: "Aceptar",
                                            });
                                        });

                                } else {

                                    swal({
                                        text: "No se actualizó los datos",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                    this.setState({ modalModDocumentoP: false });
                                }

                            })
                            .catch(error => {
                                console.log(error);
                                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                                window.location.reload(true);
                            });
                    }
                }
                else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }




    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listageneral;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNomDocumen = item.nombre_document.toUpperCase();
            const campo = itemDataNomDocumen;
            //variable del buscador
            const textData = text.toUpperCase();
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.listaBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
        this.setState({ itemOffset: 0 });
    }


    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ listaBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalAgregarDocumentos = (registro) => {

        $(function () {
            $("#periodpractid").val(registro);

        });
        this.setState({ periodpractid: registro });

        this.setState({ modalRegistroDocument: true });

    }

    //FIN DE PAGINACION
    ocultarModalAgregarDocumentos = () => {
        this.setState({ modalRegistroDocument: false });
        //console.log( e.target.value);
    }

    agregarDocumento = (documentos) => {

        var vidFileLength = $("#documento")[0].files.length;
        var comprobantemat = "";
        if (((this.state.documento == null) || (vidFileLength === 0))) {

            swal({
                text: "No se ha seleccionado ningun archivo",
                icon: "error",
                button: "Aceptar",

            });

        } else {
            //Validar el comprobante
            var fileInput = document.getElementById('documento');
            var filePath = fileInput.value;
            var allowedExtensions = /(.docx|.pdf)$/i;
            if ((!allowedExtensions.exec(filePath))) {
                swal({
                    text: "El archivo debe ser formato:.doc/.pdf",
                    icon: "error",
                    button: "Aceptar",

                });
            }
            else {

                const f = new FormData();
                f.append("nombredocu", this.state.nomdocument);
                f.append("url_documento", this.state.documento[0]);

                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }

                axios
                    //  .post(ApiUrl+"matriculas",f,config)
                    .post(urlIngresarDocumento, f, config)
                    .then(response => {
                        if (response.data.success === true) {

                            swal({
                                text: "Datos ingresados exitosamente",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.setState({ modalRegistroDocument: false });

                            axios.get(urlListDocumentos)
                                .then(response => {
                                    if (response.data.success === true) {
                                        var array = response.data.data;
                                        //lista general= lista de centros de prácticas
                                        this.setState({ listageneral: array });
                                        listaauxiliar = this.state.listageneral;
                                        //BÚSQUEDA
                                        const listaBackup = this.state.listageneral;
                                        this.setState((state) => {
                                            return { listaBackup: listaBackup }
                                        });

                                        //PAGINACION
                                        this.setState({ itemOffset: 0 });

                                        const endOffset = this.state.itemOffset + itemsPerPage;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                                        this.setState((state) => {
                                            return { itemOffset: state.itemOffset + endOffset }
                                        });
                                        //FIN DE LA PAGINACION
                                    }
                                })
                                .catch(error => {
                                    this.setState({ error: "No existe conexión con el servidor" });
                                    swal({
                                        text: "No existe conexión con el servidor",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                });
                        }
                        else {
                            swal({
                                text: "No se ingresaron los datos",
                                icon: "error",
                                button: "Aceptar",
                            });
                        }

                    })
            }
        }//fin
    }

    //Eliminar el documento

    eliminarDocumento = (documento) => {
        axios.post(urlEliminarDocumento, {
            idDocumento: documento.id_documentos_pract,
        })
            .then(res => {

                if (res.data.success == true) {
                    swal({
                        text: "Documento eliminado exitosamente!!",
                        icon: "success",
                        button: "Aceptar",
                    });

                    axios.get(urlListDocumentos)
                        .then(response => {
                            if (response.data.success === true) {
                                var array = response.data.data;
                                //lista general= lista de centros de prácticas
                                this.setState({ listageneral: array });
                                listaauxiliar = this.state.listageneral;
                                //BÚSQUEDA
                                const listaBackup = this.state.listageneral;
                                this.setState((state) => {
                                    return { listaBackup: listaBackup }
                                });

                                //PAGINACION
                                this.setState({ itemOffset: 0 });

                                const endOffset = this.state.itemOffset + itemsPerPage;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ listaBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                                this.setState((state) => {
                                    return { itemOffset: state.itemOffset + endOffset }
                                });
                                //FIN DE LA PAGINACION
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });

                }
                else {
                    swal({
                        text: "No se ha eliminado ningún registro",
                        icon: "error",
                        button: "Aceptar",
                    });
                }
            })
    }
    //Períodos Académicos
    handleChangePeriodo = async (e) => {
        /* await this.setState({ [e.target.name]: e.target.value });
 
         axios.post(inscribirUrlListarestud, {
             idPeriodoActual: this.state.idPeriodo
         })
             .then(respuesta => {
                 if (respuesta.data.success == true) {
                     listaauxiliar = respuesta.data.data;
                     const estud = respuesta.data.data;
                     this.setState((state) => {
                         return { estud: estud }
                     }
                     );
                     const EstudianteBackup = estud;
                     this.setState((state) => {
                         return { EstudianteBackup: EstudianteBackup }
                     });
                     //PAGINACION
                     this.setState({ itemOffset: 0 });
                     const endOffset = this.state.itemOffset + itemsPerPage;
                     this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                     this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                     this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                     this.setState((state) => {
                         return { itemOffset: state.itemOffset + endOffset }
                     });
                     //FIN  D DE LA PAGINACION
                 } else {
 
                     this.setState({ error: "No existe estudiantes" });
                     swal({
                         text: "No existen estudiantes para matricular",
                         icon: "warning",
                         button: "Aceptar",
                     });
                     this.setState({ currentItems: [] });
                 }
             })
             */
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Documentación </b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        GESTIÓN DE DOCUMENTOS DE PRÁCTICAS LABORALES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >


                                                        <th scope="col">Nombre del Documento</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Archivo</th>
                                                        <th scope="col">Período Académico</th>
                                                        <th scope="col">Carrera</th>
                                                        <th scope="col">Ciclo</th>
                                                        <th scope="col">Modificar</th>
                                                        <th scope="col">Eliminar</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                                        <tr key={consola.id_document_pract_lab}>
                                                            <td>{consola.nombre_document}</td>

                                                            <td >
                                                                {(() => {
                                                                    //Paralelo
                                                                    switch (consola.estado) {
                                                                        case 0: return <button class="bttsuspendido" onClick={() => this.bttActDesac(consola)}  > SUSPENDIDO</button>
                                                                        case 1: return <button class="bttactiva" onClick={() => this.bttActDesac(consola)}  > ACTIVO</button>
                                                                        default: return ""
                                                                    }
                                                                })()
                                                                }
                                                            </td>
                                                            <td>
                                                                {(() => {
                                                                    if (consola.ruta) {
                                                                        return <a href={consola.ruta} target="blank"><i class="fa fa-2x fa-file-pdf" aria-hidden="true"></i></a>
                                                                    }
                                                                })()}
                                                            </td>
                                                            <td>
                                                            {consola.descripcion_periodo_academico} 
                                                            
                                                            </td>
                                                            <td>
                                                            {consola.descripcion_carrera} </td>
                                                            <td>
                                                            {consola.ciclo} </td>
                                                            <td>
                                                                <button onClick={() => this.modalModificarDocumento(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                            <td class="eliminar">
                                                                <button onClick={() => this.eliminarDocumento(consola)}  > <i class="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>




                        <br />

                        <div className="text-center">
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                style={{ margin: "5px", width: "170px" }}
                            >
                                <b>Agregar Documento</b>
                            </button>

                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}