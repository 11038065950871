import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ApiUrl } from '../services/ApiRest';
import Cookies from 'universal-cookie';


const urlEstudiante = ApiUrl + "estudiantes/" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIdPeriodoActual = ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const cookie = new Cookies();
const idEstudiante = cookie.get("idEstudiante");    //declaramos una variable para usar la cookie del login

export default class SidebarEstudiante extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlEstudiante + idEstudiante + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data[1];
                this.setState({ estudiante });
                console.log(estudiante);
                var segundoApellido = "";
                var segundoNombre = "";

                if (estudiante.segundo_apellido_estudiante != null)
                    segundoApellido = estudiante.segundo_apellido_estudiante;

                if (estudiante.segundo_nombre_estudiante != null)
                    segundoNombre = estudiante.segundo_nombre_estudiante;

                var NombresCompleto = estudiante.primer_nombre_estudiante + " " + segundoNombre;
                var ApellidosCompletos = estudiante.primer_apellido_estudiante + " " + segundoApellido;

                cookie.set('nombres', NombresCompleto, { path: "/" })
                cookie.set('apellidos', ApellidosCompletos, { path: "/" })
            })

        //CONSULTAR EL PERIODO  ACTUAL
        axios.get(urlIdPeriodoActual)
            .then(respuesta => {
                const periodosId = respuesta.data.id_periodo_academico;
                cookie.set('idperiodoActual', periodosId, { path: "/" })
            })
    }

    render() {
        return (
            <div id="layoutSidenav_nav" style={{ width: 226 }}>

                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">ESTUDIANTE ISTMAS</div>
                            <Link to="/estudiante">
                                <div className="nav-link estudiante" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal</b>
                                </div>
                            </Link>

                            <div className="sb-sidenav-menu-heading"></div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>
                                </div>
                                MI PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/EstudianteVerPerfil" ><div className="nav-link puntero movil" href="#">Ver Perfil </div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseMatriculas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-dollar-sign"></i>
                                </div>
                                MATRÍCULAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseMatriculas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/EstudianteInscrip"><div className="nav-link movil" href="#">Inscripción pago matrícula </div></Link>
                                </nav>

                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAsignaturas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                                ASIGNATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAsignaturas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/ListarAsignaturasMatricu"><div className="nav-link movil" href="#">Listar Asignaturas Matriculadas</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapsePagos" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-dollar-sign"></i>
                                </div>
                                PAGOS COLEGIATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapsePagos" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/EstudianteColegPeriodCarr"><div className="nav-link movil" href="#">Pagos Colegiatura </div></Link>
                                </nav>
                            </div>


                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAgenda" aria-expanded="false" aria-controls="collapseLayouts">                             <div className="sb-nav-link-icon">
                                <i className="far fa-sticky-note"></i>
                            </div>
                                AGENDA
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAgenda" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/AgendaEst"><div className="nav-link movil" href="#">Agenda</div></Link>
                                </nav>
                            </div>


                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseNotificaciones" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                NOTIFICACIONES
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseNotificaciones" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/NotificacionEstudiante"><div className="nav-link movil" href="#">Notificaciones</div></Link></nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseDocumentacion" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fa fa-folder"></i>
                                </div>
                               DOCUMENTACIÓN
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDocumentacion" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/estudiante/actadecompromiso"><div className="nav-link movil" href="#">Acta de compromiso</div></Link></nav>
                            </div>


                        </div>

                    </div>

                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}
