import React from 'react';
import './Footer.css';

export const Footer = () => {
    return (
        <div>
            {/*  <div className="row footer">
              
                <div className="medium-12 columns">
                <p>(C) COPYRIGHT © 2020 - 2021</p>
                </div>
           
            </div>*/}
        </div>
    )
}
