import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
import "./matriculavicerradm.css";
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';

const cookie = new Cookies();
const inscribirUrlListarestud = ApiUrl + "listarestudpagomatriculatransf" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlCarreraEst = ApiUrl + "carreraestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutaimagencomprobantecolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const modificarEstadoAbonoagoMatricula = ApiUrl + "modestadopagoabonomatriculatransf" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const listarmostarpagomatriculaPost = ApiUrl + "mostarpagomatriculaPost" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class ListarPagosMatTransfe extends Component {
    constructor(props) {
        super(props)

        this.state = {
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            periodos: [],
            listaniveles: [],
            listAsignaturasCarrera: [],
            codigoCarrE: "", //codigocarrera
            descripCarrE: "",//descripciocarrera
            numnivelE: "",//nuemro de  niveles
            cedulaEstudianteSelect: "",
            modalModificar: false,
            ruta: "",
            nombresEstudiante: "",

            form: {
                id_estudiante: '',
                id_pago_abono_matricula_periodo: ' ',
            },

            estado: "",
            codigoEstudiante: "",
            comprobante: "",
            disabledtransfemod: true,
            disabledformpagmod: true,
            disabledtransfvicerr: true,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,

        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {

            this.setState({ modalRegistroPagoMatricula: false });


        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ comprobante: e.target.files })
        if (this.state.idTipoTransaccionmod == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod })
        }
        else {
            this.setState({ disabledctmod: !this.state.disabledtransfemod })
        }
    }

    //estados del deposito
    renderElementEstadoCuota(valor) {
        if (valor == 1) {
            return <p>Enviado</p>;
        }
        else {
            if (valor == 2) {
                return <p>Aprobado</p>;
            }
            else {
                return <p>Rechazado</p>;
            }
        }
    }

    ///controles del modal de  un registro de abono de matricula
    mostrarModalRegistroPagoMatricula = (registro) => {

        axios.post(rutaimagen, {

            nombrecomprobante: "matriculas/" + registro.comprobante,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        //dibujar el  numero de  niveles
        let listaniveles1 = []
        for (let i = 1; i <= this.state.numnivelE; i++) {

            let fila = {
                codnivel: i,
                valor: i

            };

            listaniveles1.push(fila);
        }
        this.setState({ listaniveles: listaniveles1 });
        $(function () {
            $("#nivelEstumod").val(registro.ciclo);
            $("#numdepositomod").val(registro.num_deposito);
            $("#fechadepositomod").val(registro.fecha_deposito);
            $("#cantidadPagomod").val(registro.valor_deposito);
            $("#idTipoTransaccionmod").val(registro.tipo_deposito);
            $("#cuentadebitotransferenciamod").val(registro.cuenta_debito_transferencia);
            $("#nomdueniocuentadebitotransferenciamod").val(registro.nom_dueno_cuenta_transf);
            $("#bancobeneficariomod").val(registro.nombre_banco_beneficiario);
            $("#numbancobeneficariomod").val(registro.cuenta_beneficiaria);
            $("#detallemod").val(registro.detalle);

        });
        this.setState({ nivelEstumod: registro.ciclo })
        this.setState({ numdepositomod: registro.num_deposito })
        this.setState({ fechadepositomod: registro.fecha_deposito })
        this.setState({ cantidadPagomod: registro.valor_deposito })
        this.setState({ idTipoTransaccionmod: registro.tipo_deposito })
        this.setState({ cuentadebitotransferenciamod: registro.cuenta_debito_transferencia })
        this.setState({ nomdueniocuentadebitotransferenciamod: registro.nom_dueno_cuenta_transf })
        this.setState({ bancobeneficariomod: registro.nombre_banco_beneficiario })
        this.setState({ numbancobeneficariomod: registro.cuenta_beneficiaria })
        this.setState({ id_pagos_matriculasmod: registro.id_pago_abono_matricula_periodo })
        this.setState({ detallemod: registro.detalle })

        if (registro.estado_abono_matricula == 2) {

            this.setState({ disabledpmab: this.state.disabledpmabv })
        }
        else {
            this.setState({ disabledpmab: !this.state.disabledpmabv })
        }


        if (registro.tipo_deposito == 3) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: this.state.disabledformpagmod });

        }

        if (registro.tipo_deposito == 2) {
            this.setState({ disabledctmod: !this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });

        }

        if (registro.tipo_deposito == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });

        }
        if ((registro.estado_abono_matricula == 5) || (registro.estado_abono_matricula == 2)) {

            this.setState({ disabledtransf: this.state.disabledtransfvicerr });
        }
        else {
            this.setState({ disabledtransf: !this.state.disabledtransfvicerr });
        }


        this.setState({ modalRegistroPagoMatricula: true, form: registro });
    }
    ocultarModalRegistroPagoMatricula = () => {
        this.setState({ modalRegistroPagoMatricula: false });
    }
    //metodo de validar deposito de la abono de matricula
    aprobarrechazarComprobantesMatriculas = (datos, estad) => {

        var estadoaux = "";
        if (estad == 5) {
            estadoaux = "aprobar"
        }
        else {
            estadoaux = "rechazar"
        }
        var combmsmalert = "¿Está seguro que desea " + estadoaux + " el pago?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(modificarEstadoAbonoagoMatricula, {
                            idPagoAbonoMatricula: datos.id_pago_abono_matricula_periodo,
                            estadoPagoAbonoMatricula: estad,
                            detallenoved: this.state.detallemod,
                            numdepositomof: this.state.numdepositomod,
                            nivelEstumodf: this.state.nivelEstumod,
                            idTipoTransaccionmodf: this.state.idTipoTransaccionmod,
                            fechadepositomodf: this.state.fechadepositomod,
                            cantidadPagomodf: this.state.cantidadPagomod,
                            ctadebitotransfmodf: this.state.cuentadebitotransferenciamod,
                            nomduenioctadebtransfmodf: this.state.nomdueniocuentadebitotransferenciamod,
                            bancobeneficariomodf: this.state.bancobeneficariomod,
                            numbancobenefmodf: this.state.numbancobeneficariomod,
                        })
                        .then(response => {

                            if (response.data.success === true) {

                                swal({
                                    text: "Datos Modificados Correctamente",
                                    icon: "success",
                                    button: "Aceptar",

                                });

                                this.setState({ modalRegistroPagoMatricula: false });
                                axios
                                    .post(listarmostarpagomatriculaPost, {//guarda para enviar los atributos y envia

                                        idCarrAsig: this.state.codigoCarrE,
                                        numCuotaColeg: this.state.numeroCuota,
                                        Periodoe: this.state.idPeriodo,
                                        cedEstudiantee: this.state.cedulaEstudianteSelect,

                                    })
                                    .then(response => {
                                        const listAsignaturasCarrera = response.data.data;
                                        this.setState({ listAsignaturasCarrera });
                                    })

                                window.location.reload(50000);
                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalRegistroPagoMatricula: false });
                            }
                        })
                        .catch(error => {

                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    componentDidMount() {
        //lista de cuotas
        document.addEventListener("keydown", this.escFunction, true);

        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;

                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })


    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;//variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }

    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }
    seleccionarEstudianteuno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var cedulaest = "";
        var nombrescompletos = "";
        $(".afiliado:checked").each(function () {

            cedulaest = $(this).parent().parent().find('td').eq(0).html();
            nombrescompletos = $(this).parent().parent().find('td').eq(1).html() + " " + $(this).parent().parent().find('td').eq(2).html() + " " + $(this).parent().parent().find('td').eq(3).html() + " " + $(this).parent().parent().find('td').eq(4).html();
        });
        this.setState({ nombresEstudiante: nombrescompletos });
        this.setState({ cedulaEstudianteSelect: cedulaest });
        axios
            .post(inscribirUrlCarreraEst, {//guarda para enviar los atributos y envia
                cedEstudiantee: cedulaest,
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });

            })

        //Muestra  el pago de matricula
        if (this.state.cedulaEstudianteSelect != "") {

            axios
                .post(listarmostarpagomatriculaPost, {//guarda para enviar los atributos y envia

                    idCarrAsig: this.state.codigoCarrE,
                    numCuotaColeg: this.state.numeroCuota,
                    Periodoe: this.state.idPeriodo,
                    cedEstudiantee: this.state.cedulaEstudianteSelect,

                })
                .then(response => {

                    const listAsignaturasCarrera = response.data.data;
                    this.setState({ listAsignaturasCarrera });


                })

        } else {
            swal({
                text: "No se ha seleccionado un Estudiante",
                icon: "error",
                button: "Aceptar",

            });
        }
    }

    seleccionarEstudiante = (registro) => {
        //cedula
        var idEstudianteI = registro.id_estudiante
        this.setState({ codigoEstudiante: idEstudianteI });

        axios
            .post(inscribirUrlCarreraEst, {//guarda para enviar los atributos y envia
                idestudiantese: this.state.codigoEstudiante,
            })
            .then(response => {
                const codigocarrest = response.data.data;
                const descripcarrest = response.data.message;
                const nivelcarrest = response.data.numnivel;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
            })
    }

    //BANCO BENEFICIARIO
    handleChangeCuentaBeneficiarioMod = async (e) => {
        //e.preventDefault();
        //colocar la cuenta del banco seleccionado
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.bancobeneficariomod == 1) {
            $(function () {
                $("#numbancobeneficariomod").val("7731051");
            });
            this.setState({ numbancobeneficariomod: "7731051" });
        }
        else {
            if (this.state.bancobeneficariomod == 2) {
                $(function () {
                    $("#numbancobeneficariomod").val("3257594104");
                });
                this.setState({ numbancobeneficariomod: "3257594104" });
            }
            else {
                if (this.state.bancobeneficariomod == 3) {
                    $(function () {
                        $("#numbancobeneficariomod").val("2100271381");
                    });
                    this.setState({ numbancobeneficariomod: "2100271381" });
                }
                else {
                    $(function () {
                        $("#numbancobeneficariomod").val("06073611");
                    });
                    this.setState({ numbancobeneficariomod: "06073611" });
                }

            }
        }

    }

    //Visualiza los estudiantes que abona el pago de matrícula en el período seleccionado
    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });

        //listar los estudiantes que pagaron su matricula  en el periodo actual
        axios.post(inscribirUrlListarestud,
            {//guarda para enviar los atributos y envia
                idPeriodo: this.state.idPeriodo,
            }
        )
            .then(respuesta => {
                //inicializar las
                listaauxiliar = respuesta.data.data;
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })

        let listaniveles1 = []
        for (let i = 1; i <= 5; i++) {

            let fila = {
                codnivel: i,
                valor: i
            };
            listaniveles1.push(fila);
        }
        this.setState({ listaniveles: listaniveles1 });
    }

    render() {

        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Pagos de Matrículas </b>
                    </div>
                    <div className="card-body">

                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                                <div className="mb-3">

                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>

                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >

                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td >{consola.numero_identificacion_estudiante}

                                                                </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>

                                                                    {(() => {

                                                                        switch (consola.estadoabonos) {

                                                                            case 1: return <h6><b> <span class="mensajealerta"> Procesar</span> </b></h6>;

                                                                            default: return ""
                                                                        }
                                                                    })()}
                                                                </td>
                                                                <td>
                                                                    <input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" />
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label><br />
                                    <label className="form-label">{this.state.descripCarrE}</label>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">


                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        DETALLE DEL PAGO DE MATRÍCULA
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>

                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Forma de Pago</th>
                                                        <th scope="col">Fecha del Deposito</th>
                                                        <th scope="col">Valor</th>
                                                        <th scope="col">Fecha de Registro</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Detalle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listAsignaturasCarrera.map(consola => (
                                                        <tr key={consola.id_pago_abono_matricula_periodo}>
                                                            <td class="ID">{consola.id_pago_abono_matricula_periodo}</td>
                                                            <td class="formadepago" >

                                                                {(() => {
                                                                    //Paralelo
                                                                    switch (consola.tipo_deposito) {

                                                                        case 1: return "DEPOSITO";
                                                                        case 2: return "TRANSFERENCIA";
                                                                        case 3: return "EFECTIVO";

                                                                        default: return ""
                                                                    }
                                                                })()}


                                                            </td>

                                                            <td class="fecha" >{consola.fecha_deposito}</td>
                                                            <td class="valor" >{consola.valor_deposito}</td>
                                                            <td class="fecha  registro" >{consola.fecha_registro_comprobante}</td>
                                                            <td class="estado">
                                                                {(() => {
                                                                    //Paralelo
                                                                    switch (consola.estado_abono_matricula) {

                                                                        case 1: return "Enviado";
                                                                        case 2: return "Aprobado";
                                                                        case 3: return "Rechazado";
                                                                        case 5: return "Aprobado";
                                                                        case 6: return "Rechazado";
                                                                        default: return ""
                                                                    }
                                                                })()}



                                                            </td>

                                                            <td class="detalle">

                                                                {(() => {
                                                                    if (consola.tipo_deposito == 2) {
                                                                        return <button onClick={() => this.mostrarModalRegistroPagoMatricula(consola)} > <i class="fas fa-clipboard-list"></i></button>

                                                                    }
                                                                    else

                                                                        return <button onClick={() => this.mostrarModalRegistroPagoMatricula(consola)} disabled="true"> <i class="fas fa-clipboard-list"></i></button>


                                                                })()}

                                                            </td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalRegistroPagoMatricula}>
                                    <ModalHeader>
                                        <h5>{this.state.nombresEstudiante}</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>

                                            <input name="id_pagos_matriculasmod" id="id_pagos_matriculasmod" type="hidden" value={this.state.form.id_pago_abono_matricula_periodo}></input>

                                            <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                height='100%' ></input>

                                            <h5>Detalle</h5>

                                            <div className="mb-3 centrar">

                                                <label className="form-label">Seleccione el nivel a cursar</label>
                                                <select className="form-select" id="nivelEstumod" name="nivelEstumod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                    <option value="undefined">Seleccione </option>

                                                    {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}

                                                </select>


                                                <label className="form-label">Seleccionar si realizó un Deposito o Transferencia</label>
                                                <select className="form-select" id="idTipoTransaccionmod" name="idTipoTransaccionmod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" required>
                                                    <option value="">Seleccione</option>
                                                    <option value="1">Deposito</option>
                                                    <option value="2">Transferencia</option>
                                                    <option value="3">Efectivo</option>

                                                </select>


                                                <label className="form-label">Número de deposito(número de papeleta o referencia)</label><br />
                                                <input type="text" id="numdepositomod" name="numdepositomod" onChange={this.handleChange} className="form-control" placeholder="" required />


                                                <label className="form-label">Fecha deposito</label><br />
                                                <input type="date" id="fechadepositomod" name="fechadepositomod" onChange={this.handleChange} className="form-control" placeholder="" required />


                                                <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                                <input type="text" id="cantidadPagomod" name="cantidadPagomod" onChange={this.handleChange} className="form-control" placeholder="0.00" required />

                                                <label className="form-label">#Cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="cuentadebitotransferenciamod" name="cuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" required />

                                                <label className="form-label">Nombre del Dueño de la cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="nomdueniocuentadebitotransferenciamod" name="nomdueniocuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" required />

                                                <label className="form-label">Banco Beneficiario</label><br />

                                                <select className="form-select" id="bancobeneficariomod" name="bancobeneficariomod" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioMod} aria-label="Default select example"  >
                                                    <option value="1">PACIFICO</option>
                                                    <option value="2">PICHINCHA-3257594104 </option>
                                                    <option value="3">PICHINCHA-2100271381 </option>
                                                    <option value="4">AUSTRO</option>
                                                </select>

                                                <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                                <input type="text" id="numbancobeneficariomod" name="numbancobeneficariomod" onChange={this.handleChange} className="form-control" placeholder="" required />

                                                <label className="form-label">Detalle</label><br />
                                                <input className="form-control" type="text" id="detallemod" name="detallemod" onChange={this.handleChange} required autocomplete="off" />

                                            </div>

                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" disabled={(this.state.disabledtransf) ? "disabled" : ""} onClick={() => this.aprobarrechazarComprobantesMatriculas(this.state.form, 5)} > Aprobar</button>
                                        <button class="btn btn-outline-primary" disabled={(this.state.disabledtransf) ? "disabled" : ""} onClick={() => this.aprobarrechazarComprobantesMatriculas(this.state.form, 6)} > Rechazar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalRegistroPagoMatricula}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />

                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>

            </div>

        )
    }

}
