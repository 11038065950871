import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

const urlListPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCarrera = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera = ApiUrl + "numnivelcarrperiodcostomatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const ingresarValoresMatriPeriodCarrUrl = ApiUrl + "ingresarmatriculasperiodcarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const carrerasUrl = inscribirUrlListarCarrera;
let listaauxiliar = [];
const itemsPerPage = 10;
export default class horasPracticasPreprof extends Component {

    constructor(props) {
        super(props)

        this.state = {
            carrera: [],
            estado: '',

            //nuevas variables
            numnivelescarr: '',
            listaperiodos: [],
            listaniveles: [],
        }
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
    }


    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ listaniveles: [] });

        //devuelve los niveles de  una carrera y los datos registrados por cada nivel
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.idPeriodoSelect,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
    }

    registrarValoresCostoPeriodo = async (e) => {
        // e.preventDefault(); 
        //Lista de valores
        let materiasselect = [];
        document.querySelectorAll('#tablaCosto tbody tr').forEach(function (e) {
            var idnivel = e.querySelector('.codigo').innerText
            //Costo de matrícula
            var valorCM = $("#" + idnivel + "matriculaord").val();
            if ((valorCM == 0) || (valorCM == "")) {
                valorCM = 0;
            }
            //Costo de matrícula extraordinaria
            var valorCMEXT = $("#" + idnivel + "matriculaextra").val();
            if ((valorCMEXT == 0) || (valorCMEXT == "")) {
                valorCMEXT = 0;
            }
            //Fecha de la matrícula extraordinaria

            var fechMEXT = $("#" + idnivel + "fechamatriextr").val();
            if ((fechMEXT == null) || (fechMEXT == "")) {
                fechMEXT = "";
            }

            //Costo del derecho de prácticas laborales
            var valorCDPRACTLAB = $("#" + idnivel + "practlab").val();
            if ((valorCDPRACTLAB == 0) || (valorCDPRACTLAB == "")) {
                valorCDPRACTLAB = 0;
            }
            //Costo del derecho de prácticas de servicio comunitario
            var valorCDPRACTSERVCOMUN = $("#" + idnivel + "practservicomun").val();
            if ((valorCDPRACTSERVCOMUN == 0) || (valorCDPRACTSERVCOMUN == "")) {
                valorCDPRACTSERVCOMUN = 0;
            }
            //Crea la lista
            let fila = {
                idnivelf: idnivel,
                costomatri: valorCM,
                costomatriext: valorCMEXT,
                fechamatriextr: fechMEXT,
                costoderechplab: valorCDPRACTLAB,
                costoderechpservc: valorCDPRACTSERVCOMUN,
            };
            materiasselect.push(fila);
        });
        axios
            .post(ingresarValoresMatriPeriodCarrUrl, {
                periodoactualef: this.state.idPeriodoSelect,
                listPeriodPrac: materiasselect,
                idCarreraf: this.state.idCarrera,
            })
            .then(response => {
                const list = response.data.data;
                if (response.data.success === true) {
                
                   swal({
                        text: "Datos ingresados exitosamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    //Actualiza el reporte
                    axios
                        .post(devuelNumNivelesCarrera, {
                            idCarreraf: this.state.idCarrera,
                            periodoIdf: this.state.idPeriodoSelect,
                        })
                        .then(response => {
                            const listaniveles1 = response.data.data;
                            this.setState({ listaniveles: listaniveles1 });
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                }
                else {
                    swal({
                        text: "Datos no  registrados",
                        icon: "error",
                        button: "Aceptar",
                    });

                }
            })
            .catch(error => {
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                swal({
                    text: "Datos no  registrados",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Costos-Período</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-11 col-lg-6 col-xl-6 col-sm-6  centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined">Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-1 col-sm-6 col-lg-6 col-xl-6 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-11 col-sm-6 col-lg-6 col-xl-6 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" id="idCarrera" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined">Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-1 col-sm-6  col-lg-6 col-xl-6 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-6 col-lg-6 col-xl-6 col-sm-6  centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 centrar" >
                            </div>
                        </div>


                        <div className="row ">

                            <div class="table-responsive">
                                <table className="table table-bordered" id="tablaCosto" name="tablaCosto" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>Nivel</th>
                                            <th>Matrículas Ordinarias</th>
                                            <th>Matrículas Extraordinarias</th>
                                            <th>Fecha de matrículas extraordinarias</th>
                                            <th>Prácticas Laborales</th>
                                            <th>Servicio Comunitario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listaniveles.map(consola => (
                                            <tr key={consola.codnivel}>
                                                <td class="codigo" >{consola.codnivel}</td>

                                                <td class="matriculaord" > <input type="text" name={consola.codnivel + "matriculaord"} id={consola.codnivel + "matriculaord"} className="form-control" placeholder={consola.valor_matriord} />
                                                </td>

                                                {
                                                    (() => {
                                                        $(function () {
                                                            $("#" + consola.codnivel + "matriculaord").val(consola.valor_matriord);
                                                        });
                                                    })()
                                                }
                                                <td class="matriculaextra" > <input type="text" name={consola.codnivel + "matriculaextra"} id={consola.codnivel + "matriculaextra"} className="form-control" placeholder={consola.valor_matriext} />
                                                </td>

                                                {
                                                    (() => {
                                                        $(function () {
                                                            $("#" + consola.codnivel + "matriculaextra").val(consola.valor_matriext);
                                                        });
                                                    })()
                                                }
                                                <td class="fechamatriextr" > <input type="date" name={consola.codnivel + "fechamatriextr"} id={consola.codnivel + "fechamatriextr"} className="form-control" placeholder={consola.fechamatriextr} />
                                                </td>

                                                {
                                                    (() => {
                                                        $(function () {
                                                            $("#" + consola.codnivel + "fechamatriextr").val(consola.fechamatriextr);
                                                        });
                                                    })()
                                                }
                                                <td class="practlab" > <input type="text" name={consola.codnivel + "practlab"} id={consola.codnivel + "practlab"} className="form-control" placeholder={consola.valor_practlab} />
                                                </td>

                                                {
                                                    (() => {
                                                        $(function () {
                                                            $("#" + consola.codnivel + "practlab").val(consola.valor_practlab);
                                                        });
                                                    })()
                                                }
                                                <td class="practservicomun" > <input type="text" name={consola.codnivel + "practservicomun"} id={consola.codnivel + "practservicomun"} className="form-control" placeholder={consola.valor_practservicomun} />
                                                </td>

                                                {
                                                    (() => {
                                                        $(function () {
                                                            $("#" + consola.codnivel + "practservicomun").val(consola.valor_practservicomun);
                                                        });
                                                    })()
                                                }

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>



                        </div>

                        <div className="text-center">
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                style={{ margin: "5px", width: "170px" }}
                                onClick={e =>
                                    this.registrarValoresCostoPeriodo(e)
                                }>
                                <b>Aceptar</b>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

