import axios from 'axios';
import React, { Component } from 'react'
import swal from 'sweetalert';
//import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Alert } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const urlMatrizMatriculados = ApiUrl + "listarmatrizmatriculados"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const urlListarCarreras = ApiUrl + "listarcarreras"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;
const baseUrlPeriodos = ApiUrl + "listperiodostodos"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" ;

export default class MatrizMatriculados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estudiantes: [],
            idCarrera: "",
            carrera: [],
            periodos: [],
            idPeriodo: "",

        };

        this.handleChange = this.handleChange.bind(this);

    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

    }
    handleChangeEstudCarrera = async (e) => {
      
        await
            axios
                .post(urlMatrizMatriculados, {
                    idcarrerae: this.state.idCarrera,
                    idperiodoe: this.state.idPeriodo,
                })
                .then(response => {
                    if (response.data.success == true) {
                        const estudiantes = response.data.data;
                        this.setState({ estudiantes });

                    } else {
                        if (response.data.message == "vacio") {
                            const estudiantes = [];
                            this.setState({ estudiantes });
                            this.setState({ error: "No existe datos para mostrar" });
                            swal({
                                text: "No existe datos para mostrar",
                                icon: "info",
                                button: "Aceptar",
                            });
                        }
                        else {
                            const estudiantes = [];
                            this.setState({ estudiantes });
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        }

                    }
                })
                .catch(error => {
                    const estudiantes = [];
                    this.setState({ estudiantes });
                    this.setState({ error: "No existe conexión con el servidor" });
                    swal({
                        text: "No existe conexión con el servidor",
                        icon: "error",
                        button: "Aceptar",
                    });
                });
    }
    handleChangePeriodo = async (e) => {

        await this.setState({ [e.target.name]: e.target.value });
        const idPeriodo = this.state.idPeriodo;
        this.setState({ idPeriodo });
    }
    componentDidMount = async (e) => {
        //listar todos los periodos
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })

        //Listar las carreras
        axios.get(urlListarCarreras)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })


    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">

                <div className="card mb-4">
                    <div className="card-header">



                    </div>
                    <div className="card-body">

                        <div className="table-responsive">

                            <div class="row">
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                        <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                            <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                            {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            <label className="form-label">Seleccione una carrera</label>
                                            <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                                {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeEstudCarrera} /><br />

                                        <ReactHTMLTableToExcel
                                            id="botonExportarExcel"
                                            className="btn btn-success"
                                            table="tablacientificos"
                                            filename="matriculasexcel"
                                            sheet={this.state.idCarrera}
                                            buttonText="Exportar a Excel"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                </div>
                            </div>
                            <div class="table-responsive">

                                <table className="table table-bordered" id="tablacientificos" width="100%">
                                    <thead>
                                        <tr>

                                            <th>tipoDocumentoId</th>
                                            <th>numeroIdentificacion</th>
                                            <th>primerApellido</th>
                                            <th>segundoApellido</th>
                                            <th>primerNombre</th>
                                            <th>segundoNombre</th>
                                            <th>sexoId</th>
                                            <th>generoId</th>
                                            <th>estadocivilId</th>

                                            <th>etniaId</th>
                                            <th>pueblonacionalidadId</th>
                                            <th>tipoSangre</th>
                                            <th>discapacidad</th>
                                            <th>porcentajeDiscapacidad</th>
                                            <th>numCarnetConadis</th>
                                            <th>tipoDiscapacidad</th>
                                            <th>fechaNacimiento</th>
                                            <th>paisNacionalidadId</th>
                                            <th>provinciaNacimientoId</th>

                                            <th>cantonNacimientoId</th>
                                            <th>paisResidenciaId</th>
                                            <th>provinciaResidenciaId</th>
                                            <th>cantonResidenciaId</th>
                                            <th>tipoColedioId</th>
                                            <th>modalidadCarrera</th>
                                            <th>jornadaCarrera</th>
                                            <th>fechaInicioCarrera</th>
                                            <th>fechaMatricula</th>
                                            <th>tipoMatriculaId</th>

                                            <th>nivelAcademicoQueCursa</th>
                                            <th>duracionPeriodoAcademico</th>
                                            <th>haRepetidoAlMenosUnaMateria</th>
                                            <th>paraleloId</th>
                                            <th>haPerdidoLaGratuidad</th>
                                            <th>recibePensionDiferenciada</th>
                                            <th>estudianteOcupacionId</th>
                                            <th>ingresosestudianteId</th>
                                            <th>bonodesarrolloId</th>
                                            <th>haRealizadoPracticasPreprofesionales</th>

                                            <th>nroHorasPracticasPreprofesionalesPorPeriodo</th>
                                            <th>entornoInstitucionalPra</th>
                                            <th>sectorEconómico</th>
                                            <th>tipoBecaId</th>
                                            <th>primeraRazonBecaId</th>
                                            <th>segundaRazonBecaId</th>
                                            <th>terceraRazonBecaId</th>
                                            <th>cuartaRazonBecaId</th>
                                            <th>quintaRazonBecaId</th>
                                            <th>sextaRazonBecaId</th>

                                            <th>montoBeca</th>
                                            <th>porcientoBecaCoberturaArancel</th>
                                            <th>porcientoBecaCoberturaManuntencion</th>
                                            <th>financiamientoBeca</th>
                                            <th>montoAyudaEconomica</th>
                                            <th>montoCréditoEducativo</th>
                                            <th>participaEnProyectVin</th>
                                            <th>tipoAlcanceProyecto</th>
                                            <th>correoElectronico</th>
                                            <th>numeroCelular</th>

                                            <th>nivelFormacionPadre</th>
                                            <th>nivelFormacionMadre</th>
                                            <th>IngresoTotalHogar</th>
                                            <th>cantidadMiembrosHogar</th>



                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.state.estudiantes.map(consola => (
                                            <tr key={consola.id_estudiante}>
                                                {/*Bloque1 */}
                                                <td>{consola.tipoDocumentoId}</td>
                                                <td>{consola.numeroIdentificacion}</td>

                                                <td>{consola.primerApellido}</td>

                                                {
                                                    (() => {
                                                        if (consola.segundoApellido === "Ninguno" || consola.segundoApellido == "")
                                                            return <td>NA</td>
                                                        else
                                                            return <td>{consola.segundoApellido}</td>

                                                    })()
                                                }

                                                <td>{consola.primerNombre}</td>

                                                {
                                                    (() => {
                                                        if (consola.segundoNombre === "Ninguno" || consola.segundoNombre == "")
                                                            return <td>NA</td>
                                                        else
                                                            return <td>{consola.segundoNombre}</td>

                                                    })()
                                                }
                                                <td>{consola.sexoId}</td>
                                                <td>{consola.generoId}</td>
                                                <td>{consola.estadocivilId}</td>
                                                {/*Bloque2 */}
                                                <td>{consola.etniaId}</td>
                                                {
                                                    (() => {
                                                        if (consola.etniaId == 1)
                                                            return <td>{consola.pueblonacionalidadId}</td>
                                                        else
                                                            return <td>34</td>


                                                    })()
                                                }
                                                <td>{consola.tipoSangre}</td>
                                                <td>{consola.discapacidad}</td>

                                                {
                                                    (() => {
                                                        if (consola.discapacidad == 1)
                                                            return <td>{consola.porcentajeDiscapacidad}</td>
                                                        else
                                                            return <td>NA</td>


                                                    })()
                                                }



                                                {
                                                    (() => {
                                                        if (consola.discapacidad == 1)
                                                            return <td>{consola.numCarnetConadis}</td>
                                                        else
                                                            return <td>NA</td>


                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if (consola.discapacidad == 1)
                                                            return <td>{consola.tipoDiscapacidad}</td>
                                                        else
                                                            return <td>7</td>


                                                    })()

                                                }



                                                <td>{consola.fechaNacimiento}</td>
                                                <td>{consola.paisNacionalidadId}</td>

                                                {
                                                    (() => {
                                                        if (consola.paisNacionalidadId == 56)
                                                            return <td>{consola.provinciaNacimientoId}</td>
                                                        else
                                                            return <td>NA</td>

                                                    })()
                                                }
                                                {/*Bloque3 */}
                                                {
                                                    (() => {
                                                        if (consola.paisNacionalidadId == 56)
                                                            return <td>{consola.cantonNacimientoId}</td>
                                                        else
                                                            return <td>NA</td>

                                                    })()
                                                }


                                                <td>{consola.paisResidenciaId}</td>

                                                {
                                                    (() => {
                                                        if (consola.paisResidenciaId == 56)
                                                            return <td>{consola.provinciaResidenciaId}</td>
                                                        else
                                                            return <td>NA</td>

                                                    })()
                                                }

                                                {
                                                    (() => {
                                                        if (consola.paisResidenciaId == 56)
                                                            return <td>{consola.cantonResidenciaId}</td>
                                                        else
                                                            return <td>NA</td>

                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if (consola.tipoColegioId != null)
                                                            return <td>{consola.tipoColegioId}</td>
                                                        else
                                                            return <td>6</td>

                                                    })()
                                                }


                                                <td>{consola.modalidadCarrera}</td>
                                                <td>{consola.jornadaCarrera}</td>
                                                <td>{consola.fechaInicioCarrera}</td>
                                                <td>{consola.fechaMatricula}</td>
                                                <td>{consola.tipoMatriculaId}</td>
                                                {/*Bloque4 */}
                                                <td>{consola.nivelAcademicoQueCursa}</td>
                                                <td>{consola.duracionPeriodoAcademico}</td>
                                                <td>{consola.haRepetidoAlMenosUnaMateria}</td>
                                                <td>{consola.paraleloId}</td>
                                                <td>{consola.haPerdidoLaGratuidad}</td>
                                                <td>{consola.recibePensionDiferenciada}</td>
                                                <td>{consola.estudianteocupacionId}</td>

                                                {
                                                    (() => {
                                                        if (consola.estudianteocupacionId == 2)
                                                            return <td>{consola.ingresosestudianteId}</td>
                                                        else
                                                            return <td>4</td>

                                                    })()
                                                }


                                                <td>{consola.bonodesarrolloId}</td>
                                                <td>{consola.haRealizadoPracticasPreprofesionales}</td>
                                                {/*Bloque5 */}
                                                {
                                                    (() => {
                                                        if (consola.haRealizadoPracticasPreprofesionales == 1)
                                                            return <td>{consola.nroHorasPracticasPreprofesionalesPorPeriodo}</td>
                                                        else
                                                            return <td>NA</td>

                                                    })()
                                                }

                                                {
                                                    (() => {
                                                        if (consola.haRealizadoPracticasPreprofesionales == 1)
                                                            return <td>{consola.entornoInstitucionalPracticasProfesionales}</td>
                                                        else
                                                            return <td>5</td>

                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if (consola.haRealizadoPracticasPreprofesionales == 1)
                                                            return <td>{consola.sectorEconomicoPracticaProfesional}</td>
                                                        else
                                                            return <td>22</td>

                                                    })()
                                                }

                                                <td>{consola.tipoBecaId}</td>
                                                <td>{consola.primeraRazonBecaId}</td>
                                                <td>{consola.segundaRazonBecaId}</td>
                                                <td>{consola.terceraRazonBecaId}</td>
                                                <td>{consola.cuartaRazonBecaId}</td>
                                                <td>{consola.quintaRazonBecaId}</td>
                                                <td>{consola.sextaRazonBecaId}</td>
                                                {/*Bloque6 */}
                                                <td>{consola.montoBeca}</td>
                                                <td>{consola.porcientoBecaCoberturaArancel}</td>
                                                <td>{consola.porcientoBecaCoberturaManuntencion}</td>
                                                <td>{consola.financiamientoBeca}</td>
                                                <td>{consola.montoAyudaEconomica}</td>
                                                <td>{consola.montoCreditoEducativo}</td>
                                                <td>{consola.participaEnProyectoVinculacionSociedad}</td>
                                                <td>{consola.tipoAlcanceProyectoVinculacionId}</td>
                                                <td>{consola.correoElectronico}</td>
                                                <td>{consola.numeroCelular}</td>
                                                {/*Bloque7 */}

                                                <td>{consola.nivelFormacionPadre}</td>
                                                <td>{consola.nivelFormacionMadre}</td>
                                                <td>{consola.ingresoTotalHogar}</td>
                                                <td>{consola.cantidadMiembrosHogar}</td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
