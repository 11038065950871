import React from 'react'
import RoutesApp from './routes/RoutesApp'

export const IstmasApp = () => {
    return (
        <div>
            <RoutesApp />
        </div>
    ) 
}
