import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';

const cookie = new Cookies();
const baseUrl = ApiUrl + "listarestumatriculacancelada" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
//url de detalle de pagos
const rutaimagen = ApiUrl + "mostarrutaimagencomprobantecolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlCarreraEst = ApiUrl + "carreraestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const listarmostarpagomatriculaPost = ApiUrl + "mostarpagomatriculaPost" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoAbonosSinAprobarEst = ApiUrl + "estadabonossinaprobar" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlSumaTotalAbonMat = ApiUrl + "sumabonosaprobmatricu" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class ListarEstuCanceladoMatricula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estudiantes: [],
            listaestudiantes: [],
            listaniveles: [],
            listabonosmatriestud: [],
            periodos: [],
            cantidadestud: 0,
            textBuscar: "",
            EstudianteBackup: [],
            form: {
                id_estudiante: '',
                primer_nombre_estudiante: ' ',
                segundo_nombre_estudiante: ' ',
                nombreCompeltos: ' ',
            },
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
        }
    }
    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //Listar los estudiantes que han cancelado completamente la matrícula
        await axios
            .post(baseUrl, {
                idPeriodo: this.state.idPeriodo,
            })
            .then(response => {
                this.setState({ listaestudiantes: response.data.data });
                listaauxiliar = response.data.data;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = response.data.data;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })
    }

    //Listar estudiantes matriculados 
    componentDidMount = async (e) => {
        document.addEventListener("keydown", this.escFunction, true);//activa evento esc
        //listar todos los periodos
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listaestudiantes;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    //SELECCIONAR
    seleccionarEstudianteuno = async (e) => {

        await this.setState({ [e.target.name]: e.target.value })
        var cedulaest = "";
        var nombrescompletos = "";
        $(".afiliado:checked").each(function () {
            cedulaest = $(this).parent().parent().find('td').eq(0).html();
            nombrescompletos = $(this).parent().parent().find('td').eq(1).html() + " " + $(this).parent().parent().find('td').eq(2).html() + " " + $(this).parent().parent().find('td').eq(3).html() + " " + $(this).parent().parent().find('td').eq(4).html();
        });
        this.setState({ nombresEstudiante: nombrescompletos });
        this.setState({ cedulaEstudianteSelect: cedulaest });
        //informacion carrera del estudiante
        axios
            .post(inscribirUrlCarreraEst, {
                cedEstudiantee: cedulaest,
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });

            })
        //abonos de matrícula
        if (this.state.cedulaEstudianteSelect != "") {
            axios
                .post(listarmostarpagomatriculaPost, {
                    idCarrAsig: this.state.codigoCarrE,
                    numCuotaColeg: this.state.numeroCuota,
                    Periodoe: this.state.idPeriodo,
                    cedEstudiantee: this.state.cedulaEstudianteSelect,
                })
                .then(response => {

                    const listabonosmatriestud = response.data.data;
                    this.setState({ listabonosmatriestud });
                })
        } else {
            swal({
                text: "No se ha seleccionado un Estudiante",
                icon: "error",
                button: "Aceptar",

            });
        }
        //habilita el botón de pago de matricula
        axios
            .post(urlEstadoAbonosSinAprobarEst, {
                Periodoe: this.state.idPeriodo,
                cedEstudiantee: this.state.cedulaEstudianteSelect,
            })
            .then(response => {
                const estadopendaprobados = response.data.data;
                if (estadopendaprobados == 0) {
                    this.setState({ disabledpagotmatf: !this.state.disabledpagomatric });
                }
                else {
                    this.setState({ disabledpagotmatf: this.state.disabledpagomatric });
                }

            })
            .catch(error => {
            });

        //Consultar la suma de los abonos de matricula aprobada
        if (this.state.cedulaEstudianteSelect != "") {
            axios
                .post(urlSumaTotalAbonMat, {
                    Periodoe: this.state.idPeriodo,
                    cedEstudiantee: this.state.cedulaEstudianteSelect,
                })
                .then(response => {
                    const sumaTotalAbonosAprob = response.data.data;
                    this.setState({ sumaTotalAbonosAprob });
                })
        } else {
            swal({
                text: "No se ha seleccionado un Estudiante",
                icon: "error",
                button: "Aceptar",
            });
        }
    }
    //controles del modal de  un registro de abono de matricula
    mostrarModalRegistroPagoMatricula = (registro) => {
        axios.post(rutaimagen, {
            nombrecomprobante: "matriculas/" + registro.comprobante,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        //dibujar el  numero de  niveles
        let listaniveles1 = []
        for (let i = 1; i <= this.state.numnivelE; i++) {

            let fila = {
                codnivel: i,
                valor: i
            };
            listaniveles1.push(fila);
        }
        this.setState({ listaniveles: listaniveles1 });
        $(function () {
            $("#nivelEstumod").val(registro.ciclo);
            $("#numdepositomod").val(registro.num_deposito);
            $("#fechadepositomod").val(registro.fecha_deposito);
            $("#cantidadPagomod").val(registro.valor_deposito);
            $("#idTipoTransaccionmod").val(registro.tipo_deposito);
            $("#cuentadebitotransferenciamod").val(registro.cuenta_debito_transferencia);
            $("#nomdueniocuentadebitotransferenciamod").val(registro.nom_dueno_cuenta_transf);
            $("#bancobeneficariomod").val(registro.nombre_banco_beneficiario);
            $("#numbancobeneficariomod").val(registro.cuenta_beneficiaria);
            $("#detallemod").val(registro.detalle);
        });
        this.setState({ nivelEstumod: registro.ciclo })
        this.setState({ numdepositomod: registro.num_deposito })
        this.setState({ fechadepositomod: registro.fecha_deposito })
        this.setState({ cantidadPagomod: registro.valor_deposito })
        this.setState({ idTipoTransaccionmod: registro.tipo_deposito })
        this.setState({ cuentadebitotransferenciamod: registro.cuenta_debito_transferencia })
        this.setState({ nomdueniocuentadebitotransferenciamod: registro.nom_dueno_cuenta_transf })
        this.setState({ bancobeneficariomod: registro.nombre_banco_beneficiario })
        this.setState({ numbancobeneficariomod: registro.cuenta_beneficiaria })
        this.setState({ id_pagos_matriculasmod: registro.id_pago_abono_matricula_periodo })
        this.setState({ detallemod: registro.detalle })

        if (((registro.tipo_deposito == 2) && (registro.estado_abono_matricula == 5)) || ((registro.tipo_deposito == 2) && (registro.estado_abono_matricula == 6))) {

            this.setState({ disabledtransf: !this.state.disabledtransfvicerr })
        }
        else {

            if (((registro.tipo_deposito == 2) && (registro.estado_abono_matricula == 1)) || (registro.estado_abono_matricula == 3)) {

                this.setState({ disabledtransf: this.state.disabledtransfvicerr })
            }
            else

                this.setState({ disabledtransf: !this.state.disabledtransfvicerr })
        }

        if (registro.tipo_deposito == 3) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: this.state.disabledformpagmod });
            this.setState({ disabledhabnumdepf: this.state.disabledhabnumdep });
        }

        if (registro.tipo_deposito == 2) {
            this.setState({ disabledctmod: !this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });
        }

        if (registro.tipo_deposito == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod });
            this.setState({ disabledformpagmodd: !this.state.disabledformpagmod });

        }
        //Deshabilita los botones

        if (registro.estado_abono_matricula == 2) {
            this.setState({ disabledtransf: true })
        }

        this.setState({ modalRegistroPagoMatricula: true, form: registro });
    }
    ocultarModalRegistroPagoMatricula = () => {
        this.setState({ modalRegistroPagoMatricula: false });
    }
    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de Estudiantes con Pagos de Matrículas Cancelados</b>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div class="row">
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                        <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                    <div className="mb-3">
                                        <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                        <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                            <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                            {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                </div>
                                <div className="col-12 col-sm-12 col-lg-1 col-xl-3 ">
                                </div>
                            </div>

                            <table className="table  text-left"  >
                                <thead>
                                    <tr>
                                        <th>Cedula</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Primer Nombre</th>
                                        <th>Segundo Nombre</th>
                                        <th>Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_pagos_matriculas}>
                                            <td>{consola.numero_identificacion_estudiante}</td>
                                            <td>{consola.primer_apellido_estudiante}</td>
                                            <td>{consola.segundo_apellido_estudiante}</td>
                                            <td>{consola.primer_nombre_estudiante}</td>
                                            <td>{consola.segundo_nombre_estudiante}</td>
                                            <td>
                                                {(() => {
                                                    //Paralelo
                                                    switch (consola.paralelo) {
                                                        case 1: return "A";
                                                        case 2: return "B";
                                                        case 3: return "C";
                                                        default: return ""
                                                    }
                                                })()}

                                            </td>
                                            <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" /> </td>

                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <b>
                                                Cantidad
                                            </b >
                                        </td>
                                        <td >
                                            <b>
                                                {this.state.cantidadestud}</b >

                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label><br />
                                    <label className="form-label">{this.state.descripCarrE}</label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        DETALLE DEL PAGO DE MATRÍCULA
                                    </div>

                                    <div className="card-body centrar " style={{ width: "100%" }}>

                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >

                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Forma de Pago</th>
                                                        <th scope="col">Fecha del Deposito</th>
                                                        <th scope="col">Valor</th>

                                                        <th scope="col">Fecha de Registro</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Novedad</th>
                                                        <th scope="col">Detalle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listabonosmatriestud.map(consola => (
                                                        <tr key={consola.id_pago_abono_matricula_periodo}>
                                                            <td class="ID">{consola.id_pago_abono_matricula_periodo}</td>
                                                            <td class="formadepago" >



                                                                {(() => {

                                                                    //Paralelo
                                                                    switch (consola.tipo_deposito) {

                                                                        case 1: return "DEPOSITO";
                                                                        case 2: return "TRANSFERENCIA";
                                                                        case 3: return "EFECTIVO";

                                                                        default: return ""
                                                                    }
                                                                })()}
                                                            </td>

                                                            <td class="fecha" >{consola.fecha_deposito}</td>
                                                            <td class="valor" >${consola.valor_deposito}</td>
                                                            <td class="fecha  registro" >{consola.fecha_registro_comprobante}</td>
                                                            <td class="estado">


                                                                {(() => {


                                                                    //Paralelo
                                                                    switch (consola.estado_abono_matricula) {

                                                                        case 1: return "Enviado";
                                                                        case 2: return "Aprobado";
                                                                        case 3: return "Rechazado";
                                                                        case 5: return <span class="mensajealerta"> Aprobado Vicerrectorado</span>;
                                                                        case 6: return <span class="mensajealerta"> Rechazado Vicerrectorado</span>;

                                                                        default: return ""
                                                                    }
                                                                })()}

                                                            </td>
                                                            <td class="fecha" >{consola.detalle}</td>

                                                            <td class="detalle">
                                                                <button onClick={() => this.mostrarModalRegistroPagoMatricula(consola)} > <i class="fas fa-clipboard-list"></i></button>


                                                            </td>
                                                        </tr>

                                                    ))}

                                                    <tr>
                                                        <td colspan="3">
                                                            <b>
                                                                SUMA
                                                            </b >
                                                        </td>
                                                        <td >
                                                            <b>
                                                                {this.state.sumaTotalAbonosAprob}
                                                            </b >

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" isOpen={this.state.modalRegistroPagoMatricula}>
                                    <ModalHeader>
                                        <h5>{this.state.nombresEstudiante}</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <input name="id_pagos_matriculasmod" id="id_pagos_matriculasmod" type="hidden" value={this.state.form.id_pago_abono_matricula_periodo}></input>
                                            <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                height='100%' ></input>

                                            <h5>Detalle</h5>

                                            <div className="mb-3 centrar">

                                                <label className="form-label">Seleccione el nivel a cursar</label>
                                                <select className="form-select" id="nivelEstumod" name="nivelEstumod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled="true" >
                                                    <option value="undefined">Seleccione </option>

                                                    {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}

                                                </select>


                                                <label className="form-label">Seleccionar si realizó un Deposito o Transferencia</label>
                                                <select className="form-select" id="idTipoTransaccionmod" name="idTipoTransaccionmod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" disabled="true" required>
                                                    <option value="">Seleccione</option>
                                                    <option value="1">Deposito</option>
                                                    <option value="2">Transferencia</option>
                                                    <option value="3">Efectivo</option>

                                                </select>


                                                <label className="form-label">Número de papeleta, referencia o número de comprobante</label><br />
                                                <input type="text" id="numdepositomod" name="numdepositomod" onChange={this.handleChange} className="form-control" placeholder="" disabled="true" required />

                                                <label className="form-label">Fecha deposito</label><br />
                                                <input type="date" id="fechadepositomod" name="fechadepositomod" onChange={this.handleChange} className="form-control" placeholder="" disabled="true" required />


                                                <label className="form-label">Ingresar la cantidad  de pago</label><br />
                                                <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPagomod" name="cantidadPagomod" onChange={this.handleChange} className="form-control" placeholder="0.00" disabled="true" required /></div>

                                                <label className="form-label">#Cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="cuentadebitotransferenciamod" name="cuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" disabled="true" required />

                                                <label className="form-label">Nombre del Dueño de la cuenta que se debito la transferencia</label><br />
                                                <input type="text" id="nomdueniocuentadebitotransferenciamod" name="nomdueniocuentadebitotransferenciamod" onChange={this.handleChange} className="form-control" placeholder="" disabled="true" required />

                                                <label className="form-label">Banco Beneficiario</label><br />

                                                <select className="form-select" id="bancobeneficariomod" name="bancobeneficariomod" value={this.state.value} onChange={this.handleChangeCuentaBeneficiarioMod} aria-label="Default select example" disabled="true" >
                                                    <option value="1">PACIFICO</option>
                                                    <option value="2">PICHINCHA-3257594104 </option>
                                                    <option value="3">PICHINCHA-2100271381 </option>
                                                </select>
                                                <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                                <input type="text" id="numbancobeneficariomod" name="numbancobeneficariomod" onChange={this.handleChange} className="form-control" placeholder="" disabled="true" required />
                                                <label className="form-label">Detalle</label><br />
                                                <input className="form-control" type="text" id="detallemod" name="detallemod" onChange={this.handleChange} autocomplete="off" disabled="true" required />
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalRegistroPagoMatricula}>Salir</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
