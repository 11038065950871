import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login


export default class SidebarVicerrectoradoAcad extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })

                // console.log(cookie.get("nombres"));
                // console.log(cookie.get("apellidos")); 
            })
    }

    render() {
        return (

            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">PERSONAL DE VICERRECTORADO ACADEMICO</div>
                            <Link to="/vicerrectoradoacad">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal </b>
                                </div>
                            </Link>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseMatriculas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                MATRICULAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseMatriculas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoacad/listcoordinaciones"><div className="nav-link movil" href="#">Lista Coordinaciones</div></Link>
                                    <Link to="/vicerrectoradoacad/cantidadmatri" ><div className="nav-link puntero movil" href="#">Cantidad de matriculados</div></Link>
                               
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseDocentes" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                                ASIGNATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDocentes" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoacad/AsignaturasEstCarreraCicloAcad">  <div className="nav-link movil" href="#">Asignaturas Carrera Ciclo</div></Link>
                                </nav>
                            </div>

                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>                                       </div>
                                PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePerfil" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">

                                    <Link to="/vicerrectoradoacad/VerPerfilVicerrAcad" ><div className="nav-link puntero movil" href="#">Ver Perfil </div></Link>

                                </nav>
                            </div>
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePlanes" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>                                       </div>
                                PLANES
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePlanes" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoacad/GestionPlanes" ><div className="nav-link puntero movil" href="#">Gestión de Planes </div></Link>
                                    <Link to="/vicerrectoradoacad/GestionEjes" ><div className="nav-link puntero movil" href="#">Gestión de Ejes </div></Link>
                                    <Link to="/vicerrectoradoacad/GestionEjesPlan" ><div className="nav-link puntero movil" href="#">Gestión de Ejes de Plan </div></Link>
                                    <Link to="/vicerrectoradoacad/GestionObjIndicador" ><div className="nav-link puntero movil" href="#">Gestion de Objetivos </div></Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}
