/*import axios from 'axios'
import React, { Component } from 'react'*/
import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText, Alert } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const devuelNumNivelesCarrera = ApiUrl + "numnivelescarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarEstMatCiclo = ApiUrl + "listarestmatciclobec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModEstadPensM = ApiUrl + "modestadpagpensmescol" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarBecaEst = ApiUrl + "insertarbecaEst" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarBecasEst = ApiUrl + "ListarbecasEst" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarBecasEst = ApiUrl + "EliminarbecasEst" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
//const urlListarPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlPeriodosCarrera = ApiUrl + "listperiodoscarrera" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class EstCarreraCicloBecas extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            nivel1: '',
            nivel2: '',
            nivel3: '',
            nivel4: '',
            nivel5: '',
            nivel6: '',
            periodo: '',
            idPeriodo:3,
            EstudianteBackup: [],

            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            carrera: [],
            id: '',
            monto: '',
            arancel: '',
            manutencion: '',
            tipo_beca_id: '',
            financiamiento: '',
            razon1: '',
            razon2: '',
            razon3: '',
            razon4: '',
            razon5: '',
            razon6: '',
            periodo: '',
            modalReportIngresarBecas: false,

            //nuevas variables
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listaestudiantes: [],
            listabecas: [],
            listaperiodos: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }

    handleChangeDevolverIdCarrera = async (e) => {

      
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.idPeriodo,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });

        //lista los períodos en base a la carrera

        axios
            .post(urlPeriodosCarrera, {//guarda para enviar los atributos y envia
                idCarrera: this.state.idCarrera,
            })
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                    const idUltPeriod = listaperiodos[cant - 1].id_periodo_academico;
                    $(function () {
                        $("#idPeriodo").val(idUltPeriod);
                    });
                    this.setState({ idPeriodo: idUltPeriod });
                }
            });

    }

    handleChangeDevolverIdCiclo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeListarEstMatCiclo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;

        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        axios
            .post(UrlListarEstMatCiclo, {
                idCarrera: this.state.carrera_seleccionada,
                idCiclo: this.state.ciclo_seleccionado,
                idPeriodo: this.state.idPeriodo,
            })
            .then(response => {
           
               if(response.data.data!="") {
                var array = response.data.data;
                var hash = {};
                array = array.filter(function (current) {
                    var exists = !hash[current.id_matricula];
                    hash[current.id_matricula] = true;
                    return exists;
                });
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listaestudiantes;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });


                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            } else {
                    this.setState({ error: "No existe estudiantes matriculados!" });
                    swal({
                        text: "¡No existe estudiantes matriculados!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }


            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.listaestudiantes;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }

        this.setState({
            currentItems: newData,
        })
        console.log(this.state.currentItems)
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //periodos
        /*axios.get(ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const periodos = respuesta.data.descripcion_periodo_academico;
                const periodosId = respuesta.data.id_periodo_academico;
                this.setState({ periodoId: respuesta.data.id_periodo_academico });
                this.setState({ periodos });
                this.setState({ periodosId });
                // console.log("periodos"+periodosId);
            })*/
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    seleccionarEstudianteuno = async (e) => {
        var id_estudiante = "";
        $(".afiliado:checked").each(function () {
            id_estudiante = $(this).parent().parent().find('td').eq(3).html();
            //nombrescompletos=$(this).parent().parent().find('td').eq(1).html()+" "+$(this).parent().parent().find('td').eq(2).html()+" "+$(this).parent().parent().find('td').eq(3).html()+" "+$(this).parent().parent().find('td').eq(4).html();
        });

        axios
            .post(urlListarBecasEst, {
                idEstudiante: id_estudiante,
            })
            .then(response => {
              
                const respuesta = response.data.data;
                this.setState({ listabecas: respuesta });
            })

        //periodos
        /*   axios.get(urlListarPeriodos)
               .then(response => {
                   const respuesta = response.data.data;
                   this.setState({ listaperiodos: respuesta });
               })*/
    }

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ modalReportIngresarBecas: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_beca);
            $("#monto").val(registro.monto);
            $("#arancel").val(registro.porcentaje_arancel);
            $("#manutencion").val(registro.porcentaje_manuntencion);
            $("#tipo_beca_id").val(registro.tipo_beca_id);
            $("#financiamiento").val(registro.financiamiento_beca);
            $("#razon1").val(registro.primera_razon_beca);
            $("#razon2").val(registro.segunda_razon_beca);
            $("#razon3").val(registro.tercera_razon_beca);
            $("#razon4").val(registro.cuarta_razon_beca);
            $("#razon5").val(registro.quinta_razon_beca);
            $("#razon6").val(registro.sexta_razon_beca);
            $("#periodo").val(registro.periodo_academico_id);
        });

        this.setState({ id: registro.id_beca });
        this.setState({ monto: registro.monto });
        this.setState({ arancel: registro.porcentaje_arancel });
        this.setState({ manutencion: registro.porcentaje_manuntencion });
        this.setState({ tipo_beca_id: registro.tipo_beca_id });
        this.setState({ financiamiento: registro.financiamiento_beca });
        this.setState({ razon1: registro.primera_razon_beca });
        this.setState({ razon2: registro.segunda_razon_beca });
        this.setState({ razon3: registro.tercera_razon_beca });
        this.setState({ razon4: registro.cuarta_razon_beca });
        this.setState({ razon5: registro.quinta_razon_beca });
        this.setState({ razon6: registro.sexta_razon_beca });
        this.setState({ periodo: registro.periodo_academico_id });
        this.setState({ modalReportIngresarBecas: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarBecas: false });
    }

    registrarBeca = () => {

       
        var id_estudiante = "";
        $(".afiliado:checked").each(function () {
            id_estudiante = $(this).parent().parent().find('td').eq(3).html();
            //nombrescompletos=$(this).parent().parent().find('td').eq(1).html()+" "+$(this).parent().parent().find('td').eq(2).html()+" "+$(this).parent().parent().find('td').eq(3).html()+" "+$(this).parent().parent().find('td').eq(4).html();
        });

        const f = new FormData();
        f.append("id", this.state.id);
        f.append("monto", this.state.monto);
        f.append("porcentaje_arancel", this.state.arancel);
        f.append("porcentaje_manuntencion", this.state.manutencion);
        f.append("tipo_beca_id", this.state.tipo_beca_id);
        f.append("primera_razon_beca", this.state.razon1);
        f.append("segunda_razon_beca", this.state.razon2);
        f.append("tercera_razon_beca", this.state.razon3);
        f.append("cuarta_razon_beca", this.state.razon4);
        f.append("quinta_razon_beca", this.state.razon5);
        f.append("sexta_razon_beca", this.state.razon6);
        f.append("financiamiento_beca", this.state.financiamiento);
        f.append("idEstudiante", id_estudiante);
        f.append("periodo_academico_id", this.state.periodo);
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(urlInsertarBecaEst, f, {

        }, config)
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Operación Exitosa!!",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.seleccionarEstudianteuno();
                    this.ocultarModalIngresar();
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    eliminarBeca = (id) => {
        swal({
            title: "Advertencia",
            text: "Está seguro de continuar?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.post(urlEliminarBecasEst, {
                        id: id,
                    })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Beca Eliminada Exitosamente!!",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.seleccionarEstudianteuno();
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Asignar becas a estudiantes</b>
                    </div>
                    <div className="card-body">

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Ciclos</b></label>
                                    <select className="form-select" name="idCiclo" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione un ciclo:</option>
                                        {this.state.listaniveles.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">

                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" id="idPeriodo" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} disabled={this.state.disabledbuscar} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2" >
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEstMatCiclo} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Primer Apellido</th>
                                    <th>Segundo Apellido</th>
                                    <th>Primer Nombre</th>
                                    <th>Segundo Nombre</th>
                                    <th>Seleccionar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.currentItems && this.state.currentItems.map((consola) => (
                                    <tr key={consola.id_matricula}>
                                        <td class="id oculto">{consola.id_matricula}</td>
                                        <td class="ciclo oculto">{consola.ciclo_nivel}</td>
                                        <td class="carrera oculto">{consola.carrera_id}</td>
                                        <td class="idEstudiante oculto">{consola.id_estudiante}</td>
                                        <td class="email oculto">{consola.email_estudiante}</td>
                                        <td class="codigo">{consola.numero_identificacion_estudiante}</td>
                                        <td class="primerape">{consola.primer_apellido_estudiante}</td>
                                        <td class="segundoape">{consola.segundo_apellido_estudiante}</td>
                                        <td class="primernom">{consola.primer_nombre_estudiante}</td>
                                        <td class="segundonom">{consola.segundo_nombre_estudiante}</td>
                                        <td><input type="radio" value="1" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                            <div className="card text-center ">
                                <div className="card-header ">
                                    DATOS DE BECA DEL ESTUDIANTE
                                </div>

                                <div className="card-body centrar " style={{ width: "100%" }}>

                                    <div class="table-responsive">
                                        <table id="tablaasignaturas" className="table text-left">
                                            <thead >
                                                <tr>
                                                    <th scope="col">MONTO</th>
                                                    <th scope="col">%ARANCEL</th>
                                                    <th scope="col">%MANUTENC</th>
                                                    <th scope="col">TIPO</th>
                                                    <th scope="col">FINANCIAM</th>
                                                    <th scope="col">RAZÓN1</th>
                                                    <th scope="col">RAZÓN2</th>
                                                    <th scope="col">RAZÓN3</th>
                                                    <th scope="col">RAZÓN4</th>
                                                    <th scope="col">RAZÓN5</th>
                                                    <th scope="col">RAZÓN6</th>
                                                    <th scope="col">PERIODO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.listabecas.map(consola => (
                                                    <tr key={consola.estudiante_id}>
                                                        <td class="monto">${consola.monto}</td>
                                                        <td class="arancel">{consola.porcentaje_arancel}%</td>
                                                        <td class="manutencion">{consola.porcentaje_manuntencion}%</td>
                                                        <td scope="tipo">
                                                            {(() => {
                                                                if (consola.tipo_beca_id == "1")
                                                                    return <span>Total</span>
                                                                if (consola.tipo_beca_id == "2")
                                                                    return <span>Parcial</span>
                                                                if (consola.tipo_beca_id == "3")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="financiamiento">
                                                            {(() => {
                                                                if (consola.financiamiento_beca == "1")
                                                                    return <span>Fondos Propios</span>
                                                                if (consola.financiamiento_beca == "2")
                                                                    return <span>Transferencia del estado</span>
                                                                if (consola.financiamiento_beca == "3")
                                                                    return <span>No Donaciones</span>
                                                                if (consola.financiamiento_beca == "4")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon1">
                                                            {(() => {
                                                                if (consola.primera_razon_beca == "1")
                                                                    return <span>Socioeconómica</span>
                                                                if (consola.primera_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon2">
                                                            {(() => {
                                                                if (consola.segunda_razon_beca == "1")
                                                                    return <span>Excelencia</span>
                                                                if (consola.segunda_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon3">
                                                            {(() => {
                                                                if (consola.tercera_razon_beca == "1")
                                                                    return <span>Deportista</span>
                                                                if (consola.tercera_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon4">
                                                            {(() => {
                                                                if (consola.cuarta_razon_beca == "1")
                                                                    return <span>Pueblos y Nacionalidades</span>
                                                                if (consola.cuarta_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon5">
                                                            {(() => {
                                                                if (consola.quinta_razon_beca == "1")
                                                                    return <span>Discapacidad</span>
                                                                if (consola.quinta_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="razon6">
                                                            {(() => {
                                                                if (consola.sexta_razon_beca == "1")
                                                                    return <span>Otra</span>
                                                                if (consola.sexta_razon_beca == "2")
                                                                    return <span>NA</span>
                                                            })()}
                                                        </td>
                                                        <td scope="period">
                                                            {consola.descripcion_periodo_academico}
                                                        </td>
                                                        <td class="detalle">
                                                            <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                            <button onClick={() => this.eliminarBeca(consola)} > <i className=" fas fa-trash" ></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div class="table-responsive">
                                            <ReactPaginate
                                                nextLabel="next >"
                                                onPageChange={this.handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                previousLabel="< previous"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>
                                        <Modal isOpen={this.state.modalReportIngresarBecas} >
                                            <ModalHeader>
                                                Registro de Beca
                                            </ModalHeader>
                                            <ModalBody>
                                                <FormGroup>
                                                    <div className="mb-3 centrar">
                                                        <input type="hidden" name="id" class="id" id="id" value="" />
                                                        <label className="form-label">Monto:</label>
                                                        <input type="text" id="monto" name="monto" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Arancel:</label>
                                                        <input type="text" id="arancel" name="arancel" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Manutención:</label>
                                                        <input type="text" id="manutencion" name="manutencion" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Tipo de Beca:</label>
                                                        <select className="form-select" id="tipo_beca_id" name="tipo_beca_id" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Total</option>
                                                            <option value="2">Parcial</option>
                                                            <option value="3">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Financiamiento:</label>
                                                        <select className="form-select" id="financiamiento" name="financiamiento" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Fondos Propios</option>
                                                            <option value="2">Transferencia del estado</option>
                                                            <option value="3">No Donaciones</option>
                                                            <option value="4">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Primera Razón:</label>
                                                        <select className="form-select" id="razon1" name="razon1" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Socioeconómica</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Segunda Razón:</label>
                                                        <select className="form-select" id="razon2" name="razon2" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Excelencia Académica</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Tercera Razón:</label>
                                                        <select className="form-select" id="razon3" name="razon3" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Deportista</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Cuarta Razón:</label>
                                                        <select className="form-select" id="razon4" name="razon4" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Pueblos y Nacionalidades</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Quinta Razón:</label>
                                                        <select className="form-select" id="razon5" name="razon5" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Discapacidad</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Sexta Razón:</label>
                                                        <select className="form-select" id="razon6" name="razon6" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                            <option value="">Seleccione</option>
                                                            <option value="1">Otra</option>
                                                            <option value="2">No aplica</option>
                                                        </select>
                                                        <label className="form-label">Periodo Académico:</label>
  
                                                        <select className="form-select" id="periodo" name="periodo" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                            <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                                            {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                                        </select>

                                                    </div>
                                                </FormGroup>
                                            </ModalBody>
                                            <ModalFooter>

                                                <button class="btn btn-outline-primary" onClick={() => this.registrarBeca()} > Ingresar</button>
                                                <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                            </ModalFooter>
                                        </Modal>

                                    </div>
                                    <div className="text-center">
                                        <button id="agregar" type="button"
                                            className="btn  back-istmas centrar"
                                            style={{ margin: "5px", width: "170px" }}
                                            onClick={() => this.mostrarModalIngresar()} >
                                            <b>Registrar Beca</b>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

