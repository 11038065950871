import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
//import "./inscripcionhojasestilocol.css";
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';

const cookie = new Cookies();
//const inscribirUrlListarestud = ApiUrl + "listarestudpagomatricula";
const urlListaUsuariosAdm = ApiUrl + "listusuariosadmdocen" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const listarAsignDocente = ApiUrl + "listarasigndocente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaRolesAdm = ApiUrl + "listarolesadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModAsignDocente = ApiUrl + "modificarasigndocente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngrAsignDocente = ApiUrl + "ingresarasigndocente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarAsignDocente = ApiUrl + "eliminarasigndocente" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera = ApiUrl + "numnivelescarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarAsignMatCiclo = ApiUrl + "ListarAsignMatCiclo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;


export default class AsignaturasDocentePeriodoAdm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            AsignaturasBackup: [],
            ListAsignaturas: [],
            listanivelesIng: [],
            carrera: [],
            ListAsignaturasIng: [],
            periodos: [],
            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            ruta: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            justClicked: null,

            listroles: [],
            form1: {
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalModUsuario: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ comprobante: e.target.files })
        if (this.state.idTipoTransaccionmod == 1) {
            this.setState({ disabledctmod: this.state.disabledtransfemod })
        }
        else {
            this.setState({ disabledctmod: !this.state.disabledtransfemod })
        }
    }

    componentDidMount() {
        axios.get(urlListaUsuariosAdm)
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value;
        //obtener datos del array
        const data = this.state.BackupAsignaturas;
        const newData = data.filter(function (item) {
            //variable del objeto
            //const itemData=item.primer_nombre_estudiante.toUpperCase()
            const itemDataAsignatura = item.descripcion_asignatura.toUpperCase()
            const itemDataPeriodo = item.descripcion_periodo_academico.toUpperCase()
            const campo = itemDataAsignatura + " " + itemDataPeriodo;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1
        })
        this.setState({
            ListAsignaturas: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }

    seleccionarAdministuno = () => {
        var id_adm = 0;

        $(".afiliado:checked").each(function () {
            id_adm = $(this).parent().parent().find('td').eq(0).html();
        });

        axios
            .post(listarAsignDocente, {
                id_adm: id_adm,
            })
            .then(response => {
                this.setState({ ListAsignaturas: response.data.data });
                this.setState({ BackupAsignaturas: response.data.data });
            })
    }

    ingresarDocenteAsignatura = () => {
        document.addEventListener("keydown", this.escFunction, true);

        axios
            .post(urlIngrAsignDocente, {
                idAdministrativo: this.state.idDocenteIng,
                idAsignatura: this.state.idAsignaturaIng,
                paralelo: this.state.paraleloIng,
                idPeriodo: this.state.idPeriodoIng,
                finicio: this.state.finicioIng,
                ffin: this.state.ffinIng,
                detalle: this.state.detalleIng,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.seleccionarAdministuno();
                } else {
                    swal({
                        text: "No se actualizó los datos",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalPagoColegituraMes: false });

                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });

    }

    modificarDocenteAsignatura = () => {
        var combmsmalert = "¿Está seguro que desea modificar la asignatura?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlModAsignDocente, {
                            id: this.state.id,
                            paralelo: this.state.paralelo,
                            finicio: this.state.finicio,
                            ffin: this.state.ffin,
                            detalle: this.state.detalle,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.seleccionarAdministuno();
                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalmodDocAsignatura: false });

                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    ocultarModalModDocAsign = () => {
        this.setState({ modalmodDocAsignatura: false });
    }

    mostrarModalIngDocAsign = (registro) => {
        axios.get(carrerasUrl)
            .then(res => {
                this.setState({ carrera: res.data });
            })
        this.setState({ modalIngresarAsignatura: true });
        //periodos
        axios.get(ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                this.setState({ periodos: respuesta.data.data });
            })
    }

    ocultarModalIngDocAsign = () => {
        this.setState({ modalIngresarAsignatura: false });
    }

    handleChangeDevolverIdCarreraIng = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarreraIng,
                periodoIdf: this.state.periodoId,
            })
            .then(response => {
                this.setState({ listanivelesIng: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    handleChangeDevolverIdCiclo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarreraIng });
        this.setState({ ciclo_seleccionado: this.state.idCicloIng });
        axios
            .post(UrlListarAsignMatCiclo, {
                idCarrera: this.state.carrera_seleccionada,
                idCiclo: this.state.ciclo_seleccionado,
            })
            .then(response => {
                var array = response.data.data;
                this.setState({ ListAsignaturasIng: array });
            })
    }

    eliminarDocAsign = (registro) => {
        var combmsmalert = "¿Está seguro que desea eliminar la asignación?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlEliminarAsignDocente, {
                            id: registro.id_docente_asignatura,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos elimados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.seleccionarAdministuno();
                            } else {
                                swal({
                                    text: "No se eliminaron los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalmodDocAsignatura: false });

                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    mostrarModalModDocAsign = (registro) => {

        $(function () {
            $("#id").val(registro.id_docente_asignatura);
            $("#docente").val(registro.nombres_administrativo + " " + registro.apellidos_administrativo);
            $("#asignatura").val(registro.descripcion_asignatura);
            $("#paralelo").val(registro.paralelo);
            $("#periodo").val(registro.descripcion_periodo_academico);
            $("#finicio").val(registro.fecha_inicio_asignatura);
            $("#ffin").val(registro.fecha_fin_asignatura);
            $("#detalle").val(registro.detalle);
        });
        this.setState({ modalmodDocAsignatura: true, form1: registro });
        this.setState({ id: registro.id_docente_asignatura });
        this.setState({ docente: registro.nombres_administrativo + " " + registro.apellidos_administrativo });
        this.setState({ asignatura: registro.descripcion_asignatura });
        this.setState({ paralelo: registro.paralelo });
        this.setState({ periodo: registro.descripcion_periodo_academico });
        this.setState({ finicio: registro.fecha_inicio_asignatura });
        this.setState({ ffin: registro.fecha_fin_asignatura });
        this.setState({ detalle: registro.detalle });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Reporte de Docentes</b>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        <b>DOCENTES</b>
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Nombres</th>
                                                        <th scope="col">Apellidos</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Convencional</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Dirección</th>
                                                        <th scope="col">Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_administrativo}  >
                                                                <td class="oculto">{consola.id_administrativo}</td>
                                                                <td >{consola.numero_identificacion_administrativo}
                                                                </td>
                                                                <td >{consola.nombres_administrativo}</td>
                                                                <td>{consola.apellidos_administrativo}</td>
                                                                <td>{consola.celular_administrativo}</td>
                                                                <td >{consola.convencional_administrativo}</td>
                                                                <td>{consola.email_administrativo}</td>
                                                                <td>{consola.direccion_administrativo}</td>
                                                                <td>
                                                                    <input type="radio" value="1" name="afiliado" onChange={this.seleccionarAdministuno} class="afiliado" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        <b>ASIGNATURAS ASIGNADAS</b>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                        </div>
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">ASIGNATURA</th>
                                                        <th scope="col">PARALELO</th>
                                                        <th scope="col">PERIODO</th>
                                                        <th scope="col">FECHA INICIO</th>
                                                        <th scope="col">FECHA FIN</th>
                                                        <th scope="col">DETALLE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ListAsignaturas.map(consola => (
                                                        <tr key={consola.administrativo_id}>
                                                            <td class="mes">{consola.descripcion_asignatura}</td>
                                                            <td class="mes">{consola.paralelo}</td>
                                                            <td class="mes">{consola.descripcion_periodo_academico}</td>
                                                            <td class="mes">{consola.fecha_inicio_asignatura}</td>
                                                            <td class="mes">{consola.fecha_fin_asignatura}</td>
                                                            <td class="mes">{consola.detalle}</td>
                                                            <td class="detalle">
                                                                <button onClick={() => this.mostrarModalModDocAsign(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                                <button onClick={() => this.eliminarDocAsign(consola)} > <i className=" fas fa-trash" ></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div class="table-responsive">
                                                <Modal isOpen={this.state.modalmodDocAsignatura} >
                                                    <ModalHeader>
                                                        Asignatura
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <FormGroup>
                                                            <div className="mb-3 centrar">
                                                                <input name="id" id="id" type="hidden" onChange={this.handleChange} ></input>
                                                                <label className="form-label">Docente</label><br />
                                                                <input type="text" id="docente" name="docente" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Asignatura</label><br />
                                                                <input type="text" id="asignatura" name="asignatura" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Periodo Académico</label><br />
                                                                <input type="text" id="periodo" name="periodo" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Paralelo</label><br />
                                                                <input type="text" id="paralelo" name="paralelo" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Fecha Inicio</label><br />
                                                                <input type="date" id="finicio" name="finicio" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Fecha Fin</label><br />
                                                                <input type="date" id="ffin" name="ffin" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Detalle</label><br />
                                                                <input type="text" id="detalle" name="detalle" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                            </div>
                                                        </FormGroup>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button class="btn btn-outline-primary" onClick={this.modificarDocenteAsignatura} > Modificar</button>
                                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModDocAsign}>Salir</button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                            <div class="table-responsive">
                                                <Modal isOpen={this.state.modalIngresarAsignatura} >
                                                    <ModalHeader>
                                                        Asignatura
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <FormGroup>
                                                            <div className="mb-3 centrar">
                                                                <label className="form-label">Docentes</label><br />
                                                                <select className="form-select" name="idDocenteIng" id="idDocenteIng" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione un docente:</option>
                                                                    {this.state.estud.map(consola => <option key={consola.id_administrativo} value={consola.id_administrativo} > {consola.nombres_administrativo + " " + consola.apellidos_administrativo}</option>)}
                                                                </select>
                                                                <label className="form-label">Carrera</label><br />
                                                                <select className="form-select" id="idCarreraIng" name="idCarreraIng" value={this.state.value} onChange={this.handleChangeDevolverIdCarreraIng} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione una carrera</option>
                                                                    {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                                                </select>
                                                                <label className="form-label">Ciclo</label><br />
                                                                <select className="form-select" name="idCicloIng" id="idCicloIng" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione un ciclo:</option>
                                                                    {this.state.listanivelesIng.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                                                </select>
                                                                <label className="form-label">Asignaturas</label><br />
                                                                <select className="form-select" name="idAsignaturaIng" id="idAsignaturaIng" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione una asignatura:</option>
                                                                    {this.state.ListAsignaturasIng.map(consola => <option key={consola.id_asignatura} value={consola.id_asignatura} > {consola.descripcion_asignatura}</option>)}
                                                                </select>
                                                                <label className="form-label">Periodos</label><br />
                                                                <select className="form-select" name="idPeriodoIng" id="idPeriodoIng" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione un periodo:</option>
                                                                    {this.state.periodos.map(consola => <option key={consola.id_periodo_academico} value={consola.id_periodo_academico} > {consola.descripcion_periodo_academico}</option>)}
                                                                </select>
                                                                <label className="form-label">Paralelo</label><br />
                                                                <input type="text" id="paraleloIng" name="paraleloIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Fecha Inicio</label><br />
                                                                <input type="date" id="finicioIng" name="finicioIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Fecha Fin</label><br />
                                                                <input type="date" id="ffinIng" name="ffinIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Detalle</label><br />
                                                                <input type="text" id="detalleIng" name="detalleIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                            </div>
                                                        </FormGroup>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button class="btn btn-outline-primary" onClick={this.ingresarDocenteAsignatura} > Ingresar</button>
                                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalIngDocAsign}>Salir</button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                        </div>
                                        <button type="" onClick={this.mostrarModalIngDocAsign} class="btn btn-outline-primary" ><b>Nueva Asignación</b></button>
                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

