import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login


export default class SidebarSecretaria extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })

                // console.log(cookie.get("nombres"));
                // console.log(cookie.get("apellidos")); 
            })
    }


    render() {
        return (
            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">PERSONAL DE SECRETARIA</div>
                            <Link to="/secretaria">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal A</b>
                                </div>
                            </Link>

                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>                                       </div>
                                PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePerfil" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">

                                    <Link to="/Secretaria/VerPerfilSecre" ><div className="nav-link puntero movil" href="#">Ver Perfil </div></Link>

                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseMatriculas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                MATRÍCULAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseMatriculas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Secretaria/MatricularEstudiante"><div className="nav-link movil" href="#">Matricular Estudiantes</div></Link>
                                    <Link to="/Secretaria/MatriculasPeriodo"><div className="nav-link movil" href="#">Modificar Matrículas</div></Link>
                                    <Link to="/Secretaria/MatriculasCoord"><div className="nav-link movil" href="#">Matrículas Coordinación</div></Link>
                                    <Link to="/Secretaria/MatrizMatriculados"><div className="nav-link movil" href="#">Matriz de Matriculados</div></Link>
                                    <Link to="/Secretaria/GenerarMatriPdf"><div className="nav-link movil" href="#">Generar Matrícula PDF</div></Link>
                                    <Link to="/Secretaria/PaseCicloEstCarreraCicloPerio"><div className="nav-link movil" href="#">Generar Pase Ciclo PDF</div></Link>
                                    <Link to="/Secretaria/ListEstudEstadoMatricula"><div className="nav-link movil" href="#">Lista de Estudiantes-Estado de Matrícula</div></Link>
                                    <Link to="/Secretaria/CambiarDatosEstud"><div className="nav-link movil" href="#">Cambiar Correo</div></Link>
                                    <Link to="/Secretaria/CantidadMatriculados"><div className="nav-link movil" href="#">Cantidad de Matriculados</div></Link>
                               
                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseCalif" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fas fa-graduation-cap"></i>                                        </div>
                                CALIFICACIONES
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseCalif" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Secretaria/CalificacionesEstud">  <div className="nav-link movil" href="#">Calificaciones Periodo Carrera</div></Link>
                                    <Link to="/Secretaria/Record">  <div className="nav-link movil" href="#">Acta de calificaciones</div></Link>

                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseAsignaturas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                ASIGNATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseAsignaturas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/Secretaria/ListarAsignaturasDocSec"><div className="nav-link movil" href="#">Calificaciones Asignaturas Docente</div></Link>
                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseDocumentacion" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="fa fa-folder"></i>
                                </div>
                                DOCUMENTACIÓN
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDocumentacion" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                  <Link to="/Secretaria/estaddocumenmatriculas">  <div className="nav-link movil" href="#">Estado de los documentos de Matrículas</div></Link>
                                    <Link to="/Secretaria/actacompromiso">  <div className="nav-link movil" href="#">Acta de compromiso</div></Link>
                                    <Link to="/Secretaria/fotocarnetestud">  <div className="nav-link movil" href="#">Fotografías-Carnet</div></Link>
                                </nav>
                            </div>



                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}
