import axios from 'axios';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react'
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import './notificacion.css';

const inscribirUrlListarestud = ApiUrl + "listarestudmatriperiodact" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlNotificacionEst = ApiUrl + "enviarnotificacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 10;
export default class NotificacionesEst extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            periodos: [],
            codigoEstudiante: "",
            fechaNotificacion: "",
            //Datos para email
            mensajeNotifi: "",
            correoEstudiante: "",
            nombresCompletEst: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios
            .get(baseUrlPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })
        /************DEFINIR  FECHA ACTUAL */
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = (fecha.getMonth() + 1);
        var anio = fecha.getFullYear();
        var fechaNotificacion = `${anio}-${mes}-${dia}`;

        //define mes 
        var nombremes = mes + "";
        var numeroCaracteresmes = nombremes.length;
        if (numeroCaracteresmes == 1) {
            var mesfecha = "0" + nombremes;
        } else {
            mesfecha = nombremes;
        }
        //define dia
        var nombredia = dia + "";
        var numeroCaracteresdia = nombredia.length;
        if (numeroCaracteresdia == 1) {
            var diafecha = "0" + nombredia;
        } else {
            diafecha = nombredia;
        }
        var fechaactualgenerada = anio + "-" + mesfecha + "-" + diafecha;
        $(function () {

            $("#fechaNotificacion").val(fechaactualgenerada)

        });
        this.setState({ fechaNotificacion: fechaactualgenerada });
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION
    handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION


    seleccionarEstudianteuno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var codigoestud = "";
        var nombrescompletos = "";
        var correoestud = "";
        this.setState({ disabledcambcarr: this.state.disabledcambcarrv });

        $(".afiliado:checked").each(function () {
            codigoestud = $(this).parent().parent().find('td').eq(0).html();
            nombrescompletos = $(this).parent().parent().find('td').eq(2).html() + " " + $(this).parent().parent().find('td').eq(3).html() + " " + $(this).parent().parent().find('td').eq(4).html() + " " + $(this).parent().parent().find('td').eq(5).html();
            correoestud = $(this).parent().parent().find('td').eq(6).html();
        });
        this.setState({ codigoEstudiante: codigoestud });
        this.setState({ nombresCompletEst: nombrescompletos });
        this.setState({ correoEstudiante: correoestud });
    }

    //************************************NOTIFICAR A UN ESTUDIANTE***************************** */
    notificarEstudiante = async (e) => {
        axios.post(urlNotificacionEst, {
            fecha: this.state.fechaNotificacion,
            nombrescompletest: this.state.nombresCompletEst,
            correoestudiante: this.state.correoEstudiante,
            mensaje: this.state.mensajeNotifi,
            administrativo_Id: cookie.get('idAdministrativo'),
            estudiante_Id: this.state.codigoEstudiante
        })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Mensaje Enviado",
                        icon: "success",
                        button: "Aceptar",
                    });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ nombresCompletEst: "" });
        this.setState({ mensajeNotifi: "" });
        this.setState({ correoEstudiante: "" });
    }
    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        //listar estudiantes del periodo actual
        axios.post(inscribirUrlListarestud, {
            idPeriodoActual: this.state.idPeriodo
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {

                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe estudiantes para modificar la matrícula!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })

    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Notificación al Estudiante </b>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Correo</th>
                                                        <th scope="col">Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >
                                                                <td class="oculto" >{consola.id_estudiante}
                                                                </td>
                                                                <td >{consola.numero_identificacion_estudiante}
                                                                </td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>{consola.email_estudiante}</td>
                                                                <td>
                                                                    <input type="radio" value="1" id="afiliado" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" />

                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Fecha de comentario</b></label><br />

                                    <input id="fechaNotificacion" name="fechaNotificacion" type="date" onChange={this.handleChange} className="form-control" placeholder="0000-00-00" disabled="false" required />

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <label className="form-label"><b>Mensaje</b></label><br />
                                <div className="mb-3">
                                    <textarea id="mensajeNotifi" name="mensajeNotifi" rows="5" cols="40" onChange={this.handleChange} class="textarea" placeholder={this.state.nombresCompletEst} maxlength="1300" required></textarea>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="text-center">
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                disabled={(this.state.disablmodmat) ? "disabled" : ""}
                                style={{ margin: "5px", width: "170px" }}
                                onClick={e =>
                                    this.notificarEstudiante(e)
                                }
                            >
                                <b>Enviar</b>
                            </button>
                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
