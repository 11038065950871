import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
//import "./inscripcionhojasestilocol.css";
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Alert } from 'bootstrap';

const cookie = new Cookies();
const rutaimagen = ApiUrl + "mostarrutaimagencomprobantecolec" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListaUsuariosAdm = ApiUrl + "listusuariosadm" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModDatosUsuario = ApiUrl + "moddatosusuario" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListRolesRegisAdm = ApiUrl + "rolesasigper" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;


export default class ReportePersonalAdm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            ruta: "",
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            justClicked: null,
            listroles: [],
            form1: {
            },
            passwordmod: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalModUsuario: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ imgpersonalmod: e.target.files })

    }

    //controles del modal de  un registro de abono de matricula
    componentDidMount() {
        this.setState({ disabledpagotmatf: this.state.disabledpagomatric });
        document.addEventListener("keydown", this.escFunction, true);
        //listar la  lista de roles

        axios.get(urlListaUsuariosAdm)
            .then(respuesta => {
                listaauxiliar = respuesta.data.data;
                const estud = respuesta.data.data;
                this.setState((state) => {
                    return { estud: estud }
                }
                );
                const EstudianteBackup = estud;
                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });
                //PAGINACION
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
                //FIN DE LA PAGINACION
            })
    }
    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.EstudianteBackup
        const newData = data.filter(function (item) {
            //variable del objeto
            const itemDataNombres = item.nombres_administrativo.toUpperCase()
            const itemDataApellidos = item.apellidos_administrativo.toUpperCase()
            const itemDataCedula = item.numero_identificacion_administrativo.toUpperCase()
            const campo = itemDataNombres + " " + itemDataApellidos + itemDataCedula;
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })
    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }
    ocultarModificarUsu = () => {
        this.setState({ modalModUsuario: false });
    }
    mostrarModalModUsu = (registro) => {
        //Cargar la lista de roles del personal


        axios.post(urlListRolesRegisAdm, {
            idAdministrador: registro.id_administrativo
        })
            .then(res => {

                const listroles = res.data.data;
                this.setState({ listroles });
            })
        axios.post(rutaimagen, {

            nombrecomprobante: "usuario/" + registro.imagen_perfil_administrativo,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        $(function () {
            $("#numidentificacionmod").val(registro.numero_identificacion_administrativo);
            $("#Nombremod").val(registro.nombres_administrativo);
            $("#Apellmod").val(registro.apellidos_administrativo);
            $("#NumFechaNacmod").val(registro.fecha_nacimiento_administrativo);
            $("#CorreoEstmod").val(registro.email_administrativo);
            $("#numCelularmod").val(registro.celular_administrativo);
            $("#telfConvencionalmod").val(registro.convencional_administrativo);
            $("#direcciondomimod").val(registro.direccion_administrativo);

        });

        this.setState({ modalModUsuario: true, form1: registro });
        this.setState({ numidentificacionmod: registro.numero_identificacion_administrativo });
        this.setState({ Nombremod: registro.nombres_administrativo });
        this.setState({ Apellmod: registro.apellidos_administrativo });
        this.setState({ NumFechaNacmod: registro.fecha_nacimiento_administrativo });
        this.setState({ CorreoEstmod: registro.email_administrativo });
        this.setState({ numCelularmod: registro.celular_administrativo });
        this.setState({ telfConvencionalmod: registro.convencional_administrativo });
        this.setState({ direcciondomimod: registro.direccion_administrativo });

    }
    //Modificar los datos del usuario
    modificarDatosUsuario = (datos) => {

        var combmsmalert = "¿Está seguro que desea modificar los datos?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var vidFileLength = $("#imgpersonalmod")[0].files.length;
                    var cargarArchivo = 0;
                    var comprobantenuevo = "";
                    if ((vidFileLength === 0)) {
                        cargarArchivo = 0;
                        comprobantenuevo = null;


                    } else {
                        cargarArchivo = 1;
                        comprobantenuevo = this.state.imgpersonalmod[0];
                    }

                    //Crear la lista de roles seleccionados
                    let rolesselect = [];
                    $("input[type=checkbox]:checked").each(function () {
                        var rolseleccionado = $(this).parent().parent().find('td').eq(0).text();
                        let fila = {
                            irol: rolseleccionado,
                            codigorol: rolseleccionado,
                        };
                        rolesselect.push(fila);
                    });
                    var gsonroles = JSON.stringify(rolesselect);

                    const f = new FormData();

                    f.append("fotografiamod", comprobantenuevo);
                    f.append("numidentificacionmod", this.state.numidentificacionmod);
                    f.append("Nombremod", this.state.Nombremod);
                    f.append("Apellmod", this.state.Apellmod);
                    f.append("NumFechaNacmod", this.state.NumFechaNacmod);
                    f.append("CorreoEstmod", this.state.CorreoEstmod);
                    f.append("numCelularmod", this.state.numCelularmod);
                    f.append("telfConvencionalmod", this.state.telfConvencionalmod);
                    f.append("passwordmod", this.state.passwordmod);
                    f.append("direcciondomimod", this.state.direcciondomimod);
                    f.append("cargarchivotmod", cargarArchivo);
                    f.append("idUsuario", datos.id_administrativo);
                    f.append("listroles", gsonroles);
                    const config = {
                        headers: { 'content-type': 'multipart/form-data' }
                    }

                    axios
                        .post(urlModDatosUsuario, f, config)

                        .then(response => {
                            console.log(response)
                            if (response.data.success == true) {

                                this.setState({ estado: "Datos modificados correctamente" });
                                swal({
                                    text: "Datos modificados correctamente",
                                    icon: "success",
                                    button: "Aceptar",

                                });
                                //Actualizar la tabla de usuarios
                                axios.get(urlListaUsuariosAdm)
                                    .then(respuesta => {


                                        listaauxiliar = respuesta.data.data;
                                        this.setState({ itemOffset: 0 });
                                        const estud = respuesta.data.data;
                                        this.setState((state) => {
                                            return { estud: estud }
                                        }
                                        );
                                        const EstudianteBackup = estud;

                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        const endOffset = this.state.itemOffset + itemsPerPage;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState((state) => {
                                            return { itemOffset: state.itemOffset + endOffset }
                                        });
                                        //FIN DE LA PAGINACION
                                    })
                                this.setState({ modalModUsuario: false });
                            }
                            else {

                                if (response.data.status == "failed") {
                                    this.setState({ estado: "Formato del archivo incorrecto" });
                                    swal({
                                        text: "El archivo seleccionado no corresponde al formato: jpg o png",
                                        icon: "error",
                                        button: "Aceptar",
                                    });
                                }
                                else {
                                    this.setState({ estado: "Valores no registrados" });
                                    swal({
                                        text: "Valores no registrados",
                                        icon: "error",
                                        button: "Aceptar",

                                    });
                                }
                                this.setState({ modalModUsuario: false });
                            }

                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ estado: "Error No se pudo conectar con el servidor" });
                            window.location.reload(true);
                        })
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Pagos de Matrículas </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Nombres</th>
                                                        <th scope="col">Apellidos</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Convencional</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Dirección</th>

                                                        <th scope="col">Modificar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_administrativo}  >
                                                                <td >{consola.numero_identificacion_administrativo}
                                                                </td>
                                                                <td >{consola.nombres_administrativo}</td>
                                                                <td>{consola.apellidos_administrativo}</td>
                                                                <td>{consola.celular_administrativo}</td>
                                                                <td >{consola.convencional_administrativo}</td>
                                                                <td>{consola.email_administrativo}</td>
                                                                <td>{consola.direccion_administrativo}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarModalModUsu(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row  ">
                            <div className="mb-3 ">
                                <Modal classname="modal-background" size='lg' isOpen={this.state.modalModUsuario}>
                                    <ModalHeader>
                                        <h5></h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <div className="mb-3 centrar">
                                                <label className="form-label"><h5>{this.state.form1.nombres_administrativo + " " + this.state.form1.apellidos_administrativo} </h5></label>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de identificación</label>
                                                            <input type="text" id="numidentificacionmod" name="numidentificacionmod" onChange={this.handleChange} className="form-control" placeholder="Sin guión" required />
                                                            <div id="errorNumIdentificacionId" className="form-text color-validacion "   >{this.state.errorCedula}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Nombres</label>
                                                            <input type="text" id="Nombremod" name="Nombremod" className="form-control" placeholder="Primer Nombre" onChange={this.handleChange} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Apellidos</label>
                                                            <input type="text" id="Apellmod" name="Apellmod" className="form-control" placeholder="Primer Apellido" onChange={this.handleChange} required />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Ingrese la fecha de nacimiento</label>
                                                            <input type="date" id="NumFechaNacmod" name="NumFechaNacmod" onChange={this.handleChange} className="form-control" placeholder="1999-01-01" required />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Correo</label>
                                                            <input type="email" id="CorreoEstmod" name="CorreoEstmod" className="form-control" placeholder="correo@hotmail.com" onChange={this.handleChange} required />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de celular</label>
                                                            <input type="text" id="numCelularmod" name="numCelularmod" className="form-control" placeholder="0990000000" onChange={this.handleChange} required />
                                                            <div id="errorCorreoId" className="form-text color-validacion "   ></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de Convencional</label>
                                                            <input type="text" id="telfConvencionalmod" name="telfConvencionalmod" className="form-control" placeholder="Teléfono" onChange={this.handleChange} required />

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Dirección domiciliaria</label>
                                                            <input type="text" id="direcciondomimod" name="direcciondomimod" className="form-control" placeholder="Dirección" onChange={this.handleChange} required />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Contraseña</label>
                                                            <input type="password" id="passwordmod" name="passwordmod" className="form-control" placeholder="Contraseña" onChange={this.handleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Subir la fotografía</label>
                                                            <input className="form-control" type="file" id="imgpersonalmod" name="imgpersonalmod" onChange={this.handleChange} required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <label className="form-label">Seleccione el rol</label>
                                                            <div class="table-responsive">
                                                                <table id="tablaroles" className="table  text-left" >
                                                                    <thead >
                                                                        <tr>
                                                                            <th scope="col">Código</th>
                                                                            <th scope="col">Rol</th>
                                                                            <th scope="col">Asignar</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.listroles.map(consola => (
                                                                            <tr key={consola.id_rol}>
                                                                                <td class="idrol">{consola.id_rol}</td>
                                                                                <td class="descriprol" >{consola.descripcion_rol}</td>
                                                                                <td class="selectrol">
                                                                                    <input name={"check" + consola.id_rol} id={"check" + consola.id_rol} type="checkbox" />
                                                                                    {(() => {

                                                                                        if (this.state.modalModUsuario == true) {
                                                                                            if (consola.estado == 1) {
                                                                                                $(function () {
                                                                                                    $('#check' + consola.id_rol)[0].checked = true;
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                if (consola.estado == 0) {
                                                                                                    $(function () {
                                                                                                        $('#check' + consola.id_rol)[0].checked = false;
                                                                                                    })
                                                                                                }

                                                                                            }
                                                                                        }
                                                                                    })()}
                                                                                  
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                                        <div className="mb-3">
                                                            <input name="imagen" type="image" src={this.state.ruta} align="center" alt="fotografia" width='100%'
                                                                height='100%' ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="btn btn-outline-primary" onClick={() => this.modificarDatosUsuario(this.state.form1)} > Modificar</button>
                                        <button class="btn btn-outline-primary" onClick={this.ocultarModificarUsu}>Salir</button>

                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

