import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ApiUrl } from '../components/services/ApiRest'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/Sidebar'
import ListarPagosMatriculaEst from '../components/administrador/matriculas/ListarEstudPaganMatricula'
import ColegiaturasEstCarreraCicloAdm from '../components/administrador/colegiaturas/ColegiaturasEstCarreraCicloAdm'
import ColegiaturasEstColegiaturaUnPagoAdm from '../components/administrador/colegiaturas/ColegiaturasEstColegiaturaUnPagoAdm'
import AsignaturasEstCarreraCicloAdm from '../components/administrador/asignaturas/AsignaturasEstCarreraCicloAdm'
import AsignaturasDocentePeriodoAdm from '../components/administrador/asignaturas/AsignaturasDocentePeriodoAdm'
import IngresarDocente from '../components/administrador/docente/IngresarDatosDocAdm'
import ReportePersonal from '../components/administrador/docente/ReportePersonalAdm'
import VerPerfil from '../components/administrador/perfil/VerPerfil'

const cookie = new Cookies();
const urlAdministrativo=ApiUrl +"admins/"; 
const idAdministrativo = cookie.get("idAdministrativo"); 

const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasAdministrativo extends Component {
    componentDidMount() {
        axios.get(urlAdministrativo+idAdministrativo+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71" )
          .then(res => {
            const estudiante = res.data;
            this.setState({ estudiante });
            
            cookie.set('nombres',estudiante.nombres_administrativo,{path:"/"})
            cookie.set('apellidos',estudiante.apellidos_administrativo,{path:"/"})
          })
    }    


    render() {
       // console.log(cookie.get("log"));
        if(!cookie.get("log")){
            return(<Redirect to="/login" />);
        }

        if(cookie.get("rol") !== "administrativo"){
           // return(<Redirect to="/errorpermiso" />);
        }


        return (
            
            <>
                <NavbarGeneral /> 
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>
                        
                            <div className="container-fluid">                       
                            <Contenido /> 
                                    {/* Matriculas */}
                                    <Route  path="/administrativo/ListarPagosMatricula"  component={ListarPagosMatriculaEst} />   
                                    <Route  path="/administrativo/ColegiaturasEstCarreraCicloAdm"  component={ColegiaturasEstCarreraCicloAdm} />   
                                    <Route  path="/administrativo/ColegiaturasEstColegiaturaUnPagoAdm"  component={ColegiaturasEstColegiaturaUnPagoAdm} />   
                                    <Route  path="/administrativo/AsignaturasEstCarreraCicloAdm"  component={AsignaturasEstCarreraCicloAdm} />   
                                    <Route  path="/administrativo/AsignaturasDocentePeriodoAdm"  component={AsignaturasDocentePeriodoAdm} />   
                                    <Route  path="/administrativo/IngresarDocente"  component={IngresarDocente} />
                                    <Route  path="/administrativo/reportepersonal"  component={ReportePersonal} /> 
                                    <Route path="/administrativo/VerPerfilAdm" component={VerPerfil} />       
                            </div>
                        </main>
                        <FooterDashboard />                        
                    </div>                 
                </div>            
            </>
        )
    }
}
