import axios from 'axios';
import { ApiUrl } from '../services/ApiRest';
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom'
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo"); //declaramos una variable para usar la cookie del login


export default class SidebarVicerrectoradoAdm extends Component {

    componentDidMount() {
        // Get que devuelve los datos de un estudiante recibiendo un id
        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });
                //console.log(estudiante);

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })


                // console.log(cookie.get("nombres"));
                // console.log(cookie.get("apellidos")); 
            })
    }

    render() {
        return (

            <div id="layoutSidenav_nav" style={{ width: 226 }}>
                <nav className="sb-sidenav accordion sb-sidenav-dark animate__animated animate__fadeInLeft" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">PERSONAL DE VICERRECTORADO ADMINISTRATIVO</div>
                            <Link to="/vicerrectoradoadm">
                                <div className="nav-link movil" href="#">
                                    <div className="sb-nav-link-icon">
                                        <i className="fas fa-university"></i>
                                    </div>
                                    <b>Página Principal </b>
                                </div>
                            </Link>
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-user"></i>                                         </div>
                                PERFIL
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </a>
                            <div className="collapse" id="collapsePerfil" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoadm/VerPerfilVicerrAdm" ><div className="nav-link puntero movil" href="#">Ver Perfil </div></Link>
                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseMatriculas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                MATRÍCULAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseMatriculas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoadm/aprobarpagotransferencia"><div className="nav-link movil" href="#">Pagos de Matrícula</div></Link>
                                    <Link to="/vicerrectoradoadm/cantidadmatri" ><div className="nav-link puntero movil" href="#">Cantidad de matriculados</div></Link>

                                </nav>
                            </div>
                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseColegiaturas" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                COLEGIATURAS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseColegiaturas" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoadm/colegiaturasestcarreraciclogenvic"><div className="nav-link movil" href="#">Reporte de colegiaturas (General)</div></Link>
                                    <Link to="/vicerrectoradoadm/colegiaturastransfestcarreraciclo"><div className="nav-link movil" href="#">Reporte de colegiaturas (Transferencias)</div></Link>
                                    <Link to="/vicerrectoradoadm/colegiaturasEstColegUnPagoVic"><div className="nav-link movil" href="#">Reporte de Pagos  del ciclo completo</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseDepartamentos" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                DEPARTAMENTOS
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseDepartamentos" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoadm/GestionDepartamentos"><div className="nav-link movil" href="#">Gestión de Departamentos</div></Link>
                                </nav>
                            </div>

                            <div className="nav-link collapsed puntero" href="#" data-toggle="collapse" data-target="#collapseNotificaciones" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon">
                                    <i className="far fa-sticky-note"></i>
                                </div>
                                NOTIFICACIONES
                                <div className="sb-sidenav-collapse-arrow">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseNotificaciones" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link to="/vicerrectoradoadm/notificacionestudiante"><div className="nav-link movil" href="#">Notificaciones para estudiantes</div></Link>
                                </nav>
                            </div>

                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Usuario:</div>
                        {cookie.get("nombres") + " "}
                        {cookie.get("apellidos")}
                    </div>
                </nav>
            </div>
        )
    }
}
