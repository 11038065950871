import axios from 'axios'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Contenido from '../components/contenido/Contenido'
import FooterDashboard from '../components/footer/FooterDashboard'
import NavbarGeneral from '../components/navbar/NavbarGeneral'
import Sidebar from '../components/sidebar/SidebarColecturia'
import { ApiUrl } from '../components/services/ApiRest';



import MatriculasCarrPeriodoColecturia from '../components/colecturia/matriculas/MatriculasCarrPeriodoColecturia'
import ColegiaturasCarrPeriodoColecturia from '../components/colecturia/colegiaturas/ColegiaturasCarrPeriodoColecturia'
import ListarEstInscriAproColecturia from '../components/colecturia/inscripcion/ListarEstInscriAproColeturia'
import ListarEstuCanceladoMatricula from '../components/colecturia/matriculas/ListarEstuCanceladoMatricula'
import ListarEstuPagoMatriFecha from '../components/colecturia/matriculas/ListarEstuPagoMatriFecha'
import ColegiaturasEstCarreraCiclo from '../components/colecturia/colegiaturas/ColegiaturasEstCarreraCiclo'
import ColegiaturasEstxFechas from '../components/colecturia/colegiaturas/ColegiaturasEstxFechas'
import ColegiaturasGeneralxFechas from '../components/colecturia/colegiaturas/ColegiaturasGeneralxFechas'
import ColegiaturasEstColegiaturaPagada from '../components/colecturia/colegiaturas/ColegiaturasEstColegiaturaPagada'
import ColegiaturasEstColegiaturaUnPago from '../components/colecturia/colegiaturas/ColegiaturasEstColegiaturaUnPago'
import EstadoEstCarreraCicloColect from '../components/colecturia/colegiaturas/EstadoEstCarreraCicloColect'
import VerPerfil from '../components/colecturia/perfil/VerPerfil'

const cookie = new Cookies();
const urlAdministrativo = ApiUrl + "admins/"; //pasar id del estudiante
const idAdministrativo = cookie.get("idAdministrativo");    //declaramos una variable para usar la cookie del login


const config = {
    headers: { Authorization: `Bearer ${cookie.get("rol")}` }
};

export default class RutasColeturia extends Component {
    componentDidMount() {

        axios.get(urlAdministrativo + idAdministrativo + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(res => {
                const estudiante = res.data;
                this.setState({ estudiante });

                cookie.set('nombres', estudiante.nombres_administrativo, { path: "/" })
                cookie.set('apellidos', estudiante.apellidos_administrativo, { path: "/" })

            })
    }


    render() {

        if (!cookie.get("log")) {
            return (<Redirect to="/login" />);
        }

        if (cookie.get("rol") !== "administrativo") {
        }


        return (

            <>
                <NavbarGeneral />
                <div id="layoutSidenav" >
                    <Sidebar />
                    <div id="layoutSidenav_content">
                        <main>

                            <div className="container-fluid">
                                <Contenido />

                                {/*  Matriculas */}
                                <Route path="/colecturia/MatriculaCarrPeriodoColecturia" component={MatriculasCarrPeriodoColecturia} />
                                <Route path="/colecturia/ListarEstInscriAproColecturia" component={ListarEstInscriAproColecturia} />
                                <Route path="/colecturia/ListarEstudiantesMatriculaCancelada" component={ListarEstuCanceladoMatricula} />
                                <Route path="/colecturia/ListarEstuPagoMatriFecha" component={ListarEstuPagoMatriFecha} />
                                {/*  Colegiaturas */}
                                <Route path="/colecturia/ColegiaturaCarrPeriodoColecturia" component={ColegiaturasCarrPeriodoColecturia} />
                                <Route path="/colecturia/ColegiaturasEstCarreraCiclo" component={ColegiaturasEstCarreraCiclo} />
                                <Route path="/colecturia/ColegiaturasEstxFechas" component={ColegiaturasEstxFechas} />
                                <Route path="/colecturia/ColegiaturasGeneralxFechas" component={ColegiaturasGeneralxFechas} />
                                <Route path="/colecturia/ColegiaturasEstColegiaturaPagada" component={ColegiaturasEstColegiaturaPagada} />
                                <Route path="/colecturia/ColegiaturasEstColegiaturaUnPago" component={ColegiaturasEstColegiaturaUnPago} />
                                <Route path="/colecturia/EstadoEstCarreraCicloColect" component={EstadoEstCarreraCicloColect} />
                                <Route path="/colecturia/VerPerfilColecturia" component={VerPerfil} />
                            </div>
                        </main>
                        <FooterDashboard />
                    </div>
                </div>
            </>
        )
    }
}
