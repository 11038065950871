import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de consulta en el backend
const UrlListarEvaluaciones = ApiUrl + "listarevaluaciones" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInsertarEval = ApiUrl + "insertarevaluacion" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 4;

export default class GestionEvaluaciones extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagina: 0,
            evaluaciones: [],
            modalReportIngresarEval: false,
            //nuevas variables
            listaevaluaciones: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeListarEvaluaciones = async (e) => {
        //evaluaciones
        axios.get(UrlListarEvaluaciones)
            .then(respuesta => {
                const evaluaciones = respuesta.data.data;
                this.setState({ evaluaciones });
                this.setState({ listaevaluaciones: evaluaciones });
                listaauxiliar = this.state.listaevaluaciones;

                //PAGINACION
                this.state.itemOffset = 0;
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        this.handleChangeListarEvaluaciones();
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        this.setState({ pagina: event.selected });
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalIngresar = () => {
        this.setState({ id: 0 });
        this.setState({ nombre: null });
        this.setState({ fechainicio: null });
        this.setState({ fechafin: null });
        this.setState({ estadoeval: null });
        this.setState({ modalReportIngresarEval: true });
    }

    mostrarModalModificar = (registro) => {
        $(function () {
            $("#id").val(registro.id_evaluacion);
            $("#nombre").val(registro.nombre_evaluacion);
            $("#fechainicio").val(registro.fechainicio_evaluacion);
            $("#fechafin").val(registro.fechafin_evaluacion);
            $("#estadoeval").val(registro.estado_evaluacion);
        });

        this.setState({ id: registro.id_evaluacion });
        this.setState({ nombre: registro.nombre_evaluacion });
        this.setState({ fechainicio: registro.fechainicio_evaluacion });
        this.setState({ fechafin: registro.fechafin_evaluacion });
        this.setState({ estadoeval: registro.estado_evaluacion });
        this.setState({ modalReportIngresarEval: true });
    }

    ocultarModalIngresar = () => {
        this.setState({ modalReportIngresarEval: false });
    }

    registrarEval = () => {

        if (this.state.nombre != null && this.state.fechainicio != null && this.state.fechafin != null && this.state.estadoeval != null) {
            const f = new FormData();
            f.append("id", this.state.id);
            f.append("nombre", this.state.nombre);
            f.append("fechainicio", this.state.fechainicio);
            f.append("fechafin", this.state.fechafin);
            f.append("estadoeval", this.state.estadoeval);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(urlInsertarEval, f, {

            }, config)
                .then(response => {
                    if (response.data.success === true) {
                        swal({
                            text: "Operación Exitosa!!",
                            icon: "success",
                            button: "Aceptar",
                        });
                        axios.get(UrlListarEvaluaciones)
                            .then(respuesta => {
                                const evaluaciones = respuesta.data.data;
                                this.setState({ evaluaciones });
                                this.setState({ listaevaluaciones: evaluaciones });
                                listaauxiliar = this.state.listaevaluaciones;

                                if (this.state.pageCount == 1) {
                                    this.setState({ itemOffset: 0 });
                                }

                                //PAGINACION
                                const newOffset = (this.state.pagina * itemsPerPage) % listaauxiliar.length;
                                this.setState((state) => {
                                    return { itemOffset: newOffset }
                                });
                                const endOffset = newOffset + itemsPerPage;
                                //const endOffset = (this.state.pageCount * itemsPerPage) % listaauxiliar.length;
                                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                this.setState({ itemOffset: newOffset });
                            })
                        this.ocultarModalIngresar();
                    }
                })
                .catch(error => {
                    this.setState({ error: "No existe conexión con el servidor" });
                    swal({
                        text: "No existe conexión con el servidor",
                        icon: "error",
                        button: "Aceptar",
                    });
                });
        }
        else
        {
            swal({
                text: "Ingresar campos requeridos",
                icon: "warning",
                button: "Aceptar",
            });
        }
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Gestión de Evaluaciones</b>
                    </div>
                    <div className="card border-dark text-center ">
                        <div className="card-header ">
                            <b>REPORTE DE EVALUACIONES</b>
                        </div>
                        <div class="table-responsive">
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha de Fin</th>
                                        <th>Estado</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_evaluacion}>
                                            <td class="id">{consola.id_evaluacion}</td>
                                            <td class="nombre">{consola.nombre_evaluacion}</td>
                                            <td class="f_inicio">{consola.fechainicio_evaluacion}</td>
                                            <td class="f_fin">{consola.fechafin_evaluacion}</td>
                                            {(() => {
                                                if (consola.estado_evaluacion == "En proceso") {
                                                    return <td class="estadoeval table-primary">{consola.estado_evaluacion}</td>
                                                }
                                                if (consola.estado_evaluacion == "Completado") {
                                                    return <td class="estadoeval table-success">{consola.estado_evaluacion}</td>
                                                }
                                                if (consola.estado_evaluacion == "Inactivo") {
                                                    return <td class="estadoeval table-danger">{consola.estado_evaluacion}</td>
                                                }
                                            })()}
                                            <td class="detalle">
                                                <button onClick={() => this.mostrarModalModificar(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div class="table-responsive">
                                <ReactPaginate
                                    nextLabel="next >"
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                            <Modal isOpen={this.state.modalReportIngresarEval} >
                                <ModalHeader>
                                    Registro de Evaluación
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <div className="mb-3 centrar">
                                            <input type="hidden" name="id" class="id" id="id" value="" />
                                            <label className="form-label">Nombre:</label>
                                            <input type="text" id="nombre" name="nombre" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            <label className="form-label">Fecha Inicio:</label>
                                            <input type="date" id="fechainicio" name="fechainicio" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            <label className="form-label">Fecha Fin:</label>
                                            <input type="date" id="fechafin" name="fechafin" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                            <label className="form-label">Estado:</label>
                                            <select className="form-select" id="estadoeval" name="estadoeval" value={this.state.value} onChange={this.handleChange} aria-label="Default select example" autocomplete="off" required>
                                                <option value="" selected disabled>Seleccione</option>
                                                <option value="En proceso">En proceso</option>
                                                <option value="Completado">Completado</option>
                                                <option value="Inactivo">Inactivo</option>
                                            </select>
                                        </div>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>

                                    <button type="submit" class="btn btn-outline-primary" onClick={() => this.registrarEval()} > Ingresar</button>
                                    <button class="btn btn-outline-primary" onClick={() => this.ocultarModalIngresar()}>Salir</button>

                                </ModalFooter>
                            </Modal>
                            <div className="text-center">
                                <button id="agregar" type="button"
                                    className="btn  back-istmas centrar"
                                    style={{ margin: "5px", width: "170px" }}
                                    onClick={() => this.mostrarModalIngresar()} >
                                    <b>Registrar</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

