
import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const urlListPeriodos = ApiUrl + "listperiodostodos"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarCarrera=ApiUrl+"listarcarreras"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const devuelNumNivelesCarrera=ApiUrl+"numnivelescarr"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlColegituraPeriodC=ApiUrl+"ingresarcolegituraperiodcarr"+"?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl=inscribirUrlListarCarrera;

export default class ColegiaturasCarrPeriodo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            nivel1: '',
            nivel2: '',
            nivel3: '',
            nivel4: '',
            nivel5: '',
            nivel6: '',
            carrera_seleccionada: '',
            carrera: [],
            estado: '',
            //nuevas variables
            numnivelescarr: '',
            listaperiodos: [],
            listaniveles: [],
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }

    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.idPeriodoSelect,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        axios
            .get(urlListPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ listaperiodos });
                }
            })
    }

    registrarColegiaturasPeriodo = async (e) => {
        // e.preventDefault();
        let materiasselect = [];
        document.querySelectorAll('#tablaasignaturas tbody tr').forEach(function (e) {
            var idnivel = e.querySelector('.codigo').innerText
            var valorM = $("#" + idnivel + "valormensual").val();
            var valorT = $("#" + idnivel + "valortotal").val();
            var valorNumCuota = $("#" + idnivel + "numcuotas").val();

            let fila = {
                idnivelf: idnivel,
                valormf: valorM,
                valortf: valorT,
                valornumcuotaf: valorNumCuota,
            };
            materiasselect.push(fila);
        });
        var combinancion = JSON.stringify(materiasselect);

        axios
            .post(inscribirUrlColegituraPeriodC, {//guarda para enviar los atributos y envia
                periodoactualef: this.state.idPeriodoSelect,
                listColegiaturasPf: materiasselect,
                idCarreraf: this.state.idCarrera,
            })
            .then(response => {
                const list = response.data.data;
                if (response.data.success === true) {
                    swal({
                        text: "Datos  registrados",
                        icon: "success",
                        button: "Aceptar",
                    });

                    //llama a la lista de colegiaturas para actualizar
                    axios
                        .post(devuelNumNivelesCarrera, {
                            idCarreraf: this.state.idCarrera,
                            periodoIdf: this.state.idPeriodoSelect,
                        })
                        .then(response => {
                            const listaniveles1 = response.data.data;
                            this.setState({ listaniveles: listaniveles1 });
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                }
                else {
                    swal({
                        text: "Datos no  registrados",
                        icon: "error",
                        button: "Aceptar",
                    });

                }
            })
            .catch(error => {
                this.setState({ estado: "Error No se pudo conectar con el servidor" });
                swal({
                    text: "Datos no  registrados",
                    icon: "error",
                    button: "Aceptar",
                });
            })
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Costos de Colegiatura</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodoSelect" id="idPeriodoSelect" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                        <option value="undefined">Seleccione el período</option>
                                        {this.state.listaperiodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined">Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                </div>
                            </div>
                        </div>


                        <form className="" onSubmit={this.inscribirEstudiante} style={{ padding: 10 }} id="create-course-form" >
                            <div className="row ">
                                <div className="col-12 col-lg-5 col-xl-5 centrar" >
                                    <div className="mb-3">
                                      
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                </div>
                            </div>
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>Nivel</th>
                                        <th>Valor Mensual</th>
                                        <th>Valor Total</th>
                                        <th>#Cuotas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listaniveles.map(consola => (
                                        <tr key={consola.codnivel}>
                                            <td class="codigo" >{consola.codnivel}</td>
                                            <td class="valormens" > <input type="text" name={consola.codnivel + "valormensual"} id={consola.codnivel + "valormensual"} className="form-control" placeholder={consola.valor_mensualf} />
                                            </td>
                                            {
                                                (() => {
                                                    $(function () {
                                                        $("#" + consola.codnivel + "valormensual").val(consola.valor_mensualf);
                                                    });
                                                })()
                                            }
                                            <td class="valortot" >  <input type="text" name={consola.codnivel + "valortotal"} id={consola.codnivel + "valortotal"} placeholder={consola.valor_totalf} className="form-control" /></td>
                                            {
                                                (() => {
                                                    $(function () {
                                                        $("#" + consola.codnivel + "valortotal").val(consola.valor_totalf);
                                                    });
                                                })()
                                            }
                                              <td class="numcuot" >  <input type="text" name={consola.codnivel + "numcuotas"} id={consola.codnivel + "numcuotas"} placeholder={consola.valor_numcuotasf} className="form-control" /></td>
                                              {
                                                (() => {
                                                    $(function () {
                                                        $("#" + consola.codnivel + "numcuotas").val(consola.valor_numcuotasf);
                                                    });
                                                })()
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="text-center">
                                <button id="agregar" type="button"
                                    className="btn  back-istmas centrar"
                                    style={{ margin: "5px", width: "170px" }}
                                    onClick={e =>
                                        this.registrarColegiaturasPeriodo(e)
                                    }>
                                    <b>Aceptar</b>
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

