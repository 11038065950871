import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export const NavbarLog = () => {
    return (
        <div className="row">
            <nav className="navbar navbar-color ">
                <div className="container-fluid">
                    <Link to="/">
                        <div className="navbar-brand" href="#">                  
                         <img src={"./assets/img/logoEncabezado.png"} alt="" width="35%" height="35%" className="navbar-img" />
                        </div>
                    </Link>

                 
                    <div className="d-flex">
                    <Link to="/">
                            <button type="button" className="btn ">Carreras</button>
                        </Link>
                        <Link to="/login">
                            <button type="button" className="btn btn-outline-dark">Iniciar Sesión</button>
                        </Link>
                        
                    </div>
                </div>
            </nav>
                        
        </div>

        

        
    )
}
