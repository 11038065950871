import axios from 'axios';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react'
import Cookies from 'universal-cookie';
import "../../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../../services/ApiRest';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText, Alert } from 'reactstrap';

const inscribirUrlListarCarrera = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarsedes = ApiUrl + "listarsedes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarestud = ApiUrl + "listarestudmatriperiodact" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlCrearmatricula = ApiUrl + "crearmatricula" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlCarreraEst = ApiUrl + "carreraestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const datosMatriculaEstudiantes = ApiUrl + "datosmatriculaestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const listarAsignaturasCarreraNivelPost = ApiUrl + "listarAsignaturasCarreraNivelPost" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarAsignaturas = ApiUrl + "listasignmatrireport" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstCalifRegistradasEst = ApiUrl + "estadocalifasig" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlInfoCarrera = ApiUrl + "infocarrselect" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarEstadosMatri = ApiUrl + "listestadosmatr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifEstado = ApiUrl + "modestadomatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlRegistroPazSalvo = ApiUrl + "registrpazysalvo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarPazySalvo = ApiUrl + "elimpazysalvo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const inscribirUrlListarJornadas = ApiUrl + "listarjornadasca" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEstadoPazSalvo = ApiUrl + "estadopazysalvo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifJornada = ApiUrl + "modjornada" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifCoord = ApiUrl + "modcoordestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModifObservacion = ApiUrl + "modfobservacionestud" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlDatosEstudianteCed = ApiUrl + "datosestudxced" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListParalelos = ApiUrl + "listodosparalelos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlActEntregDocumentMatri = ApiUrl + "actestadocumentmatri" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


const carrerasUrl = inscribirUrlListarCarrera;
const cookie = new Cookies();
let listaauxiliar = [];
const itemsPerPage = 10;
export default class MatriculasPeriodo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ciclosAcademicos: [],
            carrera: [],
            sedes: [],
            estud: [],
            EstudianteBackup: [],
            textBuscar: "",
            periodos: [],
            estadosmat: [],
            listaniveles: [],
            cursos: [],
            listAsignaturasCarrera: [],
            listparalelos: [],
            codigoCarrE: "", //codigocarrera
            descripCarrE: "",//descripciocarrera
            numnivelE: "",//nuemro de  niveles
            cedulaEstudianteSelect: "",
            idMatriEstudSelect: "",
            listNiveles: [],
            idCarrerra: "1",
            cursoMatricularse: "",
            tipoMatricula: "1",
            estadMat: "1",
            costoMatricula: "100",
            estado: "",
            codigoEstudiante: "",
            comprobante: "",
            idSede: "",
            fechaMatricula: "",
            nomcarrerEst: "",
            objcarreraest: "",
            listasignaturas: [],
            disabledcambcarrv: true,
            disablmodmati: true,
            mensajepazsalvo: "",
            jornadas: [],
            //Botón aceptar matrícula
            disabledbottmodtot: true,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportAsignatutasEst: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ comprobante: e.target.files })
        console.log(this.state);

    }

    handleChangeSelectNivel = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

        axios
            .post(urlInfoCarrera, {
                idCarreraS: this.state.idCarreraSelect
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                this.setState({ codigoCarrE: codigocarrest });//Verificar quien no más utiliza es variable
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                let listaniveles1 = []
                for (let i = 1; i <= nivelcarrest; i++) {
                    let fila = {
                        codnivel: i,
                        valor: i

                    };
                    listaniveles1.push(fila);
                }
                this.setState({ listaniveles: listaniveles1 });
                //Visualización de Jornadas por Carrera
                axios
                    .post(inscribirUrlListarJornadas, {//guarda para enviar los atributos y envia
                        idCarrerae: codigocarrest,
                    })
                    .then(response => {
                        const jornadas = response.data.data;
                        this.setState({ jornadas });
                    })
            })

    }

    //cada  vez que elija un nivel
    handleChangematcarrnivel = async (e) => {
        //listar las materias del estudiante
        await this.setState({ [e.target.name]: e.target.value })
        var msmpazsalvo = this.determinarPazSalvo(this.state.idCarreraSelect, this.state.nivelEstu);
        this.setState({ mensajepazsalvo: msmpazsalvo });

        axios
            .post(listarAsignaturasCarreraNivelPost, {//guarda para enviar los atributos y envia
                idCarrAsig: this.state.codigoCarrE,
                codNivelMat: this.state.nivelEstu,
                idEstudiante: this.state.cedulaEstudianteSelect,
                idPeriodo: this.state.idPeriodo
            })
            .then(response => {
                const listAsignaturasCarrera = response.data.data;
                this.setState({ listAsignaturasCarrera });
            })
        //Seleccionar el checkbox de paz y salvo según lo ingresado
        axios
            .post(urlEstadoPazSalvo, {
                cedEstudiante: this.state.cedulaEstudianteSelect,
                idrol: cookie.get("rol"),
                idCarrera: this.state.codigoCarrE,
                ciclo: this.state.nivelEstu
            })
            .then(response => {
                if (response.data.success == true) {
                    const $checkboxPSSelect = document.querySelector("#checkpazsalvo");
                    $checkboxPSSelect.checked = true;
                }
                else {
                    const $checkboxPSNoSelect = document.querySelector("#checkpazsalvo");
                    $checkboxPSNoSelect.checked = false;
                }

            })
    }
    //Verificar  si tiene un paz y salvo

    determinarPazSalvo = (carrera, nivel) => {
        var mensaje = "";
        if ((carrera == 1 || carrera == 2 || carrera == 3) && (nivel == 2 || nivel == 4 || nivel == 6)) {
            var mensaje = "";
            mensaje = "Revisar Paz y Salvo";
        }
        else {
            if ((carrera == 4) && (nivel == 5)) {
                var mensaje = "";
                mensaje = "Revisar Paz y Salvo";
            }
        }
        return mensaje;
    }


    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);


        axios
            .get(baseUrlPeriodos)
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodos: listaperiodos });
                }
            })
        //listar todos los paralelos
        axios
            .get(urlListParalelos)
            .then(response => {
                if (response.data.success == true) {
                    const listparalelos = response.data.data;
                    const cant = listparalelos.length;
                    if (cant != 0) {
                        this.setState({ listparalelos });
                    }
                }
            })

        //Mostrar los estados de las matrículas

        axios.get(urlListarEstadosMatri)
            .then(respuesta => {
                const estadosmat = respuesta.data;
                this.setState({ estadosmat });

            })

        //mostrar la carrera a la que pertenece el estudiante 
        axios.get(ApiUrl + "carreras/" + cookie.get("idCarreraEstu") + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const carrera = respuesta.data;
                console.log("esta es la carrera" + carrera.descripcion_carrera)
                this.setState({ carrera });
                //mostrar todos los ciclos(semestres)
            })

        //LISTAR SEDES
        axios.get(inscribirUrlListarsedes)
            .then(respuesta => {
                const sedes = respuesta.data;
                this.setState({ sedes });
                //mostrar todos los ciclos(semestres)
            })
        //LISTAR CARRERAS
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
                //mostrar todos los ciclos(semestres)
            })

        axios.get(ApiUrl + "ciclos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const ciclosAcademicos = respuesta.data;
                // console.log(respuesta)
                this.setState({ ciclosAcademicos });
            })
        /************DEFINIR  FECHA ACTUAL */
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = (fecha.getMonth() + 1);
        var anio = fecha.getFullYear();
        var fechaMatricula = `${anio}-${mes}-${dia}`;

        //define mes 
        var nombremes = mes + "";
        var numeroCaracteresmes = nombremes.length;
        if (numeroCaracteresmes == 1) {
            var mesfecha = "0" + nombremes;
        } else {
            mesfecha = nombremes;
        }
        //define dia
        var nombredia = dia + "";
        var numeroCaracteresdia = nombredia.length;
        if (numeroCaracteresdia == 1) {
            var diafecha = "0" + nombredia;
        } else {
            diafecha = nombredia;
        }
        var fechaactualgenerada = anio + "-" + mesfecha + "-" + diafecha;
        this.setState({ fechaActual: fechaactualgenerada });
        $(function () {

            $("#fechaMatricula").val(fechaactualgenerada)

        });
        this.setState({ fechaMatricula: fechaactualgenerada });
    }

    //BUSQUEDA......

    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.estud
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            //return itemData.indexOf(textData)> -1
            return campo.indexOf(textData) > -1

        })

        if (text == "") {
            newData = this.state.EstudianteBackup;
        }
        this.setState({
            currentItems: newData,
            textBuscar: text,
        })

    }
    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FINDE FUNCION DE PAGINACION
    seleccionarEstudianteuno = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var cedulaest = "";
        var idmatricula = "";
        this.setState({ disabledcambcarr: this.state.disabledcambcarrv });
        var idEstudiante = 0;
        var registrtitulo = 0;
        var cedfisico = 0;
        var titulofisico = 0;
        var fotofisico = 0;
        var inscripcomprofisico = 0;
        var estadoacta = 0;
        $(".afiliado:checked").each(function () {
            idmatricula = $(this).parent().parent().find('td').eq(0).html();
            cedulaest = $(this).parent().parent().find('td').eq(1).html();
            //Documentos
            idEstudiante = $(this).parent().parent().find('td').eq(7).html();
            registrtitulo = $(this).parent().parent().find('td').eq(8).html();
            cedfisico = $(this).parent().parent().find('td').eq(9).html();
            titulofisico = $(this).parent().parent().find('td').eq(10).html();
            fotofisico = $(this).parent().parent().find('td').eq(11).html();
            inscripcomprofisico = $(this).parent().parent().find('td').eq(12).html();
            estadoacta = $(this).parent().parent().find('td').eq(13).html();
        });
        this.setState({ idMatriEstudSelect: idmatricula });
        this.setState({ cedulaEstudianteSelect: cedulaest });
        var intRegistrTitulo = parseInt(registrtitulo);
        var intCedFisico = parseInt(cedfisico);
        var intTituloFisico = parseInt(titulofisico);
        var intFotoFisico = parseInt(fotofisico);
        var intInscripComproFisico = parseInt(inscripcomprofisico);
        var intEstadoActa = parseInt(estadoacta);

        this.estudAsignadoCalifAsig(cedulaest);
        this.setState({ idEstudianteSelect: idEstudiante });
        //Activar los checkbox de los documentos
        if (intRegistrTitulo == 1) {
            const $checkboxPSSelect = document.querySelector("#checkregministeduc");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checkregministeduc");
            $checkboxPSNoSelect.checked = false;
        }

        if (intCedFisico == 1) {
            const $checkboxPSSelect = document.querySelector("#checkcedfisico");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checkcedfisico");
            $checkboxPSNoSelect.checked = false;
        }

        if (intTituloFisico == 1) {
            const $checkboxPSSelect = document.querySelector("#checktitulfisico");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checktitulfisico");
            $checkboxPSNoSelect.checked = false;
        }

        if (intFotoFisico == 1) {
            const $checkboxPSSelect = document.querySelector("#checkfotfisico");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checkfotfisico");
            $checkboxPSNoSelect.checked = false;
        }

        if (intInscripComproFisico == 1) {
            const $checkboxPSSelect = document.querySelector("#checkdocinscomprofisico");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checkdocinscomprofisico");
            $checkboxPSNoSelect.checked = false;
        }

        if (intEstadoActa == 1) {
            const $checkboxPSSelect = document.querySelector("#checkaceptinscompro");
            $checkboxPSSelect.checked = true;
        }
        else {
            const $checkboxPSNoSelect = document.querySelector("#checkaceptinscompro");
            $checkboxPSNoSelect.checked = false;
        }

        axios
            .post(inscribirUrlCarreraEst, {
                cedEstudiantee: cedulaest,
            })
            .then(response => {
                const codigocarrest = response.data.objcarrea[0].id_carrera;
                const descripcarrest = response.data.objcarrea[0].descripcion_carrera;
                const nivelcarrest = response.data.objcarrea[0].num_niveles;
                const idJornadaEstud = response.data.idjorncarrestud;
                this.setState({ codigoCarrE: codigocarrest });
                this.setState({ descripCarrE: descripcarrest });
                this.setState({ numnivelE: nivelcarrest });
                this.setState({ idJornada: idJornadaEstud });

                //Selecciona la última carrera del estudiante
                $(function () {
                    $("#idCarreraSelect").val(codigocarrest);

                });
                this.setState({ idCarreraSelect: codigocarrest });
                //Visualización de Jornadas por Carrera y selecciona la jornada ingresada por el estudiante
                axios
                    .post(inscribirUrlListarJornadas, {//guarda para enviar los atributos y envia
                        idCarrerae: codigocarrest,
                    })
                    .then(response => {
                        const jornadas = response.data.data;
                        this.setState({ jornadas });

                        $(function () {
                            $("#idJornada").val(idJornadaEstud);
                        });
                    })
            })
        axios
            .post(datosMatriculaEstudiantes, {//guarda para enviar los atributos y envia
                cedEstudiantee: cedulaest,
                idPeriodo: this.state.idPeriodo
            })
            .then(response => {

                if (response.data.success == true) {
                    let listaniveles1 = []
                    for (let i = 1; i <= this.state.numnivelE; i++) {

                        let fila = {
                            codnivel: i,
                            valor: i

                        };

                        listaniveles1.push(fila);
                    }
                    this.setState({ listaniveles: listaniveles1 });
                    $(function () {

                        $("#fechaMatricula").val(response.data.data[0].fecha_matricula);
                        $("#tipoMatricula").val(response.data.data[0].tipo_matricula);
                        $("#estadMat").val(response.data.data[0].estado_matricula_id);
                        $("#idSede").val(response.data.data[0].sede_id);
                        $("#nivelEstu").val(response.data.data[0].ciclo_nivel);

                    });
                    this.setState({ tipoMatricula: response.data.data[0].tipo_matricula });
                    this.setState({ estadMat: response.data.data[0].estado_matricula_id });
                    this.setState({ idSede: response.data.data[0].sede_id });
                    this.setState({ nivelEstu: response.data.data[0].ciclo_nivel });
                    this.setState({ fechaMatriculae: response.data.data[0].fecha_matricula });
                    axios
                        .post(listarAsignaturasCarreraNivelPost, {//guarda para enviar los atributos y envia

                            idCarrAsig: this.state.codigoCarrE,
                            codNivelMat: this.state.nivelEstu,
                            idEstudiante: this.state.cedulaEstudianteSelect,
                            idPeriodo: this.state.idPeriodo
                        })
                        .then(response => {

                            const listAsignaturasCarrera = response.data.data;
                            this.setState({ listAsignaturasCarrera });
                        })
                    //Seleccionar el checkbox de paz y salvo si fue ingresado
                    axios
                        .post(urlEstadoPazSalvo, {
                            cedEstudiante: this.state.cedulaEstudianteSelect,
                            idrol: cookie.get("rol"),
                            idCarrera: this.state.codigoCarrE,
                            ciclo: this.state.nivelEstu
                        })
                        .then(response => {
                            if (response.data.success == true) {
                                const $checkboxPSSelect = document.querySelector("#checkpazsalvo");
                                $checkboxPSSelect.checked = true;
                            }
                            else {
                                const $checkboxPSNoSelect = document.querySelector("#checkpazsalvo");
                                $checkboxPSNoSelect.checked = false;
                            }

                        })

                }
                else {
                }
            })
        //Consular datos del estudiante por la cédula
        axios
            .post(urlDatosEstudianteCed, {
                cedEstudiantee: cedulaest
            })
            .then(response => {

                if (response.data.success == true) {

                    $(function () {
                        $("#observaciones").val(response.data.data[0].observacion);
                    });
                    this.setState({ observaciones: response.data.data[0].observacion });
                }
            })

    }
    //Habilita botón de cambio de carrera

    handleChangeCambiarCarr = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        // checkcambiocarr
        var checkbox = document.getElementById('checkcambiocarr');
        var checked = checkbox.checked;
        if (checked) {
            this.setState({ disabledcambcarr: !this.state.disabledcambcarrv });
        }
        else
            this.setState({ disabledcambcarr: this.state.disabledcambcarrv });
    }

    //************************************MATRICULAR UN ESTUDIANTE***************************** */
    matricularEstudiante = async (e) => {
        // e.preventDefault();
        var combmsmalert = "¿Está seguro que desea modificar los datos de la matricula?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    // $(document).ready(function(){
                    let materiasselect = [];
                   // $("input[type=checkbox]:checked").each(function () {
                    $("input:checkbox[class=checkbox-asig]:checked").each(function () {

                        console.log($(this).parent().parent().find('td').eq(0).html());
                        //numatricula
                        var estado = ";";
                        var nombreselect = "#" + $(this).parent().parent().find('td').eq(0).html();
                        estado = $(nombreselect).val();

                        //numparalelomatricula
                        var estadoparalelo = "1";
                        var nombreselectpara = "#" + "paral" + $(this).parent().parent().find('td').eq(0).html();
                        estadoparalelo = $(nombreselectpara).val();

                        let fila = {
                            codigom: $(this).parent().parent().find('td').eq(0).html(),
                            nivel: $(this).parent().parent().find('td').eq(2).html(),
                            asignatura: $(this).parent().parent().find('td').eq(3).html(),
                            nummatricula: estado,
                            numparalelomatricula: estadoparalelo,

                        };
                        materiasselect.push(fila);

                    });
                    var combinancion = JSON.stringify(materiasselect);
                    if (this.state.cedulaEstudianteSelect != "") {
                        axios
                            .post(inscribirUrlCrearmatricula, {

                                Periodoe: this.state.idPeriodo,
                                cedEstudiantee: this.state.cedulaEstudianteSelect,
                                TipoMatriculae: this.state.tipoMatricula,
                                estadoMatrie: this.state.idEstadMat,
                                IdSedee: this.state.idSede,
                                fechaMatriculae: this.state.fechaMatricula,
                                cicloestudiantee: this.state.nivelEstu,
                                idCarrerae: this.state.codigoCarrE,
                                idJornadae: this.state.idJornada,
                                listaMatMatriculare: materiasselect,
                                observacionese: this.state.observaciones
                            })
                            .then(response => {

                                if (response.data.success === true) {

                                    console.log(response.data.message);
                                    this.setState({ estado: "Datos Ingresados Correctamente" });

                                    swal({
                                        text: "Datos Ingresados Correctamente",
                                        icon: "success",
                                        button: "Aceptar",

                                    });
                                    //llamar nuevamente a la lista
                                    axios
                                        .post(listarAsignaturasCarreraNivelPost, {//guarda para enviar los atributos y envia
                                            idCarrAsig: this.state.codigoCarrE,
                                            codNivelMat: this.state.nivelEstu,
                                            idEstudiante: this.state.cedulaEstudianteSelect,
                                            idPeriodo: this.state.idPeriodo
                                        })
                                        .then(response => {

                                            const listAsignaturasCarrera = response.data.data;
                                            this.setState({ listAsignaturasCarrera });
                                        })
                                } else {


                                    if (response.data.success === false) {


                                        if (response.data.message === "No insertar") {
                                            swal({
                                                text: "No se registro la matrícula",
                                                icon: "error",
                                                button: "Aceptar",

                                            });

                                        } else {

                                            if (response.data.message === "Existe") {
                                                swal({
                                                    text: "La matrícula  ya se encuentra existe",
                                                    icon: "error",
                                                    button: "Aceptar",

                                                });

                                            } else {
                                                swal({
                                                    text: "Error no identificado",
                                                    icon: "error",
                                                    button: "Aceptar",

                                                });

                                            }
                                        }
                                    }

                                }
                            })
                            .catch(error => {
                                this.setState({ error: "No existe conexión con el servidor" });
                                swal({
                                    text: "No existe conexión con el servidor",
                                    icon: "error",
                                    button: "Aceptar",

                                });
                            });

                    }//
                    else {
                        swal({
                            text: "No se  ha seleccionado el estudiante",
                            icon: "error",
                            button: "Aceptar",

                        });
                    }
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    //Mostrar las asignaturas matriculadas de un estudiante
    mostrarAsignaturasMat = (registro) => {

        axios.post(urlListarAsignaturas, {
            idEstudiante: registro.id_estudiante,
            idPeriodo: this.state.idPeriodo,
            idCarrera: this.state.idCarreraSelect
        })
            .then(response => {
                if (response.data.success === true) {
                    const listasignaturas = response.data.data;
                    this.setState({ listasignaturas });
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
        this.setState({ modalReportAsignatutasEst: true, form1: registro });

    }
    //El estudiante tiene una calificacion>0 en las asignaturas matriculadas
    estudAsignadoCalifAsig = (cedula) => {
        axios.post(urlEstCalifRegistradasEst, {
            cedulaEst: cedula,
            idPeriodo: this.state.idPeriodo
        })
            .then(response => {
                if (response.data.success === true) {
                    const estado = response.data.data;
                    if (estado == 0) {
                        this.setState({ disablmodmat: !this.state.disablmodmati })
                    }
                    else {
                        this.setState({ disablmodmat: this.state.disablmodmati })
                    }
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });


    }


    ocultarModalReportAsignaturas = () => {
        this.setState({ modalReportAsignatutasEst: false });
    }

    handleChangePeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        
        const $checkboxPSNoSelectRegMin = document.querySelector("#checkregministeduc").checked = false;
        const $checkboxPSNoSelectCedF = document.querySelector("#checkcedfisico").checked = false;
        const $checkboxPSSelectTitulF = document.querySelector("#checktitulfisico").checked = false;
        const $checkboxPSNoSelectFotoF = document.querySelector("#checkfotfisico").checked = false;
        const $checkboxPSNoSelectInsComproF = document.querySelector("#checkdocinscomprofisico").checked = false;
        const $checkboxPSNoSelectAceptCompro = document.querySelector("#checkaceptinscompro").checked = false;

        axios.post(inscribirUrlListarestud, {
            idPeriodoActual: this.state.idPeriodo
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    listaauxiliar = respuesta.data.data;
                    const estud = respuesta.data.data;
                    this.setState((state) => {
                        return { estud: estud }
                    }
                    );
                    const EstudianteBackup = estud;

                    this.setState((state) => {
                        return { EstudianteBackup: EstudianteBackup }
                    });
                    //PAGINACION
                    this.setState({ itemOffset: 0 });
                    const endOffset = this.state.itemOffset + itemsPerPage;
                    this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                    this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                    this.setState((state) => {
                        return { itemOffset: state.itemOffset + endOffset }
                    });
                    //FIN  D DE LA PAGINACION
                } else {
                    this.setState({ error: "No existe estudiantes" });
                    swal({
                        text: "¡No existe estudiantes para modificar la matrícula!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Modifica el estado de la matrícula
    handleChangeEstado = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        axios.post(urlModifEstado, {
            idMatricula: this.state.idMatriEstudSelect,
            estado: this.state.estadMat

        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    swal({
                        text: "¡Estado Modificado!",
                        icon: "success",
                        button: "Aceptar",
                    });

                } else {
                    this.setState({ error: "No se modificó el estado de la matrícula" });
                    swal({
                        text: "¡No se modificó el estado de la matrícula!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Le entregaron paz y salvo
    handleChangePazSalvo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var checkbox = document.getElementById('checkpazsalvo');
        var checked = checkbox.checked;

        if (checked) {
            this.ingresarPazySalvo(this.state.cedulaEstudianteSelect, cookie.get("idAdministrativo"), cookie.get("rol"), this.state.idCarreraSelect, this.state.nivelEstu, this.state.fechaActual);
        }
        else
            this.eliminarPazySalvo(this.state.cedulaEstudianteSelect, cookie.get("idAdministrativo"), cookie.get("rol"), this.state.idCarreraSelect, this.state.nivelEstu);
    }
    //Ingresar Paz y Salvo
    ingresarPazySalvo = (cedula, idAdmin, idRol, idCarrera, ciclo, fecha_actual) => {
        axios
            .post(urlRegistroPazSalvo, {
                cedEstudiante: cedula,
                idAdministrativo: idAdmin,
                idrol: idRol,
                idCarrera: idCarrera,
                ciclo: ciclo,
                fechaactual: fecha_actual
            })
            .then(response => {
                if (response.data.success == false) {
                    swal({
                        text: "Datos No Registrados!",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ estado: "Datos No Registrados!" });
                }

            })
    }
    //Eliminar Paz y Salvo
    eliminarPazySalvo = (cedula, idAdmin, idRol, idCarrera, ciclo) => {
        axios
            .post(urlEliminarPazySalvo, {
                cedEstudiante: cedula,
                idAdministrativo: idAdmin,
                idrol: idRol,
                idCarrera: idCarrera,
                ciclo: ciclo
            })
            .then(response => {
                if (response.data.success == false) {

                    swal({
                        text: "Datos No Registrados!",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ estado: "Datos No Registrados!" });
                }

            })
    }
    //Modificar la jornada
    handleChangeJornada = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        axios.post(urlModifJornada, {
            idCarrerae: this.state.idCarreraSelect,
            cedEstudiantee: this.state.cedulaEstudianteSelect,
            idJornadae: this.state.idJornada

        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    swal({
                        text: "¡Jornada Modificada!",
                        icon: "success",
                        button: "Aceptar",
                    });

                } else {
                    this.setState({ error: "No se modificó la jornada" });
                    swal({
                        text: "¡No se modificó la jornada!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Cambio de coordinación

    handleChangeCoordinacion = async (e) => {
        await this.setState({ [e.target.name]: e.target.value });
        axios.post(urlModifCoord, {
            cedEstudiantee: this.state.cedulaEstudianteSelect,
            idCoord: this.state.idSede,
            idMatricula: this.state.idMatriEstudSelect
        })
            .then(respuesta => {
                if (respuesta.data.success == true) {
                    swal({
                        text: "¡Coordinación Modificado!",
                        icon: "success",
                        button: "Aceptar",
                    });

                } else {
                    this.setState({ error: "No se modificó la coordinación" });
                    swal({
                        text: "¡No se modificó la coordinación!",
                        icon: "info",
                        button: "Aceptar",
                    });
                    this.setState({ currentItems: [] });
                }
            })
    }
    //Modificar las observaciones del estudiante
    modificarObservaciones = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(urlModifObservacion, {
                cedEstudiante: this.state.cedulaEstudianteSelect,
                observacionEstud: this.state.observaciones
            })
            .then(response => {
                if (response.data.success == true) {
                    //Consular datos del estudiante por la cédula
                    axios
                        .post(urlDatosEstudianteCed, {
                            cedEstudiantee: this.state.cedulaEstudianteSelect
                        })
                        .then(response => {
                            if (response.data.success == true) {
                                $(function () {
                                    $("#observaciones").val(response.data.data[0].observacion);
                                });
                                this.setState({ observaciones: response.data.data[0].observacion });
                                swal({
                                    text: "Se han modificado las observaciones.",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                            }
                        })
                }
                else {
                    swal({
                        text: "Datos No Registrados!",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ observaciones: "" });
                }
            })
    }
    //Actualizar estados de los documentos
    handleChangeEstadoDocumentos = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var idEstud = this.state.idEstudianteSelect;
       
        //Check del registro del título en el ministerio de educación
        var resultCheckRegistMinis = document.getElementById('checkregministeduc').checked;
        var valorCheckRegistMinis = 0;
        if (resultCheckRegistMinis)
            valorCheckRegistMinis = 1;
        //Check de la cedula en físico

        var resultCheckCedFisico = document.getElementById('checkcedfisico').checked;
        var valorCheckCedFisico = 0;
        if (resultCheckCedFisico)
            valorCheckCedFisico = 1;
        //Check del título en físico

        var resultCheckTitulFisico = document.getElementById('checktitulfisico').checked;
        var valorCheckTitulFisico = 0;
        if (resultCheckTitulFisico)
            valorCheckTitulFisico = 1;
        //Check de fotos físicas

        var resultCheckFotFisico = document.getElementById('checkfotfisico').checked;
        var valorCheckFotFisico = 0;
        if (resultCheckFotFisico)
            valorCheckFotFisico = 1;
        //Check documento de inscripción en físico

        var resultCheckDocInsComproFisico = document.getElementById('checkdocinscomprofisico').checked;
        var valorCheckDocInsComproFisico = 0;
        if (resultCheckDocInsComproFisico)
            valorCheckDocInsComproFisico = 1;

        axios.post(urlActEntregDocumentMatri, {
            idEstudiante: idEstud,
            estadRegistMinis: valorCheckRegistMinis,
            estadCedFisico: valorCheckCedFisico,
            estadTitulFisico: valorCheckTitulFisico,
            estadFotFisico: valorCheckFotFisico,
            estadDocInsComproFisico: valorCheckDocInsComproFisico,
        })
            .then(response => {
                if (response.data.success === false) {
                    swal({
                        text: "No fue posible actualizar la información!",
                        icon: "error",
                        button: "Aceptar",
                    });
                
                }
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Modificar Matrículas</b>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >

                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa-magnifying-glass"></i> Buscar:</b></label>
                                    <input type="text" class="form-control col-md-12" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />

                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangePeriodo} aria-label="Default select example">
                                        <option value="undefined" selected="true" disabled>Seleccione el periódo</option>
                                        {this.state.periodos.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTUDIANTE
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >

                                                        <th scope="col">Cédula</th>
                                                        <th scope="col">Primer Apellido</th>
                                                        <th scope="col">Segundo Apellido</th>
                                                        <th scope="col">Primer Nombre</th>
                                                        <th scope="col">Segundo Nombre</th>
                                                        <th scope="col">Asignaturas</th>

                                                        <th scope="col">Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map((consola) => (
                                                            <tr class="list-afiliados" key={this.state.estud.id_estudiante}  >

                                                                <td class="oculto" >{consola.id_matricula}</td>
                                                                <td >{consola.numero_identificacion_estudiante}</td>
                                                                <td >{consola.primer_apellido_estudiante}</td>
                                                                <td>{consola.segundo_apellido_estudiante}</td>
                                                                <td>{consola.primer_nombre_estudiante}</td>
                                                                <td>{consola.segundo_nombre_estudiante}</td>
                                                                <td>
                                                                    <button onClick={() => this.mostrarAsignaturasMat(consola)}  > <i className="fas fa-clipboard-list" ></i></button>
                                                                </td>

                                                                <td class="oculto">{consola.id_estudiante}</td>
                                                                <td class="oculto">{consola.regist_titul_minist_edu}</td>
                                                                <td class="oculto">{consola.cedula_fisico}</td>
                                                                <td class="oculto">{consola.titulo_fisico}</td>
                                                                <td class="oculto">{consola.fotos_fisico}</td>
                                                                <td class="oculto">{consola.inscrip_comp_fisico}</td>
                                                                <td class="oculto">{consola.estado_acta_comprom}</td>
                                                                <td>

                                                                    <input type="radio" value="1" id="afiliado" name="afiliado" onChange={this.seleccionarEstudianteuno} class="afiliado" />

                                                                </td>

                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>

                                            <Modal size='lg' isOpen={this.state.modalReportAsignatutasEst}>
                                                <ModalHeader>

                                                </ModalHeader>
                                                <ModalBody class="colormodal" >
                                                    <FormGroup>
                                                        <input name="idestudiante" type="hidden" value=""></input>
                                                        <input name="numcomprobnate" type="hidden" value=""></input>

                                                        <div class="table-responsive">
                                                            <table className="table table-bordered" id="tablacientificos" width="100%" >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Código</th>
                                                                        <th scope="col">Asignatura</th>
                                                                        <th scope="col">Nivel</th>
                                                                        <th scope="col">No Matrícula</th>
                                                                        <th scope="col">Paralelo</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {this.state.listasignaturas.map(consola => (
                                                                        <tr key={consola.id_asignatura}>


                                                                            <td>
                                                                                {consola.codigo_asignatura}

                                                                            </td>
                                                                            <td>{consola.descripcion_asignatura}
                                                                            </td>
                                                                            <td>{consola.nivel_asignatura}</td>
                                                                            <td>{consola.tipo_matricula_asignatura}
                                                                            </td>
                                                                            <td>{consola.nombre_paralelo}
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAsignaturas}>Salir</button>
                                                </ModalFooter>
                                            </Modal>


                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <ReactPaginate
                                            nextLabel="next >"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ESTADO DE LOS DOCUMENTOS
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Registro de título en el Ministerio de Educación</th>
                                                        <th scope="col">Cédula(físico)</th>
                                                        <th scope="col">Título de bachiller(físico)</th>
                                                        <th scope="col">Fotos(físico)</th>
                                                        <th scope="col">Inscripción/Compromiso(físico)</th>
                                                        <th scope="col">Inscripción/Compromiso</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <td> <input type="checkbox" id="checkregministeduc" name="checkregministeduc" onClick={this.handleChangeEstadoDocumentos}  /> </td>
                                                    <td> <input type="checkbox" id="checkcedfisico" name="checkcedfisico" onClick={this.handleChangeEstadoDocumentos} /> </td>
                                                    <td> <input type="checkbox" id="checktitulfisico" name="checktitulfisico"  onClick={this.handleChangeEstadoDocumentos} /> </td>
                                                    <td> <input type="checkbox" id="checkfotfisico" name="checkfotfisico" onClick={this.handleChangeEstadoDocumentos}  /> </td>
                                                    <td> <input type="checkbox" id="checkdocinscomprofisico" name="checkdocinscomprofisico"  onClick={this.handleChangeEstadoDocumentos}  /> </td>
                                                    <td> <input type="checkbox" id="checkaceptinscompro" name="checkaceptinscompro"  disabled /> </td>
                           
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                       MATRÍCULA
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">


                                    <label className="form-label"><b>Carrera</b></label> <input type="checkbox" id="checkcambiocarr" name="checkcambiocarr" onClick={this.handleChangeCambiarCarr} />

                                    <select className="form-select" id="idCarreraSelect" name="idCarreraSelect" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example" disabled={(this.state.disabledcambcarr) ? "disabled" : ""}>
                                        <option value="">Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>



                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Estado</b></label>
                                    <select className="form-select" id="estadMat" name="estadMat" value={this.state.value} onChange={this.handleChangeEstado} aria-label="Default select example">

                                        {this.state.estadosmat.map(person => <option key={person.id} value={person.id} > {person.estado}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Tipo de Matrícula</b></label>
                                    <select className="form-select" id="tipoMatricula" name="tipoMatricula" value={this.state.value} onChange={this.handleChangeSelectNivel} aria-label="Default select example">
                                        <option value="undefined">Seleccione</option>

                                        <option value="1">Ordinaria</option>
                                        <option value="2">ExtraOrdinaria</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Nivel</b></label>
                                    <select className="form-select" id="nivelEstu" name="nivelEstu" value={this.state.value} onChange={this.handleChangematcarrnivel} aria-label="Default select example">
                                        <option value="undefined">Seleccione </option>
                                        {this.state.listaniveles.map(person => <option key={person.codnivel} value={person.codnivel} > {person.valor}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Fecha de Matrícula</b></label><br />
                                    <input type="date" id="fechaMatricula" name="fechaMatricula" onChange={this.handleChange} className="form-control" placeholder="0000-00-00" required />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">

                                    <label className="form-label"><b>Coordinación</b></label>
                                    <select className="form-select" id="idSede" name="idSede" value={this.state.value} onChange={this.handleChangeCoordinacion} aria-label="Default select example">
                                        <option value="undefined">Seleccione una coordinación</option>

                                        {this.state.sedes.map(person => <option key={person.id_sede} value={person.id_sede} > {person.descripcion_sede}</option>)}

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Jornada</b></label>
                                    <select className="form-select" id="idJornada" name="idJornada" value={this.state.value} onChange={this.handleChangeJornada} aria-label="Default select example" required >
                                        <option value="">Seleccione</option>
                                        {this.state.jornadas.map(person => <option key={person.id_jornada} value={person.id_jornada} > {person.descrip_jornada}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Paz y salvo</b></label> <input type="checkbox" id="checkpazsalvo" name="checkpazsalvo" onClick={this.handleChangePazSalvo} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <span class="mensajesusp">  {this.state.mensajepazsalvo}</span>
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        ASIGNATURAS A TOMAR
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table  text-left" >
                                                <thead >
                                                    <tr>

                                                        <th scope="col">Código</th>
                                                        <th scope="col">Nivel</th>
                                                        <th scope="col">Asignatura</th>
                                                        <th scope="col">No Matrícula</th>
                                                        <th scope="col">Paralelo</th>
                                                        <th scope="col">Matricular</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.listAsignaturasCarrera.map(consola => (
                                                        <tr key={this.state.cursos.id_asignatura}>
                                                            <td class="idasig oculto">{consola.id_asignatura}</td>
                                                            <td class="codigo">{consola.codigo_asignatura}</td>
                                                            <td class="nivel" >{consola.nivel_asignatura}</td>
                                                            <td class="asignatura">{consola.descripcion_asignatura}</td>
                                                            <td class="nummatricula">

                                                                <select className="form-select" name={consola.id_asignatura} id={consola.id_asignatura} value={this.state.value} disabled={(this.state.disablmodmat) ? "disabled" : ""} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione</option>

                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>

                                                                    {(() => {

                                                                        $(function () {

                                                                            $("#" + consola.id_asignatura).val(consola.tipo_matricula_asignatura);

                                                                        });

                                                                    })()}

                                                                </select>
                                                            </td>

                                                            <td class="paralelomatricula">

                                                                <select className="form-select" name={"paral" + consola.id_asignatura} id={"paral" + consola.id_asignatura} value={this.state.value} disabled={(this.state.disablmodmat) ? "disabled" : ""} aria-label="Default select example">
                                                                    <option value="undefined">Seleccione un periodo:</option>
                                                                    {this.state.listparalelos.map(consola => <option key={consola.id_paralelo} value={consola.id_paralelo} > {consola.nombre_paralelo}</option>)}
                                                                </select>

                                                                {(() => {

                                                                    $(function () {

                                                                        $("#paral" + consola.id_asignatura).val(consola.paralelo);

                                                                    });
                                                                    //alert(variable);
                                                                })()}

                                                            </td>

                                                            <td class="matricular">
                                                                <input disabled={(this.state.disablmodmat) ? "disabled" : ""} name={"check" + consola.id_asignatura} id={"check" + consola.id_asignatura} type="checkbox" class="checkbox-asig"/>

                                                                {(() => {
                                                                    if (consola.matriculado == 1) {

                                                                        $(function () {


                                                                            $('#check' + consola.id_asignatura)[0].checked = true;

                                                                        })

                                                                    } else {

                                                                        $(function () {

                                                                            $('#check' + consola.id_asignatura)[0].checked = false;
                                                                        })
                                                                    }
                                                                })()}

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                       OBSERVACIONES
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                    <textarea id="observaciones" name="observaciones" onChange={this.handleChange} className="form-control" placeholder="Ingrese..." maxlength="500" required disabled={this.state.isDisabled} ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <br />
                        <div className="text-center">
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                disabled={(this.state.disablmodmat) ? "disabled" : ""}
                                style={{ margin: "5px", width: "180px" }}
                                onClick={e =>
                                    this.matricularEstudiante(e)
                                }
                            >
                                <b>Modificar matrícula</b>
                            </button>
                            <button id="agregar" type="button"
                                className="btn  back-istmas centrar"
                                style={{ margin: "5px", width: "214px" }}
                                onClick={e =>
                                    this.modificarObservaciones(e)
                                }
                            >
                                <b>Modificar observaciones</b>
                            </button>
                        </div>
                        <div className="alert alert-warning" role="alert" hidden={this.state.hidden}>
                            {this.state.estado}


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
