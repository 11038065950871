import React, { Component } from 'react'
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

//Rutas de ingreso de matriz
const devuelNumNivelesCarrera = ApiUrl + "numnivelescarr" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const carrerasUrl = ApiUrl + "listarcarreras" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const UrlListarAsignMatCiclo = ApiUrl + "ListarAsignMatCiclo" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModAsignatura = ApiUrl + "ModificarAsignatura" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngrAsignatura = ApiUrl + "IngresarAsignatura" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 10;

export default class AsignaturasEstCarreraCicloAdm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            nivel1: '',
            nivel2: '',
            nivel3: '',
            nivel4: '',
            nivel5: '',
            nivel6: '',
            EstudianteBackup: [],

            carrera_seleccionada: '',
            ciclo_seleccionado: '',
            carrera: [],
            usuario_moodle: [],
            estado: '',

            //nuevas variables
            nombresEstudiante: "",
            cedulaEstudianteSelect: "",
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listanivelesIng: [],
            listaestudiantes: [],
            listacuotasestudiante: [],
            porcentaje_beca: 0,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables programador2
            modalReportAbonosCuotaEst: false,
            modalPagoColegituraMes: false,
            cuotascolegiaturam: [],
            ruta: "",
            disabledformpag: true,
            disabledtransfe: true,
            disabledtotalbott: true,
            disabledaprorechvicerr: true,
            disablednumdeposith: true,
            enunciadonumdeposit: "Número de depósito(número de papeleta11111 o referencia)",
            form1: {

            },
            form: {
                id_pension_abono_mes: '',
            },
            disabledabonsinap: true,
            idMatriculaEstAux: '',
            idCarreraEstAux: '',
            idCicloEstAux: '',


        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event) {
        if (event.key === "Escape") {

            this.setState({ modalPagoColegituraMes: false });
            this.setState({ modalReportAbonosCuotaEst: false });

        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        this.setState({ estado: "" });
    }

    handleChangeDevolverIdCarreraIng = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarreraIng,
                periodoIdf: this.state.periodoId,
            })
            .then(response => {
                this.setState({ listanivelesIng: response.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    handleChangeDevolverIdCarrera = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ carrera_seleccionada: this.state.idCarrera });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(devuelNumNivelesCarrera, {
                idCarreraf: this.state.idCarrera,
                periodoIdf: this.state.periodoId,
            })
            .then(response => {
                const listaniveles1 = response.data.data;
                this.setState({ listaniveles: listaniveles1 });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }


    handleChangeDevolverIdCiclo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeIngresarAsignMatCiclo = () => {
        this.setState({ modalIngresarAsignatura: true });
    }

    handleChangeListarAsignMatCiclo = () => {
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
        axios
            .post(UrlListarAsignMatCiclo, {
                idCarrera: this.state.carrera_seleccionada,
                idCiclo: this.state.ciclo_seleccionado,
            })
            .then(response => {
                var array = response.data.data;
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listaestudiantes;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });


                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);
        axios.get(carrerasUrl)
            .then(res => {
                const carrera = res.data;
                this.setState({ carrera });
            })

        //periodos
        axios.get(ApiUrl + "periodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71")
            .then(respuesta => {
                const periodos = respuesta.data.descripcion_periodo_academico;
                const periodosId = respuesta.data.id_periodo_academico;
                this.setState({ periodoId: respuesta.data.id_periodo_academico });
                this.setState({ periodos });
                this.setState({ periodosId });
                // console.log("periodos"+periodosId);
            })
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    mostrarModalAsignatura = (registro) => {
        $(function () {

            $("#id").val(registro.id_asignatura);
            $("#codigo").val(registro.codigo_asignatura);
            $("#descripcion").val(registro.descripcion_asignatura);
            $("#creditos").val(registro.creditos_asignatura);
            $("#hdocencia").val(registro.hora_docencia);
            $("#hpracticas").val(registro.hora_practica);
            $("#hautonomas").val(registro.hora_autonoma);
            $("#htotales").val(registro.hora_totales);
        });

        this.setState({ modalAsignatura: true, form: registro });
        this.setState({ id: registro.id_asignatura });
        this.setState({ codigo: registro.codigo_asignatura });
        this.setState({ descripcion: registro.descripcion_asignatura });
        this.setState({ creditos: registro.creditos_asignatura });
        this.setState({ hdocencia: registro.hora_docencia });
        this.setState({ hpracticas: registro.hora_practica });
        this.setState({ hautonomas: registro.hora_autonoma });
        this.setState({ htotales: registro.hora_totales });
    }

    ocultarModalIngresarAsignatura = () => {
        this.setState({ modalIngresarAsignatura: false });
    }

    ocultarModalAsignatura = () => {
        this.setState({ modalAsignatura: false });
    }

    ingresarAsignatura = () => {
        axios
            .post(urlIngrAsignatura, {
                codigo: this.state.codigoIng,
                descripcion: this.state.descripcionIng,
                creditos: this.state.creditosIng,
                hdocencia: this.state.hdocenciaIng,
                hpracticas: this.state.hpracticasIng,
                hautonomas: this.state.hautonomasIng,
                htotales: this.state.htotalesIng,
                idCarrera: this.state.idCarreraIng,
                idCiclo: this.state.idCicloIng,
            })
            .then(response => {
                if (response.data.success === true) {
                    swal({
                        text: "Datos procesados correctamente",
                        icon: "success",
                        button: "Aceptar",
                    });
                    this.handleChangeListarAsignMatCiclo();
                } else {
                    swal({
                        text: "No se actualizó los datos",
                        icon: "error",
                        button: "Aceptar",
                    });
                    this.setState({ modalPagoColegituraMes: false });

                }
            })
            .catch(error => {

                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",

                });
            });

    }

    modificarAsignatura = () => {
        var combmsmalert = "¿Está seguro que desea modificar la asignatura?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlModAsignatura, {
                            id: this.state.id,
                            codigo: this.state.codigo,
                            descripcion: this.state.descripcion,
                            creditos: this.state.creditos,
                            hdocencia: this.state.hdocencia,
                            hpracticas: this.state.hpracticas,
                            hautonomas: this.state.hautonomas,
                            htotales: this.state.htotales,
                            nivel: this.state.idNivelMod,

                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.handleChangeListarAsignMatCiclo();
                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalPagoColegituraMes: false });

                            }
                        })
                        .catch(error => {

                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });


                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Colegiaturas de Estudiantes (Carrera y Ciclo)</b>
                    </div>
                    <div className="card-body">

                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Carrera</b></label>
                                    <select className="form-select" name="idCarrera" value={this.state.value} onChange={this.handleChangeDevolverIdCarrera} aria-label="Default select example">
                                        <option value="undefined">Seleccione una carrera</option>
                                        {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-1 col-xl-4 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Ciclos</b></label>
                                    <select className="form-select" name="idCiclo" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                        <option value="undefined">Seleccione un ciclo:</option>
                                        {this.state.listaniveles.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarAsignMatCiclo} />
                                    <input class="btn back-istmas centrar" type="button" value="Nueva" onClick={this.handleChangeIngresarAsignMatCiclo} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Créditos</th>
                                    <th>H. Docencia</th>
                                    <th>H. Prácticas</th>
                                    <th>H. Autónomas</th>
                                    <th>H. Totales</th>
                                    <th>Seleccionar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.currentItems && this.state.currentItems.map((consola) => (
                                    <tr key={consola.id_matricula}>
                                        <td class="id oculto">{consola.id_asignatura}</td>
                                        <td class="codigo">{consola.codigo_asignatura}</td>
                                        <td class="desc">{consola.descripcion_asignatura}</td>
                                        <td class="creditos">{consola.creditos_asignatura}</td>
                                        <td class="hd">{consola.hora_docencia}</td>
                                        <td class="hp">{consola.hora_practica}</td>
                                        <td class="ha">{consola.hora_autonoma}</td>
                                        <td class="ht">{consola.hora_totales}</td>
                                        <td><button onClick={() => this.mostrarModalAsignatura(consola)} > <i className=" fas fa-clipboard-list" ></i></button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive">
                    <ReactPaginate
                        nextLabel="next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                     </div>
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                            <div className="card text-center ">

                                <div className="card-body centrar " style={{ width: "100%" }}>

                                    <div class="table-responsive">
                                        <Modal isOpen={this.state.modalAsignatura} >
                                            <ModalHeader>
                                                Asignatura
                                            </ModalHeader>
                                            <ModalBody>
                                                <FormGroup>
                                                    <div className="mb-3 centrar">
                                                        <input name="id" id="id" type="hidden" onChange={this.handleChange} ></input>
                                                        <label className="form-label">Código</label><br />
                                                        <input type="text" id="codigo" name="codigo" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Nombre</label><br />
                                                        <input type="text" id="descripcion" name="descripcion" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Ciclos</label>
                                                        <select className="form-select" id="idNivelMod" name="idNivelMod" value={this.state.value} onChange={this.handleChange} aria-label="Default select example">
                                                            <option value="undefined">Seleccione un ciclo:</option>
                                                            {this.state.listaniveles.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                                        </select>
                                                        <label className="form-label">Creditos</label><br />
                                                        <input type="text" id="creditos" name="creditos" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Docencia</label><br />
                                                        <input type="text" id="hdocencia" name="hdocencia" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Prácticas</label><br />
                                                        <input type="text" id="hpracticas" name="hpracticas" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Autónomas</label><br />
                                                        <input type="text" id="hautonomas" name="hautonomas" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Totales</label><br />
                                                        <input type="text" id="htotales" name="htotales" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                    </div>
                                                </FormGroup>
                                            </ModalBody>
                                            <ModalFooter>
                                                <button class="btn btn-outline-primary" onClick={this.modificarAsignatura} > Modificar</button>
                                                <button class="btn btn-outline-primary" onClick={this.ocultarModalAsignatura}>Salir</button>
                                            </ModalFooter>
                                        </Modal>
                                    </div>
                                    <div class="table-responsive">
                                        <Modal isOpen={this.state.modalIngresarAsignatura} >
                                            <ModalHeader>
                                                Asignatura
                                            </ModalHeader>
                                            <ModalBody>
                                                <FormGroup>
                                                    <div className="mb-3 centrar">
                                                        <input name="id" id="idIng" type="hidden" onChange={this.handleChange} ></input>
                                                        <label className="form-label">Código</label><br />
                                                        <input type="text" id="codigoIng" name="codigoIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Nombre</label><br />
                                                        <input type="text" id="descripcionIng" name="descripcionIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Carrera</label><br />
                                                        <select className="form-select" id="idCarreraIng" name="idCarreraIng" value={this.state.value} onChange={this.handleChangeDevolverIdCarreraIng} aria-label="Default select example">
                                                            <option value="undefined">Seleccione una carrera</option>
                                                            {this.state.carrera.map(person => <option key={person.id_carrera} value={person.id_carrera} > {person.descripcion_carrera}</option>)}
                                                        </select>
                                                        <label className="form-label">Ciclo</label><br />
                                                        <select className="form-select" name="idCicloIng" id="idCicloIng" value={this.state.value} onChange={this.handleChangeDevolverIdCiclo} aria-label="Default select example">
                                                            <option value="undefined">Seleccione un ciclo:</option>
                                                            {this.state.listanivelesIng.map(consola => <option key={consola.codnivel} value={consola.codnivel} > {consola.codnivel}</option>)}
                                                        </select>
                                                        <label className="form-label">Creditos</label><br />
                                                        <input type="text" id="creditosIng" name="creditosIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Docencia</label><br />
                                                        <input type="text" id="hdocenciaIng" name="hdocenciaIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Prácticas</label><br />
                                                        <input type="text" id="hpracticasIng" name="hpracticasIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Autónomas</label><br />
                                                        <input type="text" id="hautonomasIng" name="hautonomasIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                        <label className="form-label">Horas Totales</label><br />
                                                        <input type="text" id="htotalesIng" name="htotalesIng" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                    </div>
                                                </FormGroup>
                                            </ModalBody>
                                            <ModalFooter>
                                                <button class="btn btn-outline-primary" onClick={this.ingresarAsignatura} > Ingresar</button>
                                                <button class="btn btn-outline-primary" onClick={this.ocultarModalIngresarAsignatura}>Salir</button>
                                            </ModalFooter>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

