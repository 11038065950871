/*import axios from 'axios'
import React, { Component } from 'react'*/
import React, { Component } from 'react'
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, ListGroupItemText } from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NavbarLog } from '../../navbar/NavbarLog';
//import "./Carrera.css";
import { ApiUrl } from '../../services/ApiRest';
import swal from 'sweetalert';

const urlListarEstUnSoloPago = ApiUrl + "ListarEstUnSoloPago" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const baseUrlPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModEstadPagUnoTotal = ApiUrl + "modestadpagunototal" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const rutaimagen = ApiUrl + "mostarrutacomprobpensmes" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlEliminarPagoTColegAdm = ApiUrl + "elimpagtotcoleg" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";


let listaauxiliar = [];
const itemsPerPage = 10;

export default class ColegiaturasEstColegiaturaUnPagoAdm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            periodos: [],
            periodosId: [],
            periodoId: '',
            EstudianteBackup: [],
            disabledaprorechvicerr: true,
            periodo_seleccionado: '',
            estado: '',
            detalle: '',

            //nuevas variables
            nombresEstudiante: "",
            cedulaEstudianteSelect: "",
            numnivelescarr: '',
            periodosId: [],
            listaniveles: [],
            listaestudiantes: [],
            listacuotasestudiante: [],
            modalReportEstColegTotal: false,
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables del programador2
            form1: {

            },
            form: {
                id_pension_abono_mes: '',
            },
            cuotascolegiaturam: [],

            disabledformpag: true,
            disabledtransfe: true,
            disabledtotalbott: true,
            enunciadonumdeposit: "Número de depósito(número de papeleta11111 o referencia)",
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalReportEstColegTotal: false });
        }
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeDevolverIdPeriodo = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //var  = this.state.idCarrera;
        this.setState({ periodo_seleccionado: this.state.idPeriodo });
        //devuelve los niveles de  una carrera y los datos registrados de colegiatura para cada uno de ellos
    }

    handleChangeListarEstUnPago = () => {
        this.setState({ listacuotasestudiante: [] });
        //var  = this.state.idCarrera;
        this.setState({ ciclo_seleccionado: this.state.idCiclo });
        axios
            .post(urlListarEstUnSoloPago, {
                idPeriodo: this.state.periodo_seleccionado,
            })
            .then(response => {
                var array = response.data.data;
                var hash = {};
                array = array.filter(function (current) {
                    var exists = !hash[current.id_matricula];
                    hash[current.id_matricula] = true;
                    return exists;
                });
                this.setState({ listaestudiantes: array });
                listaauxiliar = this.state.listaestudiantes;
                var cantidadestud = listaauxiliar.length;
                this.setState({ cantidadestud });
                //BÚSQUEDA
                const EstudianteBackup = this.state.listaestudiantes;

                this.setState((state) => {
                    return { EstudianteBackup: EstudianteBackup }
                });

                //PAGINACION
                this.setState({ itemOffset: 0 });
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });

                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });

                //FIN DE LA PAGINACION
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }

    componentDidMount = async (e) => {
        //listar todos los periodos
        axios
            .get(baseUrlPeriodos, {})
            .then(response => {
                const listaperiodos = response.data.data;
                const cant = listaperiodos.length;
                if (cant != 0) {
                    this.setState({ periodosId: listaperiodos });
                }
            })
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }
    //FIN DE PAGINACION

    ocultarModalReportAbonosCuota = () => {
        this.setState({ modalReportEstColegTotal: false });
    }

    mostrarModalPagoColegituraMes = (registro) => {
        axios.post(rutaimagen, {

            nombrecomprobante: "colegiaturas/" + registro.comprobante_penst,
        })
            .then(res => {
                const ruta = res.data.data;
                this.setState({ ruta });
            })
        $(function () {
            $("#numdeposito").val(registro.num_deposito_penst);
            $("#fechadeposito").val(registro.fecha_deposit_penst);
            $("#cantidadPago").val(registro.cantidad_penst);
            $("#cuentadebitotransferencia").val(registro.cuenta_deb_transf_penst);
            $("#nomdueniocuentdebittransf").val(registro.nom_dueno_cuenta_transf_penst);
            $("#bancobeneficario").val(registro.nombre_banc_benef_penst);
            $("#numbancobeneficario").val(registro.cuenta_benef_penst);
            $("#idTipoTransaccion").val(registro.tipo_deposit_penst);
            $("#detalle").val(registro.detalle_penst);

        });
        this.setState({ modalReportEstColegTotal: true, form: registro });
        this.setState({ disabledformpagd: this.state.disabledformpag });
        this.setState({ disabledct: this.state.disabledtransfe });
        this.setState({ disabledtotal: this.state.disabledtotalbott });

        this.setState({ numdeposito: registro.num_deposito_penst });
        this.setState({ fechadeposito: registro.fecha_deposit_penst });
        this.setState({ cantidadPago: registro.cantidad_penst });
        this.setState({ cuentadebitotransferencia: registro.cuenta_deb_transf_penst });
        this.setState({ nomdueniocuentdebittransf: registro.nom_dueno_cuenta_transf_penst });
        this.setState({ bancobeneficario: registro.nombre_banc_benef_penst });
        this.setState({ numbancobeneficario: registro.cuenta_benef_penst });
        this.setState({ idTipoTransaccion: registro.tipo_deposit_penst });
        this.setState({ detalle: registro.detalle_penst });

        if (registro.tipo_deposito_pens == 1) {
            this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });
        }
        else {
            if (registro.tipo_deposito_pens == 2) {
                this.setState({ enunciadonumdeposit: "Número de comprobante" });

            }
            else {
                this.setState({ enunciadonumdeposit: "Número de depósito(número de papeleta o referencia)" });

            }
        }
    }

    //metodo de validar deposito de la abono de matricula
    aprobRechazComprobPensionM = (datos, estad) => {
        var estadoaux = "";
        if (estad == 2) {
            estadoaux = "aprobar"
        }
        else {
            estadoaux = "rechazar"
        }
        var combmsmalert = "¿Está seguro que desea " + estadoaux + " el pago?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlModEstadPagUnoTotal, {
                            idPagoColegTotal: datos.id_pension_total,
                            estadPensTotal: estad,
                            detalleNoved: this.state.detalle,
                            numDepositPenT: this.state.numdeposito
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.handleChangeListarEstUnPago();
                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalPagoColegituraMes: false });

                            }
                        })
                        .catch(error => {

                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });


                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }
    //Eliminar un solo pago de colegiatura
    eliminarUnSoloPagColeg = (datos) => {
        var combmsmalert = "¿Está seguro que desea eliminar el pago de colegiatura con id=" + datos.id_pension_total + "?"
        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios
                        .post(urlEliminarPagoTColegAdm, {
                            idPagoTColeg: datos.id_pension_total,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos eliminados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                axios
                                    .post(urlListarEstUnSoloPago, {
                                        idPeriodo: this.state.periodo_seleccionado,
                                    })
                                    .then(response => {
                                        var array = response.data.data;
                                        var hash = {};
                                        array = array.filter(function (current) {
                                            var exists = !hash[current.id_matricula];
                                            hash[current.id_matricula] = true;
                                            return exists;
                                        });
                                        this.setState({ listaestudiantes: array });
                                        listaauxiliar = this.state.listaestudiantes;
                                        var cantidadestud = listaauxiliar.length;
                                        this.setState({ cantidadestud });
                                        //BÚSQUEDA
                                        const EstudianteBackup = this.state.listaestudiantes;
                                        this.setState((state) => {
                                            return { EstudianteBackup: EstudianteBackup }
                                        });
                                        //PAGINACION
                                        this.setState({ itemOffset: 0 });
                                        const endOffset = this.state.itemOffset + itemsPerPage;
                                        this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                                        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                                        this.setState((state) => {
                                            return { itemOffset: state.itemOffset + endOffset }
                                        });
                                        //FIN DE LA PAGINACION
                                    })
                                    .catch(error => {
                                        this.setState({ error: "No existe conexión con el servidor" });
                                        swal({
                                            text: "No existe conexión con el servidor",
                                            icon: "error",
                                            button: "Aceptar",
                                        });
                                    });

                            } else {
                                swal({
                                    text: "Datos no eliminados",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalReportAbonosCuotaEst: false });
                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",
                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    render() {
        return (
            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.estado}</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b> Reporte de Estudiantes con Colegiaturas (Un solo pago)</b>
                    </div>
                    <div className="card-body">
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-3 " >
                                <div className="mb-3">
                                    <label className="form-label"><b> <i className="fa fa-calendar"></i> Período</b></label>
                                    <select className="form-select" name="idPeriodo" value={this.state.value} onChange={this.handleChangeDevolverIdPeriodo} aria-label="Default select example">
                                        <option value="undefined">Seleccione el periodo</option>
                                        {this.state.periodosId.map(periodo => <option key={periodo.id_periodo_academico} value={periodo.id_periodo_academico} > {periodo.descripcion_periodo_academico}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-1 col-xl-2 ">
                                <div className="mb-3">
                                    <label className="form-label"><b></b></label><br />
                                    <input class="btn back-istmas centrar" type="button" value="Consultar" onClick={this.handleChangeListarEstUnPago} />
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table className="table table-bordered" id="tablaasignaturas" name="tablaasignaturas" width="100%" >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Cédula</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Primer Nombre</th>
                                        <th>Segundo Nombre</th>
                                        <th>Carrera</th>
                                        <th>Ciclo</th>
                                        <th>Valor Total</th>
                                        <th>Estado</th>
                                        <th>Seleccionar</th>
                                        <th>Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentItems && this.state.currentItems.map((consola) => (
                                        <tr key={consola.id_matricula}>
                                            <td class="idpagot ">{consola.id_pension_total}</td>
                                            <td class="id oculto">{consola.id_matricula}</td>
                                            <td class="ciclo oculto">{consola.ciclo_nivel}</td>
                                            <td class="carrera oculto">{consola.carrera_id}</td>
                                            <td class="id_est oculto">{consola.id_estudiante}</td>
                                            <td class="codigo">{consola.numero_identificacion_estudiante}</td>
                                            <td class="primerape">{consola.primer_apellido_estudiante}</td>
                                            <td class="segundoape">{consola.segundo_apellido_estudiante}</td>
                                            <td class="primernom">{consola.primer_nombre_estudiante}</td>
                                            <td class="segundonom">{consola.segundo_nombre_estudiante}</td>
                                            <td class="nom_carrera">{consola.descripcion_carrera}</td>
                                            <td class="nivel">{consola.ciclo_nivel}</td>
                                            <td class="valort">{(consola.porcentaje_arancel != "" ? consola.valor_total * (consola.porcentaje_arancel / 100) + " (" + consola.valor_total + " - " + consola.porcentaje_arancel + "% Beca)" : consola.valor_total - (consola.valor_total * 0.1) + " (" + consola.valor_total + " - " + "10% Desc)")}</td>
                                            <td>
                                                {(() => {
                                                    switch (consola.estado_penst) {
                                                        case 1: return <h6><b> <span class="mensajeadvertencia"> En proceso Vicerrectorado</span> </b></h6>;
                                                        case 2: return <h6><b> <span> Aprobado</span> </b></h6>;
                                                        case 3: return <h6><b> <span> Rechazado</span> </b></h6>;
                                                        case 4: return <h6><b> <span class="mensajealerta"> Aprobado Vicerrectorado</span> </b></h6>;
                                                        case 5: return <h6><b> <span class="mensajealerta"> Rechazado Vicerrectorado</span> </b></h6>;
                                                        default: return ""
                                                    }
                                                })()}
                                            </td>
                                            <td><button onClick={() => this.mostrarModalPagoColegituraMes(consola)} > <i className=" fas fa-clipboard-list" ></i></button></td>
                                            <td class="eliminar">
                                                <button onClick={() => this.eliminarUnSoloPagColeg(consola)} ><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <ReactPaginate
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-body centrar " style={{ width: "100%" }}>

                                        <div class="table-responsive">
                                            {/*Modal de pago total de colegiatura*/}
                                            <Modal isOpen={this.state.modalReportEstColegTotal} >
                                                <ModalHeader>
                                                    <h5>Detalle</h5>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <FormGroup>
                                                        <input name="imagen" type="image" src={this.state.ruta} align="center" alt="Comprobante" width='100%'
                                                            height='100%' ></input>
                                                        <br />
                                                        <br />
                                                        <h5>Detalle</h5>
                                                        <div className="mb-3 centrar">
                                                            <input name="numcuota" id="numcuota" type="hidden" value={this.state.form.id} onChange={this.handleChange} ></input>
                                                            <input name="nombremescuota" id="nombremescuota" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <input name="fechmaxpagocoleg" id="fechmaxpagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <input name="recargopagocoleg" id="recargopagocoleg" type="hidden" value={this.state.form.nombremes} onChange={this.handleChange}></input>
                                                            <label className="form-label"><h5>{this.state.form.nombremes}</h5></label>
                                                            <br />
                                                            <label className="form-label">Seleccionar la forma de pago</label>
                                                            <select className="form-select" id="idTipoTransaccion" name="idTipoTransaccion" value={this.state.value} onChange={this.handleChangeFormaPagoEfect} aria-label="Default select example" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required>
                                                                <option value="">Seleccione</option>
                                                                <option value="1">Depósito</option>
                                                                <option value="2">Transferencia</option>
                                                                <option value="3">Efectivo</option>
                                                            </select>
                                                            <label className="form-label">{this.state.enunciadonumdeposit}</label><br />
                                                            <input type="text" id="numdeposito" name="numdeposito" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                            <label className="form-label">Fecha de depósito</label><br />
                                                            <input type="date" id="fechadeposito" name="fechadeposito" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Cantidad  de pago</label><br />
                                                            <div class="input-group mb-3"><div class="input-group-prepend"><span class="input-group-text">$</span></div><input type="text" id="cantidadPago" name="cantidadPago" onChange={this.handleChange} className="form-control" placeholder="0.00" disabled={(this.state.disabledtotal) ? "disabled" : ""} autocomplete="off" required /></div>
                                                            <label className="form-label">#Cuenta que se debitó la transferencia</label><br />
                                                            <input type="text" id="cuentadebitotransferencia" name="cuentadebitotransferencia" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Nombre del dueño de la cuenta que se debitó la transferencia</label><br />
                                                            <input type="text" id="nomdueniocuentdebittransf" name="nomdueniocuentdebittransf" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledct) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Banco Beneficiario</label><br />
                                                            <select className="form-select" id="bancobeneficario" name="bancobeneficario" value={this.state.value} onChange={this.handleChangeCuentaBeneficiario} disabled={(this.state.disabledct) ? "disabled" : ""} aria-label="Default select example">
                                                                <option value="1">PACIFICO</option>
                                                                <option value="2">PICHINCHA-3257594104</option>
                                                                <option value="3">PICHINCHA-2100271381</option>
                                                                <option value="4">AUSTRO</option>
                                                            </select>
                                                            <label className="form-label">#Cuenta del Banco Beneficiario</label><br />
                                                            <input type="text" id="numbancobeneficario" name="numbancobeneficario" onChange={this.handleChange} className="form-control" placeholder="" disabled={(this.state.disabledformpagd) ? "disabled" : ""} autocomplete="off" required />
                                                            <label className="form-label">Detalle</label><br />
                                                            <input className="form-control" type="text" id="detalle" name="detalle" onChange={this.handleChange} autocomplete="off" required />
                                                        </div>
                                                    </FormGroup>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button class="btn btn-outline-primary" onClick={() => this.aprobRechazComprobPensionM(this.state.form, 2)} > Aprobar</button>
                                                    <button class="btn btn-outline-primary" onClick={() => this.aprobRechazComprobPensionM(this.state.form, 3)} > Rechazar</button>
                                                    <button class="btn btn-outline-primary" onClick={this.ocultarModalReportAbonosCuota}>Salir</button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

